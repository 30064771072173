import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux" //Token
import { useSolicitudesContext } from "./SolicitudesContext"

import { toast } from "react-toastify"
import { startActualizacion } from "../../../app/actualizar"
//Funciones Api
import { postCajaAhorro } from "../../../Services/Api_solicitudes"

//Material UI
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"

//Componentes
import { BootstrapDialog, CustomDialogContent } from "../../Common/Dialog"
import { InputGrid2, InputSelectGrid2 } from "../../Common/Inputs2"
import { selectMes } from "./CajaAhorro/BotonosCajaAhorro"
import { Boton, Texto } from "./Componentes"

const montominimo = 200

function FormularioCajaAhorro({ open, setOpen = () => {} }) {
	const token = useSelector((state) => state.user.token)
	const [inputErro, setInputError] = useState(false)
	const [value, setValue] = useState({
		deduccion: 0,
		beneficiario: "",
		fecha_ingreso: "",
		check: false,
	})

	return (
		<BootstrapDialog
			open={open}
			onClose={() => {
				setOpen(false)
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CustomDialogContent sx={{ overflowY: "hidden" }}>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					spacing={2}
				>
					<Texto
						label={"Solicitud Caja De Ahorro"}
						size={12}
					/>

					<InputGrid2
						label={"$ Deducción Quincenal"}
						type="number"
						propsGrid={{ xs: 12 }}
						error={inputErro ? !(value["deduccion"] >= 150) : false}
						onChange={(event) => {
							setValue({ ...value, deduccion: event.target.value })
						}}
					/>
					<Grid
						xs={12}
						// md={6}
					>
						<Typography variant="h5">{`Deducción Mensual : $${(
							value.deduccion * 2
						).toLocaleString("en-US")}`}</Typography>
					</Grid>
					<InputSelectGrid2
						label="Fecha De Ingreso"
						data={selectMes(6)}
						dataLabel="option"
						dataID="fecha"
						error={inputErro ? !!value["fecha_ingreso"] : false}
						option={value.fecha_ingreso}
						onChange={(event) => {
							setValue({ ...value, fecha_ingreso: event.target.value })
						}}
						GridProps={{ xs: 12 }}
					/>
					<Grid xs={12}>
						<InputCheck
							check={value["check"]}
							error={inputErro ? !value["check"] : false}
							funcion={(event) => {
								setValue({
									...value,
									check: event.target.checked,
								})
							}}
							token={token}
							value={value}
							setInputError={setInputError}
							setOpen={setOpen}
						/>
					</Grid>
				</Grid>
			</CustomDialogContent>
		</BootstrapDialog>
	)
}

export default FormularioCajaAhorro

export function InputCheck({
	check,
	error = false,
	funcion = () => {},
	token,
	value,
	setInputError,
	setOpen = () => {},
}) {
	const dispatch = useDispatch()
	const { colaboradorID, actualizar, setActualizar } = useSolicitudesContext()
	function enviarDatos() {
		if (!!value["deduccion"] && !!value["fecha_ingreso"]) {
			if (value["check"]) {
				if (value["deduccion"] >= montominimo) {
					setOpen(false)
					postCajaAhorro(
						token,
						value,
						{
							colaboradorID: colaboradorID,
						},
						actualizar,
						setActualizar
					).then((x) => {
						dispatch(startActualizacion())
					})
				} else
					toast.error(
						`El monto ahorrado tendrá un mínimo de ${
							montominimo * 2
						}.00 (Trecientos pesos 00/100, m.n.) mensuales`.toUpperCase()
					)
			} else {
				toast.error("Acepta términos y condiciones ".toUpperCase())
				setInputError(true)
			}
		} else {
			setInputError(true)
			toast.error("Error En Datos".toUpperCase())
		}
	}

	return (
		<Box
			// component="section"
			sx={{
				margin: 2,
				//overflowY: "scroll",
				// position: "absolute",
				top: 0,
				bottom: 0,
			}}
			// sx={{ overflowY: "scroll" }}
		>
			<Typography paragraph>
				Al hacer tu solicitud de Caja de Ahorro, aceptas los términos y condiciones de la caja de ahorro. Para consultarlos, puedes hacerlo en el apartado de documentación Lubtrac, en la sección de Estatutos de Caja de Ahorro.
			</Typography>

			<FormControl error={error}>
				<FormLabel component="legend"></FormLabel>
				<FormControlLabel
					checked={check}
					onChange={funcion}
					required
					control={<Checkbox />}
					label={`Acepto los términos y condiciones`}
				/>
			</FormControl>
			<Boton
				label={"Enviar"}
				size={12}
				sizeB={10}
				propocito={() => {
					enviarDatos(token, value, setInputError, setOpen, dispatch)
				}}
			/>
		</Box>
	)
}
