import { perfilDetailsGet } from "#api/Api"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import CastForEducationIcon from "@mui/icons-material/CastForEducation"
import DeleteIcon from "@mui/icons-material/Delete"
import LaptopIcon from "@mui/icons-material/Laptop"
import SchoolIcon from "@mui/icons-material/School"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import TrackChangesIcon from "@mui/icons-material/TrackChanges"
import TranslateIcon from "@mui/icons-material/Translate"
import WorkIcon from "@mui/icons-material/Work"
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
} from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { VacanteHerramientas } from "Components/PerfilesPuesto/formulario/VacanteHerramientas"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
export default function FormDetails({
	perfilId,
	vacante_details,
	setVacante_details,
}) {
	const [perfilDetails, setPerfilDetails] = useState({})
	const [loading, setLoading] = useState(true)
	const [tools, setTools] = useState([])
	useEffect(() => {
		const controller = new AbortController()
		setLoading(true)
		const success = (response) => {
			setPerfilDetails(response.data)
			setLoading(false)
		}
		if (!perfilId) return
		perfilDetailsGet(perfilId, {
			all: true,
			signal: controller.signal,
			success,
		})
		return () => {
			controller.abort()
		}
	}, [perfilId])

	useEffect(() => {
		setVacante_details((data) => ({
			...data,
			list_tools: tools?.list_tools || [],
		}))
	}, [setVacante_details, tools])

	const competencias = [
		{
			nameList: "list_educacion",
			value: "Educación",
			title: "Educación y Formación",
			Icon: SchoolIcon,
			grid: { xs: 12, md: 6 },
		},
		{
			nameList: "list_experiencia",
			value: "Experiencia",
			title: "Experiencia",
			Icon: WorkIcon,
			grid: { xs: 12, md: 6 },
		},
		{
			nameList: "list_adicionales",
			value: "Adicionales",
			title: "Adicionales",
			Icon: CastForEducationIcon,
			grid: { xs: 12, md: 6 },
		},
		{
			nameList: "list_idiomas",
			value: "Idiomas",
			title: "Idiomas",
			Icon: TranslateIcon,
			grid: { xs: 12, md: 6 },
		},
		{
			nameList: "list_software",
			value: "Software",
			title: "Software",
			Icon: LaptopIcon,
			grid: { xs: 12, md: 6 },
		},
		{
			nameList: "list_training",
			value: "Capacitación",
			title: "Capacitación",
			Icon: ThumbUpIcon,
			grid: { xs: 12, md: 6 },
		},
		{
			nameList: "list_responsabilidades",
			value: "Responsabilidades",
			title: "Responsabilidades",
			Icon: TrackChangesIcon,
			grid: { xs: 12, md: 12 },
		},
	]

	let itemsCompetencias = competencias.map((competencia) => (
		<Grid2
			{...competencia.grid}
			key={competencia.value}
		>
			<Competencia
				{...competencia}
				competencias={perfilDetails?.perfil_competencia || []}
				{...{ vacante_details, setVacante_details }}
			/>
		</Grid2>
	))

	const herramientas = (
		<Grid2
			item
			md={6}
		>
			<VacanteHerramientas
				setDataSend={setTools}
				dataPerfil={perfilDetails}
				expanded="panelHerramientas"
				handleChangePanel={() => {}}
			/>
		</Grid2>
	)
	itemsCompetencias.splice(-1, 0, herramientas)

	if (!perfilId) return null
	if (loading) return <CircularSkeleton size={80} />
	return (
		<Box>
			<Grid2
				container
				spacing={2}
			>
				{itemsCompetencias}
			</Grid2>
		</Box>
	)
}

const Competencia = ({
	value,
	title,
	competencias,
	nameList,
	vacante_details,
	setVacante_details,
	Icon = TrackChangesIcon,
}) => {
	const [text, setText] = useState("")
	const [list, setList] = useState([])

	useEffect(() => {
		setVacante_details((data) => ({ ...data, [nameList]: [] }))
		function dataType(data, nombre, nameList, setData) {
			const mapType = (type) => arrayType.push(type.descripcion)
			const filterType = (competencia) => competencia.tipo === nombre
			let arrayType = []
			data.filter(filterType).map(mapType)
			setVacante_details((data) => ({ ...data, [nameList]: arrayType }))
			setData(arrayType)
		}
		dataType(competencias, value, nameList, setList)
	}, [competencias, nameList, setVacante_details, value])

	const addItem = () => {
		if (text.trim().length !== 0) {
			setList([text, ...list])
			setVacante_details((data) => ({
				...data,
				[nameList]: [...data[nameList], text],
			}))
			setText("")
		} else {
			toast.error(`${title} no puede ser vació`)
		}
	}

	const removeItem = (indexList) => {
		let items = vacante_details[nameList]
		items.splice(indexList, 1)

		setList(items)
		setVacante_details((data) => ({
			...data,
			[nameList]: items,
		}))
	}
	return (
		<Box
			width={"95%"}
			paddingBottom={4}
		>
			<Grid2
				container
				alignItems={"center"}
			>
				<Grid2 md={11}>
					<Box
						component="b"
						sx={{ fontSize: 18, color: "#555" }}
					>
						{title}
					</Box>
					<TextField
						onChange={(event) => setText(event.target.value)}
						value={text}
						fullWidth
					/>
				</Grid2>
				<Grid2 md={1}>
					<IconButton
						aria-label="delete"
						sx={{ mt: 4 }}
						onClick={addItem}
					>
						<AddCircleIcon
							color="success"
							fontSize="large"
						/>
					</IconButton>
				</Grid2>

				<Grid2
					item
					md={12}
					sx={{ pt: "0px" }}
				>
					<List dense={false}>
						{vacante_details[nameList]?.map((item, index) => {
							return (
								<Box
									key={title + index}
									paddingTop={2}
								>
									<ListItem
										secondaryAction={
											<IconButton
												edge="end"
												onClick={() => removeItem(index)}
											>
												<DeleteIcon color="error" />
											</IconButton>
										}
									>
										<ListItemIcon>
											<Icon color="primary" />
										</ListItemIcon>
										<ListItemText primary={item} />
									</ListItem>
									<Divider />
								</Box>
							)
						})}
					</List>
				</Grid2>
			</Grid2>
		</Box>
	)
}
