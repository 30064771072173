import {
	apiDeleteGeneral,
	apiPostGeneral,
	apiPutGeneral,
	getApiGeneral,
} from "./Api_Call"
import { api_rh_post, api_rh_put, apiDeleteRH, apiGetRH } from "./API_RH"
const URL_API = process.env.REACT_APP_SERVER_URL + "rh/colaboradores"
const URL_API_DATOS =
	process.env.REACT_APP_SERVER_URL + "rh/colaboradores/datos/"

/**
 * @typedef {'COLABORADOR_EDIT'|} URL_COLABORADOR
 */
const COLABORADOR_EDIT = `${URL_API}/edit`
const COLABORADOR_DOCUMENTOS = `${URL_API}/documentos`
const COLABORADOR_VERIFICATION = `${URL_API}/verification`

const FAMILIARES = (id) => `${URL_API}/${id}/familiares`

const COLABORADOR_CONTACTOS = `${URL_API_DATOS}contacto`
const COLABORADOR_BENEFICIARIOS = `${URL_API_DATOS}beneficiario`
const COLABORADOR_ESCOLARES = `${URL_API_DATOS}escolares`
const COLABORADOR_CURSOS_EXTERNOS = `${URL_API_DATOS}cursos_externos`
const COLABORADOR_TRABAJO = `${URL_API_DATOS}trabajo`
const COLABORADOR_TRAYECTORIA = `${URL_API_DATOS}trayectoria`
const COLABORADOR_CAR = `${URL_API_DATOS}car`
const COLABORADOR_PDF = `${URL_API_DATOS}concentrado`

/**
 *
 * @param {URL_COLABORADOR} URL_COLABORADOR
 * @param {*} token
 * @param {*} setDb
 * @param {*} filtro
 * @param {MessageOptions} message
 * @returns
 */
export function apiPutColaboradorGenerales(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_EDIT, token, data, params, message)
}
//Familiares

export function apiPostColaboradorFamiliar(id, data, params, message) {
	return api_rh_post(FAMILIARES(id), data, { params, message })
}
export function apiPutColaboradorFamiliar(id, data, params, message) {
	return api_rh_put(FAMILIARES(id), data, { params, message })
}
export function apiGetColaboradorFamiliar(id, setDB, params) {
	return apiGetRH(FAMILIARES(id), {
		success: setDB,
		params,
		failMessage: "Error en la consulta de familiares",
	})
}
export function apiDeleteColaboradorFamiliar(id, params) {
	return apiDeleteRH(FAMILIARES(id), { params })
}

//Contacto
export function apiPostColaboradorContacto(token, data, params, message) {
	return apiPostGeneral(COLABORADOR_CONTACTOS, token, data, params, message)
}
export function apiPutColaboradorContacto(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_CONTACTOS, token, data, params, message)
}
export function apiGetColaboradorContacto(token, setDB, params) {
	return getApiGeneral(COLABORADOR_CONTACTOS, token, setDB, params)
}
export function apiDeleteColaboradorContacto(token, params) {
	return apiDeleteGeneral(COLABORADOR_CONTACTOS, token, params)
}

//Beneficiario
export function apiPostColaboradorBeneficiario(token, data, params, message) {
	return apiPostGeneral(COLABORADOR_BENEFICIARIOS, token, data, params, message)
}
export function apiPutColaboradorBeneficiario(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_BENEFICIARIOS, token, data, params, message)
}
export function apiGetColaboradorBeneficiario(token, setDB, params) {
	return getApiGeneral(COLABORADOR_BENEFICIARIOS, token, setDB, params)
}
export function apiDeleteColaboradorBeneficiario(token, params) {
	return apiDeleteGeneral(COLABORADOR_BENEFICIARIOS, token, params)
}
//Beneficiario
export function apiPostColaboradorEscolares(token, data, params, message) {
	return apiPostGeneral(COLABORADOR_ESCOLARES, token, data, params, message)
}
export function apiPutColaboradorEscolares(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_ESCOLARES, token, data, params, message)
}
export function apiGetColaboradorEscolares(token, setDB, params) {
	return getApiGeneral(COLABORADOR_ESCOLARES, token, setDB, params)
}
export function apiDeleteColaboradorEscolares(token, params) {
	return apiDeleteGeneral(COLABORADOR_ESCOLARES, token, params)
}
//Cursos Externos
export function apiPostCursosExternos(token, data, params, message) {
	return apiPostGeneral(
		COLABORADOR_CURSOS_EXTERNOS,
		token,
		data,
		params,
		message,
	)
}
export function apiPutCursosExternos(token, data, params, message) {
	return apiPutGeneral(
		COLABORADOR_CURSOS_EXTERNOS,
		token,
		data,
		params,
		message,
	)
}
export function apiGetCursosExternos(token, setDB, params) {
	return getApiGeneral(COLABORADOR_CURSOS_EXTERNOS, token, setDB, params)
}
export function apiDeleteCursosExternos(token, params) {
	return apiDeleteGeneral(COLABORADOR_CURSOS_EXTERNOS, token, params)
}
//Cursos Trabajo
export function apiPostTrabajo(token, data, params, message) {
	return apiPostGeneral(COLABORADOR_TRABAJO, token, data, params, message)
}
export function apiPutTrabajo(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_TRABAJO, token, data, params, message)
}
export function apiGetTrabajo(token, setDB, params) {
	return getApiGeneral(COLABORADOR_TRABAJO, token, setDB, params)
}
export function apiDeleteTrabajo(token, params) {
	return apiDeleteGeneral(COLABORADOR_TRABAJO, token, params)
}

//Cursos Trayectoria
export function apiPostTrayectoria(token, data, params, message) {
	return apiPostGeneral(COLABORADOR_TRAYECTORIA, token, data, params, message)
}
export function apiPutTrayectoria(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_TRAYECTORIA, token, data, params, message)
}
export function apiGetTrayectoria(token, setDB, params) {
	return getApiGeneral(COLABORADOR_TRAYECTORIA, token, setDB, params)
}
export function apiDeleteTrayectoria(token, params) {
	return apiDeleteGeneral(COLABORADOR_TRAYECTORIA, token, params)
}

//Cursos Car
export function apiPostCar(token, data, params, message) {
	return apiPostGeneral(COLABORADOR_CAR, token, data, params, message)
}
export function apiPutCar(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_CAR, token, data, params, message)
}
export function apiGetCar(token, setDB, params) {
	return getApiGeneral(COLABORADOR_CAR, token, setDB, params)
}
export function apiDeleteCar(token, params) {
	return apiDeleteGeneral(COLABORADOR_CAR, token, params)
}

//Cursos Documentos
export function apiPostDocumentos(token, data, params, message) {
	return apiPostGeneral(COLABORADOR_DOCUMENTOS, token, data, params, message)
}
export function apiPutDocumentos(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_DOCUMENTOS, token, data, params, message)
}
export function apiGetDocumentos(token, setDB, params) {
	return getApiGeneral(COLABORADOR_DOCUMENTOS, token, setDB, params)
}
export function apiDeleteDocumentos(token, params) {
	return apiDeleteGeneral(COLABORADOR_DOCUMENTOS, token, params)
}

//PDF
export function apiGetDocumentosPDF(token, setDB, params) {
	return getApiGeneral(COLABORADOR_PDF, token, setDB, params)
}

export function apiPutVerification(token, data, params, message) {
	return apiPutGeneral(COLABORADOR_VERIFICATION, token, data, params, message)
}
