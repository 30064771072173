import {
	useState,
	createContext,
	useContext,
	useMemo,
	useCallback,
} from "react"
const FondoContext = createContext()

export function FondoProvider({ children }) {
	const [actualizar, setActualizar] = useState(false)
	const [fondo_id, setFondo_id] = useState(null)
	const [openDialog, setOpenDialog] = useState({ open: false })
	const [openDialogPDF, setOpenDialogPDF] = useState(false)
	const [sendData, setSendData] = useState({})
	const [Contenido, setContenido] = useState(() => <></>)

	const newSendData = useCallback(
		(newData) => {
			setSendData({ ...sendData, ...newData })
		},
		[sendData]
	)
	const datos = useMemo(
		() => ({
			//actualiza contenido
			actualizar,
			setActualizar,
			//id Fondo Fijo
			fondo_id,
			setFondo_id,
			// Controlador del dialog
			openDialog,
			setOpenDialog,

			openDialogPDF,
			setOpenDialogPDF,
			//datos a enviar
			sendData,
			setSendData,
			newSendData,
			updateData: () => {
				setActualizar(!actualizar)
			},
			Contenido,
			newContenido: (contenido) => setContenido(() => contenido),
		}),
		[
			actualizar,
			fondo_id,
			openDialog,
			openDialogPDF,
			sendData,
			newSendData,
			Contenido,
		]
	)
	return <FondoContext.Provider value={datos}>{children}</FondoContext.Provider>
}
/**
 * Hook personalizado que proporciona acceso al contexto del fondo.
 * @returns {Object} - Objeto que contiene las funciones y valores del contexto del fondo.
 * @property {boolean} actualizar - Estado que indica si se deben actualizar los datos del fondo.
 * @property {function} setActualizar - Función para establecer el estado de actualización del fondo.
 * @property {string} fondo_id - Identificador del fondo.
 * @property {function} setFondo_id - Función para establecer el identificador del fondo.
 * @property {object} openDialog - Estado del diálogo.
 * @property {boolean} openDialog.open - Estado que indica si el diálogo está abierto.
 * @property {function} setOpenDialog - Función para establecer el estado del diálogo.
 * @property {boolean} openDialogPDF - Estado que indica si el diálogo PDF está abierto.
 * @property {function} setOpenDialogPDF - Función para establecer el estado del diálogo PDF.
 * @property {object} sendData - Datos a enviar.
 * @property {function} setSendData - Función para establecer los datos a enviar.
 * @property {function} newSendData - Función para actualizar los datos a enviar.
 * @property {function} updateData - Función para forzar la actualización de datos del fondo.
 */
export function useFondoContext() {
	return useContext(FondoContext)
}
