import { Grid, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material/';


export const DataItem = ({label, value,size=3, fontSizeLabel=16, fontSizeValue=16, fontColor= "#555", fontBold="" }) => {
    return <Grid item md={size} sx={{ p: 1 }} xs={12} sm={12}> 
                <Typography sx={{fontSize: fontSizeLabel, fontWeight:'bold',color: "#555"}} >  {label}</Typography>
                <Typography sx={{fontSize:fontSizeValue, fontWeight: fontBold, color: fontColor}} > {value}</Typography>
            </Grid>
}

export const ListCompet = ({listItem, IconList, listName, size = 6}) => {
    if (listItem == null)
        return<></>
    return <Grid item  sx={{fontWeight:'bold',fontSize:15, p:5,pt:0}} md={size} > 
                <Typography sx={{fontSize:18,fontWeight:'bold',color: "#555"}}> {listName} </Typography>
                <List dense={false}>
                    {listItem.filter(item => item.tipo == listName).map((competencia) => 
                        <ListItem key={listName+''+competencia.id} sx={{p:.5}}>
                            <ListItemIcon sx={{minWidth:35}}> <IconList sx={{ color:'green'}}/> </ListItemIcon>
                            <ListItemText primary={competencia.descripcion} />
                        </ListItem>
                    )}
                </List>
            </Grid>
}