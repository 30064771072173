import {useEffect, useState} from 'react'
import MUIDataTable from "mui-datatables";
import { Grid } from '@mui/material';
import UniformesTotales from '../Components/Colaboradores/Uniforme/UniformesTotales';
import UniformeColaborador from '../Components/Colaboradores/Uniforme/UniformeColaborador';
import { useSelector, useDispatch } from 'react-redux';
import { totalesUniformes, listUniformesColaborador} from '../Services/Api_uniformes';
import { LoadViewTable } from '../Components/Common/LoadingView';
import { toast } from 'react-toastify';
import { changeTitle } from '../app/pageSlice';
import { PRIMARYCOLOR } from '../Constants/Styles';

const Uniformes = () => {
    const token = useSelector((state) => state.user.token);
    const [listaColaboradores, setListaColaboradores] = useState([]); 
    const [totales, setTotales] = useState({}); 
    const [loading, setLoading] = useState(true); 
    const [viewtotales, setViewTotales] = useState(true); 
    const [dataColaborador, setDataColaborador] = useState(null); 
    const [depto, setDepto] = useState(null); 
    const [reloadData, setReloadData] = useState(false); 
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeTitle('Uniformes'))
        listUniformesColaborador(token).then((response) => {
            var arraycolaboradores = [];
            response.map((item) => {
                var tipo = 'Mobil'
                if (item.depto === 'Ventas Llantas y Urea')
                    tipo = 'Llantas y Urea' 
                else if (item.depto === 'Ventas Molykote')
                    tipo = 'Molykote'
                arraycolaboradores.push([item.id, '('+item.no_empleado+ ') '+item.nombre_colaborador, item.nombre_sucursal,item.depto, tipo, item.status_uniforme == null ? 'Pendiente' : item.status_uniforme]) 
                return item;
            })
            setListaColaboradores(arraycolaboradores);
        }).catch((error) => {
            console.error("Error al obtener el listado de los colaboradores",error);
            toast.error("Ocurrio un error al cargar los totales de uniformes");
        })
         totalesUniformes(token).then((response) => {
            setTotales(response);
        }).catch((error) => {
            console.error("Error al obtener los totales de los uniformes",error);
            toast.error("Ocurrio un error al cargar los totales de uniformes");
        }).finally(() => setLoading(false));
    }, [reloadData]);

    const columns = [
        {name: 'id', label: 'id', options: { display: false, filter: false, sort: true }},
        {
            name: "Colaborador",
            options: { filter: false, sort: true}
        }, {
            name: "Sucursal",
            options: { filter: true, sort: true}
        },{
            name: "Departamento",
            options: { filter: true, sort: true}
        },{
            name: "Tipo",
            options: { filter: true, sort: true}
        } ,{
            name: "Status",
            options: { 
                filter: true, 
                sort: true, 
                customBodyRender: (value) => {
                    if (value === 'Pendiente')
                        return <span style={{color: 'red'}}>Pendiente</span>
                    else
                        return <span style={{color: PRIMARYCOLOR}}>{value}</span>
                }
            }
        }
    ];
    const handleRowClick = (rowData) => {
        setViewTotales(false)
        setDataColaborador({'idColaborador':rowData[0], 'nombre':rowData[1], 'status':rowData[4]})
    }
    const options = {
        selectableRowsHideCheckboxes: true,
        print: false,
        viewColumns: false,
        onRowClick:(rowData)=>handleRowClick(rowData),
        onFilterChange: (columnChanged, filterList) => {
            if (columnChanged !== 'Tipo')
                return
            setDepto(filterList[4][0])
            setTotales(null)
            totalesUniformes(token,filterList[4][0]).then((response) => {
                setTotales(response);
            }).catch((error) => {
                console.error("Error al obtener los totales de los uniformes",error);
                toast.error("Ocurrio un error al cargar los totales de uniformes");
            }).finally(() => setLoading(false));
        }
    };

    if(loading) 
        return <LoadViewTable/>
    return (
        <Grid container spacing={5}>
            <Grid item xs={7}>
                <MUIDataTable
                    data={listaColaboradores}
                    columns={columns}
                    options={options}
                    sx={{ with: 400 }} />
            </Grid>
            <Grid item xs={5}>
                {viewtotales ? <UniformesTotales dataTotales={totales} depto={depto}/> : <UniformeColaborador dataColaborador={dataColaborador} setClose={setViewTotales} reloadData={reloadData} setReloadData={setReloadData}/>}            
            </Grid>
        </Grid>
    )
}

export default Uniformes