import React from "react"
import { useSelector } from "react-redux" //Token
import { useEffect, useState } from "react"
//constantes
import { STATUS_LOAN } from "./constants"
import { TEXTOS_PRESTAMOS } from "./prestamos_componentes/TextosPrestamos"
//API
import { getPrestamos, putPrestamos } from "../../../Services/Api_prestamos"
import { ButtonGrid2 } from "../../Common/Buttons"

//Componentes
import { Texto } from "./Componentes"
import { useSolicitudesContext } from "./SolicitudesContext"
import PrestamosFila from "./prestamos_componentes/PrestamosFila"
import PrestamosTabla from "./prestamos_componentes/PrestamosTabla"
import DialogConfirm from "./prestamos_componentes/prestamosButtonsDialog.jsx/DialogConfirmP"
//Material UI
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Grid from "@mui/material/Unstable_Grid2"
import PrestamosFormulario from "./prestamos_componentes/PrestamosFormulario"
import { SkeletonSquareGrid } from "../../Common/Skeleton"

function Prestamos() {
	const { colaboradorID } = useSolicitudesContext()
	const token = useSelector((state) => state.user.token)
	const actualizar = useSelector((state) => state.actualizar.actualizar)
	const [dbLoan, setDBLoan] = useState({ status: 1, turno: 0 })
	const [loan, setLoan] = useState({ status: 0, turno: 0 })
	const [formulario, setFormulario] = useState(false)
	const [skeleton, setSkeleton] = useState(false)
	const [actualizar2, setActualizar2] = useState(false)
	const [confirm, setConfirm] = useState(false)
	const { TITLE, BUTTON_REQUEST, BUTTON_EXIT, TEXT_EXIT } = TEXTOS_PRESTAMOS

	useEffect(() => {
		setLoan({ status: 0, turno: 0 })
		getPrestamos(
			token,
			setDBLoan,
			{ colaborador: colaboradorID, fin: "" },
			setSkeleton
		)
	}, [token, actualizar, colaboradorID, actualizar2])

	useEffect(() => {
		if (Array.isArray(dbLoan))
			if (dbLoan.length){
				const dataInicio = new Date('2024-09-01T00:00:00.000Z')
				const fechaSolicitud = new Date(dbLoan[0].fecha_solicitud)
				if (fechaSolicitud < dataInicio) 
					setLoan({ status: 0, turno: 0 })
				else
					setLoan(dbLoan[0])
			} 
			else setLoan({ status: 0, turno: 0 })
	}, [dbLoan])

	const handleClose = () => {
		setFormulario(false)
	}
	let validation =
		loan["status"] !== 0 &&
		loan["status"] !== STATUS_LOAN["FINISHED"] &&
		loan["status"] !== STATUS_LOAN["REJECT"]
	let exitPrestamos = [
		STATUS_LOAN["PAY_BACK"],
		STATUS_LOAN["PASS_PAY"],
		STATUS_LOAN["PAY"],
		STATUS_LOAN["FINISHED"],
	]

	if (skeleton)
		return (
			<React.Fragment>
				<Grid
					container
					alignItems={"center"}
					justifyContent={"center"}
					spacing={2}
					rowSpacing={2}
				>
					<Texto
						label={TITLE}
						size={12}
					/>
					{!validation ? (
						<ButtonGrid2
							label={BUTTON_REQUEST}
							propsG={{ md: 3 }}
							show
							disabled={
								loan["status"] !== 0 &&
								loan["status"] !== STATUS_LOAN["FINISHED"] &&
								loan["status"] !== STATUS_LOAN["REJECT"]
							}
							onClick={() => setFormulario(true)}
						/>
					) : (
						//Salir de ahorro
						<ButtonGrid2
							label={BUTTON_EXIT}
							propsG={{ md: 3 }}
							disabled={exitPrestamos.includes(loan["status"])}
							onClick={() => setConfirm(true)}
						/>
					)}
					
					<React.Fragment>
						{loan["turno"] !== 0 && <PrestamosFila turno={loan["turno"]} />}

						{loan["status"] !== 0 && (
							<PrestamosTabla
								size={12}
								filtro={{ colaborador: colaboradorID }}
							/>
						)}
					</React.Fragment>
				</Grid>
				<DialogConfirm
					title={TEXT_EXIT}
					open={confirm}
					setOpen={setConfirm}
					onClick={() =>
						putPrestamos(
							token,
							{ comentario: "" },
							{ id: loan["id"], status: STATUS_LOAN["REJECT"] },
							actualizar2,
							setActualizar2
						)
					}
				/>
				<Dialog
					maxWidth={"md"}
					open={formulario}
					onClose={handleClose}
				>
					<DialogContent>
						<PrestamosFormulario
							setOpen={setFormulario}
							id={colaboradorID}
						/>
					</DialogContent>
				</Dialog>
			</React.Fragment>
		)
	else return <SkeletonSquareGrid />
}

export default Prestamos
