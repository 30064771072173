import { api_rh_get } from "#api/API_RH"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
	notification: {},
	isLoading: false,
	error: null
}

export const actualizarNotify = createAsyncThunk("content/actualizarNotify", async (token) => {	
	const response= await api_rh_get("notifications",{failMessage:"Error en las notificaciones"})
	if(response?.data?.success){
		return response?.data?.data}
	return {}
})


export const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(actualizarNotify.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(actualizarNotify.fulfilled, (state, action) => {
			state.isLoading = false
			state.notification = action.payload
		})
		builder.addCase(actualizarNotify.rejected, (state, action) => {
			state.isLoading = false
			state.error = action.error.message
		})
	}
})
//export const { setNotifications } = notificationSlice.actions;
export default notificationSlice.reducer
