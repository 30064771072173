import React, { useEffect, useState } from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import {
	apiDeleteAssetFotos,
	apiGetAsset2,
	apiGetAssetFotos,
} from "../../Services/Api_inventario"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { ButtonIcon } from "../Common/Buttons"
import { DialogConfirm } from "../Common/ModalConfirmacion"
import { HasPermission } from "#common/Funciones"
import { PERFILES } from "#constants/Strings"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"

export default function AssetPicture({ idAsset, refresh, height }) {
	const [pictures, setPictures] = useState([])
	const [open, setOpen] = useState(false)
	const [actualizar, setActualizar] = useState(false)
	const isSistemas = HasPermission(PERFILES.SISTEMAS)
	useEffect(() => {
		apiGetAssetFotos({
			success: setPictures,
			params: { link: "", idAsset: idAsset },
		})
	}, [idAsset, actualizar, refresh])

	if (!pictures.length) return "No hay imágenes"
	const imgList = pictures.map((item, index) => {
		return (
			<Grid2 key={item.url_picture}>
				<ImageListItem
					cols={1}
					rows={1}
					sx={{ width: "200px", height }}
				>
					<img
						style={{ borderRadius: "15px", width: "200px", height }}
						src={item.url_picture}
						alt={item.id}
					/>
					<ImageListItemBar
						sx={{ borderRadius: "15px 15px 0 0" }}
						title={item.update_date}
						position="top"
						actionIcon={
							isSistemas ? (
								<ButtonIcon
									title="Borrar"
									mensaje="¿Borrar Imagen?"
									sx={{ color: "red" }}
									aria-label={`star ${item.title}`}
									onClick={() => setOpen(item["id"])}
									icon={<DeleteOutlineIcon />}
								/>
							) : null
						}
						actionPosition="left"
					/>
				</ImageListItem>
			</Grid2>
		)
	})
	const imgDelete = () => {
		const success = () => {
			setOpen(false)
			setActualizar(!actualizar)
		}
		apiDeleteAssetFotos({
			params: { id: open },
			success,
		})
	}
	imgList.push(
		<DialogConfirm
			open={open}
			setOpen={setOpen}
			message="¿Borrar Imagen?"
			accept={imgDelete}
		/>,
	)
	return imgList
}
