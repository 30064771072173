import { Typography } from "@mui/material"

/**
 * @typedef {import('@mui/material').TypographyProps} TypographyProps
 */

/**
 * @typedef {object} CampoValorProps
 * @property {string} [field=""] - Nombre del campo.
 * @property {any} value - Valor del campo.
 * @property {string} [separador=": "] - Separador entre el campo y el valor.
 * @property {boolean} [campoNotNull=false] - Indica si el campo debe mostrarse o no.
 * @property {TypographyProps["textAlign"]} [textAlign="left"] - Alineación del texto.
 */
/**
 * @typedef {CampoValorProps & TypographyProps} TextCampoValorProps
 */
/**
 * Description placeholder
 * @param {TextCampoValorProps} props
 * @returns {JSX.Element}
 */

export const TextCampoValor = ({
	value,
	textAlign,
	field = "",
	campoNotNull = false,
	...props
}) => {
	if (value == null || campoNotNull) return null

	return (
		<Typography
			{...props}
			whiteSpace="pre-line"
			variant="body2"
			textAlign={textAlign}
		>
			<strong>{`${field}\n`}</strong>
			{value}
		</Typography>
	)
}
