import React from "react"
import { useEffect, useState } from "react"
//Componentes
import { InputGrid } from "../../../../Common/Inputs"
import { TextGrid } from "../../../../Common/Texto"
import { getPrestamos } from "../../../../../Services/Api_prestamos"
import { SkeletonSquare } from "../../../../Common/Skeleton"

//Material UI
import DialogContent from "@mui/material/DialogContent"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

function DialogAccountNumber({
	token,
	id,
	selected,
	setSelected,
	show = true,
}) {
	const [skeleton, setSkeleton] = useState("")
	useEffect(() => {
		getPrestamos(
			token,
			setSelected,
			{ id: id, datosBancarios: "" },
			setSkeleton
		)
	}, [id, setSelected, token])
	return show ? (
		<DialogContent>
			<Grid
				container
				paddingTop={1}
				spacing={2}
			>
				<TextGrid
					label="Datos Bancarios"
					tipo="h6"
					allSize={12}
					xs={12}
					md={12}
					xl={12}
				/>
				{skeleton ? (
					<React.Fragment>
						<InputGrid
							label="Banco"
							defaultValue={selected["banco"]}
							allSize={12}
							operation={(event) => {
								setSelected({ ...selected, banco: event.target.value })
							}}
						/>
						<InputGrid
							label="Numero de cuenta"
							type="number"
							defaultValue={selected["cuenta"]}
							allSize={12}
							operation={(event) => {
								setSelected({ ...selected, cuenta: event.target.value })
							}}
						/>
						<InputGrid
							label="Clabe interbancaria "
							type="number"
							defaultValue={selected["clabe"]}
							allSize={12}
							operation={(event) => {
								setSelected({ ...selected, clabe: event.target.value })
							}}
						/>
					</React.Fragment>
				) : (
					<SkeletonSquare height={150} />
				)}
			</Grid>
		</DialogContent>
	) : (
		<React.Fragment></React.Fragment>
	)
}

export default DialogAccountNumber
