import { useEffect } from 'react';
import DetalleColaborador from '../Components/Colaboradores/DetalleColaborador'
import { useSelector, useDispatch } from "react-redux";
import { changeTitle } from '../app/pageSlice';

const TuPerfil = () => {
    const colaborador = useSelector(state => state.user.colaborador.id);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(changeTitle('Tu perfil'))
    }, [])

    return (<DetalleColaborador idColaborador={colaborador} />)
}

export default TuPerfil