import { bajaPut } from "#api/Api/baja"
import { MasterDialog } from "#common/Dialog/Dialog"
import { HasPermission } from "#common/Funciones"
import { TERMINATION } from "#constants/StatusRequest "
import { Box, Stack, Typography } from "@mui/material"
import { Fragment, useCallback, useMemo, useState } from "react"
import * as TEXT from "../TextBaja"
import DetailTermination from "./components/DetailTermination"

export default function DialogDetailBaja({
	baja,
	open,
	setOpen,
	reload,
	dialogIndex,
	setDialogIndex,
}) {
	const isRH = HasPermission("RH")
	const [buttonsDialog, setButtonsDialog] = useState(null)
	const [buttonsDialogFinish, setButtonsDialogFinish] = useState(false)

	const closeDialog = useCallback(() => {
		setButtonsDialog(null)
		setOpen(false)
		setDialogIndex(0)
	}, [setDialogIndex, setOpen])

	const buttonFinish = useMemo(() => {
		if (
			isRH &&
			baja?.termination_status_id !== TERMINATION.FINALIZADA &&
			baja?.json_final_debts
		)
			return [
				{
					label: "Finalizar Baja",
					onClick: () => setButtonsDialogFinish(true),
					// disabled: open,
				},
			]
		else return null
	}, [baja?.json_final_debts, baja?.termination_status_id, isRH])

	return (
		<Fragment>
			<MasterDialog
				setOpen={setOpen}
				open={open}
				title={TEXT.TITLE_DIALOG}
				buttons={buttonsDialog || buttonFinish}
				maxWidth={"md"}
				closeDialog={closeDialog}
			>
				<DetailTermination
					baja={baja}
					reload={reload}
					isRH={isRH}
					dialogIndex={dialogIndex}
					setButtonsDialog={setButtonsDialog}
					closeDialog={closeDialog}
				></DetailTermination>
			</MasterDialog>
			<ConfirmaBaja
				{...{
					open: buttonsDialogFinish,
					setOpen: setButtonsDialogFinish,
					id: baja?.id,
					reload,
					closeDialog,
				}}
			/>
		</Fragment>
	)
}

const ConfirmaBaja = ({ open, setOpen, reload, id, closeDialog }) => {
	const [disabled, setDisabled] = useState(false)
	const send = () => {
		setDisabled(true)
		const fail = () => {
			setDisabled(false)
		}
		const success = () => {
			reload()
			setOpen(false)
			closeDialog()
		}
		bajaPut(
			id,
			{ termination_status: TERMINATION.FINALIZADA },
			{ success, fail },
		)
	}
	return (
		<MasterDialog
			setOpen={setOpen}
			open={open}
			title={"Confirmación Baja "}
			buttons={[{ label: "confirmar", onClick: send, disabled: disabled }]}
			maxWidth={"md"}
		>
			<Box>
				<Stack
					alignItems={"center"}
					spacing={2}
				>
					<Typography>
						¿Está seguro de dar de baja ha este colaborador?
					</Typography>
					<Typography whiteSpace={"pre-line"}>
						{`
						- Se desactivaran los accesos a las plataformas internas.
						- Se cancelaran los cursos del mes siguiente en delante. 
						- Si cuenta con personal a cargo sera reasignado a su jefe directo.
						`}
					</Typography>
				</Stack>
			</Box>
		</MasterDialog>
	)
}
