import ButtonFile from "#common/buttons/ButtonFile"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner"
import ButtonDownload, {
	ButtonDownloadUrl,
} from "#common/buttons/ButtonDownload"
import { URL_PERFIL_PUESTO } from "#api/Api/perfilPuesto"

import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload"
function EntrevistasCompetencias({
	dataPerfil,
	setDataSendFiles,
	dataSendFiles,
}) {
	const handleChange = (files, fileName) => {
		setDataSendFiles((old) => ({ ...old, [fileName]: files[0] }))
	}
	return (
		<Box>
			<Stack
				direction="column"
				spacing={2}
				alignContent={"flex-start"}
			>
				<Stack
					direction="row"
					spacing={2}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<ButtonUpload
						{...{
							label: "Entrevista RH",
							nameFile: "entrevista_rh",
							dataSendFiles,
							handleChange,
							dataPerfil,
						}}
					/>
					<ButtonUpload
						{...{
							label: "Entrevistas Departamento",
							nameFile: "entrevista_depto",
							dataSendFiles,
							handleChange,
							dataPerfil,
						}}
					/>
				</Stack>
			</Stack>
		</Box>
	)
}

export default EntrevistasCompetencias

const ButtonUpload = ({
	label,
	nameFile,
	dataSendFiles,
	handleChange,
	dataPerfil,
}) => {
	return (
		<Stack
			width={"50%"}
			spacing={2}
		>
			<ButtonFile
				fullWidth
				getFiles={(event) => {
					handleChange(event, nameFile)
				}}
				startIcon={<DocumentScannerIcon />}
				label={`subir ${label}`}
			/>
			<ButtonDownloadUrl
				{...{
					startIcon: <SimCardDownloadIcon />,
					ruta: URL_PERFIL_PUESTO(dataPerfil?.id),
					label: `Descargar ${label}`,
					nameFile,
					dataSendFiles,
					handleChange,
					hidden: !dataPerfil?.[`url_${nameFile}`],
				}}
			/>
			<Typography textAlign={"center"}>
				{dataSendFiles?.[nameFile]?.name}
			</Typography>
		</Stack>
	)
}
