import { MasterDialog } from "#common/Dialog/Dialog"
import { useEffect, useMemo, useState } from "react"
import { usePromotionsContext } from "../../PromotionContext"
import { ResponseTrue } from "./ResponseTrue"
import { ResponseFalse } from "./ResponseFalse"

export default function DialogConfirm() {
	const {
		setOpenConfirm: setOpen,
		openConfirm: open,
		response,
	} = usePromotionsContext()
	const [maxWidth, setMaxWidth] = useState("xs")
	const [buttons, setButtons] = useState([])
	const title = useMemo(() => {
		if (response === true) return "Aprobar Solicitud"
		else if (response === false) return "Rechazar Solicitud"
	}, [response])

	useEffect(() => {
		if (!open) setButtons([])
	}, [open])

	return (
		<MasterDialog
			{...{
				open,
				setOpen,
				title,
				maxWidth,
				buttons,
				closeDialog: () => setButtons([]),
			}}
		>
			<ResponseTrue {...{ setMaxWidth, setButtons }} />
			<ResponseFalse {...{ setMaxWidth, setButtons }} />
		</MasterDialog>
	)
}
