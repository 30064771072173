import { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { PRIMARYCOLOR } from "../../Constants/Styles"
import { HasPermission } from "../Common/Funciones"

//material
import MuiAccordion from "@mui/material/Accordion"
import Badge from "@mui/material/Badge"
import Divider from "@mui/material/Divider"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Tooltip from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
// import Accordion from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
//Icons
import AccountBoxIcon from "@mui/icons-material/AccountBox"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import GroupIcon from "@mui/icons-material/Group"
import NoteAltIcon from "@mui/icons-material/NoteAlt"
import SchoolIcon from "@mui/icons-material/School"
//
import { rutasItems } from "./ListURL"

const iconMenuStyle = { sx: { color: PRIMARYCOLOR } }
const menuIcon = {
	"Mi Perfil": <AccountBoxIcon {...iconMenuStyle} />,
	Colaboradores: <GroupIcon {...iconMenuStyle} />,
	Capacitaciones: <SchoolIcon {...iconMenuStyle} />,
	Solicitudes: <NoteAltIcon {...iconMenuStyle} />,
}

const ItemsGroup = ({
	group,
	perfiles,
	expanded,
	handleChange,
	notification,
}) => {
	let countNotification = 0
	let items = rutasItems[group].map((item) => {
		if (HasPermission(item.denied, true)) return null
		const { permissions } = item

		if (permissions.length > 0)
			if (!HasPermission(item.permissions)) return null
		countNotification += notification[item?.notification] || 0
		return (
			<Link
				key={item.title}
				to={`/${item.path}`}
				target={item.target}
				style={{ textDecoration: "none", color: "inherit" }}
				onClick={() => handleChange(null)}
			>
				<Tooltip
					title={item.title}
					placement="right"
				>
					<ListItemButton>
						<ListItemIcon>
							<Badge
								badgeContent={notification[item.notification]} //notificaciones
								color="error"
							>
								{item.icon}
							</Badge>
						</ListItemIcon>
						<ListItemText primary={item.title} />
					</ListItemButton>
				</Tooltip>
			</Link>
		)
	})

	items = items.filter((item) => item != null)
	if (items.length === 0) return null
	if (items.length === 1)
		return (
			<div>
				<Divider />
				{items}
			</div>
		)

	return (
		<Accordion
			expanded={expanded === group}
			onChange={handleChange(group)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1-content"
				id="panel1-header"
			>
				<ListItemIcon>
					<Badge
						badgeContent={countNotification} //notificaciones
						color="error"
					>
						{menuIcon[group]}
					</Badge>
				</ListItemIcon>
				{group}
			</AccordionSummary>
			<AccordionDetails>{items}</AccordionDetails>
		</Accordion>
	)
}

const MenuListItems = () => {
	const notification = useSelector((state) => state.notification.notification)
	const perfiles = useSelector((state) => state.user.profiles)

	const [expanded, setExpanded] = useState(false)
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}
	let menu = Object.keys(rutasItems)
	menu.shift()
	return (
		<Fragment>
			{menu.map((item) => {
				return (
					<ItemsGroup
						key={item}
						group={item}
						perfiles={perfiles}
						expanded={expanded}
						notification={notification}
						handleChange={handleChange}
					/>
				)
			})}

			<Divider textAlign="left"></Divider>
		</Fragment>
	)
}
export default MenuListItems

const Accordion = styled((props) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		{...props}
	/>
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&::before": {
		display: "none",
	},
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(1),
	borderTop: `1px solid ${PRIMARYCOLOR}`,
	backgroundColor: "#CACACA35",
}))
