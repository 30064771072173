import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { useForm } from "react-hook-form"

import FormBase from "../../Common/Forms"
import { DialogForm } from "./../../Common/Dialog"
import {
	apiPostTrayectoria,
	apiPutTrayectoria,
} from "../../../Services/ApiColaborador"
import { formatYearMonth, reverseTextBySeparator } from "../../Common/Funciones"

export default function FormTrayectoria({
	idColaborador,
	open,
	setOpen,
	edit,
	actualizar,
	sucursales,
	perfiles,
}) {
	const token = useSelector((state) => state.user.token)
	const DATA = [
		{
			field: "date",
			id: "fecha_inicio",
			label: "Fecha Inicio",
			views: ["year", "month"],
			parseFormat: "MM/YYYY",
			type: "number",
			registerParams: { required: { value: true } },
		},
		{
			field: "select",
			id: "perfil_puesto",
			label: "Perfil Puesto",
			data: perfiles,
			dataLabel: "nombre",
			dataId: "id",
			registerParams: { required: true },
		},
		{
			field: "select",
			id: "sucursal",
			label: "Sucursal",
			data: sucursales,
			dataLabel: "nombre",
			dataId: "id",
			registerParams: { required: { value: true } },
		},
	]
	const form = useForm({ defaultValues: edit })
	const { handleSubmit, reset } = form
	const [button, setButton] = useState(false)
	useEffect(() => {
		if (!!edit) {
			reset(edit)
		} else reset({})
	}, [edit, reset, open])

	const submit = async (data) => {
		data.colaborador = idColaborador
		setButton(true)
		formatYearMonth(data, "fecha_inicio")

		let response
		if (edit) response = await apiPutTrayectoria(token, data)
		else response = await apiPostTrayectoria(token, data)

		if (response.success) {
			actualizar()
			setOpen(false)
		}
		setButton(false)
	}

	return (
		<DialogForm
			title={edit ? "Editar  Trayectoria " : "Registrar Trayectoria "}
			open={open}
			setOpen={setOpen}
			accept={handleSubmit(submit)}
			disabledButtons={button}
		>
			<FormBase
				fields={DATA}
				useForm={form}
			/>
		</DialogForm>
	)
}
