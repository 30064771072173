import React from "react"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import { getApiCursos } from "../../../../Services/Api_cursos"

import {
	ESTADO_CURSOS,
	MESES,
	COLOR_TARJETA,
} from "../../constants_capacitaciones"
import { SkeletonSquareGrid } from "../../../Common/Skeleton"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import CursosTablaColores from "../CursosTablaColores"
import { InputSelectGrid2 } from "../../../Common/Inputs2"

function ResumenCursos({ departamento = 0, sucursal = 0, year }) {
	const token = useSelector((state) => state.user.token) //Token
	const [db_dbCursosColaboradores, set_DBCursosColaboradores] = useState([]) //Curso_colaborador
	const [db_data, setData] = useState([]) //Curso_colaborado
	const [filterSelect, setFilterSelect] = useState({ mesStar: 1, mesEnd: 12 }) //Curso_colaborado
	const [statistics, setStatistics] = useState([]) //Curso_colaborado
	const [skeleton, setSkeleton] = useState(false) //filtro sucursal
	//Descarga la información de los cursos con mes y estado
	useEffect(() => {
		getApiCursos(
			"statistics",
			token,
			set_DBCursosColaboradores,
			{ departamento: departamento, sucursal: sucursal, concentrado: "", year },
			setSkeleton
		).then((response) => {
			let data = response.data
			if (data.length) {
				let dataFilter = JSON.parse(JSON.stringify(data))
				setData(
					dataFilter.filter((curso) => {
						curso["cursos"] = curso["cursos"].filter((c) => {
							return c.status !== ESTADO_CURSOS["CANCELADO"]
						})
						return curso["cursos"].length > 0
					})
				)
			}
		})
	}, [token, sucursal, departamento])

	useEffect(() => {
		let statisticsTrimester = [{ nombre: "Plan Anual", plan: 0, real: 0 }]
		// //calculamos las cantidad de Q enteras que pueden salir
		// let cantidadQ = Math.floor(12 / filterSelect)
		// for (let qInteger = 1; qInteger <= cantidadQ; qInteger++) {
		// 	statisticsTrimester.push({ nombre: `Q${qInteger}`, plan: 0, real: 0 })
		// }
		// //En caso de que las Q tenga un residuo Se crea una Q adicional con las sobrantes
		// if (!!(12 % filterSelect)) {
		// 	cantidadQ += 1
		// 	statisticsTrimester.push({ nombre: `Q${cantidadQ}`, plan: 0, real: 0 })
		// }
		// /**
		//  * Permite hacer el conteo de cada q dependiendo el mes de asignación
		//  * @param {*} cursos
		//  * @param {INT} mesI mes inicial donde se toma el conteo
		//  * @param {INT} mesF mes final donde se toma el conteo
		//  * @param {INT} position lugar en el arreglo donde se lleva el conteo
		//  */
		function filtroTri(cursos, mesI, mesF, position) {
			if (cursos["mes"] >= mesI && cursos["mes"] <= mesF) {
				statisticsTrimester[position]["plan"]++
				if (cursos["status"] === ESTADO_CURSOS["APROBADO"])
					statisticsTrimester[position]["real"]++
			}
		}
		// //ciclo donde se hace la búsqueda y conteo de las Q
		db_data.forEach((curso) => {
			curso["cursos"].forEach((cursos) => {
				filtroTri(cursos, filterSelect["mesStar"], filterSelect["mesEnd"], 0)
				// let mesStart = 1,
				// 	mesEnd = filterSelect
				// for (let Q = 1; Q <= cantidadQ; Q++) {
				// 	filtroTri(cursos, mesStart, mesEnd, Q)
				// 	mesStart += filterSelect
				// 	mesEnd += filterSelect
				// }
			})
		})
		// //se asigna la información a una la variable use state
		setStatistics(statisticsTrimester)
		//Fusiona en base a la data que tenga y el filtro para invocar  este proceso
	}, [db_data, filterSelect])
	const filtro = () => {
		let meses = [...MESES]
		meses.shift()
		return meses
	}

	return !skeleton ? (
		<SkeletonSquareGrid
			size={12}
			width={"100%"}
		/>
	) : (
		<React.Fragment>
			<Grid
				container
				spacing={2}
				justifyContent={"center"}
			>
				<Grid xs={9}>
					<CursosTablaColores all />
				</Grid>
				<Grid
					item
					xs={8}
					sm={8}
					md={8}
					lg={8}
				>
					<TableContainer component={Paper}>
						<Table
							sx={{ minWidth: 650 }}
							aria-label="simple table"
						>
							<TableHead key={"tbh01"}>
								<TableRow>
									<TableCell key={"c1"}>Colaborador</TableCell>
									<TableCell key={"c2"}>total</TableCell>
									{MESES.map((mes, index) => {
										return mes.id ? (
											<TableCell
												key={"c" + index + 3}
												style={{
													color: "#333",
												}}
											>
												{mes.id}
											</TableCell>
										) : (
											<React.Fragment></React.Fragment>
										)
									})}
								</TableRow>
							</TableHead>

							<TableBody>
								{db_dbCursosColaboradores.map((curso) => {
									return (
										<TableRow>
											<TableCell
												style={{
													backgroundColor: "#f0f0f0",
													color: "#333",
													padding: "5px",
													//height: "30px",
													fontSize: "20px",
												}}
											>
												{curso["nombre"]}
											</TableCell>
											<TableCell
												style={{
													backgroundColor: "#f0f0f0",
													color: "#333",
													padding: "5px",
													fontSize: "20px",
												}}
											>
												{curso["cursos"].length}
											</TableCell>
											{MESES.map((mes) => {
												let colorCurso = "#EBE1AD"
												try {
													let mesCurso = curso["cursos"].filter(
														(c) => c.mes === mes.id
													)
													if (mesCurso.length)
														colorCurso = COLOR_TARJETA(mesCurso[0].status)
												} catch (error) {}
												return mes.id ? (
													<TableCell
														key={mes.id}
														style={{
															borderRadius: 10,
															backgroundColor: colorCurso,
														}}
													></TableCell>
												) : (
													<React.Fragment></React.Fragment>
												)
											})}
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid
					xs={3}
					sm={3}
					md={3}
					lg={3}
				>
					<div>
						<Grid
							container
							alignContent={"center"}
							justifyContent={"center"}
						>
							<InputSelectGrid2
								label="Mes inicio"
								data={filtro()}
								dataID="id"
								value={filterSelect["mesStar"]}
								setValue={(val) => {
									setFilterSelect({ ...filterSelect, mesStar: val })
								}}
								GridProps={{ xs: 12, md: 5 }}
							/>
							<InputSelectGrid2
								label="Mes fin"
								data={filtro()}
								dataID="id"
								value={filterSelect["mesEnd"]}
								setValue={(val) => {
									setFilterSelect({ ...filterSelect, mesEnd: val })
								}}
								GridProps={{ xs: 12, md: 5 }}
							/>
							<Grid xs={12}>
								<TableContainer component={Paper}>
									<Table
										//sx={{ minWidth: 650 }}
										aria-label="simple table"
									>
										<TableHead>
											<TableRow>
												<TableCell>
													<h2>🗓️</h2>
												</TableCell>
												<TableCell>Plan</TableCell>
												<TableCell>Real</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{statistics.map((trimestre) => (
												<TableRow>
													<TableCell>{trimestre["nombre"]}</TableCell>
													<TableCell>{trimestre["plan"]}</TableCell>
													<TableCell>{trimestre["real"]}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

export default ResumenCursos
