import { IconButton, Tooltip } from "@mui/material"
import RadioButtonCheckedSharpIcon from "@mui/icons-material/RadioButtonCheckedSharp"
/**
 * @typedef {import('@mui/material').IconButtonProps} IconButtonProps
 * @typedef {import('@mui/material').TooltipProps} TooltipProps
 */
/**
 * @typedef {Object} ButtonIconProps
 * @property {string} [title=""] - Text to display in the tooltip.
 * @property {boolean} [disabled=false] - Whether the button is disabled.
 * @property {string} [size="medium"] - The size of the button. Can be 'small', 'medium', or 'large'.
 * @property {string} [color="default"] - The color of the button. Supports standard MUI button colors.
 * @property {function} [onClick] - Click handler function for the button.
 * @property {boolean} [hidden=false] - Determines if the button should be hidden.
 * @property {JSX.Element} [icon=<RadioButtonCheckedSharpIcon />] - The icon to render inside the button.
 */
/**
 * @typedef {ButtonIconExtends & IconButtonProps} ButtonIconProps
 */

/**
 * Renders an icon button with optional tooltip, visibility control, and customizable properties.
 *
 * @param {ButtonIconProps} props - The properties for the `ButtonIcon` component.
 * @returns {JSX.Element|null} - Returns the `IconButton` wrapped in a `Tooltip` or `null` if hidden.
 */
export default function ButtonIcon({
	title,
	disabled,
	size,
	color,
	onClick,
	hidden = false,
	icon = <RadioButtonCheckedSharpIcon />,
}) {
	if (hidden) return null
	return (
		<Tooltip {...{ title }}>
			<IconButton {...{ disabled, size, color, onClick }}>{icon}</IconButton>
		</Tooltip>
	)
}
