import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useForm } from "react-hook-form"

//Api
import {
	getApiCursos,
	postApiCursos,
	putApiCursos,
} from "../../Services/Api_cursos"

//Componentes
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { ButtonGrid } from "../Common/Buttons"
import { InputSelectFormGrid, InputFormGrid2 } from "../Common/Inputs2"

//materia ui
import { FormControl } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { DialogNewPlataforma } from "./componentes/DialogNewPlataforma"

export default function CursosFormularioV2({
	open,
	setOpen,
	datos,
	tablaAct,
	edit = false,
	setTablaAct = () => {},
}) {
	const [button, setButton] = useState("")
	const [db_plataforma, setDBPlataforma] = useState([])
	const [plataforma, setPlataforma] = useState(false)
	const [actualizar, setActualizar] = useState(false)
	const token = useSelector((state) => state.user.token)
	const colaborador = useSelector((state) => state.user.colaborador.id)
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm()

	const handleClose = () => {
		setOpen(false)
	}

	const onSubmit = async (data) => {
		data.colaborador_creador = colaborador
		setButton(true)
		let response = ""

		if (edit)
			response = await putApiCursos("cursos", token, data, { id: data["id"] })
		else {
			response = await postApiCursos("cursos", token, data)
		}
		if (response["success"] === true) {
			setTablaAct(!tablaAct)
			setOpen(false)
			setButton(false)
		} else {
			setButton(false)
		}
	}

	useEffect(() => {
		getApiCursos("plataforma", token).then(function (response) {
			setDBPlataforma(response)
		})
	}, [token, actualizar])

	useEffect(() => {
		reset(
			datos || {
				id: "",
				curso: "",
				horas: "",
				plataforma: "",
				plataforma_nombre: "",
				objetivo: "",
				contenido: "",
				link: "",
			}
		)
	}, [datos, open, reset])

	return (
		<Modal
			open={open}
			onClose={handleClose}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Box sx={style}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormControl sx={{ width: "100%" }}>
						<Grid
							container
							spacing={4}
							columns={12}
						>
							<InputFormGrid2
								id="curso"
								label="Nombre curso"
								multiline
								register={register}
								error={errors}
								registerParams={{
									required: true,
									// maxLength: { value: 255, message: "Máximo 255 caracteres " },
								}}
								propsG={{
									xs: 12,
								}}
							/>

							<InputFormGrid2
								id="objetivo"
								label="Objetivo"
								multiline
								register={register}
								error={errors}
								registerParams={{
									required: true,
									// maxLength: { value: 255, message: "Máximo 255 caracteres " },
								}}
								propsG={{
									xs: 12,
								}}
							/>

							<InputFormGrid2
								id="contenido"
								label="Contenido"
								multiline
								register={register}
								error={errors}
								registerParams={{
									required: true,
									// maxLength: { value: 255, message: "Máximo 255 caracteres " },
								}}
								propsG={{ xs: 12 }}
							/>

							<InputFormGrid2
								id="link"
								label="Link"
								register={register}
								multiline
								registerParams={{
									required: true,
									pattern: {
										value: /^(https?:\/\/)/,
										message:
											"Link Incorrecto debe empezar con http:// o https://",
									},
								}}
								error={errors}
								propsG={{ xs: 6 }}
							/>

							<InputFormGrid2
								id="horas"
								label="Horas"
								type="number"
								register={register}
								registerParams={{
									pattern: { value: /^\d+$/, message: "Debe ser un numero" },
									min: { value: 1, message: "horas debe ser mayor 1" },
									required: true,
								}}
								error={errors}
								propsG={{ xs: 3 }}
							/>

							<React.Fragment>
								<InputSelectFormGrid
									id={"plataforma"}
									label={"Plataforma"}
									data={db_plataforma}
									dataLabel="descripcion"
									control={control}
									error={errors}
									propsG={{ xs: 3 }}
								/>
							</React.Fragment>

							<Grid xs={12}>
								<Grid
									container
									justifyContent="flex-end"
								>
									<ButtonGrid
										label={"Crear plataforma"}
										disabled={button}
										variant="contained"
										onClick={() => {
											setPlataforma(true)
										}}
										//type="submit"
										xs={3}
									/>
									<ButtonGrid
										label={edit ? "Actualizar" : "Crear curso"}
										disabled={button}
										variant="contained"
										type="submit"
										xs={3}
									/>
								</Grid>
							</Grid>
						</Grid>
					</FormControl>
				</form>
				<DialogNewPlataforma
					open={plataforma}
					setOpen={setPlataforma}
					actualizar={actualizar}
					setActualizar={setActualizar}
				/>
			</Box>
		</Modal>
	)
}
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	//width: "90%",
	bgcolor: "background.paper",
	border: "1px solid #000",
	boxShadow: 24,
	p: 5,
	borderRadius: 8,
}
