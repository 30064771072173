/**
 * @description Constantes para el manejo de los cursos
 * @param {number} APROBADO - 1 Estado aprobado
 * @param {number} RECHAZADO - 2 Estado rechazado
 * @param {number} REVISAR - 3 Estado revisar
 * @param {number} CANCELADO - 4 Estado cancelado
 * @param {number} CREADO - 5 Estado pendiente
 * @param {number} ATRAZADO - 6 Estado atrasado
 */
export const ESTADO_CURSOS = {
    "APROBADO": 1,
    "RECHAZADO": 2,
    "REVISAR": 3,
    "CANCELADO": 4,
    "CREADO": 5,
    "ATRAZADO": 6,
}

export const MESES = [
	{ id: 0, label: "Extra" },
	{ id: 1, label: "Enero" },
	{ id: 2, label: "Febrero" },
	{ id: 3, label: "Marzo" },
	{ id: 4, label: "Abril" },
	{ id: 5, label: "Mayo" },
	{ id: 6, label: "Junio" },
	{ id: 7, label: "Julio" },
	{ id: 8, label: "Agosto" },
	{ id: 9, label: "Septiembre" },
	{ id: 10, label: "Octubre" },
	{ id: 11, label: "Noviembre" },
	{ id: 12, label: "Diciembre" }
]

export const COLOR_TARJETA =(statusCurso)=>
statusCurso === ESTADO_CURSOS["APROBADO"] //Aprobado
	? "#007F00"
	: statusCurso === ESTADO_CURSOS["CANCELADO"] //Cancelado
	? "#FF0000"
	: statusCurso === ESTADO_CURSOS["ATRAZADO"] //Atarazado
	? "#FF9900"
	: statusCurso === ESTADO_CURSOS["REVISAR"] //Por Revisar
	? "#0099ff"
	: statusCurso === ESTADO_CURSOS["RECHAZADO"] //Por Revisar
	? "#C757E9B4"
	: "#918888D8"
	