import React from 'react'
import { Grid,  } from '@mui/material'
import TrabajoCard from './TrayectoriaCard/TrabajoCard'
import TrayectoriaCard from './TrayectoriaCard/TrayectoriaCard'

const Trayectoria = ({colaborador, edit}) => {    
    return (
        <Grid container>
            <Grid item md={12}  sx={{ p:1}}> 
                <TrabajoCard colaborador={colaborador} edit={edit}/>
            </Grid>
            <Grid item md={12}  sx={{ p:1}}>  
                <TrayectoriaCard colaborador={colaborador} edit={edit}/>
            </Grid>
        </Grid>
    )
}

export default Trayectoria

