import React from "react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux" //Token
//API
import { getPrestamosFila } from "../../../../Services/Api_prestamos"

import Grid from "@mui/material/Unstable_Grid2"
import { Texto } from "../Componentes"
import Avatar from "@mui/material/Avatar"
import { TEXTOS_PRESTAMOS } from "./TextosPrestamos"

function PrestamosFila({ turno = 0, show = true, actualizar }) {
	const token = useSelector((state) => state.user.token)
	useEffect(() => {
		getPrestamosFila(token, setFila)
	}, [token, actualizar])
	const [fila, setFila] = useState({})

	let avatarStyle = { bgcolor: "#333333", width: 70, height: 70 }
	let divAvatarStyle = { fontSize: 40, fontWeight: "bold", color: "#FFA500" }

	return (
		<React.Fragment>
			<Grid
				xs={12}
				md={12}
			>
				<div>
					<Grid
						xs={12}
						container
						justifyContent={"center"}
					>
						<Grid
							container
							alignItems={"center"}
							justifyContent={"center"}
						>
							<Texto
								label={TEXTOS_PRESTAMOS["shiftNow"]}
								tipo="h5"
								size={12}
							/>
							<Grid>
								<Avatar sx={avatarStyle}>
									<div style={divAvatarStyle}>{fila["turno_actual"]}</div>
								</Avatar>
							</Grid>
						</Grid>

						{show && (
							<Grid
								container
								justifyContent={"center"}
							>
								<Texto
									label={TEXTOS_PRESTAMOS["shift"]}
									size={12}
									tipo="h5"
								/>
								<Grid>
									<Avatar sx={avatarStyle}>
										<div style={divAvatarStyle}>{turno}</div>
									</Avatar>
								</Grid>
							</Grid>
						)}
					</Grid>
				</div>
			</Grid>
		</React.Fragment>
	)
}

export default PrestamosFila
