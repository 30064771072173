// import { Table } from "@mui/material"
import React from "react"
import logo from "../../../Images/logo.jpg"
//exportColaboradorPDF

// import { exportColaboradorPDF } from "../../Hooks/CreatePDF"

const COLOR_CELDA = "#C3C3C3"
const celStyle = {
	border: "1px solid",
	paddingLeft: "2px",
	paddingRight: "2px",
}

export default function InventarioPDF({ colaborador, data }) {
	const {
		familiares,
		contacto,
		beneficiario,
		autos,
		escolares,
		externos,
		trabajos,
		trayectoria,
	} = data

	return (
		<div>
			<table
				// border="0"
				cellspacing="0"
				cellpadding="4"
				style={{
					width: "1200px",
					// border: "1px solid",
					padding: "15px",
				}}
			>
				<thead style={{ border: "1" }}>
					<tr style={{ height: "40px", textAlign: "center" }}>
						<td
							rowSpan={2}
							style={{
								border: "1px solid",
								width: "15%",
								textAlign: "center", // Centro el texto
								verticalAlign: "middle", // Alineo verticalmente al medio
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									src={logo}
									alt="Logo"
									width={"100%"}
									height={"80%"}
								/>
							</div>
						</td>
						<td
							colSpan={2}
							style={{
								width: "100%",
								border: "1px solid",
								textAlign: "center", // Centro el texto
								verticalAlign: "middle", // Alineo verticalmente al medio
							}}
						>
							INVENTARIO DE RECURSOS HUMANOS
						</td>
					</tr>
					<tr
						style={{
							height: "20px",
							justifyContent: "center",
						}}
					>
						<td
							style={{
								width: "50%",
								border: "1px solid",
								textAlign: "center", // Centro el texto
								verticalAlign: "middle", // Alineo verticalmente al medio
							}}
						>
							F-DRH-12, Revisión E
						</td>
						<td
							style={{
								width: "50%",
								border: "1px solid",
								textAlign: "center", // Centro el texto
								verticalAlign: "middle", // Alineo verticalmente al medio
							}}
						>
							Revisión E
						</td>
					</tr>
				</thead>
				<tbody>
					<tr style={{ textAlign: "right" }}>
						<td colSpan={3}>
							<table style={{ width: "100%" }}>
								<tr>
									<td style={{ width: "100%" }}>{`No. Empleado :`}</td>
									<td style={{ backgroundColor: COLOR_CELDA }}>
										{colaborador.no_empleado}
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td colSpan={3}>
							<TablaPersonales colaborador={colaborador} />
						</td>
					</tr>

					<tr>
						<td colSpan={3}>
							<TablaFamiliares familiares={familiares} />
						</td>
					</tr>
					{/* <tr>
						<td
							colSpan={3}
							style={{ fontSize: "11px" }}
						>
							EN CASO DE ACCIDENTE AVISAR A:
						</td>
					</tr> */}
					<tr>
						<td colSpan={3}>
							<TablaContactoEmergencias contacto={contacto} />
						</td>
					</tr>

					<tr>
						<td colSpan={3}>
							<TablaBeneficiario beneficiario={beneficiario} />
						</td>
					</tr>

					<tr>
						<td colSpan={3}>
							<TablaAutos autos={autos} />
						</td>
					</tr>
					<tr>
						<td colSpan={3}>
							<TablaEscolares escolares={escolares} />
						</td>
					</tr>
					{/* <tr>
						<td colSpan={3}>CURSOS EXTERNOS</td>
					</tr> */}
					<tr>
						<td colSpan={3}>
							<TablaExternos externos={externos} />
						</td>
					</tr>
					{/* <tr>
						<td colSpan={3}>
							<TablaAnteriores trabajos={trabajos} />
						</td>
					</tr>
					<tr>
						<td colSpan={3}>
							<TablaTrayectoria trayectorias={trayectoria} />
						</td>
					</tr> */}
				</tbody>
			</table>
			{/* <Button
				onClick={() => {
					exportColaboradorPDF(props)
				}}
			>
				descargar
			</Button> */}
		</div>
	)
}
const TablaPersonales = ({ colaborador }) => {
	const {
		apellido_materno,
		apellido_paterno,
		fecha_nacimiento,
		lugar_nacimiento,
		curp,
		rfc,
		nombre,
		estado_civil,
		sexo,
		domicilio,
		colonia,
		poblacion,
		estado,
		telefono,
		no_licencia,
		correo_personal,
		no_imss,
		grupo_sanguineo,
		alergias,
		no_infonavit,
		clave_elector,
		seccion,
	} = colaborador
	function CeldaStyle(texto, campo) {
		return (
			<table
				cellspacing="0"
				cellpadding="0"
				style={{ width: "100%" }}
			>
				<tr>
					<td
						style={{
							textAlign: "right",
							border: "1px solid",
							backgroundColor: "#C3C3C3",
							whiteSpace: "nowrap",
							paddingRight: 4,
						}}
					>
						{texto}
					</td>
					<td
						style={{
							width: "100%",
							border: "1px solid",
							textAlign: "left",
							paddingLeft: 3,
						}}
					>
						{` ${campo}`}
					</td>
				</tr>
			</table>
		)
	}

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={4}
						style={{ backgroundColor: "gray" }}
					>
						DATOS PERSONALES
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{CeldaStyle("Apellido Paterno", apellido_paterno)}</td>
					<td>{CeldaStyle("Apellido Paterno", apellido_materno)}</td>
					<td>{CeldaStyle("Nombre", nombre)}</td>
				</tr>
				<tr>
					<td>{CeldaStyle("Fecha De Nacimiento", fecha_nacimiento)}</td>
					<td>{CeldaStyle("Lugar De Nacimiento", lugar_nacimiento)}</td>

					<td>
						<table
							width={"100%"}
							border="0"
							cellspacing="0"
							cellpadding="0"
						>
							<tr>
								<td>{CeldaStyle("Estado Civil", estado_civil)}</td>
								<td>{CeldaStyle("Sexo", sexo)}</td>
							</tr>
						</table>
					</td>
				</tr>
				<tr>
					<td>{CeldaStyle("CURP", curp)}</td>
					<td>{CeldaStyle("RFC", rfc)}</td>
					<td>{CeldaStyle("No. Licencia", no_licencia)}</td>
				</tr>
				<tr>
					<td colSpan={2}>{CeldaStyle("Domicilio", domicilio)}</td>
					<td colSpan={2}>{CeldaStyle("Colonia", colonia)}</td>
				</tr>
				<tr>
					<td colSpan={2}>{CeldaStyle("Población", poblacion)}</td>
					<td>{CeldaStyle("Estado", estado)}</td>
				</tr>
				<tr>
					<td colSpan={1}>{CeldaStyle("Teléfono", telefono)}</td>
					<td colSpan={2}>{CeldaStyle("Correo Personal", correo_personal)}</td>
				</tr>
				<tr>
					<td>{CeldaStyle("No.IMSS", no_imss)}</td>
					<td>{CeldaStyle("Grupo Sanguineo", grupo_sanguineo)}</td>
					<td>{CeldaStyle("Alergias", alergias)}</td>
				</tr>
				<tr>
					<td colSpan={2}>{CeldaStyle("Clave elector ", clave_elector)}</td>
					<td>{CeldaStyle("Sección", seccion)}</td>
				</tr>
				<tr>
					<td>{CeldaStyle("No. Crédito INFONAVIT ", no_infonavit)}</td>
				</tr>
			</tbody>
		</table>
	)
}

const TablaFamiliares = ({ familiares }) => {
	const campos = [
		{ id: "parentezco", label: "Parentesco" },
		{
			id: "nombre",
			label: "Nombre",
		},
		{
			id: "fecha_nacimiento",
			label: "Fecha Nacimiento",
		},
		{
			id: "sexo",
			label: "Sexo",
			dataID: "value",
		},
		{
			id: "estado_civil",
			label: "Estado Civil",
		},

		{
			id: "escolaridad",
			label: "Escolaridad",
		},
		{
			id: "profesion",
			label: "Profesión",
		},
		{
			id: "vive",
			label: "¿Vive?",
		},
	]

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={8}
						style={{ ...celStyle, backgroundColor: "gray" }}
					>
						FAMILIARES
					</th>
				</tr>
				<tr>
					{campos.map((campo) => (
						<th style={{ ...celStyle, backgroundColor: "#C3C3C3" }}>
							{campo.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{familiares.map((familiar) => (
					<tr>
						{campos.map((campo) => {
							// if (campo.id === "parentezco")
							// 	return <td>{familiar[campo.id]}</td>
							if (campo.id === "vive")
								return (
									<td
										style={{
											border: "1px solid",
											// backgroundColor: COLOR_CELDA
										}}
									>
										{familiar[campo.id] ? "SI" : "NO"}
									</td>
								)
							return (
								<td
									style={{
										border: "1px solid",

										// backgroundColor: COLOR_CELDA
									}}
								>
									{familiar[campo.id]}
								</td>
							)
						})}
						<td></td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

const TablaContactoEmergencias = ({ contacto }) => {
	const campos = [
		{
			id: "nombre",
			label: "Nombre",
		},
		{
			id: "domicilio",
			label: "Domicilio",
		},
		{
			id: "parentezco",
			label: "Parentesco",
		},
		{
			id: "telefono",
			label: "Teléfonos",
		},
	]

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={campos.length}
						style={{ ...celStyle, backgroundColor: "gray" }}
					>
						EN CASO DE ACCIDENTE AVISAR A:
					</th>
				</tr>
				<tr>
					{campos.map((campo) => (
						<th style={{ ...celStyle, backgroundColor: "#C3C3C3" }}>
							{campo.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{contacto.map((familiar) => (
					<tr>
						{campos.map((campo) => {
							return (
								<td
									style={{
										...celStyle,
									}}
								>
									{familiar[campo.id]}
								</td>
							)
						})}
						<td></td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

const TablaBeneficiario = ({ beneficiario }) => {
	const campos = [
		{
			id: "beneficio",
			label: "Beneficio",
		},
		{
			id: "nombre",
			label: "Nombre",
		},
		{
			id: "parentezco",
			label: "Parentesco",
		},
		{
			id: "porcentaje",
			label: "Porcentaje",
		},
		{
			id: "",
			label: "Firma",
		},
	]

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={campos.length}
						style={{ backgroundColor: "gray" }}
					>
						BENEFICIARIOS DE PRESTACIONES
					</th>
				</tr>
				<tr>
					{campos.map((campo) => (
						<th style={{ ...celStyle, backgroundColor: "#C3C3C3" }}>
							{campo.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{beneficiario.map((familiar) => (
					<tr>
						{campos.map((campo) => {
							if (campo.id === "")
								return (
									<td
										style={{
											width: !!campo.id ? "" : "30%",
											height: "60px",
											// backgroundColor: COLOR_CELDA,
											...celStyle,
										}}
									>
										{familiar[campo.id]}
										{campo.id === "porcentaje" ? "%" : ""}
									</td>
								)
							return (
								<td
									style={{
										width: !!campo.id ? "" : "20%",
										// backgroundColor: COLOR_CELDA,
										...celStyle,
									}}
								>
									{familiar[campo.id]}
									{campo.id === "porcentaje" ? "%" : ""}
								</td>
							)
						})}
					</tr>
				))}
			</tbody>
		</table>
	)
}

const TablaAutos = ({ autos }) => {
	const campos = [
		{
			id: "brand",
			label: "Marca",
		},

		{
			id: "model",
			label: "Modelo",
		},
		{
			id: "year",
			label: "Año",
		},
	]

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={3}
						style={{ backgroundColor: "gray" }}
					>
						DATOS DE AUTOMÓVIL
					</th>
				</tr>
				<tr>
					{campos.map((campo) => (
						<th style={{ ...celStyle, backgroundColor: "#C3C3C3" }}>
							{campo.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{autos.map((familiar) => (
					<tr>
						{campos.map((campo) => {
							return (
								<td
									style={{
										...celStyle,

										width: !!campo.id ? "" : "20%",
										// backgroundColor: COLOR_CELDA,
									}}
								>
									{familiar[campo.id]}
									{campo.id === "porcentaje" ? "%" : ""}
								</td>
							)
						})}
					</tr>
				))}
			</tbody>
		</table>
	)
}
const TablaEscolares = ({ escolares }) => {
	const campos = [
		{ id: "grado", label: "Grado" },
		{ id: "Duraccion", label: "Duración" },
		{ id: "institucion", label: "Institución" },
		{ id: "documento", label: "Documento" },
	]

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={8}
						style={{ ...celStyle, backgroundColor: "gray" }}
					>
						ESCOLARES
					</th>
				</tr>

				<tr>
					{campos.map((campo) => (
						<th style={{ ...celStyle, backgroundColor: "#C3C3C3" }}>
							{campo.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{escolares.map((familiar) => (
					<tr>
						<td style={celStyle}>{familiar.grado}</td>
						<td style={celStyle}>{`${familiar.inicio} - ${familiar.fin}`}</td>
						<td style={celStyle}>{familiar.institucion}</td>
						<td style={celStyle}>
							{familiar.documento ? "Certificado" : "NO"}
						</td>

						<td></td>
					</tr>
				))}
			</tbody>
		</table>
	)
}
const TablaExternos = ({ externos }) => {
	const campos = [
		{ id: "grado", label: "Nombre del curso " },
		{ id: "Duraccion", label: "Duración" },
		{ id: "institucion", label: "Impartido por (Institución)" },
		{ id: "documento", label: "Certificado" },
	]

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={8}
						style={{ ...celStyle, backgroundColor: "gray" }}
					>
						CURSOS EXTERNOS
					</th>
				</tr>

				<tr>
					{campos.map((campo) => (
						<th style={{ ...celStyle, backgroundColor: "#C3C3C3" }}>
							{campo.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{externos.map((familiar) => (
					<tr>
						<td style={celStyle}>{familiar.nombre}</td>
						<td style={celStyle}>{`${familiar.inicio} - ${familiar.fin}`}</td>
						<td style={celStyle}>{familiar.institucion}</td>
						<td style={celStyle}>
							{familiar.certificado ? "Certificado" : "NO"}
						</td>

						<td></td>
					</tr>
				))}
			</tbody>
		</table>
	)
}
const TablaAnteriores = ({ trabajos }) => {
	const campos = [
		{ id: "compania", label: "Compañía" },
		{ id: "giro", label: "Giro" },
		{ id: "fecha_inicio", label: "Fecha Inicio" },
		{ id: "fecha_fin", label: "Fecha Fin" },
		{ id: "puesto", label: "Puesto" },

		{ id: "funciones", label: "Funciones" },
	]

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={8}
						style={{ ...celStyle, backgroundColor: "gray" }}
					>
						TRABAJOS ANTERIORES
					</th>
				</tr>

				<tr>
					{campos.map((campo) => (
						<th
							style={{
								...celStyle,
								backgroundColor: "#C3C3C3",
								whiteSpace: "nowrap",
							}}
						>
							{campo.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{trabajos.map((trabajo) => (
					<tr>
						{campos.map((campo) => (
							<td style={celStyle}>{trabajo[campo.id]}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}
const TablaTrayectoria = ({ trayectorias }) => {
	const campos = [
		{ id: "perfil", label: "Puesto Actual" },
		{ id: "fecha_inicio", label: "Fecha Inicio" },
		{ id: "sucursal_name", label: "Sucursal" },
	]

	return (
		<table
			border="0"
			cellspacing="0"
			cellpadding="0"
			style={{ width: "100% ", textAlign: "center" }}
		>
			<thead>
				<tr>
					<th
						colSpan={8}
						style={{ ...celStyle, backgroundColor: "gray" }}
					>
						TRAYECTORIA EN LUBTRAC
					</th>
				</tr>

				<tr>
					{campos.map((campo) => (
						<th style={{ ...celStyle, backgroundColor: "#C3C3C3" }}>
							{campo.label}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{trayectorias.map((trayectoria) => (
					<tr>
						{campos.map((campo) => (
							<td style={celStyle}>{trayectoria[campo.id]}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}
