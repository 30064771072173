import React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import Skeleton from "@mui/material/Skeleton"

/**
 * Crea un esqueleto de forma cuadrada.
 *
 * @export
 * @param {Object} options - Opciones para personalizar el esqueleto.
 * @param {number} [options.xs=6] - Ancho en columnas para pantallas extra pequeñas.
 * @param {number} [options.md=4] - Ancho en columnas para pantallas medianas.
 * @param {number} [options.xl=2] - Ancho en columnas para pantallas extra grandes.
 * @param {number} [options.height=300] - Altura del esqueleto en píxeles.
 * @returns {*} El componente del esqueleto cuadrado.
 */
export const SkeletonSquare = ({ xs = 12, md = 12, xl = 12, height = 300 }) => {
	return (
		<React.Fragment>
			<React.Fragment>
				<Grid
					xs={xs}
					//sm={sm}
					md={md}
					//lg={lg}
					xl={xl}
					container
					spacing={2}
				>
					<Grid xs={12}>
						<Skeleton
							variant="rectangular"
							height={height}
						/>
					</Grid>
				</Grid>
			</React.Fragment>
		</React.Fragment>
	)
}
/**
 * Componente SkeletonSquareGrid: Muestra una cuadrícula de esqueletos cuadrados.
 *
 * @param {Object} props - Propiedades para personalizar el componente SkeletonSquareGrid.
 * @param {string} [props.variant="rectangular"] - Variante del esqueleto (por defecto, "rectangular").
 * @param {object} [props.propsG={}] - Propiedades del componente GridItem que envuelve el esqueleto.
 * @param {object} [props.propsGC={}] - Propiedades del componente GridContainer que contiene la cuadrícula de esqueletos.
 * @param {number} [props.quantity=1] - Cantidad de esqueletos a mostrar en la cuadrícula.
 * @param {number} [props.height=300] - Altura de cada esqueleto en píxeles.
 * @returns {*} El componente que renderizar la cuadrícula de esqueletos cuadrados.
 *
 * @example
 * // Ejemplo de uso:
 * <SkeletonSquareGrid
 *   variant="rectangular"
 *   propsG={{  Propiedades específicas del componente GridItem  }}
 *   propsGC={{  Propiedades específicas del componente GridContainer  }}
 *   quantity={3}
 *   height={250}
 * />
 */
export const SkeletonSquareGrid = ({
	propsGC = {},
	propsG = {},
	height = 300,
	quantity = 1,
	variant = "rectangular",
	...props
}) => {
	let skeletons = []
	for (let index = 0; index < quantity; index++) {
		skeletons.push(
			<Grid
				key={`G${index}`}
				xs={12}
				{...propsG}
			>
				<Skeleton
					{...props}
					variant={variant}
					height={height}
					width={"100%"}
				/>
			</Grid>
		)
	}

	return (
		<React.Fragment>
			<React.Fragment>
				<Grid
					xs={12}
					container
					spacing={2}
					{...propsGC}
				>
					{skeletons.map((s) => s)}
				</Grid>
			</React.Fragment>
		</React.Fragment>
	)
}
