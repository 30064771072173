import { useEffect, useState } from 'react'
import { Grid, Dialog, DialogContent, DialogTitle, Typography, Box } from '@mui/material'
import { getColaboradoresPorPerfil } from '../../Services/ApiColaboradores'
import { useSelector } from 'react-redux'
import { set } from 'react-hook-form'
import { toast } from 'react-toastify'

const ModalColaboradores = ({ open, setOpen, perfilID }) => {
    const token = useSelector(state => state.user.token)
    const [loading, setLoading] = useState(false)
    const [colaboradores, setColaboradores] = useState([])

    useEffect(() => {
        if (open === false) return
        setLoading(true)
        getColaboradoresPorPerfil(token, perfilID).then(data => {
            setColaboradores(data)
        }).catch(err => {
            toast.error(err.message)
        }).finally(() => {
            setLoading(false)
        })   
    }, [open])
    
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth onClose={() => setOpen(false)}>
            <DialogTitle sx={{ fontSize: 24, fontWeight: 'bold', color: "#555" }}>Colaboradores con este perfil</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {colaboradores.map((colaborador, index) => (
                        <Grid item xs={12} key={index}>
                            <Box sx={{ border: '1px solid #ccc', borderRadius: 1, padding: 1 }}>
                                <Typography variant="body1" color='primary' sx={{ fontWeight: 'bold' }}>{colaborador.nombre + " "+colaborador.apellido_paterno + " "+ colaborador.apellido_materno}</Typography>
                                <Typography variant="body2" sx={{ color: '#555' }}>{colaborador.sucursal__nombre}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ModalColaboradores