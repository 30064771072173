import { api_rh_get } from "#api/API_RH"
import { useEffect, useState } from "react"
import { useVacationContext } from "./UseContextVacation"

export default function UseVacation(
	base,
	params,
	activar = true,
	update = false
) {
	const [vacation, setVacation] = useState(base)
	const [loading, setLoading] = useState(true)
	const loading_act = () => setLoading(false)
	const { actualizar = null } = useVacationContext({}) || {}
	useEffect(() => {
		if (activar) {
			setLoading(true)
			// get_rh_api("vacation", setVacation, loading_act, params)
			api_rh_get("vacation", {
				setData: setVacation,
				loading: loading_act,
				axiosProps: params,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params, actualizar, activar, update])
	return { vacation, loading }
}
