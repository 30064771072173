import { PERFILES } from "../Constants/Strings";

export const useCheckPermission = (userPermission, permission) => {
    try {
        var hasPerm = userPermission.filter(userPerm => userPerm.name === permission || userPerm.name === PERFILES.ADMIN)
        return hasPerm.length > 0 ? true : false
    } catch (error) {
        console.error("Error al validar los permisos", error)
        return false
    }
}