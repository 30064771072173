import { useState,useEffect } from 'react'
import { Button, Grid, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { PRIMARYCOLOR, GREYCOLOR } from '../../../Constants/Styles';
import { uniformeArea, saveUniformeColaborador, getUniformeColaborador } from '../../../Services/Api_uniformes';
import { useCheckPermission } from '../../../Hooks/useCheckPermission';
import imgCamisa from '../../../Images/camisa.png' 
import imgBotas from '../../../Images/botas.png'
import imgPantalones from '../../../Images/pantalones.png'
import imgPolo from '../../../Images/polo.png'
import { useSelector } from 'react-redux';
import { LoadSimpleTable } from '../../Common/LoadingView'
import { toast } from 'react-toastify';
import Joyride from 'react-joyride';
import { OPTIONSJOYRIDE } from '../../../Constants/Strings';
import ItemUniforme from './ItemUniforme';
import { TALLASPANTALONES, TALLASBOTAS, TALLASCAMISAS } from '../../../Constants/Strings';



const MiUniforme = ({dataColaborador, reloadData, setReloadData}) => {
    const [loading, setLoading] = useState(false)
    const token = useSelector(state => state.user.token);
    const [camisa, setCamisa] = useState(null)
    const [polo, setPolo] = useState(null)
    const [pantalon, setPantalon] = useState(null)
    const [botas, setBotas] = useState(null)
    const [disabledButton, setDisabledButton] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const perfiles = useSelector(state => state.user.profiles);
    const isRH = useCheckPermission(perfiles,'RH')
    const [uniforme, setUniforme] = useState({'camisa':false,'pantalon':false,'polo':false,'botas':false})
    
    const tourSteps = [
        {
            target: '#gridTallas',
            disableBeacon: true,
            content: 'Selecciona las tallas de tu uniforme, si no necesitas algún articulo puedes dejarlo en blanco',
        },{
            target: '#btnEnviarSolicitud',
            disableBeacon: true,
            content: 'Envía tu solicitud, si no esta listado algún articulo que necesitas, acércate al departamento de RH',
        },
    ];

    useEffect(() => {
        setLoading(true)
        if (dataColaborador.uniforme){
            getUniformeColaborador(token,dataColaborador.id).then((response) => {
                var dataUniforme = {}
                dataUniforme.camisa = response.camisa == null ? false : true
                setCamisa(response.camisa)
                dataUniforme.polo = response.polo == null ? false : true
                setPolo(response.polo)
                dataUniforme.pantalon = response.pantalon == null ? false : true
                setPantalon(response.pantalon)
                dataUniforme.botas = response.botas == null ? false : true
                setBotas(response.botas)
                setUniforme(dataUniforme)
                setDisabledButton(true)
            }).catch((error) => {
                console.error("error",error)
                toast.error('Ocurrió un error al obtener los datos del uniforme')
            }).finally(() =>  setLoading(false))
        }else{
            uniformeArea(token,dataColaborador.area_id).then((response) => {
                setUniforme(JSON.parse(response))
            }).catch((error) => {
                console.error("error",error)
                toast.error('Ocurrió un error al obtener los datos del uniforme de tu area')
            }).finally(() =>  setLoading(false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const enviarTallas = () => {
        let year = new Date().getFullYear()
        setLoadingButton(true)
        toast.promise(saveUniformeColaborador(token,dataColaborador.id,camisa,polo,pantalon,botas, year), {
            pending: 'Enviando solicitud...',
            success: {
                render({ data }) {
                    setReloadData(!reloadData)
                    return 'Tu solicitud se ha registrado con éxito'
                },
            },
            error: 'No se pudo enviar tu solicitud, inténtalo mas tarde'
        }, { autoClose: 2000 }).then(() => {
            setLoadingButton(false)
        })
    }

    if (loading) 
        return <LoadSimpleTable numRows={3} />

    return (
        <>
            <Joyride
                continuous={true}
                locale={OPTIONSJOYRIDE}
                showProgress
                run={!dataColaborador.uniforme && !isRH && process.env.REACT_APP_VERSION.startsWith('1.2')}                steps={tourSteps} />
            <Grid container sx={{ textAlign: 'center' }} >
                <Grid item md={12} >
                { disabledButton ? <>
                        <Box component={'h1'} sx={{ fontSize: 22, fontWeight: 'bold', color: PRIMARYCOLOR,mb:0 }}> Tu uniforme ya fue solicitado </Box> 
                        <Box component={'span'} sx={{ fontSize: 14, fontWeight: 'bold', color: GREYCOLOR }}> Si las tallas seleccionadas son incorrectas o necesitas algún otro articulo acércate al departamento de RH</Box>    
                    </>:<>
                        <Box component={'h1'} sx={{ fontSize: 22, fontWeight: 'bold', color: PRIMARYCOLOR,mb:0 }}> Selecciona las tallas para tu uniforme </Box>    
                        <Box component={'span'} sx={{ fontSize: 14, fontWeight: 'bold', color: GREYCOLOR }}> Selecciona la talla solo de los artículos que corresponden a tu area y actividades</Box>    
                    </> }
                </Grid>
                <Grid container item md={12} id="gridTallas" >
                    {uniforme.camisa ?  <ItemUniforme imagen={imgCamisa} nombre={'Camisa'} tallas={TALLASCAMISAS} value={camisa} setValue={setCamisa} disabledSelect={disabledButton}/> :<></>}
                    {uniforme.polo ? <ItemUniforme imagen={imgPolo} nombre={'Playera Polo'} tallas={TALLASCAMISAS} value={polo} setValue={setPolo} disabledSelect={disabledButton}/> : <></> }
                    {uniforme.pantalon && <ItemUniforme imagen={imgPantalones} nombre={'Pantalon'} tallas={TALLASPANTALONES} value={pantalon} setValue={setPantalon} disabledSelect={disabledButton}/> }
                    {uniforme.botas && <ItemUniforme imagen={imgBotas} nombre={'Botas de Seguridad'} tallas={TALLASBOTAS} value={botas} setValue={setBotas} disabledSelect={disabledButton}/> }
                </Grid>
                <Grid item md={4} sx={{ mt: 2 }}></Grid>
                { disabledButton ?<></>:
                    <Grid item md={4}   sx={{ mt: 5 }}>
                        <Button
                        id='btnEnviarSolicitud'
                            variant="contained"
                            color='success'
                            fullWidth
                            disabled={loadingButton}
                            startIcon={<SendIcon />}
                            onClick={() => enviarTallas()}>
                            Enviar solicitud
                        </Button>
                    </Grid>
                }
            </Grid>

        </>
    )
}

export default MiUniforme