import { bajaFilesGet, bajaFilesPut } from "#api/Api/baja"
import ButtonFile from "#common/buttons/ButtonFile"
import { DialogViewPDF } from "#common/Dialog/DialogViewPDF"
import { HasPermission } from "#common/Funciones"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
import { TERMINATION } from "#constants/StatusRequest "
import BlockIcon from "@mui/icons-material/Block"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import { UseBajasFiles } from "Components/bajas/UseBajas"
import { saveAs } from "file-saver"
import { useState } from "react"
import { UseDialogBajaContext } from "./DialogBajaContext"

export default function Documents() {
	const [actualizar, setActualizar] = useState(false)
	const { files, loading } = UseBajasFiles(actualizar)

	const items = files.map((item, index) => {
		const filesInServer = ["Carta Renuncia", "Entrevista de salida"]
		const filesApproved = {
			"Resección Firmado": { status: TERMINATION.RECESSION_SIGN },
		}

		if (filesInServer.includes(item.description)) {
			return (
				<ButtonsDocumentsInServer
					key={"file" + item.id}
					label={item.description}
					file={item}
					setActualizar={setActualizar}
					approve={filesApproved}
				/>
			)
		} else {
			return (
				<ButtonsDocuments
					key={"file" + item.id}
					label={item.description}
					file={item}
					setActualizar={setActualizar}
					approve={filesApproved}
				/>
			)
		}
	})
	if (loading) return <CircularSkeleton />
	return (
		<Box
			display={"flex"}
			width={"100%"}
		>
			<Stack
				width={"100%"}
				spacing={2}
				justifyContent={"space-between"}
				divider={
					<Divider
						orientation="horizontal"
						// flexItem
					/>
				}
			>
				{items}
			</Stack>
		</Box>
	)
}

const ButtonsDocumentsInServer = ({ label, file, setActualizar, approve }) => {
	const { baja } = UseDialogBajaContext()
	const { url_s3, description } = file
	const [open, setOpen] = useState(false)
	const [link, setLink] = useState(false)
	let download
	if (url_s3) {
		download = () => {
			const success = (data) => {
				setOpen(true)
				setLink(data)
			}
			bajaFilesGet(baja.id, {
				success,
				params: { url_s3: file.id },
			})
		}
	} else if (description === "Carta Renuncia") {
		download = () => downloadCartaRenuncia(baja.id)
	} else if (description === "Entrevista de salida") {
		download = () => downloadEntrevistaSalida(baja.id)
	}

	let button = null
	if (url_s3)
		button = (
			<Button
				fullWidth
				onClick={download}
				startIcon={url_s3 ? <RemoveRedEyeIcon /> : <FileDownloadIcon />}
			>
				Ver Archivo
			</Button>
		)
	else
		button = (
			<Button
				fullWidth
				onClick={download}
				startIcon={url_s3 ? <RemoveRedEyeIcon /> : <FileDownloadIcon />}
			>
				Descargar Formato
			</Button>
		)

	return (
		<Stack
			direction={{ xs: "column", sm: "row" }}
			spacing={2}
			alignItems={"center"}
		>
			<Typography
				width={{ xs: "100%", md: "30%" }}
				textAlign={{ xs: "center", sm: "left" }}
			>
				{label}
			</Typography>
			<Stack
				width={"100%"}
				direction={{ xs: "column", sm: "row" }}
				spacing={2}
			>
				{button}
				<ButtonsFileUpload
					{...{
						bajaId: baja.id,
						fileId: file.id,
						url_s3,
						setActualizar,
						approve,
					}}
				/>
			</Stack>
			<DialogViewPDF {...{ open, setOpen, urlFile: link }} />
		</Stack>
	)
}
const ButtonsDocuments = ({ label, file, setActualizar, approve }) => {
	const { baja } = UseDialogBajaContext()
	const { url_s3 } = file
	const [open, setOpen] = useState(false)
	const [link, setLink] = useState(false)
	const download = () => {
		const success = (data) => {
			setOpen(true)
			setLink(data)
		}
		bajaFilesGet(baja.id, {
			success,
			params: { url_s3: file.id },
		})
	}

	return (
		<Stack
			direction={{ xs: "column", sm: "row" }}
			spacing={2}
			alignItems={"center"}
		>
			<Typography
				width={{ xs: "100%", md: "30%" }}
				textAlign={{ xs: "center", sm: "left" }}
			>
				{label}
			</Typography>
			<Stack
				width={"100%"}
				direction={{ xs: "column", sm: "row" }}
				spacing={2}
			>
				{url_s3 && (
					<Button
						fullWidth
						onClick={download}
						startIcon={url_s3 ? <RemoveRedEyeIcon /> : <FileDownloadIcon />}
					>
						Ver Archivo
					</Button>
				)}
				<ButtonsFileUpload
					{...{
						bajaId: baja.id,
						description: file.description,
						fileId: file.id,
						url_s3,
						setActualizar,
						approve,
					}}
				/>
			</Stack>
			<DialogViewPDF {...{ open, setOpen, urlFile: link }} />
		</Stack>
	)
}

const downloadCartaRenuncia = (bajaId) => {
	const success = (data) => {
		saveAs(data.data, `carta_renuncia.pdf`)
	}
	bajaFilesGet(bajaId, {
		success,
		all: true,
		params: { file: "carta_renuncia" },
		axiosProps: { responseType: "blob" },
	})
}
const downloadEntrevistaSalida = (bajaId) => {
	const success = (data) => {
		window.open(data, "_blank")
	}
	bajaFilesGet(bajaId, {
		success,
		params: { file: "entrevista_salida" },
	})
}
const uploadFile = (bajaId, id, file, success) => {
	const formData = new FormData()
	formData.append("id", id)

	formData.append("file", file)
	bajaFilesPut(bajaId, formData, {
		success,
	})
}
const deleteFile = (bajaId, id, success) => {
	bajaFilesPut(
		bajaId,
		{ id },
		{
			success,
			params: { delete_file: true },
		},
	)
}
const approveFile = (bajaId, id, approval_file, success) => {
	bajaFilesPut(
		bajaId,
		{ id, approval_file },
		{
			success,
		},
	)
}

const ButtonsFileUpload = ({
	// bajaId,
	fileId,
	url_s3,
	setActualizar,
	description,
	approve,
}) => {
	const { baja, reload, disabled } = UseDialogBajaContext()
	const { id: bajaId } = baja
	const isAdmin = HasPermission()
	const success = (data) => {
		setActualizar((value) => !value)
		reload()
	}
	let buttons = []
	if (!url_s3)
		buttons.push(
			<ButtonFile
				key={"upload"}
				fullWidth
				startIcon={<FileUploadIcon />}
				label={url_s3 ? "Actualizar el archivo" : "subir Archivo"}
				getFiles={(files) => uploadFile(bajaId, fileId, files[0], success)}
				hidden={disabled}
			/>,
		)
	if (
		url_s3 &&
		Object.keys(approve).includes(description) &&
		approve[description]?.status === baja?.termination_status_id
	)
		buttons.push(
			<Button
				key={"uploadApprove"}
				startIcon={<CheckCircleIcon />}
				fullWidth
				onClick={() => approveFile(bajaId, fileId, true, success)}
			>
				aprobar
			</Button>,
			<Button
				fullWidth
				key={"uploadReject"}
				startIcon={<BlockIcon />}
				onClick={() => approveFile(bajaId, fileId, false, success)}
			>
				rechazar
			</Button>,
		)

	if (isAdmin && url_s3 && !disabled)
		buttons.push(
			<Button
				key={"uploadDeleted"}
				onClick={() => deleteFile(bajaId, fileId, success)}
			>
				<DeleteIcon />
			</Button>,
		)
	return buttons
}
