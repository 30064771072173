import { useMemo } from "react"
//componentes
import { HasPermission } from "../Common/Funciones"
import { UseFondoFijoV2 } from "./useFondoFijo"

//mui
import Skeleton from "@mui/material/Skeleton"
import MUIDataTable from "mui-datatables"

export const TablaFondoFijoActivos = () => {
	const is_rh = HasPermission("RH")
	const params = useMemo(() => {
		return { activas: true }
	}, [])

	const { fondoFijo, loading } = UseFondoFijoV2({ params })

	const columns = [
		{
			name: "colaborador_name",
			label: "Colaborador",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "puesto_name",
			label: "Puesto",
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: "puesto_area",
			label: "Departamento",
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: "sucursal_name",
			label: "Sucursal",
			options: {
				filter: true,
				sort: false,
			},
		},

		{
			name: "fondo_fijo",
			label: "Monto",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					let valor = parseFloat(value)
					if (!valor) return "N/A"

					return `$${valor.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}`
				},
			},
		},
	]

	const options = {
		textLabels: {
			body: {
				noMatch: "No hay solicitudes", // Cambia este mensaje
			},
		},
		rowsPerPage: 100,
		search: false,
		filter: true, //, authorizedUser([PERFILES["PAGOS"]]),
		download: true,
		print: false,
		viewColumns: false,
		selectableRows: "none",
	}

	if (loading)
		return (
			<Skeleton
				variant="rectangular"
				width={"100%"}
				height={"60vh"}
			/>
		)

	return (
		<MUIDataTable
			title={""}
			data={fondoFijo}
			columns={columns}
			options={options}
		/>
	)
}
