import AddCircleIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import TrackChangesIcon from "@mui/icons-material/TrackChanges"
import { Stack } from "@mui/material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import TextField from "@mui/material/TextField"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect, useState } from "react"

export const Responsabilidades = ({
	dataPerfil,

	setDataSend,
}) => {
	const [textResponsabilidades, setTextResponsabilidades] = useState("")
	const [listResponsabilidades, setListResponsabilidades] = useState(
		dataPerfil == null
			? []
			: dataPerfil.perfil_competencia.filter(
					(item) => item.tipo === "Responsabilidades",
			  ),
	)

	const handleAdd = () => {
		setListResponsabilidades([
			...listResponsabilidades,
			{ id: 0, tipo: "Responsabilidades", descripcion: textResponsabilidades },
		])
		setTextResponsabilidades("")
	}
	const removeItem = (indexList) =>
		setListResponsabilidades(
			listResponsabilidades.filter((item, index) => index !== indexList),
		)
	useEffect(() => {
		setDataSend((oldData) => ({
			...oldData,
			[`list_responsabilidades`]: listResponsabilidades,
		}))
	}, [listResponsabilidades, setDataSend])
	return (
		<Grid2
			container
			spacing={2}
		>
			<Grid xs={12}>
				<Stack
					direction={"row"}
					spacing={2}
					margin={1}
				>
					<TextField
						margin="dense"
						onChange={(event) => setTextResponsabilidades(event.target.value)}
						value={textResponsabilidades}
						fullWidth
					/>
					<IconButton
						aria-label="delete"
						sx={{ mt: 1 }}
						onClick={handleAdd}
					>
						<AddCircleIcon
							color="success"
							fontSize="large"
						/>
					</IconButton>
				</Stack>
			</Grid>

			<Grid xs={12}>
				<List dense={false}>
					{listResponsabilidades.map((item, index) => {
						return (
							<Box key={index}>
								<ListItem
									secondaryAction={
										<IconButton
											edge="end"
											onClick={() => removeItem(index)}
										>
											<DeleteIcon color="error" />
										</IconButton>
									}
								>
									<ListItemIcon>
										<TrackChangesIcon color="primary" />
									</ListItemIcon>
									<ListItemText primary={item.descripcion} />
								</ListItem>
								<Divider />
							</Box>
						)
					})}
				</List>
			</Grid>
		</Grid2>
	)
}
