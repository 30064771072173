import { cursoDeleteApi, cursoPutApi } from "#api/Api_cursos"
import { useMemo } from "react"
import { ESTADO_CURSOS } from "../../constants_capacitaciones"
// import { cursoPutApi, deleteApiCursos, putApiCursos } from "../../../../Services/Api_cursos"
import { MasterDialog } from "#common/Dialog/Dialog"
import InputText from "#common/input/InputText"
import { Stack, Typography } from "@mui/material"
import { useState } from "react"
import { toast } from "react-toastify"

export default Confirmation
function Confirmation({
	newStatus,
	open,
	setOpen,
	idCursoColaborador,
	setActualizar = () => {},
	reload = () => {},
}) {
	const [message, setMessage] = useState("")
	let mensaje = {
		[ESTADO_CURSOS["APROBADO"]]: "¿Estas seguro de aprobar este curso?",
		[ESTADO_CURSOS["CANCELADO"]]: "¿Estas seguro de cancelar este curso?",
		[ESTADO_CURSOS["RECHAZADO"]]: "¿Estas seguro de rechazar este curso?",
		eliminar: "¿Estas seguro de Eliminar este curso?",
	}

	const handleClose = () => {
		setOpen(false)
	}
	async function aceptar() {
		const success = () => {
			setActualizar((old) => !old)
			handleClose()
			reload((old) => !old)
		}
		if (newStatus === ESTADO_CURSOS.RECHAZADO && message?.trim() === "") {
			toast.error("Debes escribir un motivo para rechazar el curso")
			return null
		}

		if (newStatus !== "eliminar")
			cursoPutApi(
				idCursoColaborador,
				{
					status: newStatus,
					mensaje: message,
				},
				{ success },
			)
		else {
			cursoDeleteApi(idCursoColaborador, { success })
		}
	}

	const content = useMemo(() => {
		if (newStatus === ESTADO_CURSOS["RECHAZADO"])
			return (
				<InputText
					label="Motivo del rechazo"
					setValue={setMessage}
				/>
			)
		else return null
	}, [newStatus])

	return (
		<MasterDialog
			title={`Confirmación`}
			open={open}
			setOpen={setOpen}
			closeDialog={handleClose}
			buttons={[
				{ label: "Cancelar", onClick: handleClose },
				{ label: "Aceptar", onClick: aceptar },
			]}
		>
			<Stack spacing={2}>
				<Typography textAlign={"center"}>{mensaje[newStatus]}</Typography>
				{content}
			</Stack>
		</MasterDialog>
	)
}
