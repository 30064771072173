import axios from 'axios'
const URLAPI = process.env.REACT_APP_SERVER_URL

export const uniformeArea = async (token, area_id) => {
    const request = await axios.get(URLAPI + 'rh/uniformes/uniforme_area', { params: {"area_id":area_id}, headers: { Authorization: 'Token ' + token } })
    return request.data
}

export const getUniformeColaborador = async (token, colaborador_id = null) => {
    const request = await axios.get(URLAPI + 'rh/uniformes/uniforme_colaborador', { params:{colaborador_id}, headers: { Authorization: 'Token ' + token } })
    return request.data
}

export const saveUniformeColaborador = async (token, colaborador, camisa, polo, pantalon, botas, periodo) => {
    const request = await axios.post(URLAPI + 'rh/uniformes/uniforme_colaborador', { colaborador,camisa,polo,pantalon,botas, periodo}, { headers: { Authorization: 'Token ' + token } })
    return request.data
}

export const totalesUniformes = async (token, area = null) => {
    const request = await axios.get(URLAPI + 'rh/uniformes/totales', { params:{area}, headers: { Authorization: 'Token ' + token } })
    return request.data
}

export const listUniformesColaborador = async (token) => {
    const request = await axios.get(URLAPI + 'rh/uniformes/lista_colaboradores', { headers: { Authorization: 'Token ' + token } })
    return request.data
}