import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { reloadDataVacante } from "../../../app/pageSlice";
import { candidatosPost } from '../../../Services/Api';
import { GridInputText } from '../../Common/Inputs';

const ModalNewCandidato = ({open, setOpen, vacanteID}) => {
    const [nombre,setNombre] = useState('')
    const [telefono,setTelefono] = useState('')
    const [correo,setCorreo] = useState('')
    const [btnDisabled,setBtnDisabled] = useState(false)
    const [cv, setCV] = useState(null);
    const dispatch = useDispatch()

    const fileChange = (e) => setCV(e.target.files && e.target.files.length > 0 ? e.target.files[0]: null);

    const save = () =>{
        setBtnDisabled(true)

        let formData = new FormData();
        formData.append("nombre", nombre);
        formData.append("correo", correo);
        formData.append("telefono", telefono);
        formData.append("vacante_id", vacanteID);
        formData.append("cv", cv);
        
        const success=()=>{
            dispatch(reloadDataVacante())
            setOpen(false)

        }
        const end=()=>{
            setBtnDisabled(false)
        }
        candidatosPost(formData,{success,end})
        // saveCandidatoVacante(token,cv,nombre,correo,telefono,vacanteID).then(response => {
        //     alert('Se ha agregado al candidato')
        //     dispatch(reloadDataVacante())
        //     setOpen(false)
        // }).catch(error =>alert(error)
        // ).finally(()=>setBtnDisabled(false))
    }

    if(!open)
        return
    return(
        <Dialog open={open} maxWidth={'xs'} fullWidth={true} >
            <DialogTitle sx={{fontSize:24,fontWeight:'bold',color: "#555"}}>Nuevo Candidato</DialogTitle>
            <DialogContent>
            <Grid container sx={{mt:0}} spacing={3}>
                <GridInputText  label={'Nombre del Candidato'} value={nombre} setValue={setNombre} size={12}/>
                <GridInputText  label={'Telefono'} value={telefono} setValue={setTelefono} size={12} typeInput={'number'}/>
                <GridInputText  label={'Correo'} value={correo} setValue={setCorreo} size={12}/>
                <Grid item md={12}>
                    <Button
                        sx={{ mt: 1, mb: 2 }}
                        fullWidth
                        variant="contained"
                        component="label" >
                        Agregar CV
                        <input type="file" hidden onChange={fileChange} accept=".pdf "/>
                    </Button>
                    <Typography sx={{ mt: 12 }} variant="span" gutterBottom>{cv == null ? '' : cv.name}</Typography>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>setOpen(false)} disabled={btnDisabled}>Cancelar</Button>
                <Button onClick={() =>save()} disabled={btnDisabled}>Agregar candidato</Button>
            </DialogActions>
        </Dialog>
    )

}

export default ModalNewCandidato