import MenuIcon from "@mui/icons-material/Menu"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"

import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import logo from "../../Images/logo.jpg"
import Rutas from "./Rutas"
//Componentes
import { COLOR_PRIMARY } from "../../Constants/Styles"
import { actualizarNotify } from "../../app/notificationSlice"
import MenuListItems from "./ItemsMenu"
import MenuUser from "./MenuUser"
import { Button } from "@mui/material"

const drawerWidth = 300

export default function MenuPrincipal(props) {
	const user = useSelector((state) => state.user)
	const titlePage = useSelector((state) => state.page.title)
	const [open, setOpen] = useState(false)
	const [isClosing, setIsClosing] = useState(false)
	const dispatch = useDispatch()

	const handleDrawerClose = () => {
		setIsClosing(true)
		setOpen(false)
	}

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false)
	}

	const handleDrawerToggle = () => {
		if (!isClosing) {
			setOpen(!open)
		}
	}
	const footerStyle = {
		position: "fixed",
		bottom: 0,
		color: "#eeeeee",
		backgroundColor: COLOR_PRIMARY,
		fontWeight: "bold",
	}
	useEffect(() => {
		dispatch(actualizarNotify(user.token))
	}, [dispatch, user.token])

	const drawer = (
		<div>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography
					sx={{ fontSize: 20, fontWeight: "bold", color: COLOR_PRIMARY, mt: 2 }}
				>
					Bienvenido
				</Typography>
				<Typography sx={{ fontSize: 16, color: COLOR_PRIMARY }}>
					{user.nombre}
				</Typography>
			</Box>
			{/* <Toolbar /> */}
			<Divider />
			<MenuListItems />
			<Typography
				variant="body1"
				color="inherit"
				textAlign={"center"}
				style={footerStyle}
				width={drawerWidth}
			>
				V. {process.env.REACT_APP_VERSION}
			</Typography>
		</div>
	)

	// Remove this const when copying and pasting into your project.

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					backgroundColor: process.env.COLOR_PRIMARY,
					position: "fixed",
					top: "0",
				}}
			>
				<Toolbar variant="dense">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2 }}
					>
						<MenuIcon />
					</IconButton>

					<Link
						to="/perfil"
						style={{ textDecoration: "none", color: "red" }}
					>
						<Button
							variant="text"
							// onClick={() => alert("hi")}
							sx={{ height: 30 }}
						>
							<img
								src={logo}
								alt="The house from the offer."
								style={{ height: 30, width: "auto" }} // Estilo para asegurar que la imagen se ajuste al tamaño del IconButton
							/>
						</Button>
					</Link>

					<Box
						display="flex"
						flexDirection="column" // Elementos en columnas
						alignItems="center"
						justifyContent="center"
						textAlign="center" // Alineación del texto al centro
						width="100%"
						sx={{ marginLeft: -20 }} // El Box toma todo el ancho disponible
					>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
						>
							{titlePage}
						</Typography>
					</Box>

					<MenuUser />
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				aria-label="mailbox folders"
			>
				<Drawer
					variant="temporary"
					open={open}
					onTransitionEnd={handleDrawerTransitionEnd}
					onClose={handleDrawerClose}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					display: "flex",
					flexDirection: "column",
					padding: "0px",
					width: "100%",
					height: "100vh",
					overflow: "hidden",
					position: "relative",
					backgroundColor: (theme) =>
						theme.palette.mode === "light"
							? theme.palette.grey[100]
							: theme.palette.grey[900],
				}}
			>
				<Toolbar
					sx={{ flexGrow: 0 }}
					variant="dense"
				/>
				{/* Establecer flexGrow en 0 para que no crezca */}
				<Box
					sx={{
						flexGrow: 1,
						overflow: "auto",
						padding: "15px",
						paddingX: "2vw",
					}}
				>
					{/* Este componente ocupará todo el espacio restante */}
					<Rutas />
				</Box>
			</Box>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
				theme="dark"
			/>
		</Box>
	)
}
