import React from "react"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux" //Token
import { getPrestamosStatus } from "../Services/Api_prestamos"
import { PERFILES } from "../Constants/Strings"
import { changeTitle } from "../app/pageSlice"
import { STATUS_LOAN } from "../Components/Colaboradores/Solicitudes/constants"
import { hasPermission, supremeRole } from "../Components/Common/Funciones"
//Componentes
import PrestamosTabla from "../Components/Colaboradores/Solicitudes/prestamos_componentes/PrestamosTabla"
//Material UI
import Grid from "@mui/material/Unstable_Grid2"
import { SkeletonSquareGrid } from "../Components/Common/Skeleton"
import PrestamosFila from "./../Components/Colaboradores/Solicitudes/prestamos_componentes/PrestamosFila"
import { InputSelectGrid2 } from "../Components/Common/Inputs2"

function PrestamosSolicitudes() {
	const dispatch = useDispatch()
	const token = useSelector((state) => state.user.token)
	const [status, setStatus] = useState(STATUS_LOAN["PROCESS"])
	const [dbStatus, setDBStatus] = useState([])
	const [skeleton1, setSkeleton1] = useState(false)
	const [actualizar, setActualizar] = useState(true)
	const PERMISOS = {
		[PERFILES["ADMIN"]]: {
			user: PERFILES["ADMIN"],
			status: status,
		},
		[PERFILES["RH"]]: {
			user: PERFILES["RH"],
			status: status,
		},
		[PERFILES["GERENTE"]]: {
			user: PERFILES["GERENTE"],
			status: STATUS_LOAN["VALIDATE_RH"],
		},
		[PERFILES["PAGOS"]]: {
			user: PERFILES["PAGOS"],
			status: STATUS_LOAN["PAY"],
		},
		[PERFILES["COLABORADOR"]]: {
			user: "Colaborador",
			status: STATUS_LOAN["AWAITED_DOCUMENT"],
		},
	}

	let puesto = useSelector((state) => state.user.profiles)
	let permiso = PERMISOS["Colaborador"]
	let filtroApi = { status: permiso["status"] }
	let selectShow = hasPermission(puesto, [PERFILES.RH])
	const rowShow = hasPermission(puesto, [PERFILES.RH, PERFILES.PAGOS])

	useEffect(() => {
		dispatch(changeTitle("Solicitud Préstamo"))
		getPrestamosStatus(token, setDBStatus, {}, setSkeleton1)
	}, [dispatch, token])

	puesto = supremeRole(puesto, [
		PERFILES["ADMIN"],
		PERFILES["RH"],
		PERFILES["PAGOS"],
		PERFILES["GERENTE"],
		PERFILES["COLABORADOR"],
	])

	permiso = PERMISOS[puesto]
	if (!permiso) permiso = PERMISOS["Colaborador"]

	filtroApi["status"] = permiso["status"]

	if (!permiso) permiso = { user: 0, status: 404 }

	if (permiso["user"] === PERFILES.GERENTE) filtroApi["jefe"] = ""

	return (
		<React.Fragment>
			{!skeleton1 && <SkeletonSquareGrid />}

			{skeleton1 && (
				<Grid
					container
					columnSpacing={3}
					rowSpacing={1}
					justifyContent={"center"}
					alignItems={"center"}
				>
					{selectShow && (
						<InputSelectGrid2
							label="Estado"
							data={dbStatus}
							dataLabel="descripcion"
							dataID="id"
							value={status}
							setValue={setStatus}
							all="TODO"
						/>
					)}
					{rowShow && (
						<Grid>
							<PrestamosFila
								show={false}
								actualizar={actualizar}
							/>
						</Grid>
					)}
					<PrestamosTabla
						showFila={true}
						filtro={filtroApi}
						permiso={permiso}
						actualizar={actualizar}
						setActualizar={setActualizar}
						size={12}
					/>
				</Grid>
			)}
		</React.Fragment>
	)
}

export default PrestamosSolicitudes
