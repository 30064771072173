import { TextInfoField } from "#common/Texto"
// Material
import Box from "@mui/material/Box"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
// Componentes
import * as TEXT from "../../TextBaja"
import { UseDialogBajaContext } from "./DialogBajaContext"

export default function InfoBajasDialog({ baja, notAll = false }) {
	const {
		perfil_puesto = "",
		no_empleado = "",
		name_collaborator = "",
	} = baja || {}
	const { disabled } = UseDialogBajaContext()
	let info = [
		{ xs: 4, campo: TEXT.NO_EMPLEADO, valor: no_empleado },
		{
			xs: 4,
			campo: TEXT.NAME_COLLABORATOR,
			valor: name_collaborator,
		},
		{ xs: 4, campo: TEXT.PERFIL_PUESTO, valor: perfil_puesto },
	]
	if (disabled) return null
	return <InformationView data={info} />
}
export function InformationSolicitudBajas({ baja }) {
	const {
		request_date,
		reason,
		name_request_collaborator,
		type,
		termination_date,
		termination_status,
		approval_date,
	} = baja
	let info = [
		{
			xs: 4,
			campo: TEXT.NAME_REQUEST_COLLABORATOR,
			valor: name_request_collaborator,
		},
		{ xs: 4, campo: TEXT.TERMINATION_STATUS, valor: termination_status },
		{ xs: 4, campo: TEXT.TYPE_BAJA, valor: type },

		{ xs: 4, campo: TEXT.REQUEST_DATE, valor: request_date },
		{ xs: 4, campo: TEXT.TERMINATION_DATE, valor: termination_date },
		{ xs: 4, campo: TEXT.APPROVAL_DATE, valor: approval_date },

		{ xs: 12, campo: TEXT.REASON, valor: reason },
	]

	return <InformationView data={info} />
}
export function InformationTracsaSolicitudBajas({ baja }) {
	const { termination_type_1, termination_type_2, termination_type_3 } = baja
	let info = [
		{ xs: 12, campo: TEXT.TYPE_1, valor: termination_type_1, noWrap: false },
		{ xs: 12, campo: TEXT.TYPE_2, valor: termination_type_2, noWrap: false },
		{ xs: 12, campo: TEXT.TYPE_3, valor: termination_type_3, noWrap: false },
	]
	return <InformationView data={info} />
}

function InformationView({ data }) {
	return (
		<Box>
			<Grid2
				container
				rowSpacing={2}
			>
				{data.map((item, index) => {
					let contenido = null
					if (item?.customRender) contenido = item.customRender
					else if (item.valor)
						contenido = (
							<TextInfoField
								whiteSpace="pre-line"
								{...item}
								separador={"\n"}
							/>
						)
					else return null
					return (
						<Grid2
							xs={item["xs"]}
							key={item.campo + index}
						>
							{contenido}
						</Grid2>
					)
				})}
			</Grid2>
		</Box>
	)
}
