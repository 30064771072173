import { apiPutRH } from "#api/API_RH"
const PROMOTIONS = "promotions"
const PROMOTION = (id) => `${PROMOTIONS}/${id}`
const PROMOTIONS_DETAIL = (id) => `${PROMOTIONS}/details/${id}`

/**
 * @typedef {Object} promotionsPutId
 * @property {number} id - Identificador único de la promoción.
 * @property {any} data - Datos asociados a la promoción.
 * @property {apiPutRhSettingsParams} settings - Configuración específica para la solicitud PUT.
 */

/**
 * @param {promotionsPutId} promotion
 */
export function promotionsDetailPut(id, data, settings) {
	apiPutRH(PROMOTIONS_DETAIL(id), data, settings)
}

/**
 * @param {promotionsPutId} promotion
 */
export function promotionPut(id, data, settings) {
	apiPutRH(PROMOTION(id), data, settings)
}
