import { reloadTable } from "app/pageSlice"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { apiPutVerification } from "#api/ApiColaborador"
import { ButtonDialog, DialogGeneral } from "#common/Dialog"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"

//mui
import { Stack, Typography } from "@mui/material"
import { api_rh_put } from "#api/API_RH"

export default function DialogEmailPassword(props) {
	const id = props?.colaborador?.id
	const colaborador = props?.colaborador?.nombre_colaborador
	const { setOpen, reloadNow } = props
	const dispatch = useDispatch()

	const [showPassword, setShowPassword] = useState(false)
	const [endEmail, setEndEmail] = useState("")
	const [dataSend, setDataSend] = useState({})

	const handleClickShowPassword = () => setShowPassword((show) => !show)
	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	useEffect(() => {
		const area_id = props.colaborador.area_id
		const sucursal = props.colaborador.sucursal
		let endMail = ""
		let not_finally = [3, 13]
		if (!not_finally.includes(area_id)) endMail = "@lubtrac.com"
		else if (area_id === 2) endMail = "@becoming"
		if (endMail)
			if (sucursal === "Guatemala") endMail += ".gt"
			else endMail += ".mx"
		setEndEmail(endMail)
	}, [props.colaborador.area_id, props.colaborador.sucursal])

	async function send() {
		let data = { ...dataSend }
		data.email = data.email + endEmail

		const response = await api_rh_put(
			`colaboradores/${id}/email_institution`,
			data,
			{
				params: { new_email: true },
			},
		)
		if (response) {
			reloadNow()
			setOpen(false)
		}
	}

	const buttonsOption = [
		<ButtonDialog
			key={"cancelar"}
			label="Cancelar"
			onClick={() => setOpen(false)}
		/>,
		<ButtonDialog
			key={"enviar"}
			label="Enviar"
			onClick={() => send()}
		/>,
	]
	return (
		<DialogGeneral
			{...props}
			title={
				<Typography variant="h6">
					Correo Lubtrac
					<br />
					{colaborador}
				</Typography>
			}
			buttons={buttonsOption}
		>
			<Stack
				spacing={2}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<TextField
					label="Correo electrónico"
					variant="outlined"
					fullWidth
					onChange={(event) => {
						setDataSend({ ...dataSend, email: event.target.value })
					}}
					InputProps={{
						endAdornment: endEmail,
					}}
				/>
				<TextField
					label="Correo electrónico"
					variant="outlined"
					fullWidth
					onChange={(event) => {
						setDataSend({ ...dataSend, password: event.target.value })
					}}
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>
		</DialogGeneral>
	)
}
