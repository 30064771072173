/**
 * Constantes para el manejo de las solicitudes caja ahorro
 * @param {number} APROBADO - 1 Estado aprobado
 * @param {number} RECHAZADO - 2 Estado rechazado
 * @param {number} REVISAR - 3 Estado revisar
 * @param {number} FINALIZADO - 4 Estado finalizado
 */
export const ESTADOS = {
	APROBADO: 1,
	RECHAZADO: 2,
	REVISAR: 3,
	FINALIZADO: 4
}

/**
 * 
 * @description STATUS_LOAN es un diccionario que mapea los diferentes estados posibles de Prestamos a 
sus valores numéricos correspondientes en la base de datos.
Cada estado está representado por una clave y tiene un valor entero asociado.
* @param {number} PROCESS - 1 Solicitudes en proceso
* @param {number} VALIDATE_RH - 2 solicitud validada por RH
* @param {number} VALIDATE_BOSS - 3 solicitud validada por jefe
* @param {number} AWAITED_DOCUMENT - 4 solicitud en espera de documentos
* @param {number} PASS_PAY - 5 Autorizan pago de préstamo RH 
* @param {number} PAY - 6 Préstamo pagado
* @param {number} PAY_BACK - 7 pagando el préstamo
* @param {number} FINISHED - 8 finalizado
* @param {number} REJECT - 9 rechazado
"""
 */
export const STATUS_LOAN = {
	PROCESS: 1,
	VALIDATE_RH: 2,
	VALIDATE_BOSS: 3,
	AWAITED_DOCUMENT: 4,
	PASS_PAY: 5,
	PAY: 6,
	PAY_BACK: 7,
	FINISHED: 8,
	REJECT: 9
}
