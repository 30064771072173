import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

import FormBase from "../../Common/Forms"
import { DialogForm } from "./../../Common/Dialog"
import { apiPostCar, apiPutCar } from "../../../Services/ApiColaborador"

const DATA = [
	{
		field: "input",
		id: "brand",
		label: "Marca",
		registerParams: { required: true },
	},
	{
		field: "input",
		id: "model",
		label: "Modelo",
		registerParams: { required: { value: true } },
	},

	{
		field: "date",
		id: "year",
		label: "Año",
		views: ["year"],
		registerParams: { required: { value: true } },
		multiline: true,
	},
]

export default function FormCar({
	idColaborador,
	open,
	setOpen,
	edit,
	actualizar,
}) {
	const token = useSelector((state) => state.user.token)
	const form = useForm({ defaultValues: edit })
	const { handleSubmit, reset } = form
	const [button, setButton] = useState(false)
	useEffect(() => {
		if (!!edit) {
			reset(edit)
		} else reset({})
	}, [edit, reset, open])

	const submit = async (data) => {
		setButton(true)
		data.colaborador = idColaborador

		if (typeof data.year === "object") {
			data.year = data.year["$y"]
		}

		let response
		if (edit) response = await apiPutCar(token, data)
		else response = await apiPostCar(token, data)

		if (response.success) {
			actualizar()
			setOpen(false)
		}
		setButton(false)
	}

	return (
		<React.Fragment>
			<DialogForm
				title={edit ? "Editar  Vehículo " : "Registrar Vehículo "}
				open={open}
				setOpen={setOpen}
				accept={handleSubmit(submit)}
				disabledButtons={button}
			>
				<FormBase
					fields={DATA}
					useForm={form}
				/>
			</DialogForm>
		</React.Fragment>
	)
}
