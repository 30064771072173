import React from "react"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"

import CircleIcon from "@mui/icons-material/Circle"
import { COLOR_TARJETA } from "../constants_capacitaciones"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"

export default function CursosTablaColores({ all = false }) {
	let xsList = {
		flex: "1",
		textAlign: "center",
		alignItems: "center", // Alinea los elementos en el centro verticalmente
		justifyContent: "center", // Alinea los elementos en el centro horizontalmente
	}
	let xsIcon = {
		marginBottom: 0,
	}
	return (
		<List
			sx={{
				display: "flex",
				flexDirection: "row",
				width: "100%",
				height: "70%",
				background: "#0FFFFF00",
				overflowX: "auto",
			}}
		>
			{[
				"Aprobado",
				"Rechazado",
				"Por aprobar",
				"Cancelado",
				"Creado",
				"Atrasado",
			].map((value, index) => (
				<ListItem
					key={value}
					disableGutters
					sx={{
						...xsList,
						borderRight: index < 5 ? "1px solid #ccc" : "none",
					}}
				>
					<Grid2
						container
						spacing={1.5}
					>
						<Grid2>
							<CircleIcon sx={{ ...xsIcon, color: COLOR_TARJETA(index + 1) }} />
						</Grid2>
						<Grid2>
							<ListItemText primary={`${value}`} />
						</Grid2>
					</Grid2>
				</ListItem>
			))}
			{all && (
				<ListItem
					key={"black"}
					disableGutters
					sx={{
						...xsList, // Alinea los elementos en el centro horizontalmente
						borderLeft: "1px solid #ccc",
					}}
				>
					<Grid2
						container
						spacing={1.5}
					>
						<Grid2>
							<CircleIcon sx={{ ...xsIcon, color: "#EBE1AD" }} />
						</Grid2>
						<Grid2>
							<ListItemText primary={`No asignado`} />
						</Grid2>
					</Grid2>
				</ListItem>
			)}
		</List>
	)
}
