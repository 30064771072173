import { Route, Routes } from "react-router-dom"
// Pages
import { HasPermission } from "../Common/Funciones"
import { rutasItems } from "./ListURL"

function Rutas() {
	return (
		<Routes>
			{Object.keys(rutasItems).map((ruta) => {
				return rutasItems[ruta].map((item) => {
					if (item?.denied?.length)
						if (HasPermission(item.denied, true)) return null

					if (item?.permissions?.length)
						if (!HasPermission(item.permissions)) return null

					return (
						<Route
							key={item.path}
							{...item}
						/>
					)
				})
			})}
		</Routes>
	)
}

export default Rutas
