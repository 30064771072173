import { apiPutGeneral, getApiGeneralV2 } from "./Api_Call"
const URL_API = process.env.REACT_APP_SERVER_URL + "rh/pagos"

const PAGOS = `${URL_API}`
/**
 * Realiza una solicitud GET o POST para obtener o enviar datos de solicitudes de pagos.
 * @param {Object} params - Parámetros de configuración de la solicitud.
 * @param {string} [params.url=""] - URL para la solicitud. Por defecto, una URL predeterminada.
 * @param {string} params.token - Token de autenticación para la solicitud.
 * @param {Function} params.setDB - Función para actualizar la base de datos con los datos recibidos.
 * @param {Object} [params.params={}] - Parámetros adicionales para la solicitud.
 * @param {AbortController|null} [params.controller=null] - Controlador de aborto para cancelar la solicitud.
 * @returns {Promise} - Retorna una promesa con los datos de las solicitudes de pagos.
 */
export function apiGetSolicitudesPagos(params) {
	return getApiGeneralV2({ url: PAGOS, ...params })
}

export function apiPutSolicitudesPagos(token, data, params, message) {
	return apiPutGeneral(PAGOS, token, data, params, message)
}
