import { api_rh_get } from "#api/API_RH"
import { useMemo } from "react"
import { useEffect, useState } from "react"

/**
 * Hook personalizado para obtener datos de colaboradores de un API especifico.
 *
 * @param {string} ruta - Ruta de la API a consultar.
 * @param {Object} params - Parámetros para la consulta.
 * @param {Object} params.params - Parámetros adicionales para la consulta.
 * @param {boolean} params.actualizar - Indica si se debe actualizar el estado después de la consulta.
 * @returns {{ colaboradores: Array; loading: boolean; }}
 */
export const UseColaborador = (ruta, { params, reload, actualizar = true }) => {
	const [colaboradores, setColaboradores] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const controller = new AbortController()
		setLoading(true)
		const success = (response) => {
			setColaboradores(response)
			setLoading(false)
		}
		api_rh_get(`colaboradores/${ruta}`, {
			params,
			success,
			signal: controller.signal,
		})

		return () => {
			controller.abort()
		}
	}, [actualizar, ruta, params, reload])
	return { colaboradores, loading }
}
/**
 *
 * @param {string} ruta - Ruta de la API a consultar.
 * @param {Object} settings - Parámetros para la consulta.
 * @param {Object} settings.params - Parámetros adicionales para la consulta.
 * @param {boolean} settings.reload - Indica si se debe actualizar el estado después de la consulta.
 * @param {boolean} settings.disable -Desactiva la consulta
 * @param {string} settings.nameData -nombre para los datos que se obtienen
 * @param {any} settings.dataType -tipo de datos que se obtienen
 * @returns {{ colaboradores: Array; loading: boolean; }}
 */

export default function UseColaboradores(ruta, settings = {}) {
	const {
		params,
		reload,
		disable = false,
		nameData = "colaboradores",
		dataType = [],
	} = settings
	const [colaboradores, setColaboradores] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const controller = new AbortController()

		const success = (response) => {
			setColaboradores(response)
			setLoading(false)
		}

		if (!disable) {
			setLoading(true)
			api_rh_get(`baja/${ruta}`, {
				params,
				success,
				signal: controller.signal,
			})
		}

		return () => {
			controller.abort()
		}
	}, [ruta, params, reload, disable])
	return { [nameData]: colaboradores || dataType, loading }
}
