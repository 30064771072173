import React, { useState } from "react"
import { useSelector } from "react-redux"
import MUIDataTable from "mui-datatables"
import { ASSET_TEXT } from "./AssetText"
import AssetPicture from "./AssetPicture"
import AssetTableDetail from "./AssetTableDetail"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { ButtonIcon } from "../Common/Buttons"
import AssetFormulario from "./AssetFormulario"
import AddModeratorIcon from "@mui/icons-material/AddModerator"
import AssetPictureUpload from "./AssetPictureUpload"
import { HasPermission, hasPermission } from "../Common/Funciones"
import { PERFILES } from "../../Constants/Strings"
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone"
import { apiPutAsset } from "../../Services/Api_inventario"
import { useSolicitudesContext } from "../Colaboradores/Solicitudes/SolicitudesContext"
import { DialogConfirm } from "../Common/ModalConfirmacion"
import { Box } from "@mui/material"

export default function AssetTable({ data, token }) {
	const [open, setOpen] = React.useState(false)
	const puestos = useSelector((state) => state.user.profiles)
	const [openForm, setOpenForm] = React.useState(false)
	const { actualizarA } = useSolicitudesContext()
	const columns = [
		{
			name: "id",
			label: "id",
			options: { filter: true, sort: true, display: false },
		},
		{
			name: "name",
			label: ASSET_TEXT.NAME,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "type_asset",
			label: ASSET_TEXT.TYPE,
			options: { filter: true, sort: true, display: true },
		},

		{
			name: "serial",
			label: ASSET_TEXT.SERIAL,
			options: { filter: true, sort: true, display: true },
		},

		{
			name: "active",
			label: ASSET_TEXT.ACTIVATE,
			options: {
				customBodyRender: (value) => {
					if (value) return ASSET_TEXT.ACTIVATE_TRUE
					return ASSET_TEXT.ACTIVATE_FALSE
				},
				filter: true,
				sort: true,
				display: true,
			},
		},
		{
			name: "phone_number",
			label: ASSET_TEXT.PHONE,
			options: { filter: true, sort: true, display: true },
		},

		{
			name: "delivery_date",
			label: ASSET_TEXT.DELIVERY_DATE,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "collection_date",
			label: ASSET_TEXT.COLLECTION_DATE,
			options: { filter: true, sort: true, display: true },
		},
		{
			name: "id",
			label: " ",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					let column = data.filter((column) => column["id"] === value)
					column = column[0]

					return (
						<ButtonIcon
							title={ASSET_TEXT.BUTTON_LOW}
							mensaje={ASSET_TEXT.DIALOG_LOW}
							disabled={!column.active}
							sx={{ color: "red" }}
							icon={<RemoveCircleTwoToneIcon />}
							onClick={() => {
								setOpen(value)
							}}
						/>
					)
				},
			},
		},
	]

	const permission = hasPermission(puestos, PERFILES["SISTEMAS"])
	/**
	 * @type {MUIDataTableProps.options}
	 */
	const options = {
		textLabels: {
			body: {
				noMatch: "No cuentas con equipo asignado ", // Cambia este mensaje
			},
		},
		filterType: "checkbox",
		// filter: false,
		view: false,
		viewColumns: false,
		print: false,
		columns: false,
		download: false,
		search: true,
		display: false,
		Checkbox: false,
		expandableRowsHeader: false,
		expandableRows: true,
		expandableRowsOnClick: true,
		selectableRows: "none",
		tableBodyHeight: "auto",
		customToolbar: () => {
			if (permission)
				return (
					<ButtonIcon
						title="Agregar Equipo"
						icon={<AddModeratorIcon />}
						onClick={() => {
							setOpenForm(true)
						}}
					/>
				)
			return null
		},
		renderExpandableRow: (rowData, rowMeta) => {
			let id = { [columns[0].name]: rowData[0] }
			const asset = data[rowMeta.dataIndex]

			const colSpan = rowData.length + 1

			return (
				<tr key={`img${id["id"]}`}>
					<td
						colSpan={colSpan}
						style={{
							padding: 1,
						}}
					>
						<ExpandeRow
							id={id}
							asset={asset}
							colSpan={colSpan}
						/>
					</td>
				</tr>
			)
		},
	}

	return (
		<Box sx={{ width: "100%" }}>
			<MUIDataTable
				title={ASSET_TEXT.TITLE}
				data={data}
				columns={columns}
				options={options}
			/>
			<AssetFormulario
				open={openForm}
				setOpen={setOpenForm}
			/>
			<DialogConfirm
				open={open}
				setOpen={setOpen}
				message={ASSET_TEXT.DIALOG_LOW}
				accept={async () => {
					setOpen(false)
					let response = await apiPutAsset(
						"ASSET_COLABORADOR",
						token,
						{},
						{ id: open, baja: "" },
					)
					if (response["success"]) actualizarA()
				}}
			/>
		</Box>
	)
}

const ExpandeRow = ({ asset }) => {
	const permission = HasPermission(PERFILES["SISTEMAS"])
	const [actualizarFotos, setActualizarFotos] = useState(false)
	const updatePicture = () => setActualizarFotos((value) => !value)
	const imgHeight = "300px"
	return (
		<Box sx={{ width: "100%" }}>
			<Grid
				container
				spacing={2}
				width={"100%"}
				alignItems={"center"}
			>
				<Grid xs={12}>
					<AssetTableDetail
						asset={asset}
						permission={permission}
					/>
				</Grid>
				<Grid xs={12}>
					<Box>
						<Grid
							container
							spacing={1}
							wrap="wrap"
						>
							{permission && (
								<Grid>
									<AssetPictureUpload
										id={asset.id}
										actualizar={updatePicture}
										height={imgHeight}
									/>
								</Grid>
							)}

							<AssetPicture
								idAsset={asset.id}
								refresh={actualizarFotos}
								height={imgHeight}
							/>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}
