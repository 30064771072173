import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux" //Token
import { toastError } from "../../../Common/componentes/Funciones"
import { useSolicitudesContext } from "../SolicitudesContext"
import { startActualizacion } from "../../../../app/actualizar"

//API
import {
	getPrestamosMethodPay,
	postPrestamos,
} from "../../../../Services/Api_prestamos"

//Componentes
import { Boton, Texto } from "../Componentes"
import { InputSelect, InputGrid } from "../../../Common/Inputs"

//Material UI
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"

const TEXT = {
	textTitle: "Solicitud Préstamo",
	textLimit: "El monto máximo de tu préstamo es de",
	inputMethod: "Método de pago",
	inputCount: "Cantidad",
	errorEmpty: "Coloca la cantidad del Préstamo",
	errorMin:
		"La cantidad debe ser mayor o igual $1,000.00 y menor o igual al máximo",
	errorMax: "Excedes Limite Préstamo",
	errorCheck: "Acepta términos y condiciones",
}

function PrestamosFormulario({ setOpen = () => {} }) {
	const token = useSelector((state) => state.user.token)
	const [value, setValue] = useState({
		metodo_pago: "",
		cantidad: 0,
		check: null,
	})
	const [inputErro, setInputError] = useState(false)
	const [dbMetodoPago, setDbMetodoPago] = useState([])
	const [montoMaximo, setMontoMaximo] = useState({ cantidad_limite: 0 })

	useEffect(() => {
		getPrestamosMethodPay(token, setDbMetodoPago, { activos: "" })
	}, [token])

	useEffect(() => {
		if (value.metodo_pago !== "")
			setMontoMaximo(dbMetodoPago.find((item) => item.id === value.metodo_pago))
	}, [value.metodo_pago, dbMetodoPago])
	return (
		<React.Fragment>
			<div width={700}>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					spacing={1.5}
				>
					<Texto
						label={TEXT["textTitle"]}
						size={12}
					/>

					<InputSelect
						label={TEXT["inputMethod"]}
						data={dbMetodoPago}
						dataLabel="descripcion"
						dataID="id"
						option={value.metodo_pago}
						setOption={setValue}
						operation={(event) => {
							setValue({ ...value, metodo_pago: event.target.value })
						}}
					/>
					{value.metodo_pago === "" ? (
						""
					) : (
						<React.Fragment>
							<Texto
								tipo="h5"
								label={`${TEXT["textLimit"]} $${montoMaximo[
									"cantidad_limite"
								].toLocaleString("en-US")}`}
								size={12}
							/>

							<InputGrid
								error={inputErro}
								label={TEXT["inputCount"]}
								max={montoMaximo["cantidad_limite"]}
								min={1000}
								xs={12}
								md={8}
								xl={7.5}
								type="number"
								operation={(event) => {
									if (event.target.value <= montoMaximo["cantidad_limite"])
										setValue({ ...value, cantidad: event.target.value })
									else {
										event.target.value =
											value["cantidad"].toLocaleString("en-US")
										toastError(TEXT["errorMax"])
									}
								}}
							/>
							<InputCheck
								setInputError={setInputError}
								token={token}
								value={value}
								operation={(event) => {
									setValue({ ...value, check: !value["check"] })
								}}
								setOpen={setOpen}
							/>
						</React.Fragment>
					)}
				</Grid>
			</div>
		</React.Fragment>
	)
}

export default PrestamosFormulario

export function InputCheck({
	check,
	error = false,
	token,
	value,
	setInputError = () => {},
	operation = () => {},
	setOpen = () => {},
}) {
	const dispatch = useDispatch()
	const { colaboradorID } = useSolicitudesContext()
	/**
	 * @description valida los datos antes de enviarlos en caso de erro muestra mensajes si todo es correcto lo envia
	 * @param {*} token
	 * @param {*} datos recibe un objeto con los datos a enviar
	 * @param {*} setInputError UseState modifica el valor para validar que la información al momento de enviar
	 */
	function enviarDatosButton() {
		let error = false
		if (!!value["metodo_pago"] && !!value["cantidad"]) {
			if (value["cantidad"] < 1000) error = TEXT["errorMin"]
			else if (value["check"]) {
				postPrestamos(token, value, { id: colaboradorID }).then((x) => {
					setOpen(false)
					dispatch(startActualizacion())
				})
			} else {
				error = TEXT["errorCheck"]
			}
		} else {
			error = TEXT["errorEmpty"]
		}
		if (error) {
			setInputError(error)
			toastError(error)
		}

		return error
	}

	return (
		<Grid
			xs={12}
			md={12}
		>
			<Box
				sx={{
					width: "100%",
					height: "300px",
					overflowY: "auto",
				}}
			>
				<Grid
					container
					justifyContent={"center"}
					alignContent={"center"}
				>
					<Typography
						paragraph
						md={4}
					>
						<body>
							<h1>Política de Préstamos y Tasas de Interés</h1>
							<ul>
								<li>
									Todos los colaboradores de Lubtrac podrán solicitar préstamos
									si se encuentran dentro de la caja de ahorro.
								</li>
								<li>
									Para tener derecho a un préstamo, el empleado deberá tener un
									mínimo de seis meses con contrato por tiempo indeterminado
									dentro de la compañía.
								</li>
								<li>
									Para solicitar un préstamo, los socios deberán ingresar a la
									plataforma de GESTOR RH para llenar su solicitud de préstamo.
								</li>
								<li>
									El inicio de préstamos será en el mes de septiembre después de
									iniciado el periodo de la Caja de ahorro.
								</li>
								<li>
									Los préstamos se programarán de acuerdo con la fecha de
									recepción de las solicitudes y con base en la capacidad de
									flujo de la caja de ahorro.
								</li>
								<li>
									Los colaboradores que soliciten un préstamo podrán hacerlo
									hasta por un monto equivalente a un mes de su sueldo.
								</li>
								<li>
									Los préstamos de la caja de ahorro que pretendan ser
									liquidados con la percepción de fondo de ahorro (pagadera en
									el mes de enero), serán autorizados únicamente dentro del
									segundo semestre del año en curso (julio-diciembre).
								</li>
								<li>
									Los préstamos de la caja de ahorro que pretendan ser
									liquidados con la percepción de aguinaldo (pagadera en el mes
									de diciembre), serán autorizados únicamente dentro del segundo
									semestre del año en curso (julio-diciembre).
								</li>
								<li>
									En caso de tener un préstamo activo, no podrá solicitarse un
									nuevo préstamo hasta no haber liquidado el activo.
								</li>
								<li>
									En caso de solicitar préstamos con descuento de manera
									quincenal, deberán ser liquidados totalmente dentro del
									periodo del ejercicio de caja de ahorro (agosto-julio).
								</li>
								<li>
									Las condiciones de plazo y fecha de pago no podrán ser
									modificadas, debiendo liquidarse en su totalidad en la fecha
									acordada.
								</li>
							</ul>

							<h2>TASAS DE INTERÉS</h2>
							<p>Tasa para Socios:</p>
							<ul>
								<li>
									Interés mensual del 1 % para préstamos descuento vía nómina.
								</li>
								<li>Interés mensual del 2% para préstamos a plazo fijo.</li>
							</ul>
							<p>Tasa para No Socios:</p>
							<ul>
								<li>
									Interés mensual del 2 % para préstamos descuento vía nómina.
								</li>
								<li>Interés mensual del 3% para préstamos a plazo fijo.</li>
							</ul>

							<p>
								Es obligación del colaborador verificar en su recibo de nómina
								los descuentos por concepto de ahorro y préstamo y en caso de
								aclaración hacerlo con el departamento de Recursos Humanos.
							</p>
						</body>
					</Typography>

					<FormControl error={error}>
						<FormLabel component="legend"></FormLabel>
						<FormControlLabel
							checked={check}
							onChange={operation}
							required
							control={<Checkbox />}
							label={`Acepto los términos y condiciones`}
						/>
					</FormControl>
					<Boton
						label={"Enviar"}
						xs={12}
						size={10}
						sizeB={5}
						disabled={false}
						propocito={(e) => {
							const buttonPresionado = e.target
							buttonPresionado.disabled = true
							if (enviarDatosButton()) buttonPresionado.disabled = false
						}}
					/>
				</Grid>
			</Box>
		</Grid>
	)
}
