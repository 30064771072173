import {Skeleton, Stack } from '@mui/material/';

export const LoadViewTable  = () => {
    return (<>
                 <Stack  direction="row" spacing={2} sx={{mb:1,mt:3}} width="80%" >
                    <Skeleton variant="rectangular" width="33%" height={60} />
                    <Skeleton variant="rectangular" width="33%" height={60} />
                    <Skeleton variant="rectangular" width="33%" height={60} />
                </Stack>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
            </>
    )
}

export const LoadViewDetail  = () => {
    return (<>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Stack  direction="row" spacing={2} sx={{mb:1}} >
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                </Stack>
                <Stack  direction="row" spacing={2} sx={{mb:1}}>
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                </Stack>
                <Stack  direction="row" spacing={2} sx={{mb:1}}>
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                </Stack>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            </>
    )
}
