import { DialogActions, DialogTitle } from "@mui/material"

import {
	ProgressBar,
	SpecialZoomLevel,
	Viewer,
	Worker,
} from "@react-pdf-viewer/core"
import ButtonDialog from "./ButtonsDialog"
import { BootstrapDialog, CustomDialogContent } from "./Dialog"

export function DialogViewPDF({ title, urlFile, open, setOpen }) {
	const handleDownload = async () => {
		// window.open(response.data, "_blank")
		const link = document.createElement("a")
		link.href = urlFile // La URL del archivo
		// link.download = "nombre_del_archivo.ext" // Nombre del archivo
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}
	return (
		<BootstrapDialog
			open={open}
			onClose={() => {
				setOpen(false)
			}}
			fullWidth={true}
			maxWidth={"xl"}
		>
			<DialogTitle id="Dialog-Form">{title}</DialogTitle>
			<CustomDialogContent dividers>
				<div>
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
						<Viewer
							defaultScale={SpecialZoomLevel.PageWidth}
							fileUrl={urlFile}
							renderLoader={(percentages) => (
								<div style={{ width: "80vh" }}>
									<ProgressBar progress={Math.round(percentages)} />
								</div>
							)}
						/>
					</Worker>
				</div>
			</CustomDialogContent>
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<ButtonDialog
					label="Cerrar"
					onClick={() => setOpen(false)}
				/>
				<ButtonDialog
					label="Descargar"
					onClick={handleDownload}
				/>
			</DialogActions>
		</BootstrapDialog>
	)
}
