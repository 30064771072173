import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

export default function CircularSkeleton({ size = 50 }) {
	return (
		<Box
			sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
		>
			<CircularProgress size={size} />
		</Box>
	)
}
