import React, { useEffect, useState } from "react"
import { SkeletonSquare } from "../Common/Skeleton"

// import { InputSelect } from "../Common/Inputs"
import FormularioCursoColaborador from "./FormularioCursoColaborador"

import { cursosColaboradorApi } from "#api/Api_cursos"
import { HasPermission } from "#common/Funciones"
import { InputSelect } from "#common/input/InputSelect"
import { Box, Button, Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { PERFILES } from "../../Constants/Strings"
import CursosTablaColores from "./componentes/CursosTablaColores"
import TarjetaCurso from "./TarjetaCurso"
import { CursoProvider } from "./CursoContext"

const VistaUsuario = ({ id }) => {
	const [cursosColaborador, setCursosColaborador] = useState([])
	const [skeleton, setSkeleton] = useState(false)
	const [skeletonDisable, setSkeletonDisable] = useState(true)
	const [actualizar, setActualizar] = useState(false)
	const [nuevoCurso, setNuevoCurso] = useState(false)
	const [edit, setEdit] = useState(false)
	const [yearOption, setYearOption] = useState(new Date().getFullYear())
	useEffect(() => {
		if (skeletonDisable) {
			setSkeleton(true)
			setSkeletonDisable(false)
		}
		const controller = new AbortController()
		const success = (data) => {
			setCursosColaborador(data)
			setSkeleton(false)
		}
		const params = { all: "", idColaborador: id, year: yearOption }
		cursosColaboradorApi({ params, success, signal: controller.signal })
		return () => {
			controller.abort()
		}
	}, [id, yearOption, actualizar, skeletonDisable])

	useEffect(() => {
		if (edit !== false) setNuevoCurso(true)
	}, [edit])

	let cursosSkeleton = []
	for (let x = 0; x < 12; x++)
		cursosSkeleton.push(
			<Grid
				xs={12}
				md={6}
				xl={4}
				key={`skeleton${x}`}
			>
				<SkeletonSquare />
			</Grid>,
		)

	if (skeleton)
		return (
			<Box>
				<Grid
					container
					spacing={1}
				>
					{cursosSkeleton}
				</Grid>
			</Box>
		)

	return (
		<Box>
			<CursoProvider
				{...{
					actualizar,
					setActualizar,
					nuevoCurso,
					setNuevoCurso,
				}}
			>
				<Grid
					container
					spacing={3}
					justifyContent={"center"}
				>
					<CursosTablaColores />
					<Grid xs={12}>
						<HeaderCursos {...{ setNuevoCurso, yearOption, setYearOption }} />
					</Grid>
					<CursosCards
						{...{
							cursosColaborador,
							setEdit,
							act: actualizar,
							setAct: setActualizar,
						}}
					/>
				</Grid>
				<FormularioCursoColaborador
					setOpen={setNuevoCurso}
					open={nuevoCurso}
					idColaborador={id}
					actualizar={actualizar}
					setActualizar={setActualizar}
					dataCurso={edit}
					setDataCurso={setEdit}
				/>
			</CursoProvider>
		</Box>
	)
}

export default VistaUsuario

const HeaderCursos = ({ yearOption, setYearOption, setNuevoCurso }) => {
	const isRH = HasPermission(PERFILES["RH"])
	const years = Array.from(
		{
			length: new Date().getFullYear() + 1 - 2023 + 1,
		},
		(_, index) => ({
			año: 2023 + index,
		}),
	)
	if (!isRH) return null

	return (
		<Stack
			spacing={2}
			direction={"row"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Button
				onClick={() => {
					setNuevoCurso(true)
				}}
			>
				Nuevo Curso
			</Button>

			<InputSelect
				data={years}
				fullWidth={false}
				label="Año"
				dataLabel="año"
				dataId="año"
				value={yearOption}
				setValue={setYearOption}
			/>
		</Stack>
	)
}

const CursosCards = ({ cursosColaborador, setEdit, act, setAct }) => {
	if (!cursosColaborador.length)
		return (
			<Typography
				xs={12}
				sx={{
					mb: 1.5,
					fontWeight: "bold",
					textAlign: "center",
					fontSize: 56,
				}}
			>
				No cuentas con plan de capacitación asignado
			</Typography>
		)

	return cursosColaborador.map((data, index) => {
		return (
			<Grid
				key={`curso_${data.id}`}
				xs={12}
				md={6}
				xl={4}
			>
				<TarjetaCurso
					db_user={data}
					db_cursos={data["curso"]}
					statusChange={act}
					reload={setAct}
					setStatusChange={setAct}
					setEdit={setEdit}
				/>
			</Grid>
		)
	})
}
