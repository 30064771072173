import { Typography } from "@mui/material"

/**
 * @typedef {import('@mui/material').TypographyProps} TypographyProps
 */

/**
 * @typedef {object} CampoValorProps
 * @property {string} [label] - Nombre del campo.
 * @property {any} value - Valor del campo.
 * @property {boolean} [campoNotNull=false] - Indica si el campo debe mostrarse o no.
 * @property {TypographyProps["textAlign"]} textAlign - Alineación del texto.
 */
/**
 * @typedef {CampoValorProps & TypographyProps} TextCampoValorProps
 */
/**
 * Description placeholder
 * @param {TextCampoValorProps} props
 * @returns {JSX.Element}
 */

export const DataItem = ({
	value,
	label,
	textAlign,
	campoNotNull = false,
	...props
}) => {
	if (value == null || campoNotNull) return null

	return (
		<Typography
			whiteSpace="pre-line"
			variant="body2"
			textAlign={textAlign}
			{...props}
		>
			{label && <strong>{`${label}\n`}</strong>}
			{value}
		</Typography>
	)
}
