import React from "react"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import SolicitudesUsuario from "./SolicitudesUsuario"
import Prestamos from "./Prestamos"

/**
 * Description placeholder
 * @date 18/8/2023 - 15:16:41
 *
 * @param {{ id?: string; }} { id = "" }
 * @returns {*}
 */
function Solicitudes({ id = "" }) {
	const [value, setValue] = React.useState("0")

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}
	return (
		<Box sx={{ width: "100%", typography: "body1" }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList
						onChange={handleChange}
						textColor="secondary"
						indicatorColor="secondary"
						aria-label="secondary tabs example"
					>
						<Tab
							label="Caja de Ahorro"
							value="0"
						/>
						<Tab
							label="Prestamos"
							value="1"
						/>
					</TabList>
				</Box>
				<TabPanel value="0">
					<SolicitudesUsuario />
				</TabPanel>
				<TabPanel value="1">
					<Prestamos />
				</TabPanel>
			</TabContext>
		</Box>
	)
}

export default Solicitudes
