//API
//componentes
//material ui
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Movimiento } from "./Movimientos"

export default function TablaPromociones({
	tableData,
	listMovimientos,
	hidden = false,
}) {
	if (hidden) return null
	return (
		<TableContainer component={Paper}>
			<Table
				sx={{ minWidth: 650 }}
				aria-label="simple table"
			>
				<TableHead>
					<TableRow>
						{tableData.map((campo) => (
							<TableCell key={campo.id}>{campo.label}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{listMovimientos.map((data) => {
						const { colaborador, perfilPuesto } = data

						return (
							<Movimiento
								tableData={tableData}
								key={colaborador.no_empleado}
								colaborador={colaborador}
								perfilPuesto={perfilPuesto}
							/>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
