import {
	apiDeleteGeneral,
	apiPostGeneral,
	apiPutGeneral,
	getApiGeneral,
} from "./Api_Call"
import { apiDeleteRH, apiGetRH, apiPutRH } from "./API_RH"

const URL_API = process.env.REACT_APP_SERVER_URL + "rh"
/**
 * @typedef {'ASSET_COLABORADOR'|'ASSET_FOTOS'|'ASSET_TYPE'} urlAsset
 */
/**
 * @typedef {Object} MessageOptions
 * @property {string} pending - Mensaje a mostrar mientras la solicitud está en curso.
 * @property {string} [success] - Mensaje a mostrar si la solicitud se completa con éxito.
 * @property {string} [error] - Mensaje a mostrar en caso de error durante la solicitud.
 */
const ASSET_COLABORADOR = `${URL_API}/asset`
const ASSET_FOTOS = `${URL_API}/asset/foto`
const URL_ASSET = {
	ASSET_COLABORADOR: `${URL_API}/asset`,
	ASSET_FOTOS: `${URL_API}/asset/foto`,
	ASSET_TYPE: `${URL_API}/asset/type`,
}
const ASSET = `asset`
const FOTOS = "asset/foto"
/**
 *
 * @param {urlAsset} urlAsset
 * @param {*} token
 * @param {*} setDb
 * @param {*} filtro
 * @returns
 */
export async function apiGetAsset2(urlAsset, token, setDb, filtro) {
	return await getApiGeneral(URL_ASSET[urlAsset], token, setDb, filtro)
}
/**
 *
 * @param {urlAsset} urlAsset
 * @param {*} token
 * @param {*} setDb
 * @param {*} filtro
 * @param {MessageOptions} message
 * @returns {success}
 */
export async function apiPostAsset(urlAsset, token, data, params, message) {
	return await apiPostGeneral(URL_ASSET[urlAsset], token, data, params, message)
}
/**
 *
 * @param {urlAsset} urlAsset
 * @param {*} token
 * @param {*} setDb
 * @param {*} filtro
 * @param {MessageOptions} message
 * @returns
 */
export function apiPutAsset(urlAsset, token, data, params, message) {
	return apiPutGeneral(URL_ASSET[urlAsset], token, data, params, message)
}

export async function apiGetAsset(token, setDb, filtro) {
	return await getApiGeneral(ASSET_COLABORADOR, token, setDb, filtro)
}

/**
 *
 * @param {any} data - Datos a enviar
 * @param {apiPutRhSettingsParams} settings
 * @returns
 */
export function apiPutAssetV2(data, settings = {}) {
	return apiPutRH(ASSET, data, settings)
}
/**
 *
 * @param {apiGetRhParams} settings
 * @returns
 */
export function apiGetAssetFotos(settings = {}) {
	return apiGetRH(FOTOS, settings)
}
/**
 *
 * @param {apiPutRhSettingsParams} settings
 * @returns
 */
export function apiDeleteAssetFotos(settings = {}) {
	return apiDeleteRH(FOTOS, settings)
}
