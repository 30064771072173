import { useState, Fragment } from "react"
import { ESTADOS } from "../constants"
//Funciones
import { useSelector } from "react-redux" //Token
import { putCajaAhorro } from "../../../../Services/Api_solicitudes"

import PropTypes from "prop-types"

//Material UI
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Button from "@mui/material/Button"
import DialogContentText from "@mui/material/DialogContentText"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import { toast } from "react-toastify"
import { MESES } from "../../../Capacitaciones/constants_capacitaciones"
import { DialogGeneral } from "../../../Common/Dialog"
import { InputGrid2, InputSelectGrid2 } from "../../../Common/Inputs2"

/**
 *
 * @param {*} ID - ID de la solicitud
 * @param {*} setActualizar - Funcion para actualizar la tabla
 * @param {*} actualizar - Estado de la tabla
 * @returns botonos para aprobar o rechazar una solicitud
 */
export default function BotonosCajaAhorro({
	ID,
	setActualizar = () => {},
	actualizar = false,
}) {
	const [data, setData] = useState(false)

	return (
		<Fragment>
			<Stack
				direction={{ xs: "column", sm: "row" }}
				spacing={{ xs: 2, sm: 2, md: 2 }}
				justifyContent={"start"}
			>
				<IconButton
					aria-label="Aprobado"
					onClick={() => {
						setData({ open: true, status: ESTADOS.APROBADO })
					}}
					sx={{ color: "green" }}
					size="small"
				>
					<CheckCircleIcon />
				</IconButton>
				<IconButton
					aria-label="Rechazado"
					onClick={() => {
						setData({ open: true, status: ESTADOS.RECHAZADO })
					}}
					sx={{ color: "red" }}
					size="small"
				>
					<CancelIcon />
				</IconButton>
			</Stack>

			<AlertSavings
				id={ID}
				data={data}
				setOpen={setData}
				actualizar={() => setActualizar(!actualizar)}
			/>
		</Fragment>
	)
}

/**
 * @description Confirmar la acción de aprobar o rechazar o Finalizar una solicitud
 * @param {Object} props - id de la solicitud
 * @param {Number} props.id - id de la solicitud
 * @param {Object} props.data - la información a usar
 * @param {Number} props.data.status - el estado a cambiar
 * @param {Boolean} props.data.open - abre el modal true
 * @param {Function} props.setOpen - Función para cambiar el estado del dialog
 * @param {Function} props.actualizar - Estado de la tabla
 * @returns Dialog para confirmar la acción de aprobar o rechazar una solicitud
 */
export function AlertSavings({ id, data, setOpen, actualizar }) {
	const token = useSelector((state) => state.user.token)
	const { open, status } = data
	const [mensaje, setMensaje] = useState("")
	const [dateExit, setDateExit] = useState("")

	const sendResponse = async () => {
		if (status === ESTADOS.RECHAZADO) {
			if (mensaje === "") {
				toast.error(`Agrega un comentario para el rechazo`)
				return false
			}
		} else if (status === ESTADOS.FINALIZADO)
			if (dateExit === "") {
				toast.error(`Selecciona una fecha de salida `)
				return false
			}
		setOpen(false)
		let response = await putCajaAhorro(token, {
			id,
			status,
			mensaje: mensaje,
			dateExit: dateExit,
		})
		if (response?.status) actualizar()
	}
	const style = {
		paddingBottom: "10px",
		textAlign: "center",

		fontSize: "20px",
		color: status === ESTADOS["FINALIZADO"] ? "red" : "",
	}
	const contenido = {
		[ESTADOS.FINALIZADO]: (
			<p style={{ color: "red" }}>
				<strong>¿Está seguro de que desea concluir su participación?</strong>
				<p>
					No podrá ingresar nuevamente hasta la próxima apertura de la caja de
					ahorros.
				</p>
			</p>
		),
		[ESTADOS.RECHAZADO]: <strong>¿Rechazar Solicitud?</strong>,
		[ESTADOS.APROBADO]: <strong>¿Aprobar Solicitud?</strong>,
	}
	const input = {
		[ESTADOS.FINALIZADO]: (
			<InputSelectGrid2
				id="fecha_baja"
				label="Selecciona la fecha de salida"
				data={selectMes(6)}
				dataLabel="option"
				dataID="fecha"
				setValue={setDateExit}
			/>
		),
		[ESTADOS.RECHAZADO]: (
			<InputGrid2
				id="mensaje"
				label="Mensaje"
				setOption={setMensaje}
			/>
		),
	}
	let exitDialog = () => {
		setOpen(false)
		setMensaje("")
		setDateExit("")
	}

	let buttons = [
		<Button
			key={"salir"}
			onClick={exitDialog}
			// disabled={disabledButtons}
		>
			cancelar
		</Button>,
		<Button
			key={"aceptar"}
			onClick={sendResponse}
			autoFocus
			// disabled={disabledButtons}
		>
			aceptar
		</Button>,
	]

	return (
		<DialogGeneral
			open={open}
			onClose={exitDialog}
			maxWidth="xs"
			buttons={buttons}
		>
			<DialogContentText style={style}>{contenido[status]}</DialogContentText>
			{input[status]}
		</DialogGeneral>
	)
}

AlertSavings.propTypes = {
	id: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
	setOpen: PropTypes.func.isRequired,
	actualizar: PropTypes.func.isRequired,
}

export function selectMes(cantidad = 0, fecha = new Date()) {
	let year = fecha.getFullYear()
	let mouth = fecha.getMonth() + 1
	let day = fecha.getDate()
	let dayEnd = new Date(year, mouth, 0).getDate()
	let daysChange = 4
	let quincena = false
	let dataSelect = []

	if (day > dayEnd - daysChange) {
		quincena = true
		mouth += 1
	} else if (day < 15 - daysChange) quincena = true

	while (cantidad > 0) {
		if (mouth > 12) {
			mouth -= 12
			year += 1
		}
		let daySelect = 15
		if (!quincena) daySelect = new Date(year, mouth, 0).getDate()

		dataSelect.push({
			fecha: `${year}-${mouth}-${daySelect}`,
			option: `${
				quincena ? "Primera Quincena" : "Segunda Quincena"
			} (${daySelect} de ${MESES[mouth].label} de ${year})`,
		})

		if (quincena === false) mouth += 1
		quincena = !quincena
		cantidad -= 1
	}

	return dataSelect
}
