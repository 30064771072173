import { useMemo } from "react"

//componentes
import UseVacation from "./UseVacation"
import { VacationProvider } from "./UseContextVacation"
import VacationDialogApprove from "./VacationDialogApprove"
import VacationDialogRequest from "./VacationDialogRequest"
import ColaboradorCard from "./VacationColaboradorCard"

//material
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import { Typography } from "@mui/material"
import { ChangeTitle } from "Components/menu/funciones"

export default function VacationBoss() {
	ChangeTitle("Vacaciones")
	return (
		<Box
			display={"flex"}
			flexWrap={"wrap"}
			justifyContent={"center"}
			gap={"20px"}
		>
			<VacationProvider>
				<ColaboradoresCards />
				<VacationDialogApprove />
				<VacationDialogRequest />
			</VacationProvider>
		</Box>
	)
}

export function ColaboradoresCards({ boss = true }) {
	const params = useMemo(() => {
		return { params: { boss } }
	}, [boss])
	const { vacation, loading } = UseVacation([], params)
	if (loading) return <VacationSkeleton />

	if (!vacation.length)
		return (
			<Box component={"div"}>
				<Typography variant="h3">No Hay solicitudes de vacaciones </Typography>
			</Box>
		)
	return vacation.map((colaborador) => {
		return (
			<Box
				component={"div"}
				key={colaborador.id}
			>
				<ColaboradorCard {...colaborador} />
			</Box>
		)
	})
}

const VacationSkeleton = () => {
	let skeletons = []
	for (let i = 0; i < 8; i++) {
		skeletons.push(
			<Box component={"div"}>
				<Skeleton sx={{ minWidth: "500px", height: 200 }} />
			</Box>
		)
	}

	return (
		<Box
			display={"inline-flex"}
			flexWrap={"wrap"}
			justifyContent={"flex-start"}
			gap={"20px"}
		>
			{skeletons}
		</Box>
	)
}
