import { apiPromotionDetailGet } from "#api/Api_promotion"
import { Box, Skeleton, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { usePromotionsContext } from "../PromotionContext"
import { CardChanges } from "./CardChanges/CardChanges"
import DialogConfirm from "./dialogConfirm/DialogConfirm"
const usePromotionDetailData = (promotion_id) => {
	const { actualizar } = usePromotionsContext()
	const [details, setDetails] = useState([])
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		const controller = new AbortController()
		setLoading(true)
		const success = (data) => {
			setDetails(data)
			setLoading(false)
		}
		apiPromotionDetailGet({
			success,
			params: { promotion_id },
			signal: controller.signal,
		})
		return () => {
			controller.abort()
		}
	}, [promotion_id, actualizar])
	return { details, loading }
}

export default function PromotionalChanges({ promotion_id, disable }) {
	const { details, loading } = usePromotionDetailData(promotion_id)
	const { openConfirm, setOpenConfirm } = usePromotionsContext()
	if (loading)
		return (
			<Skeleton
				variant="rectangular"
				width={"100%"}
				height={"12vh"}
			/>
		)

	return (
		<Box>
			<Stack spacing={2}>
				{details.map((detail) => (
					<CardChanges
						key={`card_${detail?.id}}`}
						detail={detail}
						disable={disable}
					/>
				))}
			</Stack>
			<DialogConfirm
				{...{
					open: openConfirm,
					setOpen: setOpenConfirm,
				}}
			/>
		</Box>
	)
}
