import React, { useState } from "react"
//UseForm
import { Controller } from "react-hook-form"
//Material Ui
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid2 from "@mui/material/Unstable_Grid2"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
//icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Button from "@mui/material/Button"
//Date
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs from "dayjs"
import "dayjs/locale/es-mx"
const Root = styled("div")(({ theme }) => ({
	width: "100%",
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	"& > :not(style) ~ :not(style)": {
		marginTop: theme.spacing(2),
	},
}))

/**
 * @deprecated
 * Componente para crear un formulario utilizando useForm.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.useForm - Objeto useForm proporcionado por React Hook Form.
 * @param {Array} props.fields - Lista de campos para el formulario.
 * @param {string} props.fields.field - Tipo de campo (input, select, date, checkBox, slider, file, divider).
 * @param {string} props.fields.id - Identificador único del campo.
 * @param {string} props.fields.label - Etiqueta del campo.
 * @param {Object} [props.fields.registerParams={}] - Parámetros para el registro de React Hook Form.
 * @returns {React.Element} Retorna el componente formulario.
 * @example
 * // Ejemplo de uso:
 * <FormBase useForm={useForm} fields={[{ field: 'date', id: 'fechaNacimiento', label: 'Fecha de Nacimiento' }]} />
 */
export default function FormBase({ fields = [], useForm }) {
	const {
		control,
		register,
		formState: { errors },
	} = useForm
	return (
		<FormControl key={"formPersonal"}>
			<Grid2
				container
				spacing={2}
				columns={12}
				alignItems={"baseline"}
			>
				{fields.map((campo, index) => {
					let { field } = campo
					if (field === "input")
						return (
							<InputFormGrid2
								key={index + "Input"}
								propsG={{ xs: 12 }}
								{...campo}
								register={register}
								error={errors}
							/>
						)
					if (field === "select")
						return (
							<InputSelectFormGrid
								key={index + "Input"}
								propsG={{ xs: 12 }}
								{...campo}
								control={control}
								error={errors}
							/>
						)
					if (field === "date")
						return (
							<InputDateFormGrid
								key={index + "Input"}
								propsG={{ xs: 12 }}
								{...campo}
								control={control}
								error={errors}
							/>
						)
					if (field === "checkBox")
						return (
							<CheckBoxFormGrid
								key={index + "Input"}
								propsG={{ xs: 12 }}
								{...campo}
								control={control}
								error={errors}
							/>
						)
					if (field === "slider")
						return (
							<SliderFormGrid
								key={index + "Input"}
								propsG={{ xs: 12 }}
								{...campo}
								control={control}
								error={errors}
							/>
						)
					if (field === "file")
						return (
							<FileFormGrid
								key={index + "Input"}
								propsG={{ xs: 12 }}
								{...campo}
								control={control}
								error={errors}
							/>
						)
					if (field === "divider")
						return (
							<Root>
								<Divider textAlign="left">{campo.content}</Divider>
							</Root>
						)
					return <></>
				})}
			</Grid2>
		</FormControl>
	)
}

export function InputFormGrid2({
	id = "inputGrid2",
	label = "InputGrid2",
	autoComplete = "off",
	registerParams = {},
	register = () => {},
	error = {},
	propsG,
	...props
}) {
	return (
		<Grid2 {...propsG}>
			<TextField
				id={id}
				label={label}
				autoComplete={autoComplete}
				fullWidth
				error={!!error[id]}
				helperText={!!error[id] ? error[id]["message"] : ""}
				{...register(id, registerParams)}
				{...props}
			/>
		</Grid2>
	)
}
export const InputSelectFormGrid = ({
	id,
	defaultValue = "",
	label,
	data = [],
	dataID = "id",
	dataLabel = "label",
	error = {},
	registerParams = {},
	control,
	onChange = () => {},
	propsG,
	...props
}) => {
	return (
		<Grid2 {...propsG}>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={registerParams}
				render={({ field }) => (
					<React.Fragment>
						<FormControl
							fullWidth
							error={!!error[id]}
						>
							<InputLabel id={`${id}-label`}>{label}</InputLabel>
							<Select
								labelId={`${id}-label`}
								id={id}
								label={label}
								{...props}
								{...field}
								onChange={(e) => {
									field.onChange(e)
									onChange(e)
								}}
							>
								{data.map((option) => (
									<MenuItem
										key={option[dataID]}
										value={option[dataID]}
									>
										{option[dataLabel]}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{!!error[id] ? error[id]["message"] : ""}
							</FormHelperText>
						</FormControl>
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}

/**
 * @deprecated
 * Componente para un campo de fecha dentro de un formulario que utiliza Grid2.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.id - Identificador único del campo.
 * @param {string} props.label - Etiqueta del campo de fecha.
 * @param {string[]} [props.views=["day", "month", "year"]] - Vistas disponibles para el DatePicker.
 * @param {string} [props.parseFormat="DD/MM/YYYY"] - Formato de parseo de la fecha.
 * @param {Object} props.control - Objeto de control proporcionado por React Hook Form.
 * @param {Object} props.propsG - Propiedades de diseño para el componente Grid2.
 * @param {Object} [props.registerParams={}] - Parámetros para el registro de React Hook Form.
 * @param {Object} [props.error={}] - Objeto de errores proporcionado por React Hook Form.
 * @returns {React.Element} Retorna el componente de campo de fecha.
 * @example
 * // Ejemplo de uso en un formulario:
 * <InputDateFormGrid id="fechaNacimiento" label="Fecha de Nacimiento" control={control} />
 */
function InputDateFormGrid({
	id,
	label,
	views = ["day", "month", "year"],
	parseFormat = "DD/MM/YYYY",
	control,
	propsG,
	registerParams = {},
	error = {},
}) {
	return (
		<Grid2 {...propsG}>
			<Controller
				name={id}
				control={control}
				// defaultValue={defaultValue}
				rules={registerParams}
				render={({ field }) => {
					// Formatear la fecha utilizando dayjs
					let x = null

					if (!!field.value) x = dayjs(`${field.value}`, parseFormat)
					if (x === "Invalid Date") x = null
					return (
						<DemoContainer
							components={["DatePicker"]}
							sx={{
								display: "block",
							}}
						>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="es-mx"
							>
								<DatePicker
									label={label}
									views={views}
									{...field}
									value={x}
									slotProps={{
										textField: {
											error: !!error[id], // Marcar el campo como erróneo si hay un error asociado
											helperText: !!error[id] ? error[id]["message"] : "", // Mostrar el mensaje de error
											fullWidth: true,
										},
									}}
								/>
							</LocalizationProvider>
						</DemoContainer>
					)
				}}
			/>
		</Grid2>
	)
}
export function formDateFormat(date) {
	if (!!date["$d"]) {
		let dia = date["$D"]
		let mes = date["$M"] + 1
		let year = date["$y"]
		return `${year}-${mes}-${dia}`
	}
	let fecha = dateFormat(date)
	if (fecha) return fecha

	return false
}
function dateFormat(date) {
	let fecha = ""
	if (!!date) {
		let componentesFecha = date.split("/")
		fecha =
			componentesFecha[2] +
			"-" +
			componentesFecha[1] +
			"-" +
			componentesFecha[0]
	}
	return fecha
}

export function CheckBoxFormGrid({
	id,
	label,
	control,
	propsG,
	defaultValue = null,
	registerParams = {},
	error = {},
}) {
	return (
		<Grid2 {...propsG}>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={registerParams}
				render={({ field }) => (
					<React.Fragment>
						<FormControlLabel
							error={!!error[id]}
							control={
								<Checkbox
									checked={field.value}
									{...field}
								/>
							}
							label={label}
						/>
						<FormHelperText>
							{!!error[id] ? error[id]["message"] : ""}
						</FormHelperText>
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}

export function SliderFormGrid({
	id,
	label,
	signo = "",
	control,
	propsG,
	defaultValue = null,
	registerParams = {},
	error = {},
}) {
	const [valor, setValor] = useState(0)
	function valuetext(value) {
		setValor(value)

		return `${value}%`
	}
	return (
		<Grid2
			{...propsG}
			padding={2}
		>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={registerParams}
				render={({ field }) => (
					<React.Fragment>
						<Typography
							id="input-slider"
							gutterBottom
						>
							{`${label} ${valor} ${signo}`}
						</Typography>
						<Grid2
							container
							spacing={2}
							alignItems="center"
						>
							<Grid2
								item
								xs
							>
								<Slider
									aria-label="Custom marks"
									getAriaValueText={valuetext}
									valueLabelDisplay="auto"
									step={1}
									marks
									min={1}
									max={100}
									{...field}
								/>
							</Grid2>
						</Grid2>

						<FormHelperText>
							{!!error[id] ? error[id]["message"] : ""}
						</FormHelperText>
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}

export function FileFormGrid({
	id,
	label,
	control,
	propsG,
	defaultValue = null,
	registerParams = {},
	error = {},
}) {
	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	})

	return (
		<Grid2
			{...propsG}
			padding={2}
		>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={registerParams}
				render={({ field: { value, onChange, ...field } }) => (
					<React.Fragment>
						<Button
							component="label"
							variant="contained"
							startIcon={<CloudUploadIcon />}
							{...field}
							onChange={(event) => {
								onChange(event.target.files[0])
							}}
						>
							{label}
							<VisuallyHiddenInput type="file" />
						</Button>

						<FormHelperText>
							{!!error[id] ? error[id]["message"] : ""}
						</FormHelperText>
						{/* <Typography
								id="input-slider"
								gutterBottom
							>
								{`${label} ${valor} ${signo}`}
							</Typography> */}
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}
