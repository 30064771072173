import { useSelector } from "react-redux"
import { PERFILES } from "../../Constants/Strings"
import dayjs from "dayjs"
import "dayjs/locale/es"
dayjs.locale("es")
const moneda = localStorage.getItem("moneda")
/**
 * Obtiene el nombre del perfil dependiendo el orden en que sea mas importantes en un arreglo, por defecto admin es el primero.
 *
 * @param {Array} puestos - Un array de objetos que representan los puestos del usuario. Cada objeto debe tener una propiedad "name" que indique el nombre del puesto.
 * @example
 * const puestosUsuario = [{ name: "ADMIN" }, { name: "COLABORADOR" }];
 * const puestoMayor = supremeRole(puestosUsuario);
 * // resultado esperado: "ADMIN"
 * @returns {string} "ADMIN".
 */
export function supremeRole(puestos, prioridad = []) {
	prioridad = [PERFILES["ADMIN"], ...prioridad]
	for (const index in prioridad) {
		let nombre = prioridad[index]
		if (hasPermission(puestos, nombre)) {
			return nombre
		}
	}
	return false
}
/**
 * @deprecated "Se creo una version mejor"
 * Verifica si un usuario tiene permisos específicos, buscando el rol 'Admin'.
 * @param {Object[]} puestos - Arreglo de objetos que contienen el parámetro 'name', representando los roles del usuario.
 * @param {string | string[]} permisos - Puede ser un string o un array de strings que representan los roles permitidos.
 * @returns {boolean} - Devuelve true si el usuario tiene el permiso 'Admin' o el que desea buscar , de lo contrario, devuelve false.
 *
 * @example
 * // Ejemplo de uso:
 * const tienePermiso = hasPermission([{ name: 'Admin' }]);
 * console.log(tienePermiso); // Devolverá true si el usuario tiene el rol 'Admin'.
 */
export function hasPermission(puestos, permisos = null) {
	let has = false
	if (Array.isArray(permisos)) {
		if (permisos.length) {
			for (const permiso in permisos) {
				if (hasPermission(puestos, permisos[permiso])) {
					has = true
					break
				}
			}
		} else {
			if (hasPermission(puestos)) has = true
		}
	} else {
		for (const perfil in puestos) {
			if (
				puestos[perfil].name === PERFILES["ADMIN"] ||
				puestos[perfil].name === permisos
			) {
				has = true
				break
			}
		}
	}
	return has
}
/**
 * Valida los permisos del colaborador que inicio la sección
 * @param {(Array.<string>|string)} permisos
 * @param {boolean} strict
 * @returns {boolean} si cumple con alguno de los permisos
 * @example
 * // Ejemplo de uso:
 * const tienePermiso = hasPermission(["colaborador"]);
 * console.log(tienePermiso); // Devolverá true si el usuario tiene el rol de colaborador o Admin.
 *
 * // Ejemplo de uso strict:
 * const tienePermiso = hasPermission(["colaborador"],true);
 * console.log(tienePermiso); // Devolverá true si solo los permisos seleccionados.
 */
export function HasPermission(permisos = null, strict = false) {
	const perfiles = useSelector((state) => state.user.profiles)

	let has = false
	let check = []
	if (!strict) check.push(PERFILES.ADMIN)

	if (Array.isArray(permisos)) {
		check = [...check, ...permisos]
	} else {
		check.push(permisos)
	}

	for (const perfil in perfiles) {
		if (check.includes(perfiles[perfil].name)) {
			return true
		}
	}

	return has
}

/**
 * Invierte el orden de las partes de una cadena de texto basándose en un carácter específico.
 * Útil especialmente para manipular fechas.
 *
 * @param {String} text - La cadena de texto que se desea invertir.
 * @param {String} charter - El carácter utilizado para dividir la cadena en partes.
 * @returns {String} - La cadena de texto con las partes invertidas.
 */
export function reverseTextBySeparator(text, charter) {
	// Dividir la cadena en partes utilizando el carácter especificado
	const partes = text.split(charter)

	// Invertir el orden de las partes
	const partesInvertidas = partes.reverse()

	// Unir las partes invertidas de nuevo en una cadena
	const textoInvertido = partesInvertidas.join(charter)

	return textoInvertido
}

/**
 * Formatea el texto de la fecha del año y el mes en caso de que la fecha sea un objeto.
 * Si la fecha no es un objeto, elimina el campo correspondiente.
 *
 * @param {object} data - Objeto que contiene la información de la fecha.
 * @param {string} field - Nombre del campo que contiene la fecha en el objeto.
 * @param {string} separating - Carácter utilizado para separar el mes y el año en el resultado final. Por defecto, es "/".
 * @returns {void} - No hay valor de retorno explícito; la función modifica el objeto 'data'.
 */
export function formatYearMonth(data, field, separating = "/") {
	let date = data[field]
	if (
		typeof date === "object" &&
		date["$y"] !== undefined &&
		date["$M"] !== undefined
	) {
		let year = date["$y"]
		let month = date["$M"] + 1
		if (month < 10) month = `0${month}`
		data[field] = `${month} ${separating} ${year}`
	} else {
		delete data[field]
	}
}

/**
 * Valida que las fechas de un arreglo estén ordenadas de menor a mayor.
 *
 * @param {Array} dates - Arreglo de fechas a validar.
 * @returns {boolean} - Devuelve `true` si las fechas están ordenadas correctamente, de lo contrario, devuelve `false`.
 */
export function validateDateRange(dates) {
	let esValido = true

	// Convertir las fechas a objetos de Day.js
	for (let date in dates) {
		dates[date] = dayjs(`${dates[date]}`)
	}
	// Verificar que las fechas estén ordenadas de menor a mayor
	for (let index = 0; index < dates.length - 1; index++) {
		if (dates[index]["$d"] > dates[index + 1]["$d"]) {
			esValido = false
			break
		}
	}

	return esValido
}

/**
 *formatea las cantidades con los puntos y comas
 * @param {float} value cantidad
 * @param {string} sign símbolo
 * @returns
 */
export function formatMoney(value, sign = moneda) {
	let valor = parseFloat(value)
	if (isNaN(valor)) {
		return null
	}
	return `${sign}${valor.toLocaleString("es-MX", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})}`
}

/**
 * Formatea una fecha
 *
 * @export
 * @param {String} date Fecha
 * @param {string} [format="YYYY-M-D"] Formato en que viene la fecha
 * @returns {String} Fecha en formato DD/MM/YYYY
 */
export function dateStringFormatting(date, format = "YYYY-M-D") {
	return dayjs(date, format, "es").format("DD/MMMM/YYYY")
}
