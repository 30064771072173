import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux" //Token
import { getCajaAhorro } from "../../../Services/Api_solicitudes"
import { useSolicitudesContext } from "./SolicitudesContext"
import { ESTADOS } from "./constants"

//Componentes
import { AlertSavings } from "./CajaAhorro/BotonosCajaAhorro"
import SkeletoCajaAhorro from "./CajaAhorro/SkeletoCajaAhorro"
import TablaCajaAhorro from "./CajaAhorro/TablaCajaAhorro"
import { Texto } from "./Componentes"
import FormularioCajaAhorro from "./FormularioCajaAhorro"

//Material UI
//import Dialog from "@mui/material/Dialog"
//import DialogContent from "@mui/material/DialogContent"
import Grid from "@mui/material/Unstable_Grid2"
import { ButtonGrid2 } from "../../Common/Buttons"

function SolicitudesUsuario() {
	const token = useSelector((state) => state.user.token)
	//const actualizar = useSelector((state) => state.actualizar.actualizar)
	const { colaboradorID, actualizar } = useSolicitudesContext()
	const [db_cajaAhorro, setdb_cajaAhorro] = useState([])

	const [skeleto0, setSkeleto0] = useState(false)
	const [botonCaja, setBotonCaja] = useState(false)
	const [data, setData] = useState({})
	const [id, setId] = useState(0)
	const [act, setAct] = useState(true)
	const [formularioCaja, setFormularioCaja] = useState(false)
	const actualizarSavings = () => setAct(!act)
	//const { } = useSolicitudesContext()

	useEffect(() => {
		getCajaAhorro(
			token,
			setdb_cajaAhorro,
			`?user=${colaboradorID}`,
			setSkeleto0
		)
		//Activar para desactivar el boton de caja de ahorro en el mes de julio desde el dia 10
		desactivarBotones(setBotonCaja)
	}, [token, actualizar, formularioCaja, act, colaboradorID])

	let texto = {
		titulo: "Solicitudes de Caja Ahorro",
		botonCaja: "Crear Solicitud",
		botonSalir: "Salir Caja Ahorro",
		botonCajaAlt: "Caja de ahorro no disponible en este momento",
		botonPrestamo: "Solicitud Prestamo",
	}

	return (
		<React.Fragment>
			{!skeleto0 && <SkeletoCajaAhorro />}
			{skeleto0 && (
				<Grid
					container
					alignContent={"center"}
					alignItems={"center"}
					justifyContent={"center"}
					spacing={2}
				>
					<Texto
						label={texto["titulo"]}
						size={12}
					/>

					{!botonCaja ? (
						<React.Fragment>
							{solicitudAceptada(db_cajaAhorro) ? (
								<ButtonGrid2
									label={texto["botonSalir"]}
									propsG={{ xs: 5, md: 3 }}
									disabled={disableSalir(db_cajaAhorro)}
									onClick={() => {
										setId(db_cajaAhorro[0].id)
										setData({
											open: true,
											status: ESTADOS["FINALIZADO"],
										})
									}}
								/>
							) : (
								<ButtonGrid2
									label={texto["botonCaja"]}
									propsG={{ xs: 5, md: 3 }}
									// size={3}
									// sizeB={7}
									//disabled={true}
									disabled={disableSolicitud(db_cajaAhorro)}
									onClick={() => {
										setFormularioCaja(true)
									}}
								/>
							)}
						</React.Fragment>
					) : (
						<Texto
							label={texto["botonCajaAlt"]}
							tipo="h6"
							size={6}
						/>
					)}
					{/* <FormularioCajaAhorroDialog
						open={formularioCaja}
						setOpen={setFormularioCaja}
						close={() => {
							setFormularioCaja(false)
						}}
					/> */}
					{!!db_cajaAhorro.length && (
						<TablaCajaAhorro
							db_cajaAhorro={db_cajaAhorro}
							size={10}
						/>
					)}
				</Grid>
			)}
			<FormularioCajaAhorro
				open={formularioCaja}
				setOpen={setFormularioCaja}
			/>
			<AlertSavings
				id={id}
				data={data}
				setOpen={setData}
				actualizar={actualizarSavings}
			/>
		</React.Fragment>
	)
}

export default SolicitudesUsuario

/**
 * @description Filtra la primera solicitud de caja de ahorro que encuentre en la base de datos
 * y la retorna si esta en las fechas de apertura de caja de ahorro en el trascurso del año
 * @param {Array} data  Datos de la base de datos
 * @returns {object}
 */
function filtroSolicitudes(data = []) {
	let solicitudActual = {}
	if (data.length) {
		solicitudActual = data[0]
	}
	return isActive(solicitudActual)
}
/**
 *
 * @param {object} solicitud -Un objeto que contiene la información del prestamo
 * @param {number} year - año en el cual se valida por defecto el actual
 * @param {number} month - mes que esta trascurriendo - por defecto el actual
 * @returns
 */
export function isActive(
	solicitud,
	year = new Date().getFullYear(),
	month = new Date().getMonth() + 1
) {
	if (month >= 8) year += 1
	let dateStar = new Date(`${year - 1}-7-26`)
	let dateEnd = new Date(`${year}-8-1`)
	let dateSavings = new Date(solicitud.fecha_solicitud)

	if (dateSavings > dateStar && dateSavings < dateEnd) {
		return solicitud
	}

	return null
}
/**
 * @description si hay una solicitud con el estado buscado manda true en caso contrario false
 * @param {*} data -las solicitudes
 * @returns
 */
function disableSolicitud(data = []) {
	let enable = false
	let solicitud = filtroSolicitudes(data)
	let estados = [ESTADOS["REVISAR"], ESTADOS["FINALIZADO"], ESTADOS["APROBADO"]]

	if (estados.includes(solicitud?.Status_id)) enable = true

	return enable
}

function disableSalir(db_data = []) {
	let enable = false
	let solicitud = filtroSolicitudes(db_data)
	if (solicitud?.Status_id === ESTADOS["FINALIZADO"]) enable = true
	return enable
}

function solicitudAceptada(db_data = []) {
	let enable = false
	let solicitud = filtroSolicitudes(db_data)
	if (solicitud?.Status_id === ESTADOS["APROBADO"]) enable = true
	return enable
}

function desactivarBotones(setBotonCaja) {
	let enable = false
	let mesDisable = 7
	let diaIniciDisable = 10

	const fecha = new Date()
	const diaActual = fecha.getDate()
	const mes = fecha.getMonth() + 1

	if (mes === mesDisable)
		if (diaActual >= diaIniciDisable) {
			enable = true
			setBotonCaja(true)
		}

	return enable
}
