/**
 * Description placeholder
 *
 * @type {{ SOLICITADA: number; APPROVE: number; DISFRUTADA: number; RECHAZADA: number; CANCELADAS: number; }}
 */
export const VACATION = {
	SOLICITADA: 1,
	APPROVE: 2,
	DISFRUTADA: 3,
	RECHAZADA: 4,
	CANCELADAS: 5,
}
/**
 * Estados de la terminación de un colaborador
 * @typedef {Object} TERMINATION
 * @property {number} SOLICITADA - 1
 * @property {number} VOBO_RH - 2
 * @property {number} FINALIZADA - 3
 * @property {number} CANCELADA - 4
 * @property {number} RECHAZADA - 5
 * @property {number} RECESSION_RH - 6
 * @property {number} RECESSION_SIGN - 7
 * @property {number} RECESSION_APPROVED - 8
 */
/**
 * @type {TERMINATION}
 */
export const TERMINATION = {
	SOLICITADA: 1,
	VOBO_RH: 2,
	FINALIZADA: 3,
	CANCELADA: 4,
	RECHAZADA: 5,
	RECESSION_RH: 6,
	RECESSION_SIGN: 7,
	RECESSION_APPROVED: 8,
}
