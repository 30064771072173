import React from 'react'
import { Typography, Link} from '@mui/material/';

const Copyright = () => {
    return (
        <Typography variant="body1" color="text.secondary" align="center" sx={{ mt: 5 }}>
            {'Copyright © '}
            <Link color="inherit" href="http://lubtrac.com.mx/"> Lubtrac  </Link>  
            {new Date().getFullYear()}.
        </Typography>
    );
  
}

export default Copyright