import { Box } from "@mui/material"
import React from "react"
import error404 from "../../Images/error404.gif"

function Ruta404() {
	//https://assets-v2.lottiefiles.com/a/6a21fb9a-1178-11ee-a809-cbf4c1cb708c/9SKB1aSUCp.gif
	return (
		<Box
			sx={{
				position: "relative", // Para que el contenido interno esté posicionado de manera relativa
				width: "100%", // Ancho del 100% de la ventana
				height: "100%", // Alto del 100% de la ventana
				backgroundColor: "#fefefe", // Color de fondo
				display: "flex", // Utilizar flexbox para centrar verticalmente
				alignItems: "center", // Centrar verticalmente
				justifyContent: "center", // Centrar horizontalmente
			}}
		>
			<Box
				component="img"
				sx={{
					maxWidth: "90vw", // Ajustar el ancho máximo de la imagen al 90% del ancho de la ventana
					maxHeight: "70vh", // Ajustar el alto máximo de la imagen al 90% del alto de la ventana
					objectFit: "contain", // Mantener la relación de aspecto original
				}}
				alt="Error 404"
				src={error404}
			/>
		</Box>
	)
}

export default Ruta404
