import React from "react"
//Material UI
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { ButtonGrid2 } from "../../../../Common/Buttons"

export default function DialogConfirm(props) {
	const { open, setOpen, title = "Tu Texto ", onClick = () => {} } = props
	const handleClose = () => {
		setOpen(false)
	}
	return (
		<Dialog
			open={open}
			onClose={handleClose}
		>
			<DialogTitle id="prestamos">{title}</DialogTitle>
			<DialogActions>
				<Grid2
					width={"100%"}
					container
					spacing={1}
					justifyContent="center"
					alignItems="center"
				>
					<ButtonGrid2
						label="Cancelar"
						variant="text"
						onClick={handleClose}
						propsG={{ xs: 3 }}
					/>
					<ButtonGrid2
						label="Aceptar"
						variant="text"
						onClick={() => {
							setOpen(false)
							onClick()
						}}
						propsG={{ xs: 3 }}
					/>
				</Grid2>
			</DialogActions>
		</Dialog>
	)
}
