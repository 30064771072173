import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux" //Token
import { toast } from "react-toastify"
// Common
import { apiPostTrabajo, apiPutTrabajo } from "#api/ApiColaborador"
import { DialogForm } from "#common/Dialog"
import Formulario from "#common/formulario/Formulario"
// dayjs Fechas
import dayjs from "dayjs"
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter")
dayjs.extend(isSameOrAfter)

const DATE_FORMAT = "MM / YYYY"
const DATA = [
	{
		field: "Text",
		id: "compania",
		label: "Compañía",
		rules: { required: true },
	},
	{
		field: "Text",
		id: "giro",
		label: "Giro",
		rules: { required: { value: true } },
	},
	{
		field: "DateRange",
		idA: "fecha_inicio",
		idB: "fecha_fin",
		labelA: "Fecha Inicio",
		labelB: "fecha_fin",
		views: ["month", "year"],
		parseFormat: DATE_FORMAT,
		rulesA: { required: { value: true } },
		rulesB: { required: { value: true } },
		grid: { xs: 12 },
	},
	{
		field: "Text",
		id: "puesto",
		label: "Puesto",
		rules: { required: { value: true } },
	},
	{
		field: "Text",
		id: "funciones",
		label: "Funciones",
		rules: { required: { value: true } },
		multiline: true,
	},
]

export default function FormTrabajos({
	idColaborador,
	open,
	setOpen,
	edit,
	actualizar,
}) {
	const token = useSelector((state) => state.user.token)
	const form = useForm({ defaultValues: edit })
	const { handleSubmit, reset } = form
	const [button, setButton] = useState(false)

	useEffect(() => {
		if (edit) {
			reset(edit)
		} else reset({})
	}, [edit, reset, open])

	const submit = async (data) => {
		setButton(true)
		data.colaborador = idColaborador

		let date_star = data.fecha_inicio
		let date_end = data.fecha_fin
		date_star = dateFormat(date_star, DATE_FORMAT)
		date_end = dateFormat(date_end, DATE_FORMAT)

		if (date_end.isSameOrAfter(date_star)) {
			data.fecha_inicio = date_star.format(DATE_FORMAT)
			data.fecha_fin = date_end.format(DATE_FORMAT)

			let response
			if (edit) response = await apiPutTrabajo(token, data)
			else response = await apiPostTrabajo(token, data)
			if (response.success) {
				actualizar()
				setOpen(false)
			}
		} else toast.error("La Fecha de Inicio no puede ser mayor a la final")
		setButton(false)
	}

	return (
		<DialogForm
			title={edit ? "Editar  trabajo anterior " : "Registrar trabajo anterior "}
			open={open}
			setOpen={setOpen}
			accept={handleSubmit(submit)}
			disabledButtons={button}
		>
			<Formulario
				fields={DATA}
				formMethods={form}
			/>
		</DialogForm>
	)
}
/**
 * Convierte una cadena de texto a un objeto `dayjs` con el formato especificado,
 * o devuelve el objeto `dayjs` si ya lo es.
 *
 * @param {String|dayjs} date - La fecha a convertir o validar. Puede ser una cadena de texto o un objeto `dayjs`.
 * @param {String} format - El formato esperado de la cadena de fecha, si `date` es una cadena.
 * @returns {dayjs} - Devuelve un objeto `dayjs` ya sea convertido desde una cadena de texto o el mismo objeto `dayjs` si ya lo era.
 */
function dateFormat(date, format) {
	if (dayjs.isDayjs(date)) return date
	else {
		const newDate = dayjs(date, format)
		if (newDate.isValid()) return newDate
		else return null
	}
}
