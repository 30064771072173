import React, { createContext, useContext, useState } from "react"

// Crear el contexto
const PlanAnualContext = createContext()

export function PlanAnualContextProvider({ children, colaboradorID = "" }) {
	const [actualizar, setActualizar] = useState(true)
	return (
		<PlanAnualContext.Provider
			value={{ colaboradorID, actualizar, setActualizar }}
		>
			{children}
		</PlanAnualContext.Provider>
	)
}

// Hook personalizado para usar el contexto
export function usePlanAnualContext() {
	return useContext(PlanAnualContext)
}
