import { Fragment, useMemo, useState } from "react"
//componentes
import { ViewPDF } from "../Common/Dialog"
import { HasPermission } from "../Common/Funciones"
import ButtonsFondo from "./ButtonsFondo"
import DialogInfoFondo from "./dialogsFondo/DialogInfoFondo"
import FondoFijoFormulario from "./formulario/FondoFijoFormulario"
import { useFondoContext } from "./useContextFondo"
import { UseFondoFijoV2 } from "./useFondoFijo"

//mui
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { IconButton, Tooltip } from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import MUIDataTable from "mui-datatables"

export const TablaFondoFijo = ({ status = null }) => {
	const is_rh = HasPermission("RH")
	const params = useMemo(() => {
		return { status }
	}, [status])

	const { fondoFijo, loading } = UseFondoFijoV2({ params })
	const { openDialogPDF, setOpenDialogPDF } = useFondoContext()
	const [open, setOpen] = useState(null)
	const [info, setInfo] = useState({ open: false })
	const columns = [
		{
			name: "colaborador_name",
			label: "Colaborador",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "tipo",
			label: "Tipo",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					let datos = fondoFijo[tableMeta.rowIndex]

					const { quantity, quantity_previous: previous } = datos
					if (quantity && previous == null) return "Nuevo"
					if (!quantity) return "Cancelación"
					if (quantity > previous) return "Incremento"
					if (quantity < previous) return "Decremento"
				},
			},
		},
		{
			name: "status_description",
			label: "Status",
			options: {
				filter: true,
				sort: false,
			},
		},

		{
			name: "quantity",
			label: "Cantidad",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					let valor = parseFloat(value)
					if (!valor) return "N/A"

					return `$${valor.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}`
				},
			},
		},
		{
			name: "date_request",
			label: "Fecha Solicitud",
			options: {
				filter: false,
				sort: false,

				customBodyRender: (value) => {
					return `${value.slice(0, -5)}`
				},
			},
		},
		{
			name: "",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					let datos = fondoFijo[tableMeta.rowIndex]
					return (
						<ButtonsFondo
							solicitud={datos}
							setInfo={setInfo}
						/>
					)
				},
			},
		},
	]

	const options = {
		textLabels: {
			body: {
				noMatch: "No hay solicitudes", // Cambia este mensaje
			},
		},
		rowsPerPage: 100,
		search: false,
		filter: true, //, authorizedUser([PERFILES["PAGOS"]]),
		download: true,
		print: false,
		viewColumns: false,
		selectableRows: "none",
		customToolbar: () =>
			is_rh ? (
				<Tooltip
					title="Crear Solicitud"
					onClick={() => {
						setOpen(true)
					}}
				>
					<IconButton aria-label="delete">
						<AddCircleIcon />
					</IconButton>
				</Tooltip>
			) : null,
	}

	if (loading)
		return (
			<Skeleton
				variant="rectangular"
				width={"100%"}
				height={"60vh"}
			/>
		)

	return (
		<Fragment>
			<MUIDataTable
				title={""}
				data={fondoFijo}
				columns={columns}
				options={options}
			/>
			<ViewPDF
				title="Documentó firmado"
				pdfUrl={openDialogPDF?.url}
				open={openDialogPDF?.open}
				setOpen={setOpenDialogPDF}
			/>
			<DialogInfoFondo
				setOpen={setInfo}
				open={!!info?.open}
				fondo={info?.fondo}
			/>
			<FondoFijoFormulario
				open={open}
				setOpen={setOpen}
			/>
		</Fragment>
	)
}
