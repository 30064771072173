import {
	useState,
	createContext,
	useContext,
	useMemo,
	useCallback,
} from "react"
const FondoContext = createContext()

export function VacationProvider({ children }) {
	const [actualizar, setActualizar] = useState(false)
	const [solicitud, setSolicitud] = useState({})
	const [openApprove, setOpenApprove] = useState(false)
	const [openRequest, setOpenRequest] = useState(false)

	const datos = useMemo(
		() => ({
			//actualiza contenido
			actualizar,
			setActualizar,
			solicitud,
			setSolicitud,
			openApprove,
			setOpenApprove,
			openRequest,
			setOpenRequest,

			updateData: () => {
				setActualizar(!actualizar)
			},
		}),
		[actualizar, openApprove, openRequest, solicitud]
	)
	return <FondoContext.Provider value={datos}>{children}</FondoContext.Provider>
}
/**
 * Hook personalizado que proporciona acceso al contexto del fondo.
 * @returns {Object} - Objeto que contiene las funciones y valores del contexto del fondo.
 * @property {boolean} actualizar - Estado que indica si se deben actualizar los datos del fondo.
 * @property {function} updateData - Función para forzar la actualización de datos del fondo.
 */
export function useVacationContext() {
	return useContext(FondoContext)
}
