import { promotionDetailPut } from "#api/Api/promotions"
import { Box, Stack, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { usePromotionsContext } from "../../PromotionContext"

export function ResponseFalse({ setMaxWidth, setButtons }) {
	const {
		response,
		promotionDetail,
		setOpenConfirm: setOpen,
		reload,
	} = usePromotionsContext()
	const [sendResponse, setSendResponse] = useState({
		authorized: false,
		new_vacante: false,
		comentario: "",
	})

	const success = useMemo(
		() => (response) => {
			if (response) {
				setOpen(false)
				reload()
			}
			return false
		},
		[reload, setOpen],
	)

	const onClick = useCallback(() => {
		if (sendResponse.comentario.trim() === "") {
			toast.error("Se necesita un comentario", {})
			return null
		}
		promotionDetailPut(promotionDetail.id, sendResponse, { success })
	}, [promotionDetail, sendResponse, success])

	useEffect(() => {
		if (response === false)
			setButtons([
				{
					label: "Aceptar",
					onClick,
				},
			])
	}, [onClick, response, setButtons])

	if (response) return null
	return (
		<Box>
			<Stack spacing={3}>
				<Typography>
					Especifique las razones del rechazo de la solicitud.
				</Typography>
				<TextField
					onChange={(e) =>
						setSendResponse((response) => ({
							...response,
							comentario: e.target.value,
						}))
					}
					label="Comentario*"
					autoComplete="off"
					multiline
					fullWidth
				/>
			</Stack>
		</Box>
	)
}
