import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
	apiDeleteColaboradorBeneficiario,
	apiGetColaboradorBeneficiario,
} from "../../../../Services/ApiColaborador"
import { PRIMARYCOLOR } from "../../../../Constants/Styles"
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material"

//Icons
import Stack from "@mui/material/Stack"
import { ButtonIcon } from "../../../Common/Buttons"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import FormDataBeneficiario from "../../forms/FormDataBeneficiarios"
export default function TableBeneficiary({ colaborador, edit }) {
	const token = useSelector((state) => state.user.token)
	const [beneficiarios, SetBeneficiarios] = useState([])
	const [formEditBenefit, setFormEditBenefit] = useState(false)
	const [openBenefit, setOpenBenefit] = useState(false)
	const [actualizar, SetActualizar] = useState(true)
	const actualizarNow = () => SetActualizar(!actualizar)
	let contador = {} //cuanta los puestos

	let TABLE_BENEFICIARY = [
		{
			id: "beneficio",
			label: "Beneficio",
		},
		{
			id: "nombre",
			label: "Nombre",
		},
		{
			id: "parentezco",
			label: "Parentesco",
		},
		{
			id: "fecha_nacimiento",
			label: "Fecha Nacimiento",
		},
		{
			id: "porcentaje",
			label: "Porcentaje",
		},
	]

	if (edit) {
		let button = {
			id: "buttons",
			label: "",
			styled: { align: "right" },
		}
		TABLE_BENEFICIARY.push(button)
	}

	useEffect(() => {
		apiGetColaboradorBeneficiario(token, SetBeneficiarios, {
			colaborador: colaborador.id,
		}).then((e) => {})
	}, [colaborador.id, token, actualizar])
	return (
		<React.Fragment>
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell
								colSpan={5}
								sx={{
									backgroundColor: PRIMARYCOLOR,
									color: "#eee",
									fontWeight: "bold",
									fontSize: 16,
								}}
							>
								{" "}
								Beneficiarios
							</TableCell>
							{edit && (
								<TableCell
									align="right"
									sx={{
										backgroundColor: PRIMARYCOLOR,
										color: "#eee",
										fontWeight: "bold",
										fontSize: 16,
									}}
								>
									<ButtonIcon
										title="Agregar Beneficiario"
										icon={<AddCircleOutlineIcon />}
										sx={{ color: "#ffffff" }}
										onClick={() => {
											setOpenBenefit(true)
											setFormEditBenefit(false)
										}}
									/>
								</TableCell>
							)}
						</TableRow>

						{!!beneficiarios.length && (
							<TableRow>
								{TABLE_BENEFICIARY.map((col, index) => (
									<TableCell
										key={col.label}
										sx={{
											backgroundColor: PRIMARYCOLOR,
											color: "#eee",
											fontWeight: "bold",
										}}
									>
										{col.label}
									</TableCell>
								))}
							</TableRow>
						)}
					</TableHead>
					<TableBody>
						{beneficiarios.map((beneficiario, index) => {
							if (contador[beneficiario.beneficio] === undefined)
								contador[beneficiario.beneficio] = 1
							else contador[beneficiario.beneficio] += 1
							return (
								<TableRow key={"Beneficiado" + index}>
									{TABLE_BENEFICIARY.map((campo) => {
										if (campo.id === "porcentaje")
											return (
												<TableCell key={index + campo.id}>
													{beneficiario.porcentaje}%
												</TableCell>
											)
										if (campo.id === "buttons")
											return (
												<TableCell
													align="right"
													key={index + campo.id}
													{...beneficiario.styled}
												>
													{contador[beneficiario.beneficio] === 1 && (
														<Stack
															alignContent="center"
															justifyContent={"right"}
															direction="row"
															spacing={2}
														>
															<ButtonIcon
																title="Editar Beneficiario"
																icon={<EditIcon />}
																onClick={() => {
																	setOpenBenefit(true)
																	setFormEditBenefit(
																		beneficiarios.filter(
																			(dato) =>
																				dato.beneficio ===
																				beneficiario.beneficio,
																		),
																	)
																}}
															/>
															<ButtonIcon
																title="Eliminar Beneficiario"
																icon={<DeleteIcon />}
																onClick={() => {
																	apiDeleteColaboradorBeneficiario(token, {
																		id: beneficiario.id,
																	}).then((response) => {
																		actualizarNow()
																	})
																}}
															/>
														</Stack>
													)}
												</TableCell>
											)

										return <TableCell>{beneficiario[campo.id]}</TableCell>
									})}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<FormDataBeneficiario
				idColaborador={colaborador.id}
				open={openBenefit}
				setOpen={setOpenBenefit}
				edit={formEditBenefit}
				actualizar={actualizarNow}
			/>
		</React.Fragment>
	)
}
