import { createContext, useContext, useMemo, useState } from "react"
const CursoContext = createContext()

export function CursoProvider({
	actualizar,
	setActualizar,
	nuevoCurso,
	setNuevoCurso,
	children,
}) {
	const [curso, setCurso] = useState(null)

	const datos = useMemo(
		() => ({
			curso,
			setCurso,
			actualizar,
			nuevoCurso,
			setNuevoCurso,
			reload: () => setActualizar(!actualizar),
		}),
		[curso, actualizar, nuevoCurso, setNuevoCurso, setActualizar],
	)
	return <CursoContext.Provider value={datos}>{children}</CursoContext.Provider>
}
/**
 * Hook personalizado que proporciona acceso al contexto del fondo.
 * @typedef {Object}  paramsVacante Control de la curso
 * @property {object} curso - Datos de la curso.
 * @property {function} setCurso - Función para establecer los datos de la curso.
 * @property {boolean} actualizar - Valor que indica si se debe actualizar la lista de vacantes.
 * @property {function} reload - Cambia el valor de actualizarList
 */
/**
 *
 * @returns {paramsVacante} - Control de vacantes.
 */
export function useCursoContext() {
	return useContext(CursoContext)
}
