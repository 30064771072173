import ButtonFile from "#common/buttons/ButtonFile"
import BackupIcon from "@mui/icons-material/Backup"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Button, Stack } from "@mui/material"
import Link from "@mui/material/Link"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"
import { apiPutAssetV2 } from "../../Services/Api_inventario"
import { useSolicitudesContext } from "../Colaboradores/Solicitudes/SolicitudesContext"
import { ViewPDF } from "../Common/Dialog"
import { ASSET_TEXT } from "./AssetText"
import { HasPermission } from "#common/Funciones"
import { PERFILES } from "#constants/Strings"

const columns = [
	{
		name: "fresh_service_id",
		label: ASSET_TEXT.FRESH,
		options: { filter: true, sort: true, display: true },
	},

	{
		name: "model",
		label: ASSET_TEXT.MODEL,
		options: { filter: true, sort: true, display: true },
	},
	{
		name: "brand",
		label: ASSET_TEXT.BRAND,
		options: { filter: true, sort: true, display: true },
	},
	{
		name: "description",
		label: ASSET_TEXT.DESCRIPTION,
		options: { filter: true, sort: true, display: true },
	},
	{
		name: "url_responsive",
		label: "",
		options: { align: "right" },
	},
	{
		name: "url_policy",
		label: "",
		options: { align: "right" },
	},
]
export default function AssetTableDetail({ asset }) {
	const [open, setOpen] = useState(false)

	return (
		<React.Fragment>
			<TableContainer>
				<Table
					sx={{ width: "100%", backgroundColor: "#C5C2C29C" }}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableRow key={""}>
							{columns.map((row) => (
								<TableCell
									component="th"
									scope="row"
									{...row.options}
								>
									{row.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						<RowData
							asset={asset}
							setOpen={setOpen}
						/>
					</TableBody>
				</Table>
			</TableContainer>
			<ViewPDF
				iewPDF
				title={open.name}
				open={open.open}
				setOpen={setOpen}
				pdfUrl={open.url}
			/>
		</React.Fragment>
	)
}

const RowData = ({ asset, setOpen }) => {
	const columnsData = columns.map((row) => {
		let contentRow = null
		switch (row.name) {
			case "fresh_service_id":
				contentRow = (
					<Link
						target="_blank"
						href={`https://lubtrac.freshservice.com/cmdb/items/${
							asset[row.name]
						}`}
					>
						<Typography
							variant="h7"
							sx={{
								textAlign: "center",
								justifyContent: "center",
								//paddingLeft: 5,
								color: "black",
								fontWeight: "bold",
							}}
						>
							#{asset[row.name]}
						</Typography>
					</Link>
				)
				break
			case "url_responsive":
				contentRow = (
					<ButtonFileUpload
						{...{
							asset,
							label: "Carta asignación",
							name: "url_responsive",
							setOpen,
						}}
					/>
				)
				break
			case "url_policy":
				contentRow = (
					<ButtonFileUpload
						{...{
							asset,
							label: "Políticas",
							name: "url_policy",
							setOpen,
						}}
					/>
				)
				break
			default:
				contentRow = asset[row.name]
				break
		}

		return (
			<TableCell
				key={row.name}
				align="left"
			>
				{contentRow}
			</TableCell>
		)
	})

	return <TableRow>{columnsData}</TableRow>
}

const ButtonFileUpload = ({ asset, label, name, setOpen }) => {
	const { actualizarA } = useSolicitudesContext()
	const isSistemas = HasPermission(PERFILES.SISTEMAS)
	const success = () => {
		actualizarA()
	}
	const handleFileUpload = async (files) => {
		const file = files[0]
		let formData = new FormData()
		formData.append("id", asset.id)
		formData.append(name, file)

		apiPutAssetV2(formData, {
			success,
			message: { pending: `Subiendo carta ${label}` },
		})
	}
	const handleFileDelete = () => {
		apiPutAssetV2(
			{ id: asset.id },
			{
				success,
				message: { pending: `Eliminando  carta ${label}` },
				params: { del_file: name },
			},
		)
	}
	const handleOpen = () => {
		setOpen({ open: true, url: asset[name] })
	}

	if (asset[name])
		return (
			<Stack
				direction={"row"}
				spacing={1}
			>
				<Button
					startIcon={<VisibilityIcon />}
					onClick={handleOpen}
				>
					{label}
				</Button>
				{/* {isSistemas ? (
					<Button onClick={handleFileDelete}>
						<DeleteIcon />
					</Button>
				) : null} */}
			</Stack>
		)
	if (!isSistemas) return null
	return (
		<ButtonFile
			getFiles={handleFileUpload}
			startIcon={<BackupIcon />}
			label={label}
		/>
	)
}
