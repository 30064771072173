import { useState } from "react"
import { useSelector } from "react-redux" //Token

//Material
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import { Stack, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useDispatch } from "react-redux"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../Constants/Styles"
import { apiPutSolicitudesPagos } from "../../Services/Api_Pagos"
import { actualizarNotify } from "../../app/notificationSlice"
import { ButtonDialog, DialogGeneral } from "../Common/Dialog"
import DialogInfoFondo from "../FondoFijo/dialogsFondo/DialogInfoFondo"
import { formatMoney } from "#common/Funciones"

const colaborador_campos = {
	no_empleado: { campo: "N° Empleado", grid: { xs: 3 } },
	nombre_colaborador: { campo: "Colaborador", grid: { xs: 5 } },
	sucursal: { campo: "Sucursal", grid: { md: 4 } },
}
const banco_campos = {
	banco: { campo: "Banco" },
	cuenta: { campo: "Cuenta" },
	clabe: { campo: "Clabe" },
}

export default function EnviarPago({
	open,
	setOpen,
	solicitud = {},
	actualizar = () => {},
}) {
	const token = useSelector((state) => state.user.token)
	const dispatch = useDispatch()
	const [info, setInfo] = useState(false)
	const [imageURL, setImageURL] = useState("")
	const [imgPago, setImgPago] = useState(null)
	let pay_campos = {
		pay: { campo: "Total a Pagar" },
	}
	if (solicitud?.quantity !== solicitud.pay) {
		pay_campos = {
			quantity: { campo: "Cantidad Solicitada" },
			quantity_previous: { campo: "Fondo Anterior" },
			...pay_campos,
		}
	}

	const { type_pay, moneda } = solicitud

	const handleClose = () => {
		setOpen(false)
		setImageURL(null)
	}
	const enviar = async () => {
		let formData = new FormData()
		formData.append("id", solicitud.id)
		formData.append("type_pay", type_pay)
		formData.append("img", imgPago)
		const { success } = await apiPutSolicitudesPagos(token, formData, {})
		if (success) {
			dispatch(actualizarNotify(token))
			setOpen(false)
			actualizar()
		}
	}

	let img = (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<input
				accept=".PNG"
				style={{ display: "none" }}
				id={`file-input${"id"}`}
				type="file"
				onChange={(e) => handleFileUpload(e, setImageURL, setImgPago)}
			/>
			<label htmlFor={`file-input${"id"}`}>
				<Button
					startIcon={<AddPhotoAlternateIcon />}
					sx={{
						"&:hover": {
							backgroundColor: "#DBDADA",
							boxShadow: "10",
						},
					}}
					label="Subir Imagen"
					variant="text"
					component="span"
				>
					Subir Imagen
				</Button>
			</label>
		</div>
	)

	solicitud = { ...solicitud }
	if (imageURL) {
		img = (
			<img
				style={{ objectFit: "cover" }}
				height={"100%"}
				src={imageURL}
				alt="Imagen"
			/>
		)
	}

	return (
		<DialogGeneral
			open={open}
			onClose={handleClose}
			title={`Pago ${type_pay}`}
			maxWidth="lg"
			buttons={[
				<ButtonDialog
					key="cerrar"
					label="Cerrar"
					onClick={handleClose}
				/>,
				<ButtonDialog
					key="enviar"
					label="Enviar"
					onClick={enviar}
				/>,
			]}
		>
			<Box
				onDrop={(e) => handleDrop(e, setImageURL, setImgPago)}
				onDragOver={handleDragOver}
				onPaste={(e) => handlePaste(e, setImageURL, setImgPago)}
				overflow={"hidden"}
				height={"100%"}
			>
				<Grid
					container
					height={"100%"}
					spacing={2}
				>
					{Object.keys(colaborador_campos).map((campo) => (
						<Texto
							key={campo}
							{...colaborador_campos[campo]}
							contenido={solicitud[campo]}
						/>
					))}
					<Grid
						xs={6}
						container
						justifyContent={"space-between"}
					>
						<Grid xs={6}>
							<Stack spacing={2}>
								{Object.keys(banco_campos).map((campo) => (
									<Texto2
										key={campo}
										{...banco_campos[campo]}
										contenido={solicitud[campo]}
									/>
								))}
							</Stack>
						</Grid>
						<Grid xs={6}>
							<Stack spacing={2}>
								{Object.keys(pay_campos).map((campo) => (
									<Texto2
										key={campo}
										variant="h5"
										isMony={moneda}
										{...pay_campos[campo]}
										contenido={solicitud[campo]}
									/>
								))}
							</Stack>
						</Grid>
					</Grid>
					<Grid xs={6}>
						<div
							style={{
								height: "300px",
								width: "100%",
								position: "relative",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								border: `3px dashed ${PRIMARY_COLOR}`,
								borderRadius: "19px",
								// boxShadow: `inset 28px 28px 56px ${PRIMARY_COLOR}, inset -28px -28px 56px ${PRIMARY_COLOR}`,
							}}
						>
							{img}
						</div>
					</Grid>
				</Grid>
			</Box>
			{InformationPago(solicitud.id, type_pay, info, setInfo)}
		</DialogGeneral>
	)
}

const Texto = ({ campo, contenido, grid, isMony = false }) => {
	return (
		<Grid {...grid}>
			<Typography variant="h6">
				<strong>{campo}</strong>
				<br />
				{contenido}
			</Typography>
		</Grid>
	)
}
const Texto2 = ({ campo, contenido, variant = "h6", isMony = false }) => {
	let cantidad = contenido
	if (isMony) {
		cantidad = formatMoney(contenido, isMony)
	}
	if (!campo || !contenido) return null
	return (
		<Typography
			variant={variant}
			// fontWeight="bold"
			noWrap
		>
			<strong>{campo}</strong>
			<br></br>
			{cantidad}
		</Typography>
	)
}

const handlePaste = (event, setImageURL, setImgPago) => {
	const clipboardData = event.clipboardData || window.clipboardData
	const items = clipboardData.items

	for (let item of items) {
		if (item.type.indexOf("image") !== -1) {
			const blob = item.getAsFile()
			setImgPago(blob)
			const imageUrl = URL.createObjectURL(blob)
			setImageURL(imageUrl)
			break
		}
	}
}
const handleFileUpload = (event, setImageURL, setImgPago) => {
	const file = event.target.files[0]
	setImgPago(file)
	const imageURL = URL.createObjectURL(file)
	setImageURL(imageURL)
}
const handleDrop = (event, setImageURL, setImgPago) => {
	event.preventDefault()
	const file = event.dataTransfer.files[0]
	setImgPago(file)
	if (file) {
		const imageUrl = URL.createObjectURL(file)
		setImageURL(imageUrl)
	}
}
const handleDragOver = (event) => {
	event.preventDefault()
}

function InformationPago(id, type, open, setOpen) {
	let Information = <></>
	switch (type) {
		case "Fondo Fijo":
			return <DialogInfoFondo {...{ id, open, setOpen }} />
		case "Caja Ahorro":
			return <DialogInfoFondo {...{ open, setOpen }} />
		default:
			break
	}

	return Information
}
