import React from "react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux" //Token
import { STATUS_LOAN } from "../constants"
import { PERFILES } from "../../../../Constants/Strings"
//API
import { getPrestamos } from "../../../../Services/Api_prestamos"
import { hasPermission } from "../../../Common/Funciones"
//Componentes
import PrestamosButtons from "./PrestamosButtons"
import PrestamosPago from "./PrestamosPago"

//Material UI
import Grid from "@mui/material/Unstable_Grid2"
import MUIDataTable from "mui-datatables"
import { SkeletonSquareGrid } from "../../../Common/Skeleton"

function PrestamosTabla({
	filtro = {},
	permiso = {
		user: PERFILES["COLABORADOR"],
		status: STATUS_LOAN["AWAITED_DOCUMENT"],
	},
	size = 8,
	actualizar = null,
	setActualizar = null,
}) {
	const token = useSelector((state) => state.user.token)
	const permisos = useSelector((state) => state.user.profiles)
	const [actualizar2, setActualizar2] = useState(true)
	const [dbPrestamos, setDBPrestamos] = useState([])
	const [skeleton, setSkeleton] = useState(false)
	const [showContador, setShowContador] = useState(false)
	useEffect(() => {
		filtro["all"] = ""
		setShowContador(false)
		setSkeleton(false)
		getPrestamos(token, setDBPrestamos, filtro, setSkeleton)
	}, [token, filtro, actualizar, actualizar2])

	useEffect(() => {
		setTimeout(() => {
			setActualizar2(!actualizar2)
			if (!!actualizar) setActualizar(!actualizar)
		}, 300000)
	}, [actualizar2])

	/**
	 * @description Valida los permisos del usuario, Usuarios añadidos por defecto RH
	 * @default RH-ADMIN
	 *
	 * @param {array} [user=[]]
	 * @returns {boolean} True or False
	 */
	function authorizedUser(user = []) {
		user = [PERFILES["RH"], PERFILES["ADMIN"], ...user]
		return hasPermission(permisos, user)
	}

	const options = {
		textLabels: {
			body: {
				noMatch: "No hay solicitudes", // Cambia este mensaje
			},
		},
		rowsPerPage: 100,
		search: authorizedUser([PERFILES["PAGOS"]]),
		filter: false, //, authorizedUser([PERFILES["PAGOS"]]),
		download: authorizedUser([PERFILES["PAGOS"]]),
		print: false,

		viewColumns: authorizedUser(),
		selectableRows: "none",
		onRowClick: (rowData, rowMeta) => {
			if (filtro["status"] === STATUS_LOAN["PAY"]) {
				let data2 = [...rowData]
				data2.shift()
				let info = dbPrestamos.filter((data) => {
					return data["id"] === data2[0]
				})
				setShowContador(info[0])
			}
		},
	}
	function showCol(col = []) {
		if (
			permiso.user === PERFILES.COLABORADOR ||
			filtro["status"] === 0 ||
			col.includes(filtro["status"])
		)
			return true
		else return false
	}
	const columns = [
		{
			name: "action", // Nombre de la columna para el botón
			label: " ", // Título de la columna
			options: {
				filter: false,
				sort: false,
				display:
					!(filtro["status"] === 0) && !(filtro["status"] === STATUS_LOAN.PAY),
				customBodyRender: (value, tableMeta) => {
					let data = dbPrestamos.filter(
						(loan) => loan["id"] === tableMeta.rowData[1]
					)
					data = data[0]
					return (
						<React.Fragment>
							<PrestamosButtons
								user={permiso["user"]}
								data={data}
								ID={data["id"]}
								filtro={filtro}
								setActualizar={
									setActualizar !== null ? setActualizar : setActualizar2
								}
								actualizar={actualizar !== null ? actualizar : actualizar2}
							/>
						</React.Fragment>
					)
				},
			},
		},
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "no_empleado",
			label: "N° Empleado",
			options: {
				filter: false,
				display: false,
			},
		},
		{
			name: "sucursal",
			label: "Sucursal",
			options: {
				filter: false,
				display: false,
			},
		},
		{
			name: "departamento",
			label: "Departamento",
			options: {
				filter: false,
				display: false,
			},
		},
		{
			name: "year",
			label: "Año solicitud",
			options: {
				filter: false,
				display: false,
			},
		},
		{
			name: "turno",
			label: "Turno",
			options: {
				filter: false,
				display: authorizedUser([PERFILES["COLABORADOR"], PERFILES["PAGOS"]]),
			},
		},
		{
			name: "status_descripcion",
			label: "Estado",
			options: {
				filter: false,
				display: true,
			},
		},
		{
			name: "metodo_pago_descripcion",
			label: "Método De Pago",
			options: {
				filter: false,
				display: true,
			},
		},
		{
			name: "cantidad",
			label: "Cantidad",
			options: {
				customBodyRender: (value) => {
					return `$ ${value.toLocaleString()}`
				},
				filter: false,
				display: true,
			},
		},

		{
			name: "colaborador_nombre",
			label: "Colaborador",
			options: {
				filter: false,
				display: permiso["user"] !== PERFILES["COLABORADOR"],
			},
		},

		{
			name: "autorizador_nombre",
			label: "Jefe Autorizado",
			options: {
				filter: false,
				display: authorizedUser([PERFILES["COLABORADOR"]]),
			},
		},

		{
			name: "fecha_solicitud",
			label: "Fecha Solicitud",
			options: {
				filter: false,
				display:
					permiso["user"] !== PERFILES["PAGOS"] &&
					showCol([
						STATUS_LOAN.VALIDATE_RH,
						STATUS_LOAN.PROCESS,
						STATUS_LOAN.VALIDATE_BOSS,
					]),
			},
		},
		{
			name: "fecha_vobo_rh",
			label: "Aprobación RH",
			options: {
				filter: false,
				display:
					authorizedUser([PERFILES["GERENTE"], PERFILES["COLABORADOR"]]) &&
					showCol([STATUS_LOAN.VALIDATE_RH]),
			},
		},
		{
			name: "fecha_vobo_jefe",
			label: "Aprobación Jefe",
			options: {
				filter: false,
				display:
					authorizedUser([PERFILES["COLABORADOR"]]) &&
					showCol([STATUS_LOAN.VALIDATE_BOSS]),
			},
		},
		{
			name: "fecha_pago",
			label: "Fecha Pago",
			options: {
				filter: false,
				display:
					authorizedUser([PERFILES["COLABORADOR"]]) &&
					filtro["status"] === STATUS_LOAN.FINISHED,
			},
		},
	]

	return (
		<React.Fragment>
			{skeleton ? (
				<React.Fragment>
					<Grid xs={size}>
						<MUIDataTable
							title={""}
							data={dbPrestamos}
							columns={columns}
							options={options}
						/>
					</Grid>
				</React.Fragment>
			) : (
				<SkeletonSquareGrid />
			)}
			<PrestamosPago
				open={showContador}
				setOpen={setShowContador}
				setActualizar={setActualizar !== null ? setActualizar : setActualizar2}
				actualizar={actualizar !== null ? actualizar : actualizar2}
			/>
		</React.Fragment>
	)
}

export default PrestamosTabla
