import React from "react"
import { Grid } from "@mui/material"

import AcademicLevelsCard from "./EscolaresTables/AcademicLevelsCard"
import ExternalCoursesCard from "./EscolaresTables/ExternalCoursesCard"

const Escolares = ({ colaborador, edit = false }) => {
	return (
		<Grid
			container
			spacing={1}
		>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AcademicLevelsCard
					colaborador={colaborador}
					edit={edit}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<ExternalCoursesCard
					colaborador={colaborador}
					edit={edit}
				/>
			</Grid>
		</Grid>
	)
}

export default Escolares
