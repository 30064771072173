import { PERFILES } from "../../Constants/Strings"
//rutas
import Capacitaciones from "../../Pages/Capacitaciones"
import Colaboradores from "../../Pages/Colaboradores"
import Documentacion from "../../Pages/Documentacion"
import PerfilesPuesto from "../../Pages/PerfilesPuesto"
import PrestamosSolicitudes from "../../Pages/PrestamosSolicitudes"
import SolicitudesCajaAhorro from "../../Pages/SolicitudesCajaAhorro"
import TuPerfil from "../../Pages/TuPerfil"
import Uniformes from "../../Pages/Uniformes"
import Vacantes from "../../Pages/Vacantes"
import Cursos from "../Capacitaciones/Cursos"
import CursosPorAprobar from "../Capacitaciones/CursosPorAprobar"
import MatrizCursos from "../Capacitaciones/MatrizCursos"
import FondoFijo from "../FondoFijo/FondoFijo"
import Pagos from "../pagos/Pagos"
import Ruta404 from "./Ruta404"
import VacationBoss from "Components/vacation/VacationBoss"

//Icons
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import CheckroomIcon from "@mui/icons-material/Checkroom"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import DescriptionIcon from "@mui/icons-material/Description"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"
import MoneyIcon from "@mui/icons-material/Money"
import PeopleIcon from "@mui/icons-material/People"
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import PostAddIcon from "@mui/icons-material/PostAdd"
import RecentActorsIcon from "@mui/icons-material/RecentActors"
import SavingsIcon from "@mui/icons-material/Savings"
import SolicitudesBajas from "Components/bajas/SolicitudesBajas"
import PersonOffIcon from "@mui/icons-material/PersonOff"

const {
	RH,
	SISTEMAS,
	GERENTE,
	JEFATURA,
	PAGOS,
	COMPRAS,
	CONT,
	GUATEMALA,
	GRTE_GUAT,
	CONTABILIDAD,
	MANTENIMIENTO,
	JEFE_NOMINAS,
	GRTE_RH,
} = PERFILES
const iconStyle = { sx: { color: "#0D79D2" } }

export const rutasItems = {
	rutas: [
		//!siempre debe ser el primer item los que no tiene que aparecer en el menu
		{
			path: "/",

			element: <TuPerfil />,
		},
		{
			path: "perfil",
			element: <TuPerfil />,
		},
		{
			path: "*",
			element: <Ruta404 />,
		},
	],
	Colaboradores: [
		{
			path: "colaboradores",
			title: "Colaboradores",
			element: <Colaboradores />,
			icon: <PeopleIcon {...iconStyle} />,
			permissions: [RH, SISTEMAS],
		},
		{
			path: "vacantes",
			title: "Requerimientos",
			element: <Vacantes />,
			permissions: [RH, GERENTE, JEFATURA],
			icon: <PersonAddIcon {...iconStyle} />,
			notification: "requerimientos",
		},
		{
			path: "perfiles",
			element: <PerfilesPuesto />,
			title: "Perfiles de Puesto",
			icon: <PermContactCalendarIcon {...iconStyle} />,
			permissions: [RH, GERENTE],
		},
		{
			path: "bajas",
			title: "Bajas de Colaborador",
			element: <SolicitudesBajas />,
			icon: <PersonOffIcon {...iconStyle} />,
			permissions: [
				RH,
				CONTABILIDAD,
				SISTEMAS,
				GERENTE,
				MANTENIMIENTO,
				JEFE_NOMINAS,
			],
			notification: "bajas",
		},
	],
	Capacitaciones: [
		{
			path: "cursos",
			title: "Mis Cursos",
			element: <Capacitaciones />,
			icon: <LibraryBooksIcon {...iconStyle} />,
			textDivider: "Capacitaciones",
			permissions: [],
		},
		{
			path: "cursos-lista",
			title: "Catálogo Cursos",
			element: <Cursos />,
			icon: <PostAddIcon {...iconStyle} />,
			permissions: [RH, GERENTE],
		},
		{
			path: "cursos-aprobar",
			title: "Curso por Aprobar",
			element: <CursosPorAprobar />,

			icon: <AssignmentTurnedInIcon {...iconStyle} />,
			permissions: [RH],
			notification: "curso_por_Aprobar",
		},
		{
			path: "curso-colaboradores",
			title: "Matriz de Cursos",
			element: <MatrizCursos />,
			icon: <RecentActorsIcon {...iconStyle} />,
			permissions: [RH, GERENTE],
		},
	],
	Solicitudes: [
		{
			path: "prestamos",
			title: "Prestamos",
			element: <PrestamosSolicitudes />,
			icon: <MoneyIcon {...iconStyle} />,
			permissions: [RH, GERENTE],
			denied: GUATEMALA,
			textDivider: "Solicitudes",
		},
		{
			path: "caja-ahorro",
			title: "Caja Ahorro",
			element: <SolicitudesCajaAhorro />,
			icon: <SavingsIcon {...iconStyle} />,
			denied: GUATEMALA,
			permissions: [RH],
			notification: "caja_ahorro",
		},
		{
			path: "fondo-fijo",
			title: "Fondo Fijo",
			element: <FondoFijo />,
			icon: <PointOfSaleIcon {...iconStyle} />,
			permissions: [RH, PAGOS, CONT, GERENTE],
			denied: GUATEMALA,
		},
		{
			path: "uniformes",
			title: "Uniformes",
			element: <Uniformes />,
			icon: <CheckroomIcon {...iconStyle} />,
			permissions: [RH, COMPRAS],
			denied: GUATEMALA,
		},
		{
			path: "vacaciones",
			title: "Vacaciones",
			element: <VacationBoss />,
			icon: <AirplaneTicketIcon {...iconStyle} />,
			permissions: [RH, GRTE_GUAT],
		},
	],
	Contabilidad: [
		{
			path: "pagos",
			title: "Pagos",
			element: <Pagos />,
			icon: <CurrencyExchangeIcon {...iconStyle} />,
			permissions: [PAGOS, GRTE_RH],
			textDivider: "Información Lubtrac",
			notification: "pagos",
		},
	],

	"Información Lubtrac": [
		{
			path: "Documentacion",
			element: <Documentacion />,
			title: "Documentos Lubtrac",
			icon: <DescriptionIcon {...iconStyle} />,
			permissions: [],
			textDivider: "Información Lubtrac",
		},
	],
}
