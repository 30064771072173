// import { TextField } from "@mui/material"
import TextField from "@mui/material/TextField/TextField"
import { Fragment, useState } from "react"
import { ButtonDialog, DialogGeneral } from "../../Common/Dialog"
import { ButtonFile } from "../../Common/Buttons"
import { Stack, Typography } from "@mui/material"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import { toast } from "react-toastify"

const ModalConfirm = ({ open, setOpen, aprobar, disabledBtn, opcion }) => {
	const [observation, setObservation] = useState("")
	const [file, setFile] = useState(null)
	let entradas = []
	if (opcion.observacion)
		entradas.push(
			<TextField
				sx={{ mt: 2 }}
				id={"observation"}
				label={"Observaciones* "}
				multiline
				rows={3}
				fullWidth
				onChange={(event) => setObservation(event.target.value)}
				variant="outlined"
			/>,
		)
	if (opcion.file)
		entradas.push(
			<Stack
				marginTop={3}
				justifyContent={"center"}
				spacing={2}
			>
				<ButtonFile
					label={`Subir ${opcion.file}`}
					accept=".pdf"
					operation={setFile}
				/>
				<Stack
					spacing={1}
					direction={"row"}
					alignItems="center"
					justifyItems="center"
					justifyContent="center"
				>
					{file?.name && (
						<PictureAsPdfIcon
							color="error"
							sx={{ mt: 0 }}
						/>
					)}
					<Typography
						variant="span"
						boxShadow={50}
						width="100%"
					>
						{file?.name}
					</Typography>
				</Stack>
			</Stack>,
		)

	return (
		<DialogGeneral
			title="Confirmación"
			open={open}
			setOpen={setOpen}
			maxWidth="sm"
			fullWidth={true}
			buttons={[
				<ButtonDialog
					label="Cancelar"
					key={"cancelar"}
					disabled={disabledBtn}
					onClick={() => setOpen(false)}
				/>,
				<ButtonDialog
					label="Confirmar"
					key={"aceptar"}
					disabled={disabledBtn}
					onClick={() => {
						if (opcion?.file && file == null) {
							toast.error("Seleccione un archivo")
							return null
						}

						aprobar(observation, file)
					}}
				/>,
			]}
		>
			<Stack spacing={2}>
				{opcion.confirmacion}
				{entradas}
			</Stack>
		</DialogGeneral>
	)
}

export default ModalConfirm
