import React from "react"
import { changeTitle } from "../../app/pageSlice"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux" //Token
//componentes
import Estadistica from "./Estadistica"
//import TablaCursos from "./componentes/TablaCursos"
import TablaColaboradores from "./TablaColaboradores"

//Material Ui
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { hasPermission } from "../Common/Funciones"
import { PERFILES } from "../../Constants/Strings"

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

export default function MatrizCursos() {
	const token = useSelector((state) => state.user.token)
	const puestos = useSelector((state) => state.user.profiles)

	const [value, setValue] = useState(0)

	const dispatch = useDispatch()

	useEffect(() => {
		//-------------------------------------------------------------------------------------------------------------------------------------
		dispatch(changeTitle("Matriz de Cursos"))
	}, [dispatch, token])

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}
	let showColaborador = hasPermission(puestos, PERFILES.RH)

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="Matriz de Cursos"
				>
					{showColaborador && (
						<Tab
							label="Cursos por Colaboradores"
							{...a11yProps(0)}
						/>
					)}
					<Tab
						label="Estadística"
						{...a11yProps(showColaborador ? 1 : 0)}
					/>
				</Tabs>
			</Box>
			{showColaborador && (
				<TabPanel
					value={value}
					index={0}
				>
					<TablaColaboradores />
				</TabPanel>
			)}
			<TabPanel
				value={value}
				index={showColaborador ? 1 : 0}
			>
				<Estadistica />
			</TabPanel>
		</Box>
	)
}
