import { useState } from "react"
import { Link } from "react-router-dom"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../../Constants/Styles"

//componentes
import DialogInfoFondo from "./DialogInfoFondo"

//mui
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export default function DialogVoBoPagos({ solicitud }) {
	const [info, setInfo] = useState(false)

	return (
		<Box>
			<Stack
				container
				paddingTop={1}
				spacing={2}
				// justifyItems={"center"}
				// alignContent={"center"}
				alignItems="center"
			>
				<Typography
					// sx={{ backgroundColor: "red" }}
					align="center"
					textAlign="center"
					variant="h5"
				>
					Disponible en modulo de pagos
				</Typography>
				<Link
					key={"pagos"}
					to={`/pagos`}
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<Button
						variant="contained"
						sx={{ backgroundColor: PRIMARY_COLOR }}
					>
						Ir a pagos
					</Button>
				</Link>
			</Stack>
			<DialogInfoFondo
				open={info}
				setOpen={setInfo}
				fondo={solicitud}
			/>
		</Box>
	)
}
