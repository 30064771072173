import React from "react"
import FormDataPersonal from "./FormDataPersonal"
import FormDataMedicos from "./FormDataIMedicos"
import FormDataAddress from "./FormDataIAddress"
import { DialogForm } from "../../Common/Dialog"

export default function DialogForms({ open, setOpen, dataColaborador }) {
	const [click, setClick] = React.useState(false)
	let form = []
	let title = ""

	if (open === "personales") {
		title = "Datos personales"
		form = (
			<FormDataPersonal
				click={click}
				setClick={setClick}
				dataColaborador={dataColaborador}
			/>
		)
	}
	if (open === "medicos") {
		title = "Datos Medicos"
		form = (
			<FormDataMedicos
				click={click}
				setClick={setClick}
				dataColaborador={dataColaborador}
			/>
		)
	}
	if (open === "address") {
		title = "Datos Dirección"
		form = (
			<FormDataAddress
				click={click}
				setClick={setClick}
				dataColaborador={dataColaborador}
			/>
		)
	}

	return (
		<React.Fragment>
			<DialogForm
				title={title}
				setOpen={setOpen}
				open={open}
				accept={() => setClick(true)}
				disabledButtons={click}
				buttonText="Actualizar"
			>
				{form}
			</DialogForm>
		</React.Fragment>
	)
}
