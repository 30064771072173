export const ASSET_TEXT = {
	TITLE: "Mis equipos",
	ACTIVATE: "Activo",
	NAME: "Nombre Equipo",
	MODEL: "Modelo",
	BRAND: "Marca",
	SERIAL: "N° Serie",
	DESCRIPTION: "Descripción / Observaciones",
	PHONE: "Numero De Teléfono",
	TYPE: "Tipo",
	FRESH: "ID Fresh Asset",
	DELIVERY_DATE: "Fecha De Entrega",
	COLLECTION_DATE: "Fecha De Baja",
	ACTIVATE_TRUE: "Activo",
	ACTIVATE_FALSE: "Inactivo",
	FILE: "Carta [PDF]",
	FILE_POLICY: "Política [PDF]",
	BUTTON: "Crear Solicitud",
	BUTTON_LOW: "Baja equipo",
	DIALOG_LOW: "¿Estas seguro dar de baja el equipo?",
}
