import CastForEducationIcon from "@mui/icons-material/CastForEducation"
import LaptopIcon from "@mui/icons-material/Laptop"
import SchoolIcon from "@mui/icons-material/School"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import TrackChangesIcon from "@mui/icons-material/TrackChanges"
import TranslateIcon from "@mui/icons-material/Translate"
import WorkIcon from "@mui/icons-material/Work"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import { useMemo } from "react"

export const ListCompetencias = ({ list }) => {
	const competencias = useMemo(() => {
		const competencias = list.map((competencia) => competencia.tipo)
		return [...new Set(competencias)]
	}, [list])

	const IconList = useMemo(
		() => ({
			Responsabilidades: WorkspacePremiumIcon,
			Educación: SchoolIcon,
			Experiencia: WorkIcon,
			Adicionales: CastForEducationIcon,
			Idiomas: TranslateIcon,
			Software: LaptopIcon,
			Capacitación: TrackChangesIcon,
		}),
		[],
	)
	const componentes = useMemo(() => {
		let listCompetencias = []
		for (const competencia in competencias) {
			const nameList = competencias[competencia]
			const listFilter = list.filter(
				(competencia) => competencia.tipo === nameList,
			)

			listCompetencias.push(
				CompetenciaItem({
					key: nameList,
					listName: nameList,
					listItem: listFilter,
					IconList: IconList[nameList] ?? ThumbUpIcon,
				}),
			)
		}
		return listCompetencias
	}, [IconList, competencias, list])

	return (
		<Box padding={3}>
			<Grid container>{componentes}</Grid>
		</Box>
	)
}

const CompetenciaItem = ({ listItem, IconList, listName, size = 6 }) => {
	if (listItem == null) return null
	return (
		<Grid
			item
			md={size}
		>
			<Typography sx={{ fontSize: 18, fontWeight: "bold", color: "#555" }}>
				{listName}
			</Typography>
			<List dense={false}>
				{listItem.map((competencia) => (
					<ListItem key={`${listName}_${competencia.id}`}>
						<ListItemIcon sx={{ minWidth: 35 }}>
							<IconList sx={{ color: "green" }} />
						</ListItemIcon>
						<ListItemText primary={competencia.descripcion} />
					</ListItem>
				))}
			</List>
		</Grid>
	)
}
