import React from "react"
import { useState } from "react"
import { useSelector } from "react-redux" //Token
//Funciones
import { putPrestamos } from "../../../../../Services/Api_prestamos"
//Material UI
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import DialogConfirm2 from "./DialogConfirm2"
import DialogMoney2 from "./DialogMoney2"
import DialogAccountNumber from "./DialogAccountNumber"
import { STATUS_LOAN } from "../../constants"
import { toast } from "react-toastify"
import { DialogEditMethod } from "./DialogEditMethod"
/**
 * Componente funcional DialogBase
 *
 * Este componente se utiliza para crear una base para cuadros de diálogo en una aplicación React.
 *
 * @param {object} props - Las propiedades pasadas al componente.
 * @param {string} props.ID - El identificador único relacionado con el cuadro de diálogo.
 * @param {boolean} props.open - Indica si el cuadro de diálogo está abierto o cerrado.
 * @param {function} props.setOpen - Función utilizada para cambiar el estado de apertura del cuadro de diálogo.
 * @param {function} props.actualizar - Función utilizada para realizar alguna acción de actualización.
 * @param {function} props.setActualizar - Función opcional utilizada para cambiar el estado de la actualización.
 *                                       Por defecto, es una función vacía si no se proporciona.
 *
 * @returns {JSX.Element} - Devuelve un elemento JSX que representa la base del cuadro de diálogo.
 */
export default function DialogBase(props) {
	const {
		ID,
		open,
		cantidad = 0,
		setOpen,
		actualizar,
		setActualizar = () => {},
		whatIs = "confirm",
	} = props
	const TOKEN = useSelector((state) => state.user.token)
	const [data, setData] = useState("")
	const [button, setButton] = useState(false)
	const [money, setMoney] = React.useState(cantidad)
	const [clabe, setClabe] = React.useState({ clabe: "", cuenta: "", banco: "" })
	let isProcess = open["status"] === STATUS_LOAN["PROCESS"]
	let isCheckDoc = open["status"] === STATUS_LOAN["PAY"]
	let query = { id: ID, status: open["status"] }
	let apiData = { comentario: data }
	if (isProcess) {
		query["status"] = STATUS_LOAN["VALIDATE_RH"]
		query["cantidad"] = money
	}
	if (isCheckDoc) {
		apiData = clabe
	}

	const handleClose = () => {
		setOpen(false)
		setMoney(cantidad)
	}
	return open["status"] === "editMethodPay" ? (
		<DialogEditMethod {...props} />
	) : (
		<Dialog
			maxWidth="xs"
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-confirm-loan"
			aria-describedby="difieren-confirm-loan"
		>
			<DialogTitle id="alert-confirm-loan"></DialogTitle>

			<DialogConfirm2
				show={!(isCheckDoc || isProcess)}
				setComentario={setData}
				data={open}
			/>

			<DialogMoney2
				show={isProcess}
				selected={money}
				setSelected={setMoney}
				cantidad={cantidad}
			/>
			<DialogAccountNumber
				token={TOKEN}
				id={ID}
				show={isCheckDoc}
				selected={clabe}
				setSelected={setClabe}
				number={cantidad}
			/>
			<DialogActions>
				<Button onClick={handleClose}>Cancelar</Button>
				<Button
					disabled={button}
					onClick={() => {
						let petición = true
						if (isCheckDoc) {
							if (apiData["banco"] === "" || apiData["banco"] === null) {
								petición = false
								toast.error("Banco No puede estar vació")
							}
							if (apiData["clabe"] === "" || apiData["clabe"] === null) {
								petición = false
								toast.error("Clave interbancaria  No puede estar vació")
							} else if (apiData["clabe"].length !== 18) {
								petición = false
								toast.error("Clave interbancaria Tienen que ser 18 dígitos")
							}
						} else if (isProcess) {
							if (money === "") {
								petición = false
								toast.error("Cantidad del préstamo no pueden estar vació")
							}
						}
						if (petición) {
							setButton(true)
							putPrestamos(
								TOKEN,
								apiData,
								query,
								actualizar,
								setActualizar
							).then((x) => {
								if (x) {
									setOpen(false)
									setButton(false)
								} else setButton(false)
							})
						}
					}}
					//autoFocus
				>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
