import { sucursalesGet } from "#api/Api"
import { perfilesPuestoGet } from "#api/Api/perfilPuesto"
import { colaboradoresAllGet, colaboradoresTeamGet } from "#api/colaboradores"
import { HasPermission } from "#common/Funciones"
import { InputAutocomplete } from "#common/input/InputAutocomplete"
import InputNumber from "#common/input/InputNumber"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
import { PERFILES } from "#constants/Strings"
import { Box, Typography } from "@mui/material"
// Icon
import Grid2 from "@mui/material/Unstable_Grid2"
import { useEffect, useMemo, useState } from "react"

export function FormularioSolicitud({ vacante, setVacante, promotion, list }) {
	const { sucursales, colaboradores, perfiles, tipos, duration, loading } =
		UseVacanteFormulario(promotion, list)

	const inputs = useMemo(() => {
		const inputs = [
			{
				label: "Sucursal *",
				value: "sucursal_id",
				grid: { xs: 12, md: 4 },
				options: sucursales,
				valueLabel: "nombre",
				type: "Autocomplete",
				disabled: promotion,
			},
			{
				label: "Perfil Puesto *",
				value: "perfil_puesto_id",
				grid: { xs: 12, md: 4 },
				options: perfiles,
				valueLabel: "nombre",
				type: "Autocomplete",
				disabled: promotion,
			},
			{
				label: "Jefe Directo *",
				value: "jefe_directo_id",
				grid: { xs: 12, md: 4 },
				options: colaboradores,
				valueLabel: "full_name",
				type: "Autocomplete",
				disabled: promotion,
			},
			{
				label: "Tipo de Vacante *",
				value: "tipo_vacante",
				grid: { xs: 12, md: 4 },
				options: tipos,
				type: "Autocomplete",
				disabled: promotion,
			},
			{
				label: "¿Por Quien? *",
				value: "colaborador_id",
				grid: { xs: 12, md: 4 },
				options: colaboradores,
				valueLabel: "full_name",
				type: "Autocomplete",
				disabled: promotion,
				notShow: !["Reemplazo", "Promoción"].includes(vacante?.tipo_vacante),
			},
			{
				label: "Sueldo",
				grid: { xs: 12 },
				type: "Separador",
			},
			{
				label: "Sueldo Mensual *",
				value: "sueldo_mensual",
				grid: { xs: 12, md: 3 },
				type: "Number",
			},
			{
				label: "Bono Trimestral *",
				value: "bono_trimestral",
				grid: { xs: 12, md: 3 },
				type: "Number",
			},
			{
				label: "Garantía *",
				value: "garantia",
				grid: { xs: 12, md: 3 },
				type: "Number",
			},

			{
				label: "Duración de la Garantía",
				value: "duracion",
				grid: { xs: 12, md: 3 },
				options: duration,
				valueLabel: "id",
				type: "Autocomplete",
			},
		]

		return inputs.map((input) => {
			let component = null

			if (input.notShow) return null
			switch (input.type) {
				case "Autocomplete":
					component = (
						<InputAutocomplete
							disabled={input?.disabled}
							key={input.value}
							label={input.label}
							options={input?.options || []}
							valueLabel={input.valueLabel}
							value={vacante[input.value]}
							setValue={(value) =>
								setVacante((data) => ({ ...data, [input.value]: value }))
							}
							valueId="id"
						/>
					)
					break
				case "Number":
					component = (
						<InputNumber
							key={input.value}
							size="medium"
							fullWidth
							label={input.label}
							value={vacante[input.value]}
							setValue={(value) =>
								setVacante((data) => ({ ...data, [input.value]: value }))
							}
						/>
					)
					break
				case "Separador":
					component = (
						<Typography
							variant="h6"
							key={input.label}
						>
							{input.label}
						</Typography>
					)
					break
				default:
					component = null
			}

			return (
				<Grid2
					key={input.value}
					{...input.grid}
				>
					{component}
				</Grid2>
			)
		})
	}, [
		colaboradores,
		duration,
		perfiles,
		promotion,
		setVacante,
		sucursales,
		tipos,
		vacante,
	])

	if (loading) return <CircularSkeleton />

	return (
		<Box>
			<Grid2
				container
				spacing={2}
			>
				{inputs}
			</Grid2>
		</Box>
	)
}

const UseVacanteFormulario = (promotion, list) => {
	const [colaboradores, setColaboradores] = useState([])
	const [perfiles, setPerfiles] = useState([])
	const [sucursales, setSucursales] = useState([])
	const [loading, setLoading] = useState(true)
	const isRh = HasPermission(PERFILES.RH)
	const tipos = useMemo(() => {
		let tipos = [
			{ id: "Nueva Creación", label: "Nueva Creación" },
			{ id: "Reemplazo", label: "Reemplazo" },
			{ id: "Becario", label: "Becario" },
		]
		if (promotion) tipos.push({ id: "Promoción", label: "Promoción" })
		return tipos
	}, [promotion])
	const duration = useMemo(
		() => [
			{ id: "N/A", label: "N/A" },
			{ id: "Indefinido", label: "Indefinido" },
			{ id: "3 meses", label: "3 meses" },
			{ id: "6 meses", label: "6 meses" },
		],
		[],
	)
	const list_control = useMemo(() => {
		return list
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(list)])

	useEffect(() => {
		if (!promotion) {
			let all = []
			all.push(
				perfilesPuestoGet({
					all: true,
					success: (data) => {
						setPerfiles(data.data)
					},
				}),
			)
			if (isRh)
				all.push(
					colaboradoresAllGet({
						success: (data) => setColaboradores(data),
						params: { list: true },
					}),
				)
			else
				all.push(
					colaboradoresTeamGet(0, {
						success: (data) => setColaboradores(data),
						params: {
							list: true,
							sub_colaboradores: true,
							boss: true,
							all: true,
						},
					}),
				)

			all.push(
				sucursalesGet({
					all: true,
					success: (data) => setSucursales(data.data),
				}),
			)
			Promise.all(all).then(() => setLoading(false))
		} else {
			setSucursales(list_control.sucursales)
			setPerfiles(list_control.perfiles)
			setColaboradores(list_control.colaboradores)
			setLoading(false)
		}
	}, [isRh, list_control, promotion])

	return {
		sucursales,
		colaboradores,
		perfiles,
		tipos,
		duration,
		loading,
	}
}
