import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { ListTools } from "Components/PerfilesPuesto/DetallePuesto"
import dayjs from "dayjs"
import { createContext, useState } from "react"
import { DataItem } from "../Common/DataDisplay"
import ButtonsVacante from "./ButtonsVacante"
import ModalNewCandidato from "./Candidatos/ModalNewCandidato"
import TableCandidatos from "./Candidatos/TableCandidatos"
import { ListCompetencias } from "./DetalleVacante/ListCompetencias"
import ModalHistorial from "./ModalHistorial"
import ModalVoBo from "./ModalVoBo"
import { useVacanteContext } from "./VacantesContext"

export const ContextVacante = createContext()

const DetalleVacante = ({ dataVacante, days_passed }) => {
	const [expanded, setExpanded] = useState("panelCandidatos") // PANEL
	const [openModalCand, setOpenModalCand] = useState(false)
	const [openModalVoBo, setOpenModalVoBo] = useState(false)
	const [openModalHist, setOpenModalHist] = useState(false)
	const { herramientas = [] } = dataVacante
	const handleChangePanel = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}
	function currencyFormat(num) {
		return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	const buttons = (
		<ButtonsVacante
			statusVacante={dataVacante.status_id}
			setOpenCandidato={setOpenModalCand}
			setOpenModalVoBo={setOpenModalVoBo}
			setOpenModalHist={setOpenModalHist}
			vacante={dataVacante.id}
		/>
	)

	return (
		<Box
			component={Paper}
			elevation={12}
			id={"pdf"}
			padding={2}
		>
			<Grid container>
				<Grid
					item
					xs={12}
				>
					<Stack
						direction="row"
						spacing={2}
						justifyContent={"space-between"}
						paddingTop={2}
					>
						<Typography
							variant="h6"
							paddingLeft={2}
							sx={{ fontWeight: "bold", color: "#555" }}
						>
							{`#${dataVacante.id} ${dataVacante.perfil} (${dataVacante.area})`}
						</Typography>
						<ButtonsVacante
							statusVacante={dataVacante.status_id}
							setOpenCandidato={setOpenModalCand}
							setOpenModalVoBo={setOpenModalVoBo}
							setOpenModalHist={setOpenModalHist}
							vacante={dataVacante.id}
						/>
					</Stack>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<VacanteDetalle
						vacante={dataVacante}
						buttons={buttons}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sx={{ p: 3, pt: 0 }}
				>
					<Accordion
						expanded={expanded === "panelSueldo"}
						onChange={handleChangePanel("panelSueldo")}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Box
								component="b"
								sx={{ fontSize: 20, color: "#1d3454" }}
							>
								{" "}
								Sueldo{" "}
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container>
								<DataItem
									label={"Sueldo mensual"}
									value={currencyFormat(parseFloat(dataVacante.sueldo_mensual))}
								/>
								<DataItem
									label={"Bono trimestral"}
									value={
										dataVacante.bono_trimestral == 0
											? "N/A"
											: currencyFormat(parseFloat(dataVacante.bono_trimestral))
									}
								/>
								<DataItem
									label={"Garantía"}
									value={
										dataVacante.garantia == 0
											? "N/A"
											: currencyFormat(parseFloat(dataVacante.garantia))
									}
								/>
								<DataItem
									label={"Duración"}
									value={dataVacante.duracion}
								/>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>

				<Grid
					item
					xs={12}
					sx={{ p: 3, pt: 0 }}
				>
					<Accordion
						expanded={expanded === "panelResponsabilidades"}
						onChange={handleChangePanel("panelResponsabilidades")}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Box
								component="b"
								sx={{ fontSize: 20, color: "#1d3454" }}
							>
								Características de la vacante
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<ListCompetencias list={dataVacante.vacante_caracteristica} />
							<ListTools
								listItem={herramientas}
								listName={"Herramientas de Trabajo"}
							/>
						</AccordionDetails>
					</Accordion>
				</Grid>
				{dataVacante.status_id < 3 ? (
					<></>
				) : (
					<Grid
						item
						xs={12}
						sx={{ p: 3, pt: 0 }}
					>
						<Accordion
							expanded={expanded === "panelCandidatos"}
							onChange={handleChangePanel("panelCandidatos")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Box
									component="b"
									sx={{ fontSize: 20, color: "#1d3454" }}
								>
									{" "}
									Candidatos ({dataVacante.vacante_candidato.length})
								</Box>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									item
									xs={12}
									sx={{ p: 3, pt: 0 }}
								>
									<ContextVacante.Provider value={dataVacante}>
										<TableCandidatos
											candidatos={dataVacante.vacante_candidato}
										/>
									</ContextVacante.Provider>
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
				)}
			</Grid>
			<ModalNewCandidato
				open={openModalCand}
				setOpen={setOpenModalCand}
				vacanteID={dataVacante.id}
			/>
			<ModalVoBo
				open={openModalVoBo}
				setOpen={setOpenModalVoBo}
				autorizaciones={dataVacante.vacante_autorizacion}
				idVacante={dataVacante.id}
			/>
			<ModalHistorial
				open={openModalHist}
				setOpen={setOpenModalHist}
				historial={dataVacante.vacante_historial}
			/>
		</Box>
	)
}

export default DetalleVacante

const VacanteDetalle = ({ vacante, buttons }) => {
	const { vacanteSelect } = useVacanteContext()
	const { fecha_solicitud, fecha_rh } = vacante
	const today = new Date()
	const dateRequest = dayjs(fecha_solicitud, "D-M-YYYY")
	const dateRH = dayjs(fecha_rh, "D-M-YYYY")
	const daysTrascurridos = parseInt(
		(today - dateRequest) / (24 * 60 * 60 * 1000),
	)
	let daysTrascurridosRh = parseInt((today - dateRH) / (24 * 60 * 60 * 1000))

	const fields = [
		// {
		// 	label: "Vacante",
		// 	value: "#" + vacante.id,
		// 	size: 1,
		// },
		// {
		// 	label: "Perfil",
		// 	value: `${vacante.perfil} (${vacante.area})`,
		// 	size: 4,
		// },
		{
			label: "Status",
			value: vacante.status,
			size: 2,
		},
		{
			label: "Solicitante",
			value: vacante.solicitante,
			size: 4,
		},
		{
			label: "Jefe Directo",
			value: vacante.jefe_directo,
			size: 4,
		},
		{
			label: "Dias sin Movimientos",
			value: vacanteSelect.last_modification,
			size: 2,
			// sizeLabel:14
		},
		{
			label: "Sucursal",
			value: vacante.sucursal,
			size: 3,
		},
		{
			label: "Tipo",
			value: vacante.tipo_vacante,
			size: 2,
		},
		{
			label:
				vacante.tipo_vacante === "Reemplazo" ? "¿Por Quien?" : "Remplazo de",
			value: vacante.colaborador,
			size: 4,
			hidden: !["Reemplazo", "Promoción"].includes(vacante.tipo_vacante),
			sizeLabel: 14,
		},
		{
			label: "# Promoción",
			value: vacante.promotion || "N/A",
			size: 1,
			hidden: !vacante?.promotion,
			sizeLabel: 14,
		},
		{
			label: "Fecha Solicitud",
			value: vacante.fecha_solicitud || "N/A",
			size: 3,
			sizeLabel: 14,
		},
		{
			label: "Dias en Proceso",
			value: daysTrascurridos,
			size: 3,
			sizeLabel: 14,
		},
		// {
		//     label:"Fecha autorización",
		//     value:vacante.fecha_autorizacion||'N/A',
		//     size:2,
		//     sizeLabel:14
		// },
		{
			label: "Fecha Recepción RH",
			value: fecha_rh || "N/A",
			size: 3,
			sizeLabel: 14,
			hidden: !fecha_rh,
		},
		{
			label: "Dias RH Recepción",
			value: daysTrascurridosRh,
			size: 3,
			hidden: !daysTrascurridosRh,
			sizeLabel: 14,
		},
		{
			label: "Reclutador",
			value: vacante.reclutador,
			size: 4,
			hidden: !vacante.reclutador,
		},
	]

	const items = fields.map((item) => {
		if (!item.hidden)
			return (
				<DataItem2
					key={item.label}
					{...item}
				/>
			)
		else return null
	})

	return (
		<Box padding={2}>
			<Grid
				container
				spacing={0}
			>
				{items}
			</Grid>
		</Box>
	)
}

const DataItem2 = ({
	label,
	value,
	size = 3,
	sizeLabel = 14,
	fontColor = "#555",
	fontBold = "",
}) => {
	return (
		<Grid
			item
			md={size}
			sx={{ p: 1 }}
			xs={12}
			sm={12}
		>
			<Typography
				sx={{ fontSize: sizeLabel, fontWeight: "bold", color: "#555" }}
			>
				{" "}
				{label}
			</Typography>
			<Typography
				sx={{ fontSize: sizeLabel, fontWeight: fontBold, color: fontColor }}
			>
				{" "}
				{value}
			</Typography>
		</Grid>
	)
}
