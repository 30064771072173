import React from 'react'
import { Grid, Box } from '@mui/material/';
import { PRIMARYCOLOR } from '../../../Constants/Styles'
import { GridInputSelect } from '../../Common/Inputs';


const ItemUniforme =  ({ imagen, nombre, tallas, value, setValue,disabledSelect = false , size = 6}) => {
        return <Grid container item md={size} sx={{ pt: 1, mt: 2 ,borderBottom: 1, borderBlockColor:'#0000001f'}}>
                    <Grid item md={1} xs={0}></Grid>
                    <Grid item md={2}><Box component={'img'} src={imagen} sx={{ width: 75 }} /></Grid>
                    <Grid item md={4} xs={9} sx={{ pt: 2}}><Box component={'span'} sx={{ fontSize: 22, fontWeight: 'bold', color: PRIMARYCOLOR }}> {nombre} </Box></Grid>
                    <Grid item md={3} xs={12}><GridInputSelect size={12} label="Talla" options={tallas} value={value} setValue={setValue} disabled={disabledSelect}/></Grid>
                </Grid>
    }

export default ItemUniforme