import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import FormatoPerfilPuesto from "./FormatoPerfilPuesto"
import { ButtonGrid } from "../Common/Buttons"
import { exportPerfilPuestoPdf } from "../../Hooks/CreatePDF"
const style = {
	position: "absolute",
	top: "5%",
	left: "25%",
	// transform: "translate(-50%, -50%)",
	// width: 800,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
	overflow: "auto",
}

export default function ModalPerfilPuestoPDF({ open, setOpen, data }) {
	const handleClose = () => setOpen(false)
	
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ overflow: "auto" }}
			>
				<Box sx={style}>
					<ButtonGrid
						label="Descargar"
						onClick={() => exportPerfilPuestoPdf(data)}
					/>
					<FormatoPerfilPuesto {...data} />
				</Box>
			</Modal>
		</div>
	)
}
