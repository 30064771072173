import { useState } from 'react';
import { toast } from "react-toastify";
import { authorizationVacante, autorizacionVacante } from '../../Services/Api';
import { GREENCOLOR, REDCOLOR } from '../../Constants/Styles';
import { reloadDataVacante } from '../../app/pageSlice';
import { Grid, Box, Dialog, DialogContent, DialogTitle, Typography, Button, Tooltip, IconButton, DialogContentText, DialogActions } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending'
import CancelIcon from '@mui/icons-material/Cancel'
import { useSelector, useDispatch } from "react-redux"

const ModalVoBo =  ({open, setOpen, autorizaciones, idVacante}) => {
    const [openConfirm,setOpenConfirm] = useState(false)
    const [mensaje,setMensaje]         = useState('')
    const [authorization,setAuthorization]    = useState(null)
    const [colabVobo,setColabVobo]     = useState(null)
    const [btnDisabled,setBtnDisabled] = useState(false)
    const token = useSelector(state => state.user.token);
    const colaborador = useSelector(state => state.user.colaborador.id);
    const profiles = useSelector(state => state.user.profiles);
    const dispatch = useDispatch()

    let hasPerm = profiles.filter(userProfile => userProfile.name === 'RH' || userProfile.name === 'Admin').length
    let isRH = hasPerm > 0 ? true : false
    let modalSize = 'lg' 
    let gridSize = 3
    if(autorizaciones.length < 3){
        modalSize = 'xs'
        gridSize = 12 / autorizaciones.length 
    }
    if(autorizaciones.length === 3)
        gridSize = 4
    if(autorizaciones.length === 4)
        gridSize = 3

    const modalConfirmation = (authorization, colaborador) => {
        if(authorization === true)
            setMensaje('¿Esta seguro que desea dar VoBo a esta vacante?')
        else
            setMensaje('¿Esta seguro que desea rechazar a esta vacante?')
        setAuthorization(authorization)  
        setColabVobo(colaborador)  
        setOpenConfirm(true)
    }

    const autorizar = () =>{
        setBtnDisabled(true)
        const success=()=>{dispatch(reloadDataVacante())}
        const end=()=>{setBtnDisabled(false)}
        authorizationVacante(idVacante,colabVobo,authorization,{success,end})

    }
    if(!open)
        return
    return(
        <Dialog open={open} maxWidth={modalSize} fullWidth={true}  onClose={()=>setOpen(false)}>
            <DialogTitle sx={{fontSize:24,fontWeight:'bold',color: "#555",pb:0}}>VoBo's </DialogTitle>
            <DialogContent>
                <Grid container sx={{pt:0}} spacing={3}>
                    {autorizaciones.map(autorizacion => {
                        var content = <></>
                        if (autorizacion.fecha_autorizacion == null && (isRH || autorizacion.autorizacion === colaborador ))
                            content = <>
                                        <Typography sx={{ fontSize: 18, color: "#555", textAlign: 'center'}}>Dar Autorización </Typography>
                                        <Box > 
                                            <Tooltip title="Dar VoBo.">
                                                <IconButton color="success" size="large" onClick={() => modalConfirmation(true,autorizacion.id)}> <CheckCircleIcon fontSize="inherit" /> </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Rechazar">
                                                <IconButton color="error" size="large" onClick={() => modalConfirmation(false,autorizacion.id)}> <CancelIcon fontSize="inherit" /> </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Dialog
                                            open={openConfirm}
                                            onClose={() => setOpenConfirm(false)}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description" >
                                            <DialogTitle id="alert-dialog-title"> {"Confirmación"} </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                {mensaje}
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button disabled={btnDisabled} onClick={() => setOpenConfirm(false)}>Cancelar</Button>
                                                <Button disabled={btnDisabled} onClick={() => autorizar()} autoFocus> Aceptar  </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                        else if(autorizacion.fecha_autorizacion == null)
                            content = <>
                                        <Typography sx={{fontSize:18,color: "#555",textAlign:'center',mb:2}}> Pendiente </Typography>
                                        <PendingIcon/>
                                    </>
                        else
                            content = <>
                                        <Typography sx={{fontSize:18,color: "#555",textAlign:'center',mb:2}}> {autorizacion.fecha_autorizacion} </Typography>
                                        {autorizacion.autorizado === true ? 
                                             <><CheckCircleIcon color={'success'} /><Typography sx={{ fontSize: 18, color: GREENCOLOR, textAlign: 'center', fontWeight:'bold' }}> Autorizado </Typography></> :
                                             <><CancelIcon color={'error'} /><Typography sx={{ fontSize: 18, color: REDCOLOR, textAlign: 'center', fontWeight:'bold' }}> Rechazado </Typography></> 
                                        }
                                    </>
                        return <Grid item md={gridSize} key={'auto'+autorizacion.id}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}> 
                                <Typography sx={{fontSize:18,fontWeight:'bold',color: "#555",textAlign:'center'}}> {autorizacion.autorizacion_nombre+' '+autorizacion.autorizacion_apellido} </Typography>
                                {content}
                            </Box>
                        </Grid>
                    })}
                    
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ModalVoBo