import { MasterDialog } from "#common/Dialog/Dialog"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
import Box from "@mui/material/Box"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux" //Token
import {
	getApiCursos,
	postApiCursos,
	putApiCursos,
} from "../../Services/Api_cursos"
import { InputAutocomplete } from "#common/input/InputAutocomplete"
import { InputSelect } from "#common/input/InputSelect"
import { Stack } from "@mui/material"
import { MESES } from "./constants_capacitaciones"
import { useCursoContext } from "./CursoContext"
function FormularioCursoColaborador({
	idColaborador = "",
	open = false,
	setOpen = () => {},
	actualizar,
	setActualizar = () => {},
	setDataCurso = () => {},
}) {
	const [loading, setLoading] = useState(true)
	const [notCall, setNotCall] = useState(false)
	const [cursos, setCursos] = useState([])
	const token = useSelector((state) => state.user.token)
	const { curso, setCurso } = useCursoContext() || {}
	const handleClose = useCallback(() => {
		setOpen(false)
		setCurso(null)
		setDataCurso(false)
		setData({
			mes: "",
			colaborador_id: idColaborador,
			area_id: "",
			curso_id: "",
		})
	}, [idColaborador, setCurso, setDataCurso, setOpen])
	const [data, setData] = useState({
		mes: "",
		colaborador_id: idColaborador,
		area_id: "",
		curso_id: "",
	})

	useEffect(() => {
		if (open && !notCall) {
			getApiCursos("cursos", token, (data) => {
				setCursos(data)
				setNotCall(true)
				setLoading(false)
			})
		}
	}, [notCall, open, token])

	useEffect(() => {
		if (curso !== null)
			setData({
				colaborador_id: idColaborador,
				mes: curso.mes,
				area_id: curso.area,
				curso_id: curso.curso_id,
			})
	}, [curso, idColaborador, open])
	const dialogText = useMemo(() => {
		if (curso === null) return { title: "Crear curso", button: "Crear curso" }
		else return { title: "Editar curso", button: "Actualizar curso" }
	}, [curso])

	const apiCurso = useMemo(() => {
		if (curso === null) return postApiCursos
		else return putApiCursos
	}, [curso])

	const buttons = useMemo(() => {
		if (loading) return null
		return [
			{
				label: dialogText.button,
				onClick: () => {
					let dataUpdate = {}
					if (curso !== null) dataUpdate = { dataUpdate: "", id: curso.id }
					apiCurso(
						"colaborador",
						token,
						data,
						dataUpdate,
						actualizar,
						setActualizar,
					).then(handleClose)
				},
				disabled: !(!!data["curso_id"] && data["mes"] !== ""),
			},
		]
	}, [
		actualizar,
		apiCurso,
		curso,
		data,
		dialogText.button,
		handleClose,
		loading,
		setActualizar,
		token,
	])

	if (loading)
		return (
			<DialogCurso
				{...{
					title: dialogText.title,
					open,
					setOpen,
					closeDialog: handleClose,
					buttons,
				}}
			>
				<CircularSkeleton />
			</DialogCurso>
		)
	return (
		<DialogCurso
			{...{
				title: dialogText.title,
				open,
				setOpen,
				closeDialog: handleClose,
				buttons,
			}}
		>
			<Box>
				<Stack
					spacing={2}
					alignItems={"center"}
					justifyContent={"center"}
					alignContent={"center"}
				>
					<InputSelect
						label="Mes"
						dataId="id"
						dataLabel="label"
						data={MESES}
						value={data["mes"]}
						setValue={(value) => setData({ ...data, mes: value })}
						fullWidth
					/>

					<InputAutocomplete
						{...{
							label: "Cursos",
							options: cursos,
							value: data["curso_id"],
							setValue: (value) => setData({ ...data, curso_id: value }),
							valueId: "id",
							valueLabel: "curso",
							fullWidth: true,
						}}
					/>
				</Stack>
			</Box>
		</DialogCurso>
	)
}

export default FormularioCursoColaborador

const DialogCurso = ({
	title,
	open,
	setOpen,
	closeDialog,
	children,
	buttons,
}) => {
	return (
		<MasterDialog {...{ title, open, setOpen, closeDialog, buttons }}>
			{children}
		</MasterDialog>
	)
}
