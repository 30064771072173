import React from "react"
import { useSelector } from "react-redux" //Token
import { useState, useEffect } from "react"
import { getApiCursos } from "../../Services/Api_cursos"
import AddIcon from "@mui/icons-material/Add"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import MUIDataTable from "mui-datatables"
import { SkeletonSquareGrid } from "../Common/Skeleton"
import CursosFormulario from "./CursosFormulario"

const columns = [
	{
		name: "id",
		label: "Id",
		options: {
			filter: false,
			sort: true,
			display: false,
		},
	},
	{
		name: "curso",
		label: "Curso",
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: "horas",
		label: "horas",
		options: {
			filter: true,
			sort: false,
		},
	},
	{
		name: "plataforma",
		label: "Plataforma Id",
		options: {
			filter: true,
			sort: false,
			display: false,
		},
	},
	{
		name: "plataforma_nombre",
		label: "Plataforma",
		options: {
			filter: true,
			sort: false,
		},
	},
	{
		name: "objetivo",
		label: "Objetivo",
		options: {
			filter: false,
			sort: false,
		},
	},
	{
		name: "contenido",
		label: "Contenido",
		options: {
			filter: false,
			sort: false,
		},
	},
	{
		name: "link",
		label: "Link",
		options: {
			filter: false,
			sort: false,
		},
	},
]
function TablaCursos() {
	const token = useSelector((state) => state.user.token)
	//Datos DB------------------------------------------------------------->
	const [db_cursos, set_DBCurso] = useState([])
	const [skeleton, setSkeleton] = useState(false)
	const [formEdit, setFormEdit] = React.useState(false)
	const [editData, setEditData] = React.useState({})
	const [tablaAct, setTablaAct] = React.useState(false)
	const [edit, setEdit] = React.useState(false)

	useEffect(() => {
		getApiCursos("cursos", token, set_DBCurso, {}, setSkeleton)
	}, [token, tablaAct])
	const handleOpen = () => {
		setEdit(false)
		setEditData(false)
		setFormEdit(true)
	}

	const options = {
		filter: true,
		download: false,
		print: true,
		selectableRows: "none",
		filterType: "dropdown",
		rowsPerPage: 10,
		tableBodyHeight: "600px",

		viewColumns: false,
		customToolbar: () => (
			<React.Fragment>
				<Tooltip
					title="Agregar Curso"
					onClick={handleOpen}
				>
					<IconButton aria-label="delete">
						<AddIcon />
					</IconButton>
				</Tooltip>
			</React.Fragment>
		),
		onRowClick: (rowData, rowMeta) => {
			let datos = {}
			columns.forEach((column, index) => {
				datos[column["name"]] = rowData[index]
			})
			setEdit(true)
			setFormEdit(true)
			setEditData(datos)
		},
	}

	return !skeleton ? (
		<SkeletonSquareGrid height={"80vh"} />
	) : (
		<React.Fragment>
			<MUIDataTable
				title={"Catalogo de cursos"}
				data={db_cursos}
				columns={columns}
				options={options}
			/>
			<CursosFormulario
				open={formEdit}
				setOpen={setFormEdit}
				datos={editData}
				tablaAct={tablaAct}
				setTablaAct={setTablaAct}
				edit={edit}
			/>
		</React.Fragment>
	)
}

export default TablaCursos
