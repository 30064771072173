import React from "react"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { PERFILES } from "../../../Constants/Strings"

//API
import { getApiCursos } from "../../../Services/Api_cursos"

//Componentes
import { TextGrid } from "../../Common/Texto"
import { usePlanAnualContext } from "./PlanAnualContext"
import CursosPlanAnualSelection from "./CursosPlanAnualSelection"
import { hasPermission } from "../../Common/Funciones"
import { SkeletonSquare } from "../../Common/Skeleton"

//Material Ui
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

function ListAreas(props) {
	const { actualizar } = usePlanAnualContext()
	const token = useSelector((state) => state.user.token)
	const [areas, setAreas] = useState([])
	const [state, setState] = useState([])
	const [areaSelected, setAreaSelected] = useState([])
	const [count, setCount] = useState(0)
	const [areasId, setAreasId] = useState([])
	const [skeleton, setSkeleton] = useState(false)
	let puesto = useSelector((state) => state.user.profiles)
	let perfil = hasPermission(puesto, PERFILES["RH"])

	useEffect(() => {
		let filtro = {
			idColaborador: "",
			personally: "",
		}
		if (perfil) filtro = { personally: "" }
		getApiCursos("area", token, setAreas, filtro, setSkeleton)
		setState([])
	}, [token, actualizar, perfil])

	useEffect(() => {
		let show = {}
		let count = 0
		show = areas.filter((area) => {
			if (state[area.id]) count += parseInt(area.count)
			return state[area.id]
		})
		setCount(count)
		setAreaSelected(show)
		let areaId = []
		for (let area in state) {
			if (state[area]) areaId.push(area)
		}
		setAreasId(areaId)
	}, [state, areas])

	const handleChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		})
	}

	return skeleton ? (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center", // Centrar horizontalmente
				alignItems: "center", // Centrar verticalmente
				width: "100%", // Ajustar el ancho al contenedor padre
			}}
		>
			<Grid
				sx={{
					width: "100%",
				}}
				container
				justifyContent={"center"}
				spacing={1}
			>
				<Grid md={3}>
					<Grid
						container
						justifyContent={"center"}
						spacing={1}
					>
						{!areas.length ? (
							<TextGrid
								allSize={12}
								label="No Tienes departamentos a tu cargo"
							/>
						) : (
							<React.Fragment>
								<Grid md={12}>
									<Grid
										container
										alignContent={"flex-start"}
										justifyContent={"flex-start"}
										alignItems={"center"}
										spacing={1}
									>
										<TextGrid
											allSize={1}
											label={`Departamentos`}
											tipo="h5"
										/>
										<Grid md={12}>
											<FormControl
												required
												component="fieldset"
												sx={{
													border: "2px solid black",
													borderTopLeftRadius: "10px",
													borderTopRightRadius: "5px",
													borderBottomRightRadius: "5px",
													borderBottomLeftRadius: "10px",
													width: "100%",
													maxWidth: 500,
													maxHeight: 300,
													position: "relative",
													overflow: "auto",
													"& ul": { padding: 0.5 },
													"&::-webkit-scrollbar": {
														width: "10px",
													},
													"&::-webkit-scrollbar-thumb": {
														margin: 10,
														backgroundColor: "black", // Color del pulgar de la barra de desplazamiento
														borderRadius: "10%", // Borde redondeado para hacerlo circular
													},
												}}
												variant="standard"
											>
												<FormGroup>
													{areas.map((area) => {
														return (
															<DataCheck
																disabled={area.planAnual}
																key={area.id}
																label={area.description}
																idArea={area.id}
															/>
														)
													})}
												</FormGroup>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>
						)}
						{areaSelected.length ? (
							<React.Fragment>
								<Grid
									md={12}
									//xl={3}
								>
									<Grid
										container
										alignContent={"flex-start"}
										justifyContent={"flex-start"}
										alignItems={"center"}
										spacing={1}
									>
										<TextGrid
											allSize={12}
											label={`Colaboradores: ${count}`}
											tipo="h5"
										/>
										<Grid md={12}>
											<ListPersonally data={areaSelected} />
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>
						) : (
							<React.Fragment></React.Fragment>
						)}
					</Grid>
				</Grid>
				{areaSelected.length ? (
					<React.Fragment>
						<Grid md={9}>
							<CursosPlanAnualSelection areas={areasId} />
						</Grid>
					</React.Fragment>
				) : (
					<React.Fragment></React.Fragment>
				)}
			</Grid>
		</Box>
	) : (
		<SkeletonSquare />
	)

	function DataCheck({ label = "a", idArea = "1", disabled = false }) {
		return (
			<FormControlLabel
				control={
					<Checkbox
						disabled={disabled}
						checked={state[idArea]}
						onChange={handleChange}
						name={`${idArea}`}
					/>
				}
				label={label}
			/>
		)
	}
}

export default ListAreas
export function ListPersonally({ data }) {
	return (
		<List
			sx={{
				border: "2px solid black",
				borderTopLeftRadius: "10px",
				borderTopRightRadius: "5px",
				borderBottomRightRadius: "5px",
				borderBottomLeftRadius: "10px",
				width: "100%",
				maxWidth: 500,
				maxHeight: 300,
				position: "relative",
				overflow: "auto",
				"& ul": { padding: 0.5 },
				"&::-webkit-scrollbar": {
					width: "10px",
				},
				"&::-webkit-scrollbar-thumb": {
					margin: 10,
					backgroundColor: "black", // Color del pulgar de la barra de desplazamiento
					borderRadius: "10%", // Borde redondeado para hacerlo circular
				},
			}}
			subheader={<li />}
		>
			{data.map((sectionId) => (
				<li key={`section-${sectionId.id}`}>
					<ul>
						<ListSubheader>{`${sectionId.description} | Colaboradores : ${sectionId.count}`}</ListSubheader>
						{sectionId["personally"].map((item) => (
							<ListItem key={`item-${sectionId}-${item["nombre"]}`}>
								<ListItemText primary={`${item["nombre"]}`} />
							</ListItem>
						))}
					</ul>
				</li>
			))}
		</List>
	)
}
