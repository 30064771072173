export const OPTIONS_PARENTEZCO = [
	{ value: "Padre",      label: "Padre" },
	{ value: "Madre",      label: "Madre" },
	{ value: "Hermano(a)", label: "Hermano(a)" },
	{ value: "Esposo(a)",  label: "Esposo(a)" },
	{ value: "Hijo(a)",    label: "Hijo(a)" },
	{ value: "Nieto(a)",   label: "Nieto(a)" },	
	{ value: "Abuelo(a)",  label: "Abuelo(a)" },
]

export const OPTIONS_SEXO = [
	{ value: "Masculino",  label: "Masculino" },
	{ value: "Femenino",   label: "Femenino" },
	{ value: "Indistinto", label: "Indistinto" },
]
export const OPTIONS_ESTADO_CIVIL = [
	{ value: "Soltero",    label: "Soltero" },
	{ value: "Casado",     label: "Casado" },
	{ value: "Divorciado", label: "Divorciado" },
	{ value: "Viudo",      label: "Viudo" },
]
export const OPTIONS_ESCOLARIDAD = [
	{ value: "No Aplica",             label: "No Aplica" },
	{ value: "Primaria",             label: "Primaria" },
	{ value: "Secundaria",           label: "Secundaria" },
	{ value: "Preparatoria",         label: "Preparatoria" },
	{ value: "Bachillerato Técnico", label: "Bachillerato Técnico" },
	{ value: "Universidad",          label: "Universidad" },
	{ value: "Maestría - Posgrado",       label: "Maestría - Posgrado" },
]
export const OPTIONS_ESCOLARIDAD_GT = [
	{ value: "No Aplica",             label: "No Aplica" },
	{ value: "Primaria",             label: "Primaria" },
	{ value: "Básico",           label: "Básico" },
	{ value: "Diversificado",         label: "Diversificado" },
	{ value: "Licenciatura O Profesional", label: "Licenciatura O Profesional" },
	{ value: "Posgrado", label: "Posgrado" },

]
export const OPTIONS_BENEFICIO = [
	{ value: "Seguro de vida", label: "Seguro de vida" },
	{ value: "Prestaciones Empresa", label: "Prestaciones Empresa" },
]
export const OPTIONS_TIPO_SANGRE = [
	{ value: " A+" },
	{ value: " A-" },
	{ value: " B+" },
	{ value: " B-" },
	{ value: "AB+" },
	{ value: "AB-" },
	{ value: " O+" },
	{ value: " O-" },
]
