import React from "react"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"

const meses = [
	{ id: 1, label: "Enero" },
	{ id: 2, label: "Febrero" },
	{ id: 3, label: "Marzo" },
	{ id: 4, label: "Abril" },
	{ id: 5, label: "Mayo" },
	{ id: 6, label: "Junio" },
	{ id: 7, label: "Julio" },
	{ id: 8, label: "Agosto" },
	{ id: 9, label: "Septiembre" },
	{ id: 10, label: "Octubre" },
	{ id: 11, label: "Noviembre" },
	{ id: 12, label: "Diciembre" }
]
function TarjetasMesesDrop() {
	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			spacing={1}
			//sx={{ height: "100%" }}
			style={
				{
					//background: "red",
				}
			}>
			{meses.map((mes, index) => (
				<Grid
					xs={12}
					key={`mes${index}`}>
					<Card
						//key={`mes${index}`}
						style={{
							// background: "lightblue",
							height: 32
						}}>
						<Typography
							component="h4"
							variant="poster"
							align="center"
							alignItems="center"
							style={{
								paddingTop: 5,
								height: "100%",
								width: "100%"
							}}>
							{mes.label}
						</Typography>
					</Card>
				</Grid>
			))}
		</Grid>
	)
}

export default TarjetasMesesDrop
