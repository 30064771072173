import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux" //TOKEN
//API
import { getApiCursos, putApiCursos } from "../../Services/Api_cursos"

//Componentes
import { changeTitle } from "../../app/pageSlice"
import VistaUsuario from "./VistaUsuario"
import CursosZip from "./componentes/CursosZip"

//materia ui
import MUIDataTable from "mui-datatables"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import CachedIcon from "@mui/icons-material/Cached"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import { SkeletonSquareGrid } from "../Common/Skeleton"
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload"

const columns2 = [
	{
		name: "id",
		label: "Id",
		options: {
			filter: false,
			sort: true,
			display: false,
		},
	},
	{
		name: "no_empleado",
		label: "N° Empleado",
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: "nombre",
		label: "Nombre",
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: "area",
		label: "Departamento",
		options: {
			filter: true,
			sort: true,
		},
	},
	{
		name: "sucursal",
		label: "Sucursal",
		options: {
			filter: true,
			sort: true,
		},
	},
]

export default function TablaColaboradores() {
	const dispatch = useDispatch()
	const token = useSelector((state) => state.user.token) //Token
	const [db_colaborador, set_DBcolaborador] = useState([])
	const [modalCursosZip, setModalCursosZip] = useState(false)
	let [skeleton, setSkeleton] = useState(false)

	useEffect(() => {
		dispatch(changeTitle("Cursos por colaborador"))
		getApiCursos(
			"colaborador",
			token,
			set_DBcolaborador,
			{ list_colaborador: "" },
			setSkeleton
		)
	}, [dispatch, token])

	const options = {
		filter: true,
		download: false,
		print: false,
		viewColumns: false,
		selectableRows: "none",
		expandableRowsHeader: false,
		rowsPerPage: 100,
		tableBodyHeight: "700px",
		expandableRows: true,
		renderExpandableRow: (rowData, rowMeta) => {
			return (
				<React.Fragment>
					<tr>
						<td colSpan={6}>
							<VistaUsuario id={rowData[0]} />
						</td>
					</tr>
				</React.Fragment>
			)
		},
		customToolbar: () => (
			<React.Fragment>
				<Tooltip
					title="Descargar Cursos"
					onClick={() => {
						setModalCursosZip(true)
					}}
				>
					<IconButton aria-label="delete">
						<SimCardDownloadIcon />
					</IconButton>
				</Tooltip>
				<Tooltip
					title="Actualizar Cursos"
					onClick={() => {
						putApiCursos("colaborador", token, {}, { actualizar: true })
					}}
				>
					<IconButton aria-label="delete">
						<CachedIcon />
					</IconButton>
				</Tooltip>
			</React.Fragment>
		),
	}
	return !skeleton ? (
		<SkeletonSquareGrid height={"80vh"} />
	) : (
		<React.Fragment>
			<Grid
				container
				spacing={2}
				columns={12}
			>
				<Grid
					item
					xs={12}
					sx={{ width: "95%" }}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
						<MUIDataTable
							title={"Curso Colaborador"}
							data={db_colaborador}
							columns={columns2}
							options={options}
						/>
					</Paper>
				</Grid>
			</Grid>
			<CursosZip
				open={modalCursosZip}
				setOpen={setModalCursosZip}
			/>
		</React.Fragment>
	)
}
