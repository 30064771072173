import { useEffect, useState } from "react"
//API
//componentes
//material ui
import { api_rh_get } from "#api/API_RH"
export function UseColaboradores(id, activar) {
	const [colaboradores, setColaboradores] = useState([])
	const [load, setLoad] = useState(false)
	useEffect(() => {
		const success = (response) => {
			if (activar) setColaboradores(response?.colaboradores)
			else setColaboradores(response)
			setLoad(false)
		}
		const controller = new AbortController()
		setLoad(true)
		setColaboradores([])
		if (!activar) {
			api_rh_get(`colaboradores/${id}/my_team`, {
				success,
				params: { small_data: true },
				signal: controller.signal,
			})
		} else
			api_rh_get(`colaboradores`, {
				success,
				params: { small_data: true },
				signal: controller.signal,
			})

		return () => {
			controller.abort()
		}
	}, [activar, id])
	return { colaboradores, load }
}
export function UseColaborador(id, params) {
	const [colaboradores, setColaboradores] = useState([])
	const [loading, setLoading] = useState(false)
	const success = (response) => {
		setColaboradores(response)
		setLoading(false)
	}
	useEffect(() => {
		const controller = new AbortController()
		setLoading(true)
		setColaboradores([])
		api_rh_get(`colaboradores/${id}`, {
			success,
			params: { small_data: true, ...params },
			signal: controller.signal,
		})

		return () => {
			controller.abort()
		}
	}, [id, params])
	return { colaboradores, loading }
}

export function DataPerfiles(id) {
	const [perfiles, setPerfiles] = useState([])
	const [loadPerfiles, setLoadPerfiles] = useState(false)

	useEffect(() => {
		const controller = new AbortController()
		setLoadPerfiles(true)
		const success = (response) => {
			setPerfiles(response)
			setLoadPerfiles(false)
		}
		api_rh_get(`perfiles_puesto/${id}/reporta_a`, {
			signal: controller.signal,
			success,
		})

		return () => {
			controller.abort()
		}
	}, [id])
	return { perfiles, loadPerfiles }
}
