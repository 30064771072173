import { useEffect, useState } from "react"
import { useSelector } from "react-redux" //Token

import { COLOR_TARJETA, MESES } from "./constants_capacitaciones"

import { getApiCursos } from "../../Services/Api_cursos"
import { TextGrid2 } from "../Common/Texto"
import TCCardContent from "./componentes/TarjetaCurso/TCCardContent"

import "react-toastify/dist/ReactToastify.css"

import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { ButtonsCard } from "./componentes/TarjetaCurso/ButtonsCard"

/**
 * Componente TarjetaCurso
 *
 * Este componente muestra una tarjeta con los datos del curso del colaborador.
 * @typedef {'usuario' | 'planAnual' | 'porAprobar'} filtro
 * Definición de las opciones posibles para el prop 'tipo'.
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.db_user - Datos del usuario.
 * @param {Object} props.db_cursoArea - Datos del área del curso.
 * @param {Object} props.db_cursos - Datos del curso.
 * @param {filtro} props.filtro - Opción de filtro para mostrar todos los datos o no (por defecto, "usuario").
 * @param {boolean} props.open - Indica si la tarjeta está abierta o cerrada (por defecto, cerrada).
 * @param {Function} props.reload - UseState Set para actualizar la lista de cursos.
 * @returns {JSX.Element} - Devuelve un elemento JSX que representa la tarjeta del curso del colaborador.
 */
export default function TarjetaCurso({
	db_user = {},
	db_cursoArea,
	db_cursos,
	filtro = "usuario",
	open = false,
	reload = () => {},
}) {
	//Selector
	const token = useSelector((state) => state.user.token)
	const [urlPDF, setUrlPDF] = useState("")

	const linkPDF = db_user.url_s3
	useEffect(() => {
		if (open) {
			getApiCursos("link", token, setUrlPDF, { url: linkPDF })
		}
	}, [linkPDF, open, token])

	const buttonColor = "#F9F9F9"

	let mes
	if (!!db_cursoArea) mes = db_cursoArea.mes
	else mes = db_user.mes
	return (
		<Card
			key={`${db_user.id}`}
			sx={{
				// minWidth: "200px",
				height: "90%",
				m: 0.5,
				borderRadius: 5,
				boxShadow: 10,
			}}
		>
			<Grid
				container
				borderRadius={80}
			>
				<Grid
					xs={12}
					sx={{
						padding: "10px",
						backgroundColor: COLOR_TARJETA(db_user.status),
					}}
				>
					{!!db_cursoArea && (
						<TextGrid2
							label={MESES[mes].label}
							variant="h6"
							propsG={{ xs: 1 }}
							sx={{ fontWeight: "bold" }}
							color="text.secondary"
						/>
					)}
					{filtro !== "planAnual" && (
						<CardActions sx={{ height: 40, m: 0.1 }}>
							{/* ----------------------------------------------------------------------------------------------------- */}
							<Grid
								container
								justifyContent="space-between"
								sx={{ width: "100%" }}
							>
								<TextGrid2
									label={MESES[mes].label}
									variant="h6"
									propsG={{ xs: 1 }}
									sx={{ fontWeight: "bold", color: buttonColor }}
									color="text.secondary"
								/>

								<Grid xs={9}>
									<ButtonsCard
										{...{
											cursoColaborador: { ...db_user, curso_id: db_cursos.id },

											setUrlPDF,
											reload,
										}}
									/>
								</Grid>
							</Grid>
						</CardActions>
					)}
				</Grid>
				<Grid xs={12}>
					<TCCardContent
						db_user={db_user}
						db_cursos={db_cursos}
						db_cursoArea={db_cursoArea}
						urlPDF={urlPDF}
						filtro={filtro}
						statusCurso={db_user.status}
					/>
				</Grid>
			</Grid>
		</Card>
	)
}
