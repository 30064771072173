import {useState, useEffect} from 'react';
import {Grid, Button, TextField, Box, InputLabel, MenuItem, FormControl, Select, Dialog, DialogActions, DialogContent, List, ListItem, IconButton, DialogTitle, Divider, ListItemText, ListItemIcon, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import {GridInputSelect, GridInputText  } from '../Common/Inputs';
import { getPerfiles, getPerfilDetail, saveVacante, getMisColaboradores, getSucursales } from '../../Services/Api';
import { useSelector } from "react-redux";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCheckPermission } from '../../Hooks/useCheckPermission';
import { Fragment } from 'react';
import { VacanteHerramientas } from 'Components/PerfilesPuesto/formulario/VacanteHerramientas';
import { toast } from 'react-toastify';
// Icon
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import SchoolIcon from '@mui/icons-material/School';
import TranslateIcon from '@mui/icons-material/Translate';
import LaptopIcon from '@mui/icons-material/Laptop';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WorkIcon from '@mui/icons-material/Work';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';

const ModalNewVacante = ({open, setOpen , setLoadList, loadList}) => {
    const handleClose = () =>  setOpen(false); 
    const [btnDisabled,setbtnDisabled]     = useState(false)
    const [listPerfiles, setListPerfiles]  = useState([])
    const [listSuc,setListSuc]             = useState([])
    const [listColab,setListColab]         = useState([])
    const [listaColabReemplazo,setListaColabReemplazo] = useState([])
    const [perfilVacante,setPerfilVacante] = useState('')
    const [jefe,setJefe]                   = useState('')
    const [sucursal,setSucursal]           = useState('')
    const [tipoVac,setTipoVac]             = useState('')
    const [colaborador,setColaborador]     = useState('')
    const [sueldo,setSueldo]               = useState(0)
    const [bono,setBono]                   = useState(0)
    const [garantia,setGarantia]           = useState(0)
    const [duracion,setDuracion]           = useState('')
    const [listRespo,setListRespo]         = useState([])
    const [listEduc,setListEduc]           = useState([])
    const [listExp,setListExp]             = useState([])
    const [listAdic,setListAdic]           = useState([])
    const [listTools,setListTools]         = useState([]) 
    const [listIdiomas,setListIdiomas]= useState([]) 
    const [listSoftware,setListSoftware]= useState([]) 
    const [listInterna,setListInterna]= useState([]) 
    const [listExterna,setListExterna]= useState([]) 
    const [listTraining,setListTraining]= useState([]) 
    const [listToolsDefault,setListToolsDefault]         = useState([])    
    
    const token   = useSelector(state => state.user.token);
    const dataColaborador = useSelector(state => state.user.colaborador);
    const profiles = useSelector(state => state.user.profiles);
    const isRH = useCheckPermission(profiles, 'RH')

    useEffect(()=>{
        if (!open) return
        getPerfiles(token).then((response) => {
            let arrayPerfiles = []
            response.map(perfil => arrayPerfiles.push({'label': perfil.nombre,'value':perfil.id}) )
            setListPerfiles(arrayPerfiles)
            getSucursales(token).then(response =>{
                let arraySuc = []
                response.map(sucursal => arraySuc.push({'value':sucursal.id, 'label':sucursal.nombre}) )
                setListSuc(arraySuc)
                
            }).catch(error =>{
                alert(error)
            })
        }).catch( error => { console.error('Error '+error) })
        
        getMisColaboradores(token,dataColaborador.id).then(response => {
            let arrayColab = []
            let arrayReemplazo = []
            response.map(colab => {
                arrayReemplazo.push({ 'value': colab.id, 'label': colab.nombre + ' ' + colab.apellido_paterno })
                if(!colab.baja)
                    arrayColab.push({ 'value': colab.id, 'label': colab.nombre + ' ' + colab.apellido_paterno })
                return null
            })
            setListaColabReemplazo(arrayReemplazo)
            setListColab(arrayColab)
        }).catch(error => {
            alert(error)
        })
    },[dataColaborador.id, open, token])

    const save =() =>{
        if(sucursal === '' || perfilVacante === '' || jefe === '' || tipoVac === '' || ((tipoVac === 'Reemplazo' || tipoVac === 'Promoción') && colaborador ==='') || (sueldo == 0 && sueldo == '') || duracion == '' ){
            alert('Debes de llenar todos los campos requeridos')
            return;
        }
        setbtnDisabled(true)
        saveVacante(token,
            perfilVacante, 
            jefe, 
            tipoVac, 
            sueldo, 
            bono, 
            garantia, duracion, sucursal, colaborador, listRespo, listEduc, listExp, listAdic,listTools?.list_tools).then(response=>{
            alert('La vacante ha sido creada con exito')
            setLoadList(!loadList)
            setOpen(false)
        }).catch(error => alert(error) )
        .finally(()=> setbtnDisabled(false) )
    }

    const changePerfil = (perfil) => {
        setPerfilVacante(perfil)
        getPerfilDetail(token, perfil).then( response =>{
            const { perfil_competencia,perfil_interaccion,herramientas}=response
            dataType(perfil_competencia,'Responsabilidades',setListRespo)
            dataType(perfil_competencia,'Educación',setListEduc)
            dataType(perfil_competencia,'Experiencia',setListExp)
            dataType(perfil_competencia,'Adicionales',setListAdic)

            dataType(perfil_competencia,'Idiomas',setListIdiomas)
            dataType(perfil_competencia,'Software',setListSoftware)
            dataType(perfil_competencia,'Capacitación',setListTraining)
            
            dataTypeInteraction(perfil_interaccion,'Interna',setListInterna)
            dataTypeInteraction(perfil_interaccion,'Externa',setListExterna)
            // dataType(perfil_competencia,'Capacitación',setListAdic)
            setListToolsDefault(herramientas)
        }).catch( err =>{
            console.error(err)
        })
    }

    const competencias=[
        {title:"Educación y Formación", list:listEduc, setList:setListEduc, Icon:SchoolIcon},
        {title: "Experiencia", list:listExp,setList:setListExp, Icon:WorkIcon},
        {title: "Adicionales", list:listAdic,setList:setListAdic ,Icon:CastForEducationIcon},
        {title: "Software", list:listSoftware,setList:setListSoftware ,Icon:LaptopIcon},
        {title: "Idiomas", list:listIdiomas,setList:setListIdiomas ,Icon:TranslateIcon},
        {title: "Capacitación", list:listTraining,setList:setListTraining ,Icon:ThumbUpIcon},
    ]
    
    const competenciasMap=competencias.map((item,index)=>(
        <CompetenciasType 
            {...item} 
            key={item.type+index} 
        />

    ))
    
    let listsCompetencias = <></>
    if(listRespo.length > 0 || listEduc.length > 0)
    listsCompetencias = <>
                <Grid item md={12}>
                    <Typography sx={{fontSize:20,fontWeight:'bold',color: "#555"}} >Competencias</Typography>
                </Grid>
                {competenciasMap}                
                <Grid item md={6}>
                    <VacanteHerramientas setDataSend={setListTools}
                    dataPerfil={perfilVacante}
                    expanded="panelHerramientas"
                    handleChangePanel={()=>{}} />
                </Grid>
                <CompetenciasType 
                    title="Responsabilidades" 
                    list={listRespo}
                    setList={setListRespo} 
                    md={12}
                />
                
    </>

    let viewColab = <></>
    if(tipoVac === 'Reemplazo' || tipoVac === 'Promoción')
        viewColab = <Grid item xs={4}>
                        <Autocomplete
                        disablePortal
                        id="jefe"
                        options={listaColabReemplazo}
                        sx={{ width: 400 }}
                        onChange={(event, value) => setColaborador(value == null ? null : value.value)}
                        renderInput={(params) => <TextField {...params} label={tipoVac === 'Reemplazo' ? '¿Por Quien?*':'¿Para Quien?* '} />}/>
                    </Grid>
    if(!open)
        return
    return(
        <Dialog open={open} maxWidth={'xl'} fullWidth={true} onClose={handleClose}>
            <DialogTitle sx={{fontSize:24,fontWeight:'bold',color: "#555"}}>Nueva vacante</DialogTitle>
            <DialogContent>
            <Grid container sx={{mt:0}} spacing={3} alignItems={'flex-start'}>
                <GridInputSelect label={'Sucursal*'} value={sucursal} setValue={setSucursal} options={listSuc} size={4}/>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="perfil"
                        options={listPerfiles}
                        sx={{ width: 400 }}
                        onChange={(event, value) => changePerfil( value == null ? null : value.value)}
                        renderInput={(params) => <TextField {...params} label="Perfil de puesto" />}/>
                </Grid>
                <Grid item md={4}>
                    <Autocomplete
                        disablePortal
                        id="jefe"
                        options={listColab}
                        sx={{ width: 400 }}
                        onChange={(event, value) => setJefe(value == null ? null :  value.value)}
                        renderInput={(params) => <TextField {...params} label="Jefe directo" />}/>
                </Grid>
                <Grid item md={12}>
                    <Typography sx={{fontSize:20,fontWeight:'bold',color: "#555"}} >Razones de la vacante</Typography>
                </Grid>
                <GridInputSelect label={'Tipo vacante*'} value={tipoVac} setValue={setTipoVac} options={[{'value':'Nueva Creación','label':'Nueva Creación'},{'value':'Reemplazo','label':'Reemplazo'},{'value':'Becario','label':'Becario'}]} size={6}/>
                {viewColab}
                <Grid item md={12}>
                    <Typography sx={{fontSize:20,fontWeight:'bold',color: "#555"}} >Sueldo</Typography>
                </Grid>
                <GridInputText  label={'Sueldo Mensual*'} value={sueldo} setValue={setSueldo} size={3} typeInput='number'/>
                <GridInputText  label={'Bono trimestral'} value={bono} setValue={setBono} size={3} typeInput='number'/>
                <GridInputText  label={'Garantia'} value={garantia} setValue={setGarantia} size={3} typeInput='number'/>
                <GridInputSelect label={'Duración de la garantia'} value={duracion} setValue={setDuracion} options={[{ 'value': 'N/A', 'label': 'N/A' },{ 'value': 'Indefinido', 'label': 'Indefinido' }, { 'value': '3 meses', 'label': '3 meses' }, { 'value': '6 meses', 'label': '6 meses' }]} />
                {listsCompetencias}
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={btnDisabled}>Cancelar</Button>
                <Button onClick={() => save()} disabled={btnDisabled}>Enviar a autorización</Button>
            </DialogActions>
        </Dialog>
    )

}

export default ModalNewVacante

function dataType(data,nombre,setData){
    const mapType=type => arrayType.push(type.descripcion)
    const filterType=competencia => competencia.tipo === nombre
    let arrayType = []
    data.filter(filterType).map(mapType )
    setData(arrayType)
}

function dataTypeInteraction(data,nombre,setData){
    const mapType=type => arrayType.push(type.con_quien)
    const filterType=competencia => competencia.tipo === nombre    
    let arrayType = []
    data.filter(filterType).map(mapType )
    setData(arrayType)
}

const CompetenciasType=({title,list,setList,Icon=TrackChangesIcon,md=6})=>{
    const [textRespo,setTextRespo] = useState('')

    const addItem = () =>{
        if(textRespo.trim().length !== 0){
            setList([textRespo,...list])
            setTextRespo("")
        }else{
            toast.error(`${title} no puede ser vació`)
        }
    }

    const removeItem = (indexList) =>{
        setList(list.filter((item,index) => index !== indexList))
    }

    return <Grid item container md={md} sx={{pt:'0px'}}>
                <Grid item md={11} sx={{pt:'0px'}}>
                    <Box component="b" sx={{fontSize:18,color: "#555" }}> {title} </Box>
                    <TextField
                        margin="dense"
                        onChange={(event)=> setTextRespo(event.target.value)}
                        value={textRespo}
                        fullWidth />
                </Grid>
                <Grid item md={1} > 
                    <IconButton aria-label="delete" sx={{mt:4}} 
                        onClick={addItem}
                    > 
                        <AddCircleIcon color='success' fontSize='large'/> 
                    </IconButton> 
                </Grid>
                
                <Grid item md={12} sx={{pt:'0px'}}>
                    <List dense={false} >
                        {list?.map((item,index)=>{
                            return (
                                <Box key={title+index}>
                                    <ListItem secondaryAction={ 
                                        <IconButton edge="end" 
                                            onClick={()=>removeItem(index)}
                                            > 
                                                <DeleteIcon color='error'/> 
                                        </IconButton> } >
                                        <ListItemIcon> 
                                            <Icon color='primary'/> 
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                    <Divider/>
                                </Box>)
                        })}
                    </List>
                </Grid>
        </Grid>
}
