import React from "react"
import Typography from "@mui/material/Typography"

export default function TextoTypography({ Texto }) {
  return (
    <Typography sx={{ mb: 1.5, fontWeight: "bold" }} color="text.secondary">
      {Texto}
    </Typography>
  )
}
