import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

/**
 * Input de tipo fecha
 * @type {DatePicker}
 * @returns {JSX.Element}
 * @example
 * <InputDate
 *   label="Fecha de nacimiento"
 *   onChange={(date)=>setDate({  date?.format("YYYY-MM-DD") })}
 * />
 */
export const InputDate = ({
	views = ["day", "month", "year"],
	format = "DD/MMMM/YYYY",
	...props
}) => {
	return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			adapterLocale="es-mx"
		>
			<DemoContainer components={["DatePicker"]}>
				<DatePicker
					views={views}
					format={format}
					{...props}
				/>
			</DemoContainer>
		</LocalizationProvider>
	)
}
