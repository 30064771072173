import React from "react"
import { useSelector, useDispatch } from "react-redux" //Token
import { useState, useEffect } from "react"
import { actualizarNotify } from "../../app/notificationSlice"
import { ESTADO_CURSOS } from "./constants_capacitaciones"

//Api
import { getApiCursos } from "../../Services/Api_cursos"

//Componentes
import { changeTitle } from "../../app/pageSlice"
import TarjetaCurso from "./TarjetaCurso"
import { SkeletonSquareGrid } from "../Common/Skeleton"

//Material Ui
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

//Componente --------------------------------------------->
export default function CursosPorAprobar() {
	const token = useSelector((state) => state.user.token)
	const [dbColaborador, set_DBColab] = useState([]) //Curso_colaborar
	const [skeleton, setSkeleton] = useState(false)

	const [actualizarPorAprobar, setActualizarPorAprobar] = useState([]) //permite cambiar el estado de la tarjeta

	const dispatch = useDispatch()

	useEffect(() => {
		//-------------------------------------------------------------------------------------------------------------------------------------
		dispatch(actualizarNotify(token))
		dispatch(changeTitle("Curso Por Aprobar"))
		//descarga DB----------------------------------------->
		getApiCursos(
			"colaborador",
			token,
			set_DBColab,
			{ status: ESTADO_CURSOS["REVISAR"], all: "" },
			setSkeleton,
		)
	}, [dispatch, token, actualizarPorAprobar])

	return !skeleton ? (
		<SkeletonSquareGrid
			propsG={{
				xs: 12,
				md: 6,
				xl: 4,
			}}
			quantity={12}
		/>
	) : (
		<React.Fragment>
			{}

			{dbColaborador.length ? (
				<Grid container>
					{dbColaborador.map((curso, index) => {
						return (
							<Grid
								xs={12}
								md={6}
								xl={4}
								key={curso.id}
							>
								<TarjetaCurso
									key={`${curso.id}`}
									db_user={curso}
									db_cursos={curso["curso"]}
									statusChange={actualizarPorAprobar}
									setStatusChange={setActualizarPorAprobar}
									filtro="porAprobar"
									open={false}
									reload={setActualizarPorAprobar}
								/>
							</Grid>
						)
					})}
				</Grid>
			) : (
				<Typography
					xs={12}
					sx={{
						mb: 1.5,
						fontWeight: "bold",
						textAlign: "center",
						fontSize: 40,
					}}
				>
					No Hay Curso Por Aprobar 🎉
				</Typography>
			)}
		</React.Fragment>
	)
}
