import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable } from "react-beautiful-dnd"
//Componentes
import StrictModeDroppable from "./StrictModeDroppable"
import ButtonDeleted from "./ButtonDeleted"
//Material
import Grid from "@mui/material/Unstable_Grid2"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"

export default function ListaDrag({ items, setItems, db_cursos, set_DBCurso }) {
	const [isDraggable, setIsDraggable] = useState(false)
	useEffect(() => {
		if (items.length === 12) {
			setIsDraggable(true)
		}
	}, [items])

	const handleDragEnd = (result) => {
		if (!result.destination) return

		const newItems = [...items]
		const [reorderedItem] = newItems.splice(result.source.index, 1)
		newItems.splice(result.destination.index, 0, reorderedItem)
		setItems(newItems)
	}
	return (
		<React.Fragment>
			<Grid
				container
				spacing={1}
				justifyContent="center"
				alignItems="center"
				style={
					{
						//background: "lightblue",
					}
				}
			>
				<DragDropContext onDragEnd={handleDragEnd}>
					<StrictModeDroppable droppableId="lista">
						{(provided) => (
							<div
								style={{
									width: "100%"
								}}
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{items.map((item, index) => {
									return (
										<Draggable
											key={item.id}
											draggableId={`${item.id}`}
											index={index}
											isDragDisabled={!isDraggable}
										>
											{(provided, snapshot) => (
												<Grid
													xs={12}
													sm={12}
													md={12}
												>
													<Card
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={{
															height: 32,

															...provided.draggableProps.style,
															background: snapshot.isDragging
																? "lightblue"
																: "white"
														}}
													>
														{/* Controla solo el texto interior */}
														<Grid
															container
															sx={{ paddingLeft: 2 }}
														>
															<Grid xs={11}>
																<Typography
																	variant="poster"
																	component="h4"
																	align="left"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		height: "100%",
																		width: "100%"
																	}}
																>
																	{item.curso}
																</Typography>
															</Grid>
															<ButtonDeleted
																cursoDrop={item}
																items={items}
																setItems={setItems}
																db_cursos={db_cursos}
																set_DBCurso={set_DBCurso}
															/>
														</Grid>
													</Card>
												</Grid>
											)}
										</Draggable>
									)
								})}
								{provided.placeholder}
							</div>
						)}
					</StrictModeDroppable>
				</DragDropContext>
			</Grid>
			{/*  ---------------------------------------------------------------------------------------------------------- */}
		</React.Fragment>
	)
}
