export const TIPOCONFIRMACION = {
    ACEPTAR: 'Aceptar',
    RECHAZAR: 'Rechazar'
}


/**
 * Constante que define los perfiles de usuarios en el sistema.
 * 
 * @typedef {Object} PERFILES
 * @property {string} ADMIN - Perfil de Administrador.
 * @property {string} RH - Perfil de Recursos Humanos.
 * @property {string} COLABORADOR - Perfil de Colaborador general.
 * @property {string} GUATEMALA - Perfil de Colaborador específico para Guatemala.
 * @property {string} TEST - Perfil de Usuario para pruebas.
 * @property {string} COMPRAS - Perfil de Usuario de Compras.
 * @property {string} SUPER_USER - Perfil de Super Usuario.
 * @property {string} CONTABILIDAD - Perfil de Contabilidad.
 * @property {string} JEFE_NOMINAS - Perfil de Jefe de Contabilidad.
 * @property {string} GRTE - Perfil de Gerente.
 * @property {string} GRTE_COM - Perfil de Gerente Comercial.
 * @property {string} GRTE_DS - Perfil de Gerente de Departamento de Sucursal.
 * @property {string} GRTE_RH - Perfil de Gerente de Recursos Humanos.
 * @property {string} GRTE_S - Perfil de Gerente de Sucursal.
 * @property {string} GRTE_GUAT - Perfil de Gerente de Guatemala.
 * @property {string} JEFATURA - Perfil de Jefatura general.
 * @property {string} SISTEMAS - Perfil de Sistemas.
 * 
 * @property {string} ALM - Perfil de Almacén.
 * @property {string} CONT - Perfil de Contabilidad.
 * @property {string} PAG - Perfil de Pagos.
 * @property {string} SIS - Perfil de Sistemas.
 * @property {string} VEND - Perfil de Vendedor.
 * @property {string} DIR - Perfil de Dirección.
 * @property {string} DIR_OP - Perfil de Director de Operaciones.
 * @property {string} GERENTE - Perfil de Gerente general.
 * @property {string} JEF - Perfil de Jefatura.
 * @property {string} MANTENIMIENTO - Perfil de Mantenimiento.
 * 
 * @param {PERFILES}
 */
/**@type {PERFILES} */
export const PERFILES ={
    ADMIN: "Admin",
	RH: "RH",
	COLABORADOR: "Colaborador",
	GUATEMALA: "ColaboradorGuatemala",
	TEST: "Test",
	COMPRAS: "Compras",
	SUPER_USER: "SuperUser",
	CONTABILIDAD: "Contabilidad",
	SISTEMAS: "Sistemas",
	MANTENIMIENTO: "Mantenimiento",
	
    ALM: "Almacen",
	
	COLAB: "Colaborador",
	GUAT: "ColaboradorGuatemala",
	//Administrativos
	CONT: "Contabilidad",
	PAG: "Pagos",
	PAGOS: "Pagos",
	SIS: "Sistemas",
	VEND: "Vendedor",
	//Dirección
	DIRECCION: "Direccion",
	DIR_OP: "DirectorOperaciones",
	//Gerentes
	GERENTE: "Gerente",
	GRTE: "Gerente",
    GRTE_GUAT:"GerenteGuatemala",
	GRTE_COM: "GerenteComercial",
	GRTE_DS: "GerenteDeptoSucursal",
	GRTE_RH: "GerenteRH",
	GRTE_S: "GerenteSucursal",
	JEFE_NOMINAS: "JefeNominas",
	//Jefatura
	JEFATURA: "Jefatura", 
}
export const OPTIONSJOYRIDE = { back: 'Regresar', close: 'Cerrar', last: 'Ultimo', next: 'Siguiente', open: 'Abrr dialogo', skip: 'Cerrar' }

export const OPCIONESCANDIDATO = {
    RECHAZAR: {
        tooltip: 'Rechazar Candidato',
        alerta: "El candidato se ha rechazado para la vacante",
        confirmacion: 'El candidato será rechazado para el proceso de la vacante. ¿Estás seguro?',
        siguienteStatus: 5,
        observacion: true,
    },

    PORAGENDAR: {
        tooltip: 'Pasar a entrevista',
        alerta: "El candidato está en espera de la fecha para la entrevista",
        confirmacion: 'El candidato pasará al filtro con el solicitante de la vacante. ¿Estás seguro?',
        siguienteStatus: 2,
        observacion: false,
        file:"Entrevista por competencias"
    },
    AGENDAR: {
        tooltip: 'Agendar Entrevista',
        alerta: "Se ha agendado la entrevista del candidato",
        confirmacion: 'Selecciona la hora y la fecha de la entrevista',
        siguienteStatus: 3,
        observacion: false,
        file:false
    },
    ENTREVISTADO:{
        tooltip: 'Entrevistado y Aprobado',
        alerta: "El candidato se ha marcado como apto para el puesto",
        confirmacion: 'El candidato pasará al siguiente proceso de reclutamiento, ¿Estás seguro? ',
        siguienteStatus: 4,
        observacion: true,
        file:"Entrevista por competencias"
    },
    CANDIDATOFINAL:{
        tooltip: 'Candidato Final',
        alerta: "El candidato se ha marcado como el candidato final para la vacante",
        confirmacion: 'Esta persona será el candidato final de la vacante, ¿Estás seguro? ',
        siguienteStatus: 6,
        observacion: false
    }, 
    AGENDARINGRESO: {
        tooltip: 'Enviar carta propuesta',
        alerta: "Se ha registrado la fecha de ingreso del candidato",
        confirmacion: 'Selecciona la fecha de ingreso para la vacante, ¿Estás seguro? ',
        siguienteStatus: 7,
        observacion: false
    },
    INGRESO: {
        tooltip: 'Registrar Ingreso',
        alerta: "Se ha registrado el ingreso del candidato",
        confirmacion: 'Será registrada la fecha de ingreso del candidato ¿Estás seguro? ',
        siguienteStatus: 8,
        observacion: false
    },
    PROPUESTA: {
        tooltip: 'Agendar Ingreso',
        alerta: "se ha subido la propuesta",
        confirmacion: 'Anexar propuesta económica que se enviara al candidato ',
        siguienteStatus: 9,
        observacion: false,
        file:"Propuesta"
    },
}
// Tallas uniformes
export const TALLASCAMISAS = [{ 'value': 'XS', 'label': 'Extra Chica' }, { 'value': 'S', 'label': 'Chica' }, { 'value': 'M', 'label': 'Mediana' }, { 'value': 'L', 'label': 'Grande' },
                          { 'value': 'XL', 'label': 'XL' }, { 'value': 'XXL', 'label': 'XXL' }, { 'value': 'XXXL', 'label': 'XXXL' }]
export const TALLASBOTAS = [{ 'value': '23', 'label': '23' }, { 'value': '24', 'label': '24' }, { 'value': '25', 'label': '25' }, { 'value': '26', 'label': '26' }, { 'value': '27', 'label': '27' },
                            { 'value': '28', 'label': '28' }, { 'value': '29', 'label': '29' }, { 'value': '30', 'label': '30' }, { 'value': '31', 'label': '31' }, { 'value': '32', 'label': '32' }]
export const TALLASPANTALONES = [{ 'value': '28', 'label': '28' }, { 'value': '30', 'label': '30' }, { 'value': '32', 'label': '32' }, { 'value': '34', 'label': '34' }, { 'value': '36', 'label': '36' },{'value': '38', 'label': '38'},{'value': '40', 'label': '40'},{'value': '42', 'label': '42'}]