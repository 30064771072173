import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"

/**
 * @type {Button}
 */
export default function ButtonDialog({ label, ...props }) {
	const ButtonS = styled(Button)(({ theme }) => ({
		// color: theme.palette.primary.main, // color secundario del tema

		"&:hover": {
			color: theme.palette.primary.contrastText,
			borderColor: theme.palette.primary.light, // color primario del tema
			boxShadow: `0 0 0 0.15rem ${theme.palette.primary.light}`,
			backgroundColor: theme.palette.primary.light,
		},
	}))
	return <ButtonS {...props}>{label}</ButtonS>
}
