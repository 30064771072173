import Grid from "@mui/material/Unstable_Grid2"
import Typography from "@mui/material/Typography"
/**
 *
 * @example
 * <TextGrid label="Título" xs={12} md={6} xl={4} tipo="h3" style={{ color: 'blue' }} />
 *
 * @param {object} props - Las propiedades del componente.
 * @param {string} props.label- Texto del Typography
 * @param {number} props.allSize - El ancho del botón en todos los tamaño de pantalla.
 * @param {number} props.xs - El ancho del botón en pantallas extra pequeñas (XS).
 * @param {number} props.md - El ancho del botón en pantallas medianas (MD).
 * @param {number} props.xl - El ancho del botón en pantallas extra grandes (XL).
 * @param {string} props.tipo - Tipo de encabezado de Typography, como "h4".
 * @param {string} props.style - Estilos CSS personalizados para el componente.
 * @param {string} props.align - Alineación del texto
 * @returns {JSX.Element} Componente TextGrid que muestra un encabezado de texto con formato.
 *
 */
export function TextGrid({
	label = "tu texto",
	allSize = false,
	xs = 12,
	md = 8,
	xl = 6,
	tipo = "h4",
	style = {},
	align = "center",
}) {
	if (allSize) {
		xs = allSize
		md = allSize
		xl = allSize
	}
	return (
		<Grid
			xs={xs}
			md={md}
			xl={xl}
		>
			<Typography
				style={style}
				textAlign={align}
				variant={tipo}
			>
				{label}
			</Typography>
		</Grid>
	)
}
/**
 * Componte de texto con Grid2
 * @example
 * <TextGrid2 label="hola mundo" variant="h6" propsG={{ xs: 1 }} sx={{ fontWeight: "bold" }}color="text.secondary"/>
 * @param {object} props - Las propiedades del componente.
 * @param {string} props.label- Texto del Typography
 * @param {number} props.propsG - props para el componente grid.
 * @param {string} props.variant - Tipo de encabezado de Typography, como "h4".
 * @param {string} props.textAlign - Alineación del texto
 * @returns {JSX.Element} Componente TextGrid que muestra un encabezado de texto con formato.
 *
 */
export function TextGrid2({
	label = "tu texto",
	variant = "h4",
	textAlign = "center",
	propsG,
	...props
}) {
	return (
		<Grid {...propsG}>
			<Typography
				{...props}
				textAlign={textAlign}
				variant={variant}
			>
				{label}
			</Typography>
		</Grid>
	)
}
/***
 * @type {Typography}
 */
export const TextInfoField = ({
	campo = "",
	valor,
	separador = ": ",
	noWrap = true,
	...props
}) => {
	if (valor == null) return null

	if (campo !== "") campo = `${campo}${separador}`

	return (
		<Typography
			mb={-1}
			{...props}
		>
			<strong>{campo}</strong>
			{valor}
		</Typography>
	)
}
