import React from 'react'
import infinity from '../../Images/Infinity.gif'
import {Skeleton, Stack, Box } from '@mui/material/';


export const LoadingView = () => {
  return (
        <Box
            component="img"
            sx={{ height: 500, marginTop:2 }}
            alt="The house from the offer."
            src={infinity} />
  )
}

export const LoadViewTable  = () => {
    return (<>
                 <Stack  direction="row" spacing={2} sx={{mb:1,mt:3}} width="80%" >
                    <Skeleton variant="rectangular" width="33%" height={60} />
                    <Skeleton variant="rectangular" width="33%" height={60} />
                    <Skeleton variant="rectangular" width="33%" height={60} />
                </Stack>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
                <Skeleton variant="rectangular" width="80%" height={60} sx={{mb:2}}/>
            </>
    )
}

export const LoadSimpleTable  = ({numRows = 6}) => {
    return (<>
                {Array.from(new Array(numRows)).map((item, index) => (
                    <Stack key={index}  direction="row" spacing={2} sx={{mb:1,mt:3}} width="100%" >
                        <Skeleton variant="rectangular" width="20%" height={60} />
                        <Skeleton variant="rectangular" width="20%" height={60} />
                        <Skeleton variant="rectangular" width="20%" height={60} />
                        <Skeleton variant="rectangular" width="20%" height={60} />
                        <Skeleton variant="rectangular" width="20%" height={60} />
                    </Stack>
                    ))}    
            </>
    )
}

export const LoadViewDetail  = () => {
    return (<>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Stack  direction="row" spacing={2} sx={{mb:1}} >
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                </Stack>
                <Stack  direction="row" spacing={2} sx={{mb:1}}>
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                </Stack>
                <Stack  direction="row" spacing={2} sx={{mb:1}}>
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                </Stack>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            </>
    )
}
export const LoadViewUniformes = () => {
    return (<>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Stack direction="row" spacing={2} sx={{ mb: 1 }} >
            <Skeleton variant="rectangular" width={410} height={60} />
            <Skeleton variant="rectangular" width={410} height={60} />
            <Skeleton variant="rectangular" width={410} height={60} />
            <Skeleton variant="rectangular" width={410} height={60} />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
            <Skeleton variant="rectangular" width={410} height={60} />
            <Skeleton variant="rectangular" width={410} height={60} />
            <Skeleton variant="rectangular" width={410} height={60} />
            <Skeleton variant="rectangular" width={410} height={60} />
        </Stack>
    </>
    )
}


