// import { post_rh_api } from "#api/Api_Call"
import { api_rh_post, apiGetRH } from "#api/API_RH"
import Button from "@mui/material/Button"

/**
 *
 * @param {object} props
 * @param {string} props.label - Texto del botón
 * @param {ReactNode} props.startIcon - Icono del botón
 * @param {function} props.onChange - Función para el evento de cambio de archivo
 * @param {function} props.getFiles - Función para obtener los archivos
 * @param {string} props.accept - Tipo de archivo aceptado
 * @param {boolean} props.multiple - Indica si se debe permitir múltiples archivos
 * @param {boolean} props.fullWidth - Indica si el botón debe tener ancho completo
 * @param {boolean} props.hidden - Indica si el botón debe estar oculto
 * @returns
 */
export function ButtonDownloadUrl({
	label,
	startIcon,
	ruta,
	fileName,
	fullWidth = false,
	hidden = false,
	message = "Descargar Exitosa",
}) {
	const success = (singed) => {
		// window.open(singed, "_blank")
		const link = document.createElement("a")
		link.href = singed
		link.setAttribute("download", "") // Esto fuerza la descarga
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}
	const handleDownload = () => {
		apiGetRH(ruta, {
			success,
			params: {
				file: fileName,
			},
			message: { success: message },
		})
	}
	if (hidden) return null
	return (
		<Button
			fullWidth={fullWidth}
			component="label"
			variant="contained"
			startIcon={startIcon}
			onClick={handleDownload}
		>
			{label}
		</Button>
	)
}
/**
 * @deprecated
 * @param {object} props
 * @param {string} props.label - Texto del botón
 * @param {ReactNode} props.startIcon - Icono del botón
 * @param {function} props.onChange - Función para el evento de cambio de archivo
 * @param {function} props.getFiles - Función para obtener los archivos
 * @param {string} props.accept - Tipo de archivo aceptado
 * @param {boolean} props.multiple - Indica si se debe permitir múltiples archivos
 * @param {boolean} props.fullWidth - Indica si el botón debe tener ancho completo
 * @returns
 */
export default function ButtonDownload({
	label,
	startIcon,
	url,
	fullWidth = false,
	hidden = false,
	message = "Descargar Exitosa",
}) {
	const handleDownload = () => {
		api_rh_post(
			"s3",
			{ url },
			{
				success: (singed) => {
					// window.open(singed, "_blank")
					const link = document.createElement("a")
					link.href = singed
					link.setAttribute("download", "") // Esto fuerza la descarga
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				},
				message: { success: message },
			},
		)
	}
	if (hidden) return null
	return (
		<Button
			fullWidth={fullWidth}
			component="label"
			variant="contained"
			startIcon={startIcon}
			onClick={handleDownload}
		>
			{label}
		</Button>
	)
}
