import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux" //Token

//API
import { postApiCursos } from "../../../Services/Api_cursos"

//componentes
import ListaDrag from "../componentes/ListaDrop/ListaDrag"
import TarjetasMesesDrop from "./TarjetasMesesDrop"
import { usePlanAnualContext } from "./PlanAnualContext"
import { ButtonGrid2 } from "../../Common/Buttons"

//material
import Grid from "@mui/material/Unstable_Grid2"
import Typography from "@mui/material/Typography"

/**
 * Componente ListaDrop - Crea una lista de elementos que se pueden arrastrar y soltar.
 * Permite agregar elementos a la lista, editar la lista y devolver elementos eliminados al lugar original.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.items - Datos que se añadirán a la lista arrastrable.
 * @param {function} props.setItems - Función para editar los datos de la lista arrastrable.
 * @param {function} props.set_DBCurso - Función para devolver el elemento eliminado de la lista a su ubicación original.
 * @param {Array} props.db_cursos - Elementos de donde se pueden añadir a la lista arrastrable.
 * @param {Array} [props.areas=[]] - Áreas donde se añadirán los cursos del plan anual.
 *
 * @returns {JSX.Element} - El componente ListaDrop que permite arrastrar y soltar elementos.
 */
export default function ListaDrop({
	items,
	setItems,
	set_DBCurso,
	db_cursos,
	areas = [],
}) {
	const { actualizar, setActualizar } = usePlanAnualContext()
	const token = useSelector((state) => state.user.token)
	const [vistaMeses, setVistaMeses] = useState(false)
	const [buttonClick, setButtonClick] = useState(false)

	useEffect(() => {
		if (items.length === 12) {
			setVistaMeses(true)
		} else setVistaMeses(false)
	}, [items])

	return (
		<React.Fragment>
			<Grid
				container
				spacing={1}
				rowSpacing={4}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<Grid xs={12}>
					<div>
						<Typography
							variant="poster"
							component="h2"
							align="center"
						>
							{items.length > 0 && !vistaMeses
								? `Cursos Seleccionados: ${items.length}`
								: "Asignación de Mes"}
						</Typography>
					</div>
				</Grid>
				{vistaMeses && (
					<Grid
						xs={2}
						md={2}
						xl={1.4}
					>
						<TarjetasMesesDrop />
					</Grid>
				)}

				<Grid xs={10}>
					<ListaDrag
						items={items}
						setItems={setItems}
						db_cursos={db_cursos}
						set_DBCurso={set_DBCurso}
					/>
				</Grid>
				{vistaMeses && (
					<ButtonGrid2
						label="Crear Plan Anual"
						disabled={buttonClick}
						propsG={{ md: 4 }}
						onClick={() => {
							setButtonClick(true)
							let cursos = []
							items.forEach((element, index) => {
								cursos.push({ curso: element.id, mes: index + 1 })
							})
							postApiCursos(
								"planAnual",
								token,
								{ areas, cursos },
								{},
								actualizar,
								setActualizar
							).finally(function (value) {
								setButtonClick(false)
							})
						}}
					/>
				)}
			</Grid>
		</React.Fragment>
	)
}
