import { createContext, useContext, useMemo } from "react"

const DialogBajaContext = createContext()

export function DialogBajaProvider({
	children,
	reload,
	baja,
	isRH,
	setButtonsDialog,
	closeDialog,
	disabled,
}) {
	const value = useMemo(
		() => ({ reload, baja, isRH, setButtonsDialog, closeDialog, disabled }),
		[reload, baja, isRH, setButtonsDialog, closeDialog, disabled],
	)

	return (
		<DialogBajaContext.Provider value={value}>
			{children}
		</DialogBajaContext.Provider>
	)
}

// Hook personalizado para usar el contexto
/**
 *
 * @returns {{ baja: Object, isRH: boolean, setButtonsDialog: function, closeDialog: function ,reload:function,disabled:boolean }}
 */
export function UseDialogBajaContext() {
	return useContext(DialogBajaContext)
}
