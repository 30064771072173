export const NAME_COLLABORATOR = "Colaborador"
export const NAME_REQUEST_COLLABORATOR = "Solicitante"
export const TYPE_BAJA = "Tipo de Baja"
export const TERMINATION_STATUS = "Estatus Solicitud"
export const TERMINATION_DATE = "Fecha de Baja"
export const REQUEST_DATE = "Fecha de Solicitud"
export const APPROVAL_DATE = "Fecha VoBo RH"
export const NO_EMPLEADO = "No. Colaborador"
export const PERFIL_PUESTO = "Perfil de Puesto"
export const REASON = "Razón"
export const BAJA_MOTIVO = "Motivo de la Baja"
export const TYPE_1 = "Tipo de Baja"
export const TYPE_2 = "Causa de Baja"
export const TYPE_3 = "Descripción de Baja"
export const TITLE_DIALOG = "Baja de colaborador"
export const OPTION_REJECT = "Motivo del rechazo"
export const SEND_BUTTON = "Enviar Solicitud"
export const DIVIDER = "Categorías TRACSA"

//Errores
export const ERROR_TYPE_1 = "Se requiere un tipo de baja"
export const ERROR_TYPE_2 = "Se requiere una causa de baja"
export const ERROR_TYPE_3 = "Se requiere una descripción de baja"
export const ERROR_REASON_REJECTION = "Se requiere una descripción de baja"
