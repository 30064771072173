import { Button } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { changeTitle } from "../../app/pageSlice"
import FondoDialog from "./FondoDialog"
import { TablaFondoFijo } from "./TablaFondoFijo"
import FondoFijoFormulario from "./formulario/FondoFijoFormulario"
import { FondoProvider } from "./useContextFondo"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { FONDO_STATUS } from "./ButtonsFondo"
import { TablaFondoFijoActivos } from "./TablaFondoFijoActivos"
import { HasPermission } from "../Common/Funciones"
import { PERFILES } from "../../Constants/Strings"

export const STATUS = {
	GENERADA: 1,
	VOBO_RH: 2,
	VOBO_PAGOS: 3,
	PAGADO: 4,
	// CONTABILIDAD: 5,
	FINALIZADO: 8,
	RECHAZADO: 9,
	PENDIENTE_PAGO_COLAB: 10,
	PAGADO_COLAB: 11,
	DOC_COLAB: 12,
}
const { RH, CONT, PAG } = PERFILES
export default function FondoFijo(edit) {
	const dispatch = useDispatch()

	dispatch(changeTitle("Fondo Fijo"))
	const [openForm, setOpenForm] = useState(false)
	const [value, setValue] = useState(0)
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const taps = [
		{ label: "Solicitudes", contenido: <TablaFondoFijo /> },
		{
			label: "Fondo Fijos Activos",
			contenido: <TablaFondoFijoActivos />,
			permission: [RH, CONT, PAG],
		},
		{
			label: "Historial de solicitudes",
			contenido: <TablaFondoFijo status={5}/>,
			permission: [RH, CONT, PAG],
		},
	]
	return (
		<FondoProvider>
			<Box sx={{ width: "100%" }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						{taps.map((tap, index) => {
							if (tap.permission && !HasPermission(tap.permission)) return null

							return (
								<Tab
									key={tap?.label}
									label={tap?.label}
									{...a11yProps(index)}
								/>
							)
						})}
					</Tabs>
				</Box>
				{taps.map((tap, index) => (
					<CustomTabPanel
						key={tap?.label + index}
						value={value}
						index={index}
					>
						{tap?.contenido}
					</CustomTabPanel>
				))}

				{/* <TablaFondoFijo /> */}
			</Box>
			<FondoFijoFormulario
				open={openForm}
				setOpen={setOpenForm}
			/>
			<FondoDialog />
		</FondoProvider>
	)
}

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}
