import { useEffect, useState } from "react"
// material
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
//icons
import CachedIcon from "@mui/icons-material/Cached"
import PeopleIcon from "@mui/icons-material/People"
import PersonIcon from "@mui/icons-material/Person"
// componentes
import ModalMovimientos from "./ModalMovimientos/ModalMovimientos"
import GroupsIcon from "@mui/icons-material/Groups"

export const ColaboradorTools = ({
	colaborador,
	colaboradores,
	setColaboradoresFilter,
	setColaboradoresCargo,
	colaboradoresCargo,
}) => {
	const [search, setSearch] = useState("")
	const [openPromotion, setOpenPromotion] = useState(false)

	useEffect(() => {
		const colaboradoresFilter = colaboradores?.filter((colaborador) => {
			if (search.length === 0) return colaborador
			if (
				colaborador.nombre_completo
					?.toLowerCase()
					?.includes(search?.toLowerCase())
			)
				return colaborador
			return false
		})
		setColaboradoresFilter(colaboradoresFilter)
	}, [colaboradores, search, setColaboradoresFilter])

	return (
		<Box>
			<Stack
				width={"100%"}
				height={"40px"}
				direction={"row"}
				spacing={1}
				justifyContent={"right"}
			>
				<TextField
					sx={{ width: `350px`, height: "40px" }}
					label="Buscar por nombre"
					variant="outlined"
					size="small"
					autoComplete="off"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Button
					variant="contained"
					disabled={colaboradoresCargo}
					onClick={() => setColaboradoresCargo(!colaboradoresCargo)}
					// startIcon={}
				>
					<GroupsIcon />
					{/* Mostrar todos los colaboradores */}
				</Button>
				<Button
					variant="contained"
					size="large"
					onClick={() => setOpenPromotion(!openPromotion)}
				>
					<PersonIcon />
					<CachedIcon />
					{/* Movimientos de personal */}
				</Button>
			</Stack>
			<ModalMovimientos
				colaboradorId={colaborador?.id}
				reporta_a={colaborador?.perfil_puesto_id}
				open={openPromotion}
				setOpen={setOpenPromotion}
			/>
		</Box>
	)
}
