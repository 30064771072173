import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux" //Token
import "@react-pdf-viewer/core/lib/styles/index.css"
import { DialogConfirm, ViewPDF } from "../../../Common/Dialog"

import {
	apiDeleteCursosExternos,
	apiGetCursosExternos,
} from "../../../../Services/ApiColaborador"
import FormDataCursosExternos from "../../forms/FormCursosExternos"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { CardBar, CardComponen } from "./CardComponent"
import Box from "@mui/material/Box"

export default function ExternalCoursesCard({ colaborador, edit = false }) {
	const token = useSelector((state) => state.user.token)
	const TEXT_COLOR_TABLET = "#eeeeee"
	const [cursos, setCursos] = useState([])
	const [open, setOpen] = useState(false)
	const [openPDF, setOpenPDF] = useState({ open: false, url: null })
	const [openConfirm, setOpenConfirm] = useState({ open: false, id: null })
	const [actualizar, setActualizar] = useState(true)
	const actualizarNow = () => setActualizar(!actualizar)

	useEffect(() => {
		apiGetCursosExternos(token, setCursos, { colaborador: colaborador.id })
	}, [actualizar, colaborador.id, token])

	const handleNew = () => {
		setOpen({ open: true })
	}
	function handleEdit(curso) {
		setOpen({ open: true, edit: curso })
	}
	function handleDelete(id) {
		apiDeleteCursosExternos(token, {
			id: id,
		}).then((response) => {
			actualizarNow()
			setOpenConfirm(false)
		})
	}
	function handlePDF(url) {
		setOpenPDF({
			open: true,
			url: url,
		})
	}
	return (
		<React.Fragment>
			<Box sx={{ flexGrow: 1 }}>
				<Grid2
					container
					spacing={1}
				>
					<Grid2 xs={12}>
						<CardBar
							title="Cursos Externos"
							edit={edit}
							buttonAdd={handleNew}
							color={TEXT_COLOR_TABLET}
						/>
					</Grid2>
					{cursos.map((curso) => {
						return (
							<Grid2
								key={curso.id}
								xs={12}
								md={6}
							>
								<CardComponen
									key={"card" + curso.id}
									edit={edit}
									external
									estudios={curso}
									buttonEdit={() => handleEdit(curso)}
									buttonDeleted={() =>
										setOpenConfirm({ open: true, id: curso.id })
									}
									buttonCertificado={() => handlePDF(curso.certificado)}
									buttonCertificadoEnable={!curso.certificado}
								/>
							</Grid2>
						)
					})}
				</Grid2>
			</Box>

			<FormDataCursosExternos
				idColaborador={colaborador.id}
				open={open.open}
				edit={open.edit}
				setOpen={setOpen}
				actualizar={actualizarNow}
			/>

			<ViewPDF
				open={openPDF.open}
				pdfUrl={openPDF.url}
				setOpen={setOpenPDF}
			/>

			<DialogConfirm
				open={openConfirm.open}
				setOpen={setOpenConfirm}
				message={"Desea Borrar el curso"}
				accept={() => {
					handleDelete(openConfirm.id)
				}}
			/>
		</React.Fragment>
	)
}
