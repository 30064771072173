import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

//Funciones
import { putPrestamos } from "../../../../../Services/Api_prestamos"

//Componentes
import {} from "../../../../Common/Inputs"

//Material UI
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Checkbox from "@mui/material/Checkbox"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { getPrestamosMethodPay } from "../../../../../Services/Api_prestamos"
import { InputGrid2, InputSelectGrid2 } from "../../../../Common/Inputs2"
import { SkeletonSquareGrid } from "../../../../Common/Skeleton"

export function DialogEditMethod(props) {
	const {
		ID,
		open,
		setOpen,
		actualizar,
		setActualizar,
		method_pay_id = 1,
		cantidad = 0,
	} = props
	const TOKEN = useSelector((state) => state.user.token)
	const [dbMethodPay, setDbMethodPay] = useState([])
	const [methodPay, setMethodPay] = useState(method_pay_id)
	const [cantidadNow, setCantidadNow] = useState(cantidad)
	const [skeleton, setSkeleton] = useState(false)
	const [state, setState] = React.useState({
		method: true,
		cantidad: true,
	})
	const handleClose = () => {
		setOpen(false)
	}
	const handleChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		})
	}
	useEffect(() => {
		getPrestamosMethodPay(TOKEN, setDbMethodPay, {}, setSkeleton)
	}, [TOKEN])
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Editar Prestamo</DialogTitle>
			<DialogContent>
				{!skeleton ? (
					<SkeletonSquareGrid />
				) : (
					<Grid
						container
						paddingTop={1}
						spacing={3}
					>
						<InputSelectGrid2
							label="Método de pago"
							data={dbMethodPay}
							dataLabel="descripcion"
							value={methodPay}
							setValue={setMethodPay}
							disabled={state["method"]}
							GridProps={{ xs: 10 }}
						/>
						<Grid xs={2}>
							<Checkbox
								onChange={handleChange}
								name="method"
								checked={state["method"]}
							/>
						</Grid>
						<InputGrid2
							disabled={state["cantidad"]}
							label={"Cantidad"}
							setOption={setCantidadNow}
							defaultValue={cantidad}
							propsGrid={{ xs: 10 }}
						/>
						<Grid xs={2}>
							<Checkbox
								onChange={handleChange}
								name="cantidad"
								checked={state["cantidad"]}
							/>
						</Grid>
					</Grid>
				)}
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>Cancelar</Button>
				<Button
					onClick={() => {
						setOpen(false)
						putPrestamos(
							TOKEN,
							{},
							{
								id: ID,
								method_pay: methodPay,
								cantidad: cantidadNow,
							},
							actualizar,
							setActualizar
						)
					}}
					autoFocus
				>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
