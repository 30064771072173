import React from 'react'
import {Grid} from '@mui/material'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

const ListCompetencia = ({titulo, list, nameList, addItem, removeItem, textValue, setTextValue, Iconlist}) => {
  return (
        <Grid item xs={6} sx={{paddingX:3}}>
            <Grid container spacing={2}>
                <Grid item md={10}>
                    <Box component="b" sx={{fontSize:16, }}> {titulo} </Box>
                    <TextField
                        margin="dense"
                        onChange={(event)=> setTextValue(event.target.value)}
                        value={textValue}
                        fullWidth />
                </Grid>
                <Grid item md={2} > <IconButton aria-label="delete" sx={{mt:4}} onClick={()=>addItem(nameList, textValue)}> <AddCircleIcon color='success' fontSize='large'/> </IconButton> </Grid>
                <Grid item md={12} >
                    <List dense={false}>
                        {list.map((item,index)=>{
                            return <Box key={nameList+index}>
                                        <ListItem
                                            secondaryAction={ <IconButton edge="end" onClick={()=>removeItem(nameList,index)}> <DeleteIcon color='error'/> </IconButton> } >
                                            <ListItemIcon> <Iconlist color='primary'/> </ListItemIcon>
                                            <ListItemText primary={item.descripcion} />
                                        </ListItem>
                                        <Divider />
                                    </Box>
                        })}
                    </List>
                </Grid>
            </Grid>
        </Grid>
  )
}

export default ListCompetencia
