import { TextField } from "@mui/material"
import { useEffect, useMemo, useState } from "react"

/**
 * @typedef {string }inputType
 * @property {"int"} int - Números enteros
 * @property {"float"} float - Números flotantes
 */

/**
 * Componente `InputNumber`
 *
 * Este componente es un campo de texto personalizado que solo permite la entrada de números, ya sean enteros o flotantes,
 * según la configuración. Utiliza Material-UI's `TextField` y ofrece opciones para adornos, validación de entrada y
 * manejo de cambios.
 *
 * @component
 * @param {Object} props - Las propiedades del componente.
 * @param {string} props.label - El texto de la etiqueta que se muestra dentro del campo de texto.
 * @param {string|number} props.value - El valor actual del campo de texto.
 * @param {function} props.setValue - Función que da el valor del compo de texto validado.
 * @param {React.ReactNode} [props.startAdornment] - Un adorno que se coloca al inicio del campo de texto
 * @param {boolean} props.disabled - Indica si el campo de texto está deshabilitado.
 * @param {inputType} props.isInt - Indica si el numero de retorno es un número entero o no.
 * @param {boolean} props.fullWidth - Indica si el campo de texto se debe expandir a toda la anchura del componente.
 * @param {function} [props.onChange=()=>{}] - Función opcional que se ejecuta cuando cambia el valor del campo.
 * @returns {React.ReactElement} Un campo de texto personalizado que solo permite la entrada de números.
 */
export default function InputNumber({
	label,
	value,
	setValue,
	startAdornment,
	disabled,
	fullWidth,
	isInt,
	size = "small",
	onChange = () => {},
}) {
	const props = { label, disabled, fullWidth }
	const [text, setText] = useState(validateNumber(value, isInt))

	useEffect(() => {
		let parseValue = parseNumber(value, isInt)
		if (!parseValue && parseValue !== 0) {
			setText((text) => {
				if (text.length === 1) return `${text}`
				return ""
			})
		} else {
			setText(parseValue)
		}
	}, [isInt, value])

	const useRegex = useMemo(() => {
		const float = /^-?\d*\.?\d*$/
		const int = /^\d*$/
		return isInt ? int : float
	}, [isInt])

	const handleChange = useMemo(
		() => (event) => {
			let newValue = event.target.value
			if (useRegex.test(newValue)) {
				setText(newValue)
				newValue = parseNumber(newValue, isInt)
				setValue ? setValue(newValue) : onChange(event)
			}
		},
		[onChange, setValue, isInt, useRegex],
	)

	return (
		<TextField
			{...props}
			value={text}
			size={size}
			autoComplete="off"
			onChange={handleChange}
			InputProps={{
				startAdornment,
				inputMode: "numeric",
				pattern: useRegex,
			}}
		/>
	)
}

function parseNumber(value, isInt) {
	let parseValue
	if (typeof value === "number") {
		parseValue = value
	} else parseValue = isInt ? parseInt(value) : parseFloat(value)
	return parseValue
}

function validateNumber(value, isInt) {
	value = parseNumber(value, isInt)
	if (isNaN(value)) return ""
	return value
}
