import React from "react"
import { STATUS_LOAN } from "../../constants"
//Funciones
import { useSelector } from "react-redux" //Token
import { putPrestamos } from "../../../../../Services/Api_prestamos"

//Componentes
import { InputGrid } from "../../../../Common/Inputs"
import { TextGrid } from "../../../../Common/Texto"

//Material UI
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

function DialogMoney2({ selected, setSelected, cantidad, show = true }) {
	return show ? (
		<DialogContent>
			<Grid
				container
				paddingTop={0}
			>
				<Grid xs={6}>
					<TextGrid
						tipo="h6"
						xs={12}
						md={12}
						xl={12}
						label={`Cantidad solicitada `}
					/>
					<TextGrid
						tipo="h6"
						style={{ fontWeight: "bold" }}
						xs={12}
						md={12}
						xl={12}
						label={` ${cantidad.toLocaleString("en-US")}`}
					/>
				</Grid>
				<Grid xs={6}>
					<TextGrid
						tipo="h6"
						//style={{ fontWeight: "bold" }}
						xs={12}
						md={12}
						xl={12}
						label={`Nueva Cantidad`}
					/>
					<TextGrid
						tipo="h6"
						style={{ fontWeight: "bold" }}
						xs={12}
						md={12}
						xl={12}
						label={` ${
							selected ? parseInt(selected).toLocaleString("en-US") : ""
						}`}
					/>
				</Grid>
				<InputGrid
					label="Editar cantidad de préstamo"
					type="number"
					defaultValue={selected}
					xs={12}
					md={12}
					xl={12}
					setOption={setSelected}
				/>
			</Grid>
		</DialogContent>
	) : (
		<React.Fragment></React.Fragment>
	)
}

export default DialogMoney2
