import * as React from "react"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Typography from "@mui/material/Typography"
import { InputSelect } from "../../Common/Inputs"
import { MESES } from "../constants_capacitaciones"
import { ButtonGrid } from "../../Common/Buttons"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { getDocs } from "../../../Services/Api_cursos"
import { useSelector } from "react-redux" //TOKEN
import { toast } from "react-toastify"

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	// border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}

export default function CursosZip({ open = false, setOpen = () => {} }) {
	const token = useSelector((state) => state.user.token) //Token
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)
	const [mesSelect, setMesSelect] = React.useState(null)
	let mes = [...MESES]
	// if (mes.length > 12) mes.shift()

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
						>
							Descargar PDF cursos
						</Typography>
						<Grid
							container
							spacing={2}
							alignItems={"center"}
							justifyContent={"center"}
							alignContent={"center"}
						>
							<InputSelect
								md={10}
								xl={10}
								label="Mes"
								data={mes}
								option={mesSelect}
								setOption={setMesSelect}
							/>
							<ButtonGrid
								label="Descargar"
								disabled={!(mesSelect !== null)}
								onClick={() => {
									toast.promise(
										getDocs(
											token,
											{ mes: mesSelect },
											MESES[mesSelect]["label"]
										),
										{
											pending: {
												render() {
													return "Descargando cursos.zip"
												},
											},
											success: {
												render({ data }) {
													return `Zip pdf descargado`
												},
											},
											error: "Promise rejected 🤯",
										}
									)
								}}
							/>
						</Grid>
					</Box>
				</Fade>
			</Modal>
		</div>
	)
}
