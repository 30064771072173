import React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import IconButton from "@mui/material/IconButton"

function ButtonDeleted({ cursoDrop, items, setItems, db_cursos, set_DBCurso }) {
	return (
		<Grid
			xs={1}
			container
			justify="flex-end"
		>
			<Grid
				xs={12}
				style={{ margin: 0, padding: 0 }}
			>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<IconButton
						aria-label="delete"
						size="small"
						style={{ color: "red" }}
						onClick={() => {
							set_DBCurso([
								...db_cursos,
								cursoDrop // { id: cursoDrop.id, label: cursoDrop.curso }
							])
							let filter = items.filter((curso) => {
								if (curso.id !== cursoDrop.id) return curso
								return false
							})
							setItems(filter)
						}}
					>
						<DeleteForeverIcon />
					</IconButton>
				</div>
			</Grid>
		</Grid>
	)
}

export default ButtonDeleted
