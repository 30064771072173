import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import CardActions from "@mui/material/CardActions"
import { usePromotionsContext } from "../../PromotionContext"
import { useSelector } from "react-redux"
import { HasPermission } from "#common/Funciones"

export const CardButtons = ({ disable, detail }) => {
	const { setOpenConfirm, setResponse, setPromotionDetail } =
		usePromotionsContext()
	const authorization = useSelector((state) => state.user.colaborador.id)
	const isRH = HasPermission("RH")
	let openResponse = (response) => {
		setOpenConfirm(true)
		setResponse(response)
		setPromotionDetail(detail)
	}
	if (disable || (detail.authorization !== authorization && !isRH)) return null
	return (
		<CardActions>
			<Stack
				direction={{ xs: "column", md: "row" }}
				spacing={3}
			>
				<Button
					size="small"
					onClick={() => {
						openResponse(true)
					}}
				>
					Aprobar
				</Button>
				<Button
					onClick={() => {
						openResponse(false)
					}}
					size="small"
				>
					Rechazar
				</Button>
			</Stack>
		</CardActions>
	)
}
