import { COLOR_PRIMARY_DARK } from "#constants/Styles"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import {
	Box,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material"
import { useEffect, useState } from "react"

const Interacciones = ({ dataPerfil, id, name, title, setDataSend }) => {
	const [quien, setQuien] = useState("")
	const [paraQue, setParaQue] = useState("")

	const [interacciones, setInteracciones] = useState(
		dataPerfil == null
			? []
			: dataPerfil.perfil_interaccion.filter((item) => item.tipo === id),
	)

	const addInteracción = (quien, para) => {
		setInteracciones([...interacciones, { con_quien: quien, para_que: para }])
		setQuien("")
		setParaQue("")
	}

	const removeInteraction = (indexList) =>
		setInteracciones(interacciones.filter((item, index) => index !== indexList))

	useEffect(() => {
		setDataSend((oldData) => ({
			...oldData,
			[`interacciones_${name}`]: interacciones,
		}))
	}, [interacciones, setDataSend, name])
	return (
		<Grid
			container
			spacing={2}
			sx={{ mb: 3 }}
		>
			<Grid
				item
				xs={12}
				sx={{ mt: 0, mb: 1 }}
			>
				<Box
					component="b"
					sx={{ fontSize: 20, color: COLOR_PRIMARY_DARK }}
				>
					{title}
				</Box>
			</Grid>
			<Grid
				item
				xs={4}
			>
				<TextField
					id="nombre"
					label="¿Con quien?"
					fullWidth
					onChange={(event) => setQuien(event.target.value)}
					value={quien}
					variant="standard"
				/>
			</Grid>
			<Grid
				item
				xs={6}
			>
				<TextField
					id="nombre"
					label="¿Para que?"
					fullWidth
					onChange={(event) => setParaQue(event.target.value)}
					value={paraQue}
					variant="standard"
				/>
			</Grid>
			<Grid
				item
				md={2}
			>
				<IconButton
					aria-label="delete"
					sx={{ mt: 1 }}
					onClick={() => addInteracción(quien, paraQue)}
				>
					<AddCircleIcon
						color="success"
						fontSize="large"
					/>
				</IconButton>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							{interacciones.length > 0 ? (
								<TableRow>
									<TableCell
										sx={{
											backgroundColor: "#eee",
											color: "#1d3454",
											fontWeight: "bold",
										}}
									>
										¿Con quien?
									</TableCell>
									<TableCell
										sx={{
											backgroundColor: "#eee",
											color: "#1d3454",
											fontWeight: "bold",
										}}
									>
										¿Para que?
									</TableCell>
									<TableCell
										sx={{
											backgroundColor: "#eee",
											color: "#1d3454",
											fontWeight: "bold",
										}}
									></TableCell>
								</TableRow>
							) : (
								<></>
							)}
						</TableHead>
						<TableBody>
							{interacciones.map((inter, index) => {
								return (
									<TableRow
										key={index}
										index={title + index}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell
											component="th"
											scope="row"
										>
											{" "}
											{inter.con_quien}{" "}
										</TableCell>
										<TableCell>{inter.para_que}</TableCell>
										<TableCell>
											<DeleteIcon
												color="error"
												onClick={() => removeInteraction(index)}
											/>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	)
}

export default Interacciones
