import { useMemo, useState } from "react"
import { DialogGeneral, ViewFile, ViewPDF } from "../../Common/Dialog"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useFondoFijo } from "../useFondoFijo"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../../Constants/Styles"

function DialogInfoFondo({ open = true, setOpen, fondo = null, id = null }) {
	const [openPDF, setOpenPDF] = useState(false)
	const [openImg, setOpenImg] = useState(false)
	const params = useMemo(() => ({ id }), [id])
	const { fondoFijo } = useFondoFijo({ params }, open && id)

	if (fondo == null) fondo = fondoFijo
	const { quantity, quantity_previous } = fondo
	const campos_name = {
		colaborador_name: "Colaborador",
		requester_name: "Solicitante",
		status_description: "Status",
		date_request: "Fecha de solicitud",
		type: "Tipo de movimiento",
		date_authorized_rh: "Fecha Autorización RH",
		date_authorized_contabilidad: "Fecha Autorización Contabilidad",
		sap_policy: "Numero de póliza SAP",
		quantity_previous: "Cantidad Anterior",
		quantity: "Cantidad solicitada",
		pay: "Monto",
	}

	const campos = Object.keys(campos_name)
	let pago = null
	let texto = "Diferencia a pagar"
	let tipo = "Solicitud"

	if (quantity_previous != null)
		if (quantity > quantity_previous) {
			tipo = "Incremento"
			pago = quantity - quantity_previous
		} else if (quantity < quantity_previous) {
			tipo = "Decremento"
			pago = -quantity + quantity_previous
		}

	fondo["type"] = tipo
	fondo["pay"] = pago

	const buttons_files = []
	function FOpenPDF(url, variant = "pdf") {
		setOpenPDF({
			open: true,
			setOpen: setOpenPDF,
			baseUrl: url,
			variant,
		})
	}

	if (fondo["file"])
		buttons_files.push(
			<Button
				variant="contained"
				onClick={() => FOpenPDF(fondo["file"])}
			>
				Solicitud
			</Button>
		)
	if (fondo["file_signed"])
		buttons_files.push(
			<Button
				variant="contained"
				onClick={() => FOpenPDF(fondo["file_signed"])}
			>
				Documento Firmado
			</Button>
		)

	if (fondo["img"])
		buttons_files.push(
			<Button
				variant="contained"
				onClick={() => FOpenPDF(fondo["img"], "img")}
				// onClick={() => setOpenImg(true)}
			>
				Pago
			</Button>
		)

	return (
		<DialogGeneral {...{ open, setOpen }}>
			<Box>
				<Grid2
					container
					justifyContent={"space-between"}
					spacing={3}
				>
					{campos.map((item, index) => {
						if (!(campos_name[item] && fondo[item])) return null
						let contenido = fondo[item]
						let ancho = 6

						if (["quantity_previous", "quantity", "pay"].includes(item)) {
							ancho = 4
							let pago = parseFloat(contenido)
							contenido = `$${pago.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}`
						}
						if (item === "type") ancho = 12
						if (item === "file_signed" || item === "img") {
							contenido = (
								<Button
									variant="outlined"
									sx={{ backgroundColor: PRIMARY_COLOR }}
								>
									hola
								</Button>
							)
						}

						return (
							<Grid2
								xs={ancho}
								key={item + index}
							>
								<Typography>
									<strong>{item !== "pay" ? campos_name[item] : texto}</strong>
									<br></br>
									{contenido}
								</Typography>
							</Grid2>
						)
					})}
				</Grid2>
			</Box>
			<Box marginTop={3}>
				<Stack
					justifyContent={"center"}
					spacing={2}
					direction={"row"}
				>
					{buttons_files}
				</Stack>
			</Box>
			<ViewFile {...openPDF} />
		</DialogGeneral>
	)
}

export default DialogInfoFondo
