import { useEffect, useState } from "react"
// import React from "react"
//compontes
import { useSelector } from "react-redux"
import { apiPutFondoFijo } from "../../Services/Api_Fondo_Fijo"
import { ButtonDialog } from "../Common/Dialog"
import { ContentNF8, ContentNF9 } from "./dialogsFondo/ButtonsContent"
import DialogVoBoRH from "./dialogsFondo/DialogVoBoRH"
import { useFondoContext } from "./useContextFondo"
//material
import { Box, Button, Stack, TextField, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
//Icons
import AppRegistrationIcon from "@mui/icons-material/AppRegistration"
import BeenhereIcon from "@mui/icons-material/Beenhere"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import LocalAtmIcon from "@mui/icons-material/LocalAtm"
import PostAddIcon from "@mui/icons-material/PostAdd"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { PERFILES } from "../../Constants/Strings"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../Constants/Styles"
import { getLinkPDF } from "../../Services/Api_cursos"
import { HasPermission } from "#common/Funciones"
import DialogVoBoPagos from "./dialogsFondo/DialogVoBoPagos"
import { toast } from "react-toastify"
export const STATUS = {
	GENERADA: 1,
	VOBO_RH: 2,
	VOBO_PAGOS: 3,
	PAGADO: 4,
	FINALIZADO: 8,
	RECHAZADO: 9,
	PENDIENTE_PAGO_COLAB: 10,
	PAGADO_COLAB: 11,
	DOC_COLAB: 12,
	VOBO_PAGO_RH: 5,
}
/**
 * Constante que representa el estado de cada paso en el proceso de solicitud de fondo.
 *
 * @typedef {Object} FondoStatus
 * @property {number} NF0 - Estado: Rechazado.
 * @property {number} NF1 - Estado: Generada.
 * @property {number} NF2 - Estado: VoBo RH (Visto Bueno Recursos Humanos).
 * @property {number} NF3 - Estado: VoBo Contabilidad (Visto Bueno Contabilidad).
 * @property {number} NF4 - Estado: En espera de pago.
 * @property {number} NF5 - Estado: Finalizada.
 * @property {number} NF6 - Estado: Pendiente de pago por Colaborador.
 * @property {number} NF7 - Estado: Pagado por Colaborador.
 * @property {number} NF8 - Estado: RH Valida Documento (Recursos Humanos Valida Documento).
 * @property {number} NF9 - Estado: Espera de Documento.
 */

/** @type {FondoStatus} */
export const FONDO_STATUS = {
	NF0: 10,
	NF1: 1,
	NF2: 2,
	NF3: 3,
	NF4: 4,
	NF5: 5,
	// pasos extras para decremento o finalización
	NF6: 6,
	NF7: 7,
	NF8: 8,
	NF9: 9,
}
const { CONT, RH, GRTE, PAG } = PERFILES
export default function ButtonsFondo({ solicitud, setInfo }) {
	const { status_id } = solicitud
	let cancelar = null

	if (
		(status_id === FONDO_STATUS.NF1 || status_id === FONDO_STATUS.NF2) &&
		HasPermission([CONT, RH, GRTE])
	)
		cancelar = <ButtonCancelar solicitud={solicitud} />
	let button = <></>
	HasPermission()

	switch (status_id) {
		case FONDO_STATUS.NF1:
			if (HasPermission([RH])) button = <ButtonGenerada solicitud={solicitud} />
			break
		case FONDO_STATUS.NF2:
			if (HasPermission([CONT])) button = <ButtonVoBoRH solicitud={solicitud} />
			break
		case FONDO_STATUS.NF3:
			if (HasPermission([PAG]))
				button = <ButtonVoBoPagos solicitud={solicitud} />
			break
		case FONDO_STATUS.NF4:
			if (HasPermission([CONT])) button = <ButtonPagado solicitud={solicitud} />
			break
		case FONDO_STATUS.NF7:
			if (HasPermission([CONT])) button = <ButtonPagado solicitud={solicitud} />
			break
		case FONDO_STATUS.NF8:
			if (HasPermission([GRTE])) button = <ButtonNF8 solicitud={solicitud} />
			break
		case FONDO_STATUS.NF9:
			if (HasPermission([RH])) button = <ButtonNF9 solicitud={solicitud} />
			break
		default:
			break
	}

	return (
		<Stack
			direction={"row"}
			spacing={1}
		>
			<ButtonInfo
				title={"Información Fondo"}
				onClickInfo={() => {
					setInfo({ open: true, fondo: solicitud })
				}}
			/>
			{button}
			{cancelar}
		</Stack>
	)
}

const ButtonsD = ({ aceptar = () => true, retorno = false }) => {
	const token = useSelector((state) => state.user.token)
	const { sendData, updateData, setSendData, setOpenDialog } = useFondoContext()
	const [disabled, setDisabled] = useState(false)
	let button_extra = []
	if (retorno)
		button_extra.push(
			<ButtonDialog
				disabled={disabled}
				onClick={async () => {
					if (aceptar(sendData)) {
						const { success } = await apiPutFondoFijo(token, {
							...sendData,
							status: FONDO_STATUS.NF3,
						})
						if (success) {
							setOpenDialog(false)
							setSendData({})
							updateData()
						}
					}
				}}
				key="Solicitar nuevo comprobante "
				label="Solicitar nuevo comprobante "
			/>,
		)

	return [
		<ButtonDialog
			disabled={disabled}
			onClick={() => {
				setOpenDialog(false)
				setSendData({})
			}}
			key="Cerrar"
			label="Cerrar"
		/>,
		...button_extra,
		<ButtonDialog
			disabled={disabled}
			onClick={async () => {
				setDisabled(true)
				if (aceptar(sendData)) {
					const { success } = await apiPutFondoFijo(token, sendData)
					if (success) {
						setOpenDialog(false)
						setSendData({})
						updateData()
					}
				}
				setDisabled(false)
			}}
			key="aceptar"
			label="aceptar"
		/>,
	]
}

const ButtonInfo = ({ title, onClickInfo }) => {
	return (
		<Tooltip title={title}>
			<IconButton
				aria-label={title}
				// sx={{ color: "red" }}
				onClick={onClickInfo}
			>
				<ErrorOutlineIcon />
			</IconButton>
		</Tooltip>
	)
}

const ButtonCancelar = ({ solicitud }) => {
	const { setOpenDialog, newSendData } = useFondoContext()
	const title = "¿Cancelar Solicitud de fondo?"
	const buttons = <ButtonsD />

	return (
		<Tooltip title="Cancelar solicitud">
			<IconButton
				aria-label="Cancelar solicitud"
				sx={{ color: "red" }}
				onClick={() => {
					newSendData({ fondo: solicitud.id, status: FONDO_STATUS.NF0 })
					setOpenDialog({
						open: true,
						title,
						contenido: null,
						buttons,
					})
				}}
			>
				<CancelIcon />
			</IconButton>
		</Tooltip>
	)
}

const ButtonNF8 = ({ solicitud }) => {
	const { setOpenDialog } = useFondoContext()
	const title = "Subir Documento"

	const contenido = <ContentNF8 solicitud={solicitud} />

	return (
		<Tooltip title={title}>
			<IconButton
				aria-label={title}
				sx={{ color: "green" }}
				onClick={() => {
					setOpenDialog({
						open: true,
						title,
						contenido,
						buttons: <ButtonsD />,
					})
				}}
			>
				<PostAddIcon />
			</IconButton>
		</Tooltip>
	)
}
const ButtonNF9 = ({ solicitud }) => {
	const { setOpenDialog, newSendData } = useFondoContext()
	const title = "Aprobar documento"
	const contenido = <ContentNF9 solicitud={solicitud} />

	return (
		<Tooltip title={title}>
			<IconButton
				aria-label={title}
				sx={{ color: "green" }}
				onClick={() => {
					newSendData({ fondo: solicitud.id, status: FONDO_STATUS.NF5 })
					setOpenDialog({
						open: true,
						title,
						contenido,
						buttons: <ButtonsD />,
					})
				}}
			>
				<BeenhereIcon />
			</IconButton>
		</Tooltip>
	)
}

const ButtonGenerada = ({ solicitud }) => {
	const { setOpenDialog, newSendData } = useFondoContext()
	const title = "Aprobar Fondo Fijo RH"
	const buttons = (
		<ButtonsD
			aceptar={(e) => {
				const { editar_banca, banco, clabe, cuenta } = e
				if (editar_banca) {
					if (!banco || banco.trim() === "") {
						toast.error("Banco no puede estar vació")
						return false
					}
					if (!parseInt(cuenta)) {
						toast.error("cuenta no puede estar vació")
						return false
					}

					if (!parseInt(clabe) || clabe.length !== 18) {
						toast.error("Clabe no puede estar vació y debe ser 18 Dígitos")
						return false
					}
				}
				return true
			}}
		/>
	)
	let contenido = <DialogVoBoRH solicitud={solicitud} />
	if (!solicitud.quantity || solicitud?.quantity < solicitud?.quantity_previous)
		contenido = null
	return (
		<Tooltip title="Aprobar solicitud RH">
			<IconButton
				aria-label="Aprobar solicitud RH"
				sx={{ color: "green" }}
				onClick={() => {
					newSendData({
						fondo: solicitud.id,
						status: FONDO_STATUS.NF2,
					})
					setOpenDialog({
						open: true,
						title,
						contenido,
						buttons,
					})
				}}
			>
				<FactCheckIcon />
			</IconButton>
		</Tooltip>
	)
}

const ButtonVoBoRH = ({ solicitud }) => {
	const { setOpenDialog, newSendData, setOpenDialogPDF } = useFondoContext()
	const title = "VoBo Contabilidad"
	const buttons = <ButtonsD />
	const pdf_url = solicitud?.file_signed
	let contenido = null
	if (pdf_url)
		contenido = (
			<Button
				startIcon={<RemoveRedEyeIcon />}
				variant="contained"
				fullWidth
				sx={{ backgroundColor: PRIMARY_COLOR }}
				onClick={() => {
					setOpenDialogPDF({ open: true, url: pdf_url })
					// setOpenPDF(true)
				}}
			>
				ver documento
			</Button>
		)
	return (
		<Tooltip title={title}>
			<IconButton
				aria-label={title}
				sx={{ color: "green" }}
				onClick={() => {
					newSendData({ fondo: solicitud.id, status: FONDO_STATUS.NF3 })
					setOpenDialog({
						open: true,
						title,
						contenido,
						buttons,
					})
				}}
			>
				<PriceCheckIcon />
			</IconButton>
		</Tooltip>
	)
}

const ButtonVoBoPagos = ({ solicitud }) => {
	const { setOpenDialog, newSendData } = useFondoContext()
	const title = "Pagar Fondo"
	const contenido = <DialogVoBoPagos solicitud={solicitud} />
	return (
		<Tooltip title={title}>
			<IconButton
				aria-label={title}
				sx={{ color: "green" }}
				onClick={() => {
					newSendData({ fondo: solicitud.id, status: STATUS.VOBO_PAGOS })
					setOpenDialog({
						open: true,
						title,
						contenido,
					})
				}}
			>
				<LocalAtmIcon />
			</IconButton>
		</Tooltip>
	)
}

const ButtonPagado = ({ solicitud }) => {
	const { img } = solicitud
	const { setOpenDialog, newSendData } = useFondoContext()
	const [sap_policy, setSap_policy] = useState("")
	const title = "Finalizar Proceso"
	const buttons = (
		<ButtonsD
			key="defaultButtons"
			aceptar={(d) => {
				const { sap_policy } = d
				if (sap_policy.trim() === "") {
					toast.error("Se necesita Póliza sap")
					return false
				}
				return true
			}}
			retorno={
				!solicitud?.quantity ||
				solicitud?.quantity < solicitud?.quantity_previous
			}
		/>
	)
	useEffect(() => {
		newSendData({
			fondo: solicitud.id,
			status: FONDO_STATUS.NF5,
			sap_policy,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sap_policy, solicitud.id])

	let quantity = solicitud.quantity
	let previous = solicitud.quantity_previous
	let monto
	if (quantity > previous) monto = quantity - previous
	else if (quantity < previous) monto = previous - quantity
	else if (quantity === 0) monto = previous

	let valor = parseFloat(monto)
	let value = `$${valor.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})}`
	let contenido = (
		<Stack
			key="Sap"
			spacing={2}
		>
			<TextField
				label="Póliza sap"
				fullWidth
				required
				onChange={(event) => {
					setSap_policy(event.target.value)
				}}
			/>
			<Stack
				direction={"row"}
				justifyContent={"space-around"}
			>
				<div>
					<Typography>
						<strong>Colaborador :</strong>
					</Typography>

					<Typography>{solicitud.colaborador_name}</Typography>
				</div>
				<div>
					<Typography>
						<strong>Monto :</strong>
					</Typography>
					<Typography> {value}</Typography>
				</div>
			</Stack>
			<ImgDownload url={img} />
		</Stack>
	)

	return (
		<Tooltip title={title}>
			<IconButton
				aria-label={title}
				sx={{ color: "green" }}
				onClick={() => {
					// set_open_img(true)
					newSendData({
						fondo: solicitud.id,
						status: FONDO_STATUS.NF5,
						sap_policy,
					})
					setOpenDialog({
						open: true,
						title,
						contenido,
						buttons,
					})
				}}
			>
				<AppRegistrationIcon />
			</IconButton>
		</Tooltip>
	)
}

function ImgDownload({ url, open = true }) {
	const token = useSelector((state) => state.user.token)
	const [img, setImg] = useState(null)

	useEffect(() => {
		if (open) {
			getLinkPDF(token, url).then((result) => {
				setImg(result)
			})
		}
	}, [open, token, url])

	return (
		<Box
			sx={{
				// bgcolor: "#cfe8fc",
				height: "50vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img
				src={img}
				alt="Comprobante de pago"
				width="100%"
				height="100%"
			></img>
		</Box>
	)
}
