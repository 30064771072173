import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
//Api
import {
	apiDeleteColaboradorEscolares,
	apiGetColaboradorEscolares,
} from "../../../../Services/ApiColaborador"
//material UI
import Box from "@mui/material/Box"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { DialogConfirm, ViewPDF } from "../../../Common/Dialog"
import FormDataEscolares from "../../forms/FormDataEscolares"

import { CardBar, CardComponen } from "./CardComponent"

export default function AcademicLevelsCard({ colaborador, edit = true }) {
	const token = useSelector((state) => state.user.token)
	const [open, setOpen] = useState(false)
	const [dataEdit, setDataEdit] = useState(false)
	const [openConfirm, setOpenConfirm] = useState({ open: false, id: null })
	const [actualizar, setActualizar] = useState(true)
	const [openPDF, setOpenPDF] = useState(false)
	function actualizarNow() {
		setActualizar(!actualizar)
	}
	const [escolares, setEscolares] = useState([])
	const TEXT_COLOR_TABLET = "#eeeeee"

	useEffect(() => {
		apiGetColaboradorEscolares(token, setEscolares, {
			colaborador: colaborador.id,
		})
	}, [token, actualizar, colaborador.id])

	function handleDelete(id) {
		apiDeleteColaboradorEscolares(token, {
			id: id,
		}).then((response) => {
			setOpenConfirm(false)
		})
	}

	const handleNew = () => {
		setOpen({ open: true })
	}
	function handleEdit(grado) {
		setOpen({ open: true, edit: grado })
		setDataEdit(grado)
	}

	function handlePDF(url) {
		setOpenPDF({
			open: true,
			url: url,
		})
	}
	return (
		<React.Fragment>
			<Box sx={{ flexGrow: 1 }}>
				<Grid2
					container
					spacing={1}
				>
					<Grid2 xs={12}>
						<CardBar
							title="Grados Escolares"
							edit={edit}
							buttonAdd={handleNew}
							color={TEXT_COLOR_TABLET}
						/>
					</Grid2>
					{escolares.map((estudios) => {
						return (
							<Grid2
								key={estudios.id}
								xs={12}
								md={6}
								sx={{ display: "flex" }}
							>
								<CardComponen
									key={estudios.id}
									edit={edit}
									estudios={estudios}
									buttonEdit={() => handleEdit(estudios)}
									buttonDeleted={() =>
										setOpenConfirm({ open: true, id: estudios.id })
									}
									buttonCertificado={() => handlePDF(estudios.documento)}
									buttonCertificadoEnable={!estudios.documento}
								/>
							</Grid2>
						)
					})}
				</Grid2>
			</Box>
			<ViewPDF
				open={openPDF.open}
				pdfUrl={openPDF.url}
				setOpen={setOpenPDF}
			/>
			<FormDataEscolares
				idColaborador={colaborador.id}
				open={open.open}
				edit={open.edit}
				setOpen={setOpen}
				actualizar={actualizarNow}
			/>
			<DialogConfirm
				open={openConfirm.open}
				setOpen={setOpenConfirm}
				message={"Desea Borrar el grado escolar"}
				accept={() => {
					handleDelete(openConfirm.id)
				}}
			/>
		</React.Fragment>
	)
}
