import { DataItem } from "#common/text/DataItem"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Grid2 from "@mui/material/Unstable_Grid2"
import { usePromotionsContext } from "Components/promotions/PromotionContext"
import { STATUS } from "Components/promotions/utils/constants"
import { CardButtons } from "./CardButtons"

export function CardChanges({ detail, disable = false }) {
	let estado = "Espera de autorización"
	const { promotion } = usePromotionsContext()
	if (detail.authorized === true) estado = "Aprobado"
	else if (detail.authorized === false) estado = "Rechazada"
	if (typeof detail.authorized === "boolean") disable = true
	const data = [
		{
			label: "",
			value: "name",
			variant: "h6",
			fontWeight: "bold",
			grid: { md: 12, lg: 12 },
		},
		{
			label: "Puesto Actual",
			value: "old_profile",
			grid: { md: 12, lg: 4 },
		},
		{
			label: "Puesto Requerido",
			value: "new_profile",
			grid: { md: 12, lg: 4 },
		},
		{
			label: estado,
			value: "authorization_name",
			grid: { md: 12, lg: 4 },
			notShow:
				promotion.status_id !== STATUS.Finalizada &&
				promotion.status_id !== STATUS.Aprobada_RH,
		},
	]

	const dataItem = data.map((item) => {
		if (item?.notShow) return null
		return (
			<Grid2
				{...item.grid}
				key={item.value}
			>
				<DataItem
					{...item}
					value={detail[item.value]}
				/>
			</Grid2>
		)
	})
	return (
		<Card
			sx={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<CardMedia
				sx={{
					minWidth: "150px",
					maxWidth: "150px",
					borderRadius: "15px",
					padding: "10px",
				}}
				component="img"
				image={detail?.photo}
				title="Colaborador"
				onError={(e) => {
					e.target.onerror = null
					e.target.src =
						"https://imagenespublicas.s3.us-east-2.amazonaws.com/usuario.webp"
				}}
			/>
			<CardContent sx={{ flexGrow: 1 }}>
				<Grid2
					container
					spacing={2}
					columnSpacing={2}
				>
					{dataItem}
				</Grid2>
			</CardContent>

			<CardButtons
				disable={disable}
				detail={detail}
			/>
		</Card>
	)
}
