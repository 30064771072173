import React from "react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux" //Token
//Componentes
import { TextGrid2, TextGrid } from "../../../Common/Texto"
import { ButtonGrid2 } from "../../../Common/Buttons"
import { getPrestamos, putPrestamos } from "../../../../Services/Api_prestamos"
import { STATUS_LOAN } from "../constants"

//Material
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { styled } from "@mui/material/styles"

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "1300px",
	bgcolor: "background.paper",
	//border: "2px solid #000",
	borderRadius: "1%",
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
}

function PrestamosPago({
	open,
	setOpen,
	actualizar = true,
	setActualizar = () => {},
}) {
	const token = useSelector((state) => state.user.token)
	const {
		id,
		no_empleado,
		colaborador_nombre,
		sucursal,
		departamento,
		autorizador_nombre,
	} = open
	const handleClose = () => {
		setOpen(false)
	}
	const [dataBanco, setDataBanco] = useState("")
	const [archivo, setArchivo] = useState("")
	const enviar = () => {
		setOpen(false)
		putPrestamos(
			token,
			archivo,
			{
				id: id,
				status: STATUS_LOAN["PAY_BACK"],
			},
			actualizar,
			setActualizar
		)
	}
	useEffect(() => {
		getPrestamos(token, setDataBanco, { id: id, datosBancarios: "" })
	}, [id, token])
	let sxButton = {
		"&:hover": {
			backgroundColor: "#DBDADA",
			boxShadow: "10",
		},
	}

	return (
		<React.Fragment>
			<Modal
				open={open}
				onClose={handleClose}
			>
				<Box sx={{ ...style }}>
					<Grid container>
						<Grid
							xs={12}
							md={5}
							xl={6}
						>
							<TextGrid
								label="Pago Prestamo"
								tipo="h4"
								align="left"
								md={12}
								xl={12}
							/>
							<TextGrid
								label="Datos Colaborador"
								tipo="h5"
								align="left"
								xl={12}
								style={{ fontWeight: "bold" }}
							/>
							<Grid xs={12}>
								<div>
									<Grid
										container
										justifyContent={"flex-start"}
									>
										<TextGrid2
											label={`Colaborador: `}
											variant="h6"
											textAlign="left"
											xs={6}
										/>
										<TextGrid2
											label={`${colaborador_nombre}`}
											variant="h6"
											textAlign="left"
											style={{ fontWeight: "bold" }}
										/>
									</Grid>
								</div>
							</Grid>
							<TextGrid
								label={`N° Empleado: ${no_empleado}`}
								tipo="h6"
								align="left"
							/>
							<TextGrid
								label={`Sucursal: ${sucursal}`}
								tipo="h6"
								align="left"
							/>
							<TextGrid
								label={`Departamento: ${departamento}`}
								tipo="h6"
								align="left"
								md={12}
								xl={12}
							/>
							<TextGrid
								label={`Jefe Autorizo: ${autorizador_nombre}`}
								tipo="h6"
								align="left"
								md={12}
								xl={12}
							/>
							<TextGrid
								label={`Banco: ${dataBanco["banco"]}`}
								tipo="h6"
								align="left"
								md={12}
								xl={12}
							/>
							<TextGrid
								label={`Cuenta: ${dataBanco["cuenta"]}`}
								tipo="h6"
								align="left"
								md={12}
								xl={12}
							/>
							<TextGrid
								label={`Clave: ${dataBanco["clabe"]}`}
								tipo="h6"
								align="left"
								md={12}
								xl={12}
							/>
						</Grid>
						<Grid
							md={7}
							xl={6}
						>
							<div>
								<ButtonUrl
									archivo={archivo}
									setArchivo={setArchivo}
								/>
							</div>
						</Grid>
					</Grid>
					<Button
						onClick={handleClose}
						sx={sxButton}
					>
						Cerrar
					</Button>
					<Button
						onClick={enviar}
						sx={sxButton}
					>
						Enviar
					</Button>
				</Box>
			</Modal>
		</React.Fragment>
	)
}
function ButtonUrl({ id, archivo, setArchivo = () => {}, show = true }) {
	const [imageURL, setImageURL] = React.useState("")
	const [color, setColor] = React.useState("")

	const handleFileUpload = (event) => {
		const file = event.target.files[0]
		let formData = new FormData()
		formData.append("archivo", file)
		setArchivo(formData)
		const imageURL = URL.createObjectURL(file)
		setImageURL(imageURL)
	}

	const handleDrop = (event) => {
		event.preventDefault()
		const file = event.dataTransfer.files[0]
		var formData = new FormData()
		formData.append("archivo", file)
		setArchivo(formData)
		if (file) {
			const imageUrl = URL.createObjectURL(file)
			setImageURL(imageUrl)
		}
	}

	const handleDragOver = (event) => {
		event.preventDefault()
	}

	const handlePaste = (event) => {
		const clipboardData = event.clipboardData || window.clipboardData
		const items = clipboardData.items

		for (let item of items) {
			if (item.type.indexOf("image") !== -1) {
				const blob = item.getAsFile()
				var formData = new FormData()
				formData.append("archivo", blob)
				setArchivo(formData)
				const imageUrl = URL.createObjectURL(blob)
				setImageURL(imageUrl)
				break
			}
		}
	}
	const Root = styled("div")(({ theme }) => ({
		padding: theme.spacing(1),
		borderRadius: "10%",
		[theme.breakpoints.down("md")]: {},
		[theme.breakpoints.up("md")]: {
			height: "400px",
		},
		[theme.breakpoints.up("xl")]: {
			height: "400px",
		},
	}))
	if (show)
		return (
			<div>
				<Grid
					container
					spacing={1}
					alignContent={"center"}
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid xs={5}>
						<input
							accept=".PNG"
							style={{ display: "none" }}
							id={`file-input${id}`}
							type="file"
							onChange={handleFileUpload}
						/>
						<label htmlFor={`file-input${id}`}>
							<ButtonGrid2
								sx={{
									"&:hover": {
										backgroundColor: "#DBDADA",
										boxShadow: "10",
									},
								}}
								label="Subir Imagen"
								variant="text"
								component="span"
							/>
						</label>
					</Grid>
					<Grid
						md={12}
						xl={12}
						container
					>
						<Grid md={12}>
							<Root
								onMouseDown={() => {
									setColor("#00FF80")
								}}
								onDrop={handleDrop}
								onDragOver={handleDragOver}
								onPaste={handlePaste}
								style={{
									overflow: "auto",
									position: "relative",
									width: "100%",
									boxShadow:
										color === "#00FF80"
											? "10px 10px 20px rgba(0, 255, 0, 0.5)"
											: "none",
									border: `2px solid ${color}`,
									padding: "5px",
									textAlign: "center",
								}}
							>
								{imageURL && (
									<img
										width="100%"
										src={imageURL}
										alt="Imagen"
									/>
								)}
							</Root>
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	else return <React.Fragment></React.Fragment>
}
export default PrestamosPago
