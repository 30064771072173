import React from "react"
import { ButtonIcon } from "../../../Common/Buttons"
import { COLOR_PRIMARY, PRIMARYCOLOR } from "#constants/Styles"

//Material UI
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"

//icons
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DescriptionIcon from "@mui/icons-material/Description"

export function CardComponen({
	estudios,
	edit,
	buttonEdit,
	buttonDeleted,
	buttonCertificado,
	buttonCertificadoEnable,
}) {
	const { grado, institucion: institution, inicio, fin, nombre } = estudios
	const fontSize = 15
	return (
		<Card sx={{ flex: 1, minWidth: 250, borderRadius: 3 }}>
			<CardActions
				backgroundColor="red"
				sx={{ backgroundColor: PRIMARYCOLOR }}
			>
				<div
					style={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{ fontSize: 17, color: "#ffffff" }}
						variant="h7"
					>
						{nombre ? institution : grado}
					</Typography>
					{edit && (
						<Stack
							direction="row"
							justifyContent="flex-end"
						>
							<ButtonIcon
								title="Editar"
								icon={<EditIcon />}
								onClick={buttonEdit}
								size="small"
								sx={{ color: "#ffffff" }}
							/>
							<ButtonIcon
								title="Eliminar"
								icon={<DeleteIcon />}
								onClick={buttonDeleted}
								size="small"
								sx={{ color: "#ffffff" }}
							/>
						</Stack>
					)}
				</div>
			</CardActions>

			<CardContent>
				<div
					style={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						height: "20px",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{ fontSize: fontSize }}
						color="text.secondary"
						gutterBottom
					>
						{nombre ? nombre : institution}
					</Typography>
				</div>
				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					{`Duración : ${inicio} a ${fin}`}
				</Typography>

				{buttonCertificadoEnable && (
					<Typography
						sx={{ fontSize: fontSize }}
						color="text.secondary"
						gutterBottom
					>
						{`No Certificado`}
					</Typography>
				)}
				<Button
					variant="text"
					startIcon={<DescriptionIcon />}
					disabled={buttonCertificadoEnable}
					onClick={buttonCertificado}
				>
					Certificado
				</Button>
			</CardContent>
		</Card>
	)
}

export function CardBar({ title, buttonAdd, color, edit }) {
	return (
		<AppBar
			position="static"
			sx={{ borderRadius: 3, backgroundColor: COLOR_PRIMARY }}
		>
			<Toolbar>
				<Typography
					variant="h6"
					component="div"
					sx={{
						flexGrow: 1,
						// fontSize: 18,
						fontWeight: "bold",
						color: color,
					}}
				>
					{title}
				</Typography>
				{edit && (
					<ButtonIcon
						title="Agregar"
						icon={<AddCircleOutlineIcon />}
						sx={{
							color: color,
						}}
						onClick={buttonAdd}
					/>
				)}
			</Toolbar>
		</AppBar>
	)
}
