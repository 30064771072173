import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

//componentes
import { apiPostFondoFijo } from "../../../Services/Api_Fondo_Fijo"
import { DialogGeneral } from "../../Common/Dialog"
import { UseFondoFijoV2 } from "../useFondoFijo"
import SelectColaborador from "./SelectColaborador"
import SelectMovimiento from "./SelectMovimiento"

//Mui
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Skeleton from "@mui/material/Skeleton"
import Step from "@mui/material/Step"
import StepContent from "@mui/material/StepContent"
import StepLabel from "@mui/material/StepLabel"
import Stepper from "@mui/material/Stepper"
import Typography from "@mui/material/Typography"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../../Constants/Styles"

const NEW = "Nuevo",
	INC = "INCREMENTO",
	DEC = "Decremento",
	DEL = "Cancelación"

export default function FondoFijoFormulario({
	open,
	setOpen,
	colaborador = null,
	actualizar = () => {},
}) {
	const token = useSelector((state) => state.user.token)
	const params = useMemo(() => {
		if (colaborador) return { colaborador }
		else return { colaboradores: true }
	}, [colaborador])

	const { fondoFijo: colaboradores, loading } = UseFondoFijoV2({
		activar: !!open,
		params,
	})

	const [activeStep, setActiveStep] = useState(0)
	const [data, setData] = useState({})
	const [archivo, setArchivo] = useState(null)

	const getArchivo = (archivo) => {
		setArchivo(archivo)
	}

	const getDatos = (dato) => {
		setData({ ...data, ...dato })
	}

	const steps = [
		{
			label: "Seleccionar Colaborador",
			description: (
				<SelectColaborador
					colaboradores={colaboradores}
					colaborador_select={colaborador}
					onChang={(datos) => setData({ ...data, ...datos })}
				/>
			),
			next: !data.colaborador || !data.type,
		},
		{
			label: "Proceso",
			description: (
				<SelectMovimiento
					data={data}
					onChang={getDatos}
					onChangDoc={getArchivo}
				/>
			),
		},
	]

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}

	const handleClose = () => {
		setData({})
		setOpen(false)
		setActiveStep(0)
	}

	const handleSend = async () => {
		const { type, cantidad, quantity } = data
		let erro = false
		if (type === INC) {
			if (cantidad < quantity) erro = true
		} else if (type === DEC) {
			if (cantidad > quantity) erro = true
		} else if (type === NEW) if (cantidad <= 0) erro = true

		if (erro) {
			toast.error("Error en las cantidad del fondo")
			return null
		}

		if (data.type === DEL) data.quantity = 0
		if (data.cantidad) {
			data.quantity = data.cantidad
			delete data.cantidad
		}
		let formData = new FormData()
		for (let dato in data) {
			formData.append(dato, data[dato])
		}
		if (archivo) formData.append("doc", archivo)
		const { success } = await apiPostFondoFijo(token, formData)
		if (success) {
			actualizar()
			handleClose()
		}
	}

	if (loading)
		return (
			<DialogGeneral
				title="Solicitud Fondo Fijo"
				open={open}
				setOpen={setOpen}
				onClose={handleClose}
			>
				<Box sx={{ maxWidth: "100%" }}>
					<Skeleton
						variant="rectangular"
						width={"100%"}
						height={80}
					/>
				</Box>
			</DialogGeneral>
		)
	return (
		<DialogGeneral
			title="Solicitud Fondo Fijo"
			open={open}
			setOpen={setOpen}
			onClose={handleClose}
		>
			<Box sx={{ maxWidth: "100%" }}>
				<Stepper
					activeStep={activeStep}
					orientation="vertical"
				>
					{steps.map((step, index) => (
						<Step key={step.label}>
							<StepLabel
								optional={
									index === 2 ? (
										<Typography variant="caption">
											Seleccionar Colaborador
										</Typography>
									) : null
								}
							>
								{step.label}
							</StepLabel>
							<StepContent>
								{step.description}
								<Box sx={{ mb: 2 }}>
									<div>
										<Button
											// disabled={index === 0}
											onClick={handleClose}
											variant="contained"
											sx={{ backgroundColor: PRIMARY_COLOR, mt: 1, mr: 1 }}
										>
											Cancelar
										</Button>
										<Button
											disabled={step.next}
											variant="contained"
											onClick={
												index === steps.length - 1 ? handleSend : handleNext
											}
											sx={{ backgroundColor: PRIMARY_COLOR, mt: 1, mr: 1 }}
										>
											{index === steps.length - 1 ? "Enviar" : "Continuar"}
										</Button>
									</div>
								</Box>
							</StepContent>
						</Step>
					))}
				</Stepper>
			</Box>
		</DialogGeneral>
	)
}
