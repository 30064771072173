import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ButtonIcon } from "../../Common/Buttons"
import { DialogGeneral } from "../../Common/Dialog"
import ModalNewDoc from "./ModalNewDoc"
//icons
import { candidatoDocsDelete, CandidatoDocsGet } from "#api/Api/requerimientos"
import { HasPermission } from "#common/Funciones"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DeleteIcon from "@mui/icons-material/Delete"
import DownloadIcon from "@mui/icons-material/Download"
const ModalDocumentation = ({ open, setOpen, candidato }) => {
	const token = useSelector((state) => state.user.token)
	const [openNewDoc, setOpenNewDoc] = useState(false)
	const [documentos, setDocumentos] = useState([])
	const [reload, setReload] = useState()
	const [loading, setLoading] = useState(true)
	const isAdmin = HasPermission()

	const getUrl = useCallback(
		(doc_id) => {
			const success = (url_s3) => {
				window.open(url_s3, "_blank")
			}
			CandidatoDocsGet(candidato.id, {
				success,
				params: { id: doc_id, url: true },
			})
		},
		[candidato?.id],
	)
	const delFile = useCallback(
		(doc_id) => {
			const success = (url_s3) => {
				setReload((old) => !old)
			}
			candidatoDocsDelete(candidato.id, {
				success,
				params: { id: doc_id },
			})
		},
		[candidato?.id],
	)

	useEffect(() => {
		if (candidato == null || !open) return
		setDocumentos([])
		setLoading(true)
		const success = (response) => {
			setDocumentos(response)
			setLoading(false)
		}
		CandidatoDocsGet(candidato.id, { success })
	}, [candidato, token, open, reload])

	if (!open || candidato == null) return
	const columns = [
		{ title: "Archivo" },
		{ title: "Fecha de subida" },
		{
			title: (
				<Stack
					width={"100%"}
					direction={"row"}
					alignItems={"center"}
					spacing={1}
					justifyContent="space-between"
				>
					<Typography fontWeight={"bold"}>Subido por</Typography>
					<ButtonIcon
						title="Añadir Documento"
						icon={<AddCircleOutlineIcon />}
						sx={{ color: "#eee" }}
						onClick={() => setOpenNewDoc(true)}
					/>
				</Stack>
			),
		},
	]
	const styleBody = {
		fontWeight: "bold",
		paddingTop: 1,
		paddingBottom: 1,
		fontSize: 16,
	}
	if (loading)
		return (
			<DialogDocumentationBase {...{ name: candidato.nombre, setOpen }}>
				<CircularSkeleton />
			</DialogDocumentationBase>
		)
	return (
		<DialogDocumentationBase {...{ name: candidato.nombre, setOpen }}>
			{documentos.length === 0 ? (
				<>No hay documentos disponibles</>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										sx={{
											backgroundColor: "#1d3454",
											color: "#eee",
											fontWeight: "bold",
											paddingTop: 1,
											paddingBottom: 1,
											fontSize: 16,
											...column.style,
										}}
									>
										{column.title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{documentos.map((documento) => {
								return (
									<TableRow key={documento.fecha}>
										<TableCell sx={{ ...styleBody, textAlign: "left" }}>
											<Stack
												direction={"row"}
												spacing={1}
											>
												{isAdmin && (
													<Button onClick={() => delFile(documento.id)}>
														<DeleteIcon />
													</Button>
												)}
												<Button
													fullWidth
													variant="contained"
													startIcon={<DownloadIcon />}
													onClick={() => getUrl(documento?.id)}
												>
													{documento.nombre_documento}
												</Button>
											</Stack>
										</TableCell>
										<TableCell sx={styleBody}>{documento.fecha}</TableCell>
										<TableCell sx={styleBody}>
											{documento.colaborador}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<ModalNewDoc
				open={openNewDoc}
				setOpen={setOpenNewDoc}
				candidato={candidato}
				setReload={setReload}
			/>
		</DialogDocumentationBase>
	)
}

export default ModalDocumentation
const DialogDocumentationBase = ({ name, setOpen, children }) => {
	return (
		<DialogGeneral
			title={`${name}`}
			propsTitle={{
				sx: {
					paddingBottom: "0",
					fontWeight: "bold",
					color: "#555",
					whiteSpace: "pre-line",
				},
			}}
			open={true}
			setOpen={setOpen}
			maxWidth={"md"}
			fullWidth={true}
		>
			{children}
		</DialogGeneral>
	)
}
