export const textButton = {
	PROCESS: {
		mensaje: "¿Esta seguro de aprobar solicitud?",
		tooltip: "Aprobar RH",
	},
	VALIDATE_RH: {
		mensaje: "¿Esta seguro de aprobar solicitud?",
		tooltip: "Aprobar jefe",
	},
	VALIDATE_BOSS: {
		mensaje: "¿Esta seguro de subir documentos?",
		tooltip: "Subir documentos",
	},
	AWAITED_DOCUMENT: {
		mensaje: "¿Esta seguro de pago de préstamo?",
		tooltip: "Pago de préstamo",
	},
	PASS_PAY: {
		mensaje: "¿Esta seguro de autorizar pago?",
		tooltip: "Autorizar pago",
	},
	PAY: {
		mensaje: "¿Esta seguro de pago de préstamo colaborador ?",
		tooltip: "Pago de préstamo",
	},
	PAY_BACK: {
		mensaje: "¿Esta seguro de termino de pagar el préstamo?",
		tooltip: "Termino de pago préstamo",
	},
	DOCUMENT_FAIL: {
		mensaje: `¿Estás seguro de que deseas rechazar el documento?, el prestamo regresara al estado "espera de documentación"`,
		tooltip: "Documento incorrecto",
	},
	FINISHED: {
		mensaje: "",
		tooltip: "",
	},
	REJECT: {
		mensaje: "",
		tooltip: "",
	},
	RECHAZAR: {
		mensaje:
			"¿Estas seguro de rechazar el prestamo?, saldrá de fila de espera y no podrá reactivarse",
		tooltip: "Rechazar",
	},
	ROW_DOWN: {
		mensaje: "¿Estas seguro de bajar un turno este prestamo?",
		tooltip: "Bajar turno",
	},
}
export const TEXTOS_PRESTAMOS = {
	TITLE: "Solicitudes de Prestamos",
	BUTTON_REQUEST: "Crear Solicitud",
	BUTTON_EXIT: "Cancelar solicitud de prestamo",
	TEXT_EXIT: "¿Estas seguro de cancelar tu solicitud de prestamo?",
	buttonLoanAlt: "Caja de ahorro no disponible en este momento",
	buttonLoan: "Solicitud Préstamo",
	shiftNow: "Turno",
	shift: "Tu Turno Es",
}
