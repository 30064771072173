import React from "react"
import { useSelector } from "react-redux"
//Componentes
import { ChangeTitle } from "Components/menu/funciones"
import VistaUsuario from "../Components/Capacitaciones/VistaUsuario"
import { Box } from "@mui/material"

const Capacitaciones = () => {
	ChangeTitle("Mis cursos")
	const user = useSelector((state) => state.user)

	return (
		<Box>
			<VistaUsuario id={user.colaborador.id} />
		</Box>
	)
}

export default Capacitaciones
