import { apiGetRH } from "./API_RH"

// import { API } from "#api/API_RH"
const BAJAS = "bajas"
const BAJA = (id) => `${BAJAS}/${id}`

/**
 *
 * @param {int} id
 * @param {apiGetRhParams} params
 */
export function apiBajaGet(id, params = {}) {
	apiGetRH(BAJA(id), params)
}
