import { apiGetRH } from "./API_RH"

export const COLABORADORES = "colaboradores"
export const COLABORADORES_ALL = "colaboradores/all"
export const COLABORADORES_RH = `${COLABORADORES}/rh`
export const COLABORADORES_TEAM = (id) => `${COLABORADORES}/${id}/my_team`

/**
 * @param {apiGetRhParams} params - Parámetros para controlar la solicitud.
 */
export function colaboradoresGet(params = {}) {
	return apiGetRH(COLABORADORES, params)
}

/**
 * @param {apiGetRhParams} settings
 */
export function colaboradoresAllGet(settings = {}) {
	return apiGetRH(COLABORADORES_ALL, settings)
}

/**
 * @param {int} id
 * @param {apiGetRhParams} settings
 */
export function colaboradoresTeamGet(id, settings = {}) {
	return apiGetRH(COLABORADORES_TEAM(id), settings)
}
