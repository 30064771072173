import { useState } from 'react';
//const
import { DataItem } from '#common/DataDisplay';
import { COLOR_PRIMARY } from '#constants/Styles';
//mui
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { Grid, Typography } from '@mui/material/';
import IconButton from '@mui/material/IconButton';

import DialogForms from '../forms/DialogForms';
import IsGuatemala from '../forms/IsGuatemala';

function Personales({dataColaborador, edit=false}) {
    const {have}=IsGuatemala()
    let colorButton="#ffffff"
    const [form,setForm]=useState(false)

    let section=null
    let infonavit=null
    let cp=null
    if (!have){
        infonavit= <DataItem label={'N° INFONAVIT'} value={dataColaborador.no_infonavit} />
        section=<DataItem label={'Sección'} value={dataColaborador.seccion} />
        cp=<DataItem label={'CP'} value={dataColaborador.cp} size={1}/>
    }
    return (
        <Grid container >
            <Grid item xs={12} md={12} sx={{ p:1.5,background:COLOR_PRIMARY}} >
                <Grid container justifyContent={"space-between"} alignContent={"center"} textAlign={"center"} >
                    <Grid item >
                        <Typography sx={{textAlign:"center", fontSize:18,fontWeight:'bold',color: "#eee"}} >Datos Personales</Typography> 
                    </Grid>
                        <ButtonEdit onClick={()=>setForm("personales")} color={"#eee"} edit={edit}/>
                </Grid>
            </Grid>
            <DataItem label={'Fecha de Nacimiento'} value={dataColaborador.fecha_nacimiento}/>
            <DataItem label={'Lugar de Nacimiento'} value={dataColaborador.lugar_nacimiento}/>
            <DataItem label={'Estado Civil'} value={dataColaborador.estado_civil} />
            <DataItem label={'Sexo'} value={dataColaborador.sexo} />
            <DataItem label={have?"DPI / CUI":'CURP'} value={dataColaborador.curp} />
            <DataItem label={have?"NIT":'RFC'} value={dataColaborador.rfc} />
            <DataItem label={have?"Código Único De Identificación (CUI)":'Clave Elector'} value={dataColaborador.clave_elector} />
            {section}
            <DataItem label={'No Licencia'} value={dataColaborador.no_licencia} />
            <DataItem label={'Fecha Vencimiento'} value={dataColaborador.vencimiento} />
            <DataItem label={'Telefono'} value={dataColaborador.telefono} />
            <DataItem label={'Correo Personal'} value={dataColaborador.correo_personal} />
            <Grid item xs={12} md={12} sx={{ p:1.5,backgroundColor:COLOR_PRIMARY}} >
                <Grid container justifyContent={"space-between"}>
                    <Grid item >
                        <Typography sx={{fontSize:18,fontWeight:'bold',color: "#eee"}} >{have?"IGSS O CUI":"IMSS e Infonavit"}</Typography> 
                    </Grid>
                    <ButtonEdit onClick={()=>setForm("medicos")} color={colorButton} edit={edit}/>
                </Grid>
            </Grid>

            <DataItem label={have?"IGSS O CUI":'N° IMSS'} value={dataColaborador.no_imss} />
            <DataItem label={'Tipo Sanguineo'} value={dataColaborador.grupo_sanguineo} />
            <DataItem label={'Alergias'} value={dataColaborador.alergias} />
           {infonavit}
            <Grid item xs={12} md={12} sx={{ p:1.5,backgroundColor:COLOR_PRIMARY}} >
                <Grid container justifyContent={"space-between"}>
                    <Grid item >
                        <Typography sx={{fontSize:18,fontWeight:'bold',color: "#eee"}} >Domicilio</Typography> 
                    </Grid>
                            <ButtonEdit onClick={()=>setForm("address")} color={colorButton} edit={edit}/>
                </Grid>
            </Grid>
            
            <DataItem label={have? "Calle O Avenida":'Calle'} value={dataColaborador.domicilio}  size={4}/>
            <DataItem label={'Colonia'} value={dataColaborador.colonia} size={2}/>
            {cp}
            <DataItem label={have?"Zona":'Ciudad / Población'} value={dataColaborador.poblacion} size={2}/>
            <DataItem label={have?"Departamento":'Estado'} value={dataColaborador.estado}/>         
        <DialogForms open={form} setOpen={setForm} dataColaborador={dataColaborador}/>
        </Grid>
  )
}


function ButtonEdit({onClick,color,edit=false}) {
if (!edit)
    return null

return (
    <Grid item >
        <IconButton
            sx={{color:color}}
            onClick={onClick}
        >
            <ModeEditOutlineIcon/>
        </IconButton>
    </Grid>

    )
}


export default Personales