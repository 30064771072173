import { useMemo } from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

/**
 * @typedef {import("@mui/material").SelectProps} SelectProps
 */
/**
 * @typedef {Object} InputSelectExtendedProps
 * @property {string} [id] - ID del campo de selección.
 * @property {string} [label] - Etiqueta del campo de selección.
 * @property {Array} [data] - Datos para las opciones del campo de selección.
 * @property {string} [dataId] - Nombre de la propiedad que contiene el ID en los datos.
 * @property {string} [dataLabel] - Nombre de la propiedad que contiene la etiqueta en los datos.
 * @property {function} [setValue=()=>{}] - Función para establecer el valor seleccionado.
 * @property {function} [onChange=(event, obj)=>{}] - si es editada no se necesita setValue .
 * @property {string} [notOptionsText] - Texto que se muestra cuando no hay opciones.
 * @property {FormControl} [formControlProps] - Propiedades para el componente Form.
 * @property {InputLabel} [inputLabelProps] - Propiedades adicionales para el componente InputLabel.
 * @property {MenuItem} [menuItemProps] - Propiedades adicionales para el componente MenuItem.
 */
/**
 * @typedef {InputSelectExtendedProps & SelectProps} InputSelectProps
 */

/**
 * Muestra un desplegable con opciones.
 * Su valor base es es un string vacío.
 *
 * @component
 * @param {InputSelectProps} props - Propiedades del componente Select.
 *
 * @returns {} - El componente InputSelectGrid2.
 * @example
 * // Ejemplo de uso:
 * <InputSelect
 *   id="input-select"
 *   label="Seleccionar opción"
 * 	 dataId="id"
 * 	 dataLabel="label"
 *   data={[{ id: 1, label: "Opción 1" }, { id: 2, label: "Opción 2" }]}
 * 	 value={value}
 * 	 setValue={setValue}
 * />
 */
export const InputSelect = ({
	id = "InputSelect",
	label = "",
	data = [],
	dataId = null,
	dataLabel = null,
	value = null,
	setValue = () => {},
	notOptionsText = "No hay opciones.",
	onChange = (event, obj) => {
		setValue(event.target.value)
	},
	fullWidth = true,
	inputLabelProps,
	formControlProps,
	menuItemProps,
	...props
}) => {
	if (!Array.isArray(data)) {
		data = []
	}

	const notItems = useMemo(() => {
		if (data.length === 0)
			return (
				<MenuItem
					key={"noOptions"}
					disabled
					{...menuItemProps}
				>
					{notOptionsText}
				</MenuItem>
			)
		else return null
	}, [data.length, menuItemProps, notOptionsText])

	const valueSelect = useMemo(() => {
		if (dataId) return (dato) => dato[dataId]
		else return (dato) => dato
	}, [dataId])

	const valueLabel = useMemo(() => {
		if (dataLabel) return (dato) => dato[dataLabel]
		else return (dato) => dato
	}, [dataLabel])

	return (
		<FormControl
			{...{ fullWidth }}
			sx={{ m: 1 }}
			{...formControlProps}
		>
			<InputLabel
				id={id}
				{...inputLabelProps}
			>
				{label}
			</InputLabel>
			<Select
				id={id}
				label={label}
				labelId={`label${id}`}
				value={value}
				onChange={onChange}
				{...props}
			>
				{notItems}
				{data.map((option, index) => (
					<MenuItem
						key={label + index}
						value={valueSelect(option)}
						{...menuItemProps}
					>
						{valueLabel(option)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
