import React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import { COLOR_TARJETA, ESTADO_CURSOS } from "../../constants_capacitaciones"
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from "victory"
/**
 *
 * @param {array}  datos
 * @param {number}  size -Tamaño del Grid
 * @param {*}  XD -Dato a buscar de X
 * @param {*}  YD -Datos a buscar en Y
 * @param {*}  labelX -Texto extra por la parte de X
 * @param {*}  labelY -Texto extra por la parte de Y
 * @returns
 */
function Graficas({ datos, size = 3, XD, YD, labelX, labelY, labelBarra }) {
	return (
		<Grid xs={size}>
			{!!datos.length && (
				<VictoryChart
					height={400}
					//height={00}
					padding={{ top: 70, bottom: 110, left: 70, right: 0 }}
					domainPadding={{ x: [40, 31] }}
					//minDomain={{ y: 0 }}

					//</Grid>domain={{ y: [0, "auto"] }}
				>
					<VictoryAxis
						dependentAxis
						//tickCount={17}
						tickFormat={(tick) => {
							return tick.toFixed(0) > 1 ? tick.toFixed(0) : ""
						}}
						tickLabelComponent={
							<VictoryLabel
								//angle={-45}
								textAnchor="end"
							/>
						}
						label={labelY}
						style={{
							axisLabel: { padding: 35 },
						}}
					/>

					<VictoryAxis
						tickLabelComponent={
							<VictoryLabel
								angle={-45}
								textAnchor="end"
							/>
						}
					/>
					{/* <VictoryAxis dependentAxis /> */}
					<VictoryBar
						data={datos}
						x={XD}
						y={YD}
						labels={labelBarra}
						barRatio={datos.length === 1 ? 4.5 : 0.5}
						style={{
							data: {
								fill: ({ datum }) => {
									let color = datum.x % 2 === 0 ? "#000000" : "#c43a31"
									if (datum["xName"] === "Aprobados")
										color = COLOR_TARJETA(ESTADO_CURSOS["APROBADO"])
									if (datum["xName"] === "Por Aprobar")
										color = COLOR_TARJETA(ESTADO_CURSOS["REVISAR"])
									if (datum["xName"] === "Atrasado")
										color = COLOR_TARJETA(ESTADO_CURSOS["ATRAZADO"])
									if (datum["xName"] === "Cancelados")
										color = COLOR_TARJETA(ESTADO_CURSOS["CANCELADO"])
									if (datum["xName"] === "Rechazados")
										color = COLOR_TARJETA(ESTADO_CURSOS["RECHAZADO"])
									if (datum["xName"] === "Pendientes") color = COLOR_TARJETA()

									return color
								},
								stroke: ({ index }) =>
									+index % 2 === 0 ? "#000000" : "#c43a31",
								fillOpacity: 0.7,
								strokeWidth: 0,
							},
							labels: {
								fontSize: 10,
							},
						}}
					/>
				</VictoryChart>
			)}
		</Grid>
	)
}

export default Graficas
