import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import pageReducer from './pageSlice'
import colabReducer from './colabSlice'
import notificationSlice from './notificationSlice'
import actualizarComponete from './actualizar'

export default configureStore({
  reducer: {
    user: userReducer,
    page: pageReducer,
    colaborador: colabReducer,
    notification: notificationSlice,
    actualizar: actualizarComponete
  },
})