import { bajaPut } from "#api/Api/baja"
import { TERMINATION } from "#constants/StatusRequest "
import { TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import {
	ERROR_REASON_REJECTION,
	OPTION_REJECT,
	SEND_BUTTON,
} from "../../TextBaja"
import { UseDialogBajaContext } from "./DialogBajaContext"

export default function RejectTermination({ hidden = true }) {
	const { setButtonsDialog, baja, reload, closeDialog } = UseDialogBajaContext()
	const [message, setMessage] = useState("")

	const sendResponse = useCallback(() => {
		if (message === null || message.trim() === "") {
			toast.error(ERROR_REASON_REJECTION)
			return false
		}
		const data = {
			id: baja.id,
			termination_status: TERMINATION.RECHAZADA,
			reason_rejection: message,
		}

		const success = () => {
			reload()
			closeDialog()
		}
		bajaPut(baja.id, data, { success })
	}, [baja.id, closeDialog, message, reload])

	useEffect(() => {
		if (!hidden)
			setButtonsDialog([
				{
					label: SEND_BUTTON,
					onClick: sendResponse,
				},
			])
	}, [hidden, sendResponse, setButtonsDialog])

	if (hidden) return null
	return (
		<TextField
			label={OPTION_REJECT}
			variant="outlined"
			fullWidth
			multiline
			onChange={(event) => {
				setMessage(event.target.value)
			}}
		/>
	)
}
