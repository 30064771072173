
import { Button,  Dialog, DialogActions, DialogContent,  DialogTitle,  } from '@mui/material'
import { useState } from 'react';
import { useSelector } from "react-redux";


export const ModalConfirmacion = ({ open, setOpen, message, actionApi, perfilID }) => {
    const token = useSelector(state => state.user.token);
    const [loading,setLoading] = useState(false)

    const confirm = () => {
        setLoading(true)
        actionApi(token, perfilID).then(response => {
            window.location.reload();
        }).finally(()=> setLoading(false))
    }

    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth={true} >
            <DialogTitle sx={{ fontSize: 24, fontWeight: 'bold', color: "#555" }}>Confirmación</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => setOpen(false)}>Cancelar</Button>
                <Button disabled={loading} onClick={() => confirm()}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    )
}

export const ModalConfirmacion2 = ({ open, setOpen, message, action, loading }) => {
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth={true} >
            <DialogTitle sx={{ fontSize: 24, fontWeight: 'bold', color: "#555" }}>Confirmación</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => setOpen(false)}>Cancelar</Button>
                <Button disabled={loading} onClick={action()}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    )
}

export function DialogConfirm({ open, setOpen, message,disabledButtons ,accept = () => {} }) {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
		>
			<DialogTitle id="alert-dialog-title">{message}</DialogTitle>
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<Button disabled={disabledButtons} onClick={() => setOpen(false)}>Cancelar</Button>
				<Button
                disabled={disabledButtons}
					autoFocus
					onClick={accept}
				>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	)
}