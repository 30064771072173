import React from "react"
import { STATUS_LOAN } from "../../constants"
//Componentes
import { InputGrid } from "../../../../Common/Inputs"

//Material UI
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"

function DialogConfirm2({ setComentario, data, show }) {
	return show ? (
		<React.Fragment>
			<DialogContent>
				<DialogContentText
					style={{
						paddingBottom: "10px",
					}}
				>{`${data["mensaje"]}`}</DialogContentText>
				{data["status"] === STATUS_LOAN["REJECT"] && (
					<InputGrid
						label="Comentarios"
						setOption={setComentario}
						style={{ paddingTop: "10px" }}
					/>
				)}
			</DialogContent>
		</React.Fragment>
	) : (
		<React.Fragment />
	)
}

export default DialogConfirm2
