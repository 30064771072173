import { toast } from "react-toastify"
/**
 * Convierte un texto de texto en estilo Titulo
 * @description Convierte en mayúscula el primer carácter del texto y el resto en minúsculas
 * @export
 * @param {string} text
 * @returns {string} texto modificada
 */
export function textTitle(text) {
	const primeraLetra = text.charAt(0).toUpperCase()
	const restoCadena = text.slice(1).toLowerCase()
	return primeraLetra + restoCadena
}

/**
 * Alerta Error
 * @description Crear una alerta de error de tipo toast ademas el texto es modificado a un estándar
 * utilizando la fusión textTitle
 * @date 21/8/2023
 * @export
 * @param {string} text
 *
 */
export function toastError(text) {
	toast.error(textTitle(`${text}`))
}
