import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux" //Token
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import {
	OPTIONS_BENEFICIO,
	OPTIONS_PARENTEZCO,
} from "../../../assets/OptionsSelects"
import FormBase, { formDateFormat } from "../../Common/Forms"
import { DialogForm } from "./../../Common/Dialog"
import { apiPostColaboradorBeneficiario } from "../../../Services/ApiColaborador"
import dayjs from "dayjs"

const dataValidation = (value) => {
	let nowDate = new Date()
	let date
	if (typeof value === "string") date = dayjs(value, "DD/MM/YYYY")
	else date = value["$d"]
	let years = parseInt((nowDate - date) / (1000 * 60 * 60 * 24 * 365.25))
	return years >= 18 || "El beneficiario debe ser mayor de 18 años"
}
const DATA = [
	{
		field: "select",
		id: "beneficio",
		label: "Beneficio",
		dataID: "value",
		data: OPTIONS_BENEFICIO,
		registerParams: { required: { value: true } },
		propsG: { xs: 12 },
	},

	{ field: "divider", content: "Beneficiario" },
	{
		field: "input",
		id: "nombre",
		label: "Nombre",
		registerParams: {
			required: {
				value: true,
				message: "Se requiere nombre",
			},
		},
	},
	{
		field: "select",
		id: "parentezco",
		label: "Parentesco",
		dataID: "value",
		data: OPTIONS_PARENTEZCO,
		propsG: { xs: 3.5 },
		registerParams: { required: true },
	},
	{
		field: "input",
		id: "porcentaje",
		label: "Porcentaje 1% - 100%",
		type: "number",
		propsG: { xs: 3.5 },
		registerParams: {
			required: {
				value: true,
				message: "El porcentaje tiene que ser un numero entero ",
			},
		},
	},
	{
		field: "date",
		id: "fecha_nacimiento",
		label: "Fecha Nacimiento (+18)",
		propsG: { xs: 5 },
		registerParams: {
			required: {
				value: true,
				message: " ",
			},
			validate: dataValidation,
		},
	},
]
const DATA_EXTRA = [
	{ field: "divider", content: "Beneficiario 2" },
	{
		field: "input",
		id: "nombre2",
		label: "Nombre",
		registerParams: { required: true },
	},
	{
		field: "select",
		id: "parentezco2",
		label: "Parentesco",
		dataID: "value",
		data: OPTIONS_PARENTEZCO,
		propsG: { xs: 3.5 },
		registerParams: { required: true },
	},
	{
		field: "input",
		id: "porcentaje2",
		label: "Porcentaje 1% - 100%",
		type: "number",
		disabled: true,
		propsG: { xs: 3.5 },
		registerParams: {
			pattern: {
				value: /^[0-9]+$/,
				message: "Ingresa solo números.",
			},
			min: 0,
			max: 99,
		},
	},
	{
		field: "date",
		id: "fecha_nacimiento2",
		label: "Fecha Nacimiento (+18)",
		propsG: { xs: 5 },
		registerParams: {
			required: {
				value: true,
				message: " ",
			},
			validate: dataValidation,
		},
	},
]

export default function FormDataBeneficiario({
	idColaborador,
	open,
	setOpen,
	edit,
	actualizar,
}) {
	const token = useSelector((state) => state.user.token)
	const form = useForm({ defaultValues: edit })
	const { handleSubmit, reset, watch, setValue } = form
	const showPorcentaje = watch("porcentaje")
	const [button, setButton] = useState(false)
	const [campos, setCampos] = useState([])

	useEffect(() => {
		reset({
			nombre: "",
			beneficio: "",
			porcentaje: "100",
		})
		if (!!edit) {
			let data = {}
			edit.forEach((element, index) => {
				if (index > 0) {
					for (let e in element) {
						if (e !== "beneficio") data[e + 2] = element[e]
					}
				} else {
					data = element
				}
			})
			reset(data)
		}
	}, [edit, reset, open])

	useEffect(() => {
		let porcentaje = parseInt(showPorcentaje)

		if (porcentaje > 100) {
			setValue("porcentaje", 100)
			toast.error("El porcentaje no puede ser mayor a 100")
		} else if (porcentaje < 1) {
			setValue("porcentaje", 1)
			toast.error("El porcentaje no puede ser menor a 1")
		}

		if (porcentaje < 100 && porcentaje >= 1) {
			setValue("porcentaje2", 100 - showPorcentaje)
			setCampos([...DATA, ...DATA_EXTRA])
		} else {
			setCampos([...DATA])
		}
	}, [setValue, showPorcentaje])

	const submit = async (data) => {
		setButton(true)
		data.colaborador = idColaborador
		let response
		data.fecha_nacimiento = formDateFormat(data.fecha_nacimiento)
		if (!!data.fecha_nacimiento2)
			data.fecha_nacimiento2 = formDateFormat(data.fecha_nacimiento2)
		data["porcentaje"] = parseInt(data["porcentaje"])
		if (data["porcentaje"] !== 100) data["par"] = true
		response = await apiPostColaboradorBeneficiario(token, data)
		if (response.success) {
			actualizar()
			setOpen(false)
		}
		setButton(false)
	}

	return (
		<React.Fragment>
			<DialogForm
				title={edit ? "Editar Beneficiario" : "Registrar Beneficiario"}
				open={open}
				setOpen={setOpen}
				accept={handleSubmit(submit)}
				disabledButtons={button}
			>
				<FormBase
					fields={campos}
					useForm={form}
				/>
			</DialogForm>
		</React.Fragment>
	)
}
