import { apiDeleteRH, apiGetRH, apiPostRH, apiPutRH } from "#api/API_RH"

const VACANTES = "vacantes"
const CANDIDATO_DOCS = (candidato_id) => `candidatos/${candidato_id}/docs`
const CANDIDATO_HISTORY = (candidato_id) => `candidatos/${candidato_id}/history`
const CANDIDATO = (candidato_id) => `candidatos/${candidato_id}`

/**
 * @param {int} candidato_id
 * @param {any} data
 * @param {apiPutRhSettingsParams} settings
 * @returns {any}
 */
export const candidatoPut = (candidato_id, data, settings) => {
	apiPutRH(CANDIDATO(candidato_id), data, settings)
}

export const CandidatoDocsGet = (candidato_id, settings) => {
	apiGetRH(CANDIDATO_DOCS(candidato_id), settings)
}

export const candidatoHistoryGet = (candidato_id, settings) => {
	apiGetRH(CANDIDATO_HISTORY(candidato_id), settings)
}

/**
 *
 * @param {int} candidato_id
 * @param {any} data
 * @param {apiPutRhSettingsParams} settings
 */
export const candidatoDocsPost = async (candidato_id, data, settings) => {
	apiPostRH(CANDIDATO_DOCS(candidato_id), data, settings)
}
/**
 *
 * @param {int} candidato_id

 * @param {apiPutRhSettingsParams} settings
 */
export const candidatoDocsDelete = async (candidato_id, settings) => {
	apiDeleteRH(CANDIDATO_DOCS(candidato_id), settings)
}
// export const CandidatoHistorialGet = async (candidato_id, settings) => {
// 	apiGetRH(`candidatos/${candidato_id}/docs`, settings)

/**
 * Get de vacantes
 * @param {apiGetRhParams} settings
 */
export const vacantesGet = (settings) => {
	apiGetRH(VACANTES, settings)
}
