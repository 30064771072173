import { TERMINATION } from "#constants/StatusRequest "
import { Box, Button, Stack } from "@mui/material"
import { useState } from "react"

import ApproveTermination from "./ApproveTermination"
import { UseDialogBajaContext } from "./DialogBajaContext"
import RejectTermination from "./RejectTermination"

export default function ApprovalRH({ hidden = false }) {
	const { baja } = UseDialogBajaContext()
	const { termination_status_id } = baja
	const notShow = termination_status_id !== TERMINATION.SOLICITADA
	const [select, setSelect] = useState(null)

	if (hidden || notShow) return null
	return (
		<Box>
			<ButtonsSelected
				hidden={select !== null}
				onClick={setSelect}
			/>
			<ApproveTermination hidden={select !== true} />
			<RejectTermination hidden={select !== false} />
		</Box>
	)
}

function ButtonsSelected({ hidden = false, onClick = () => {} }) {
	if (hidden) return null
	return (
		<Stack
			direction={"row"}
			spacing={5}
			justifyContent={"center"}
		>
			<Button
				variant="contained"
				onClick={() => onClick(false)}
			>
				Rechazar
			</Button>
			<Button
				variant="contained"
				onClick={() => onClick(true)}
			>
				Aceptar
			</Button>
		</Stack>
	)
}
