import { apiPutRH } from "#api/API_RH"
const PROMOTION = "promotion"
const PROMOTION_DETAILS = (id) => `${PROMOTION}/details/${id}`
/**
 * @deprecated
 *
 * @param {int} id
 * @param {any} data
 * @param {apiPutRhSettingsParams} settings
 */
export function promotionDetailPut(id, data, settings) {
	apiPutRH(PROMOTION_DETAILS(id), data, settings)
}
