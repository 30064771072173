import { createSlice } from '@reduxjs/toolkit'
export const SCHEMA_PERSONALES_COLABORADOR = {
    colaborador_id: 0,
    apellido_paterno: '',
    apellido_materno: '',
    nombre: '',
    fecha_nacimiento:null,
    estado_nacimiento: '',
    municipio_nacimiento: '',
    lugar_nacimiento:'',
    estado_civil: '',
    sexo: '',
    curp:'',
    rfc:'',
    no_licencia:'',
    vencimiento: null,
    clave_elector:'',
    seccion:'',
    no_imss:'', 
    grupo_sanguineo:'',
    alergias:'',
    no_infonavit:'',
    tipo_credito_infonavit:'',
    porcentaje_infonavit:'',
    sucursal_nombre:'',
    foto_colaborador:null
} 

export const SCHEMA_DOMICILIO_COLABORADOR = {
    colaborador_id: 0,
    domicilio : '',
    colonia:'',
    poblacion: '',
    municipio_domicilio: '',
    estado:'',
    cp:'',
    telefono:'',
    correo_personal:'',
}
export const SCHEMA_LUBTRAC_COLABORADOR = {
    colaborador_id: 0,
    sucursal_id: 0,
    perfil_id: 0,
    no_colaborador: '',
    photo_colaborador:''
}

export const colabSlice = createSlice({
    name: "Colaborador",
    initialState:{
        personales: SCHEMA_PERSONALES_COLABORADOR,
        domicilio: SCHEMA_DOMICILIO_COLABORADOR,
        vehiculos: [],
        familiares:[],
        contactos:[],
        beneficiarios:[],
        grados_escolares:[],
        cursos_externos:[],
        idiomas:[],
        trabajos:[],
        trayectoria:[],
        info_lubtrac:SCHEMA_LUBTRAC_COLABORADOR
    },
    reducers:{
        savePersonal: (state, action) => {
            var payload = action.payload
            state.personales=  payload
            localStorage.setItem('personales', JSON.stringify(payload));
        },
        saveDomicilio: (state, action)=>{
            var payload = action.payload
            state.domicilio=  payload
            localStorage.setItem('domicilio', JSON.stringify(payload));
        },
        saveVehiculos: (state,action) => {
            var payload = action.payload
            state.vehiculos = payload
            localStorage.setItem('vehiculos',JSON.stringify(payload))
        },
        saveFamiliares: (state,action) => {
            var payload = action.payload
            state.familiares = payload
            localStorage.setItem('familiares',JSON.stringify(payload))
        },
        saveContactos: (state,action) => {
            var payload = action.payload
            state.contactos = payload
            localStorage.setItem('contactos',JSON.stringify(payload))
        },
        saveBeneficiarios: (state,action) => {
            var payload = action.payload
            state.beneficiarios = payload
            localStorage.setItem('beneficiarios',JSON.stringify(payload))
        },
        saveGrados: (state,action) => {
            var payload = action.payload
            state.grados_escolares = payload
            localStorage.setItem('grados_escolares',JSON.stringify(payload))
        },
        saveCursos: (state,action) => {
            var payload = action.payload
            state.cursos_externos = payload
            localStorage.setItem('cursos_externos',JSON.stringify(payload))
        },
        saveIdiomas: (state,action) => {
            var payload = action.payload
            state.idiomas = payload
            localStorage.setItem('idiomas',JSON.stringify(payload))
        },
        saveTrabajos: (state,action) => {
            var payload = action.payload
            state.trabajos = payload
            localStorage.setItem('trabajos',JSON.stringify(payload))
        },
        saveTrayectoria: (state,action) => {
            var payload = action.payload
            state.trayectoria = payload
            localStorage.setItem('trayectoria',JSON.stringify(payload))
        },
        saveInfoLubtrac: (state,action) => {
            var payload = action.payload
            state.info_lubtrac = payload
            localStorage.setItem('info_lubtrac',JSON.stringify(payload))
        },

    }
})


export const { savePersonal, saveDomicilio, saveVehiculos } = colabSlice.actions

export default colabSlice.reducer