import React from "react"
import { useSelector } from "react-redux" //Token
import { TextGrid } from "../../Common/Texto"
import { getApiCursos } from "../../../Services/Api_cursos"
import TarjetaCurso from "../TarjetaCurso"
import { useState, useEffect } from "react"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { SkeletonSquareGrid } from "../../Common/Skeleton"
import { InputSelect } from "../../Common/Inputs"
import { hasPermission } from "../../Common/Funciones"
import { PERFILES } from "../../../Constants/Strings"

export default function VistaPlanAnual() {
	const token = useSelector((state) => state.user.token)
	const [db_cursos, set_dbCurso] = useState(null)
	const [skeleton1, setSkeleton1] = useState(false)
	//Data Select
	const [areas, setAreas] = useState([])
	const [year, setYear] = useState([])
	//opciones
	const [areasOption, setAreasOption] = useState(0)
	const [yearOption, setYearOption] = useState(new Date().getFullYear())
	let puesto = useSelector((state) => state.user.profiles)
	let perfil = hasPermission(puesto, PERFILES["RH"])

	//Crean la información para los inputs
	useEffect(() => {
		let filtro = {
			idColaborador: "",
		}

		if (perfil) filtro = {}

		getApiCursos("area", token, setAreas, filtro, setSkeleton1).then(
			(response) => {
				let areas = response.data
				if (areas.length) setAreasOption(areas[0].id)
			}
		)

		let years = []
		for (let index = 2023; index <= new Date().getFullYear() + 1; index++) {
			years.push({ año: index })
		}
		setYear(years)
	}, [perfil, token])

	//Trae los cursos del plan anual.
	useEffect(() => {
		set_dbCurso(null)
		getApiCursos("planAnual", token, set_dbCurso, {
			area: areasOption,
			year: yearOption,
		})
	}, [token, areasOption, yearOption])

	let xsAll = 12
	let mdAll = 6
	let xlAll = 4

	if (!skeleton1) return <SkeletonSquareGrid height={"80vh"} />

	if (!areas.length)
		return (
			<Grid
				container
				spacing={2}
			>
				<TextGrid
					allSize={12}
					label="No Tienes departamentos a tu cargo"
				/>
			</Grid>
		)

	return (
		<React.Fragment>
			<Grid
				container
				spacing={2}
			>
				<Grid md={12}>
					<Grid
						container
						spacing={1}
						justifyContent={"center"}
					>
						<InputSelect
							label="Areas "
							sizeAll={4}
							data={areas}
							dataLabel="description"
							dataID="id"
							disabled={areas.length <= 1}
							option={areasOption}
							setOption={setAreasOption}
						/>
						<InputSelect
							data={year}
							dataLabel="año"
							dataID="año"
							sizeAll={4}
							label="Año"
							option={yearOption}
							setOption={setYearOption}
						/>
					</Grid>
				</Grid>

				{db_cursos ? (
					db_cursos["cursos_departamento"].length ? (
						db_cursos["cursos_departamento"].map((curso, index) => (
							<Grid
								xs={xsAll}
								md={mdAll}
								xl={xlAll}
								key={`cursosA${index}`}
							>
								<TarjetaCurso
									db_cursoArea={curso}
									db_cursos={db_cursos["cursos"][index]}
									filtro={"planAnual"}
								/>
							</Grid>
						))
					) : (
						<TextGrid
							allSize={12}
							label="No Hay Plan Anual"
						/>
					)
				) : areas.length ? (
					<SkeletonSquareGrid
						quantity={12}
						propsG={{ xs: xsAll, md: mdAll, xl: xlAll }}
					/>
				) : (
					<React.Fragment>
						<TextGrid label="No Hay Plan Anual" />
					</React.Fragment>
				)}
			</Grid>
		</React.Fragment>
	)
}
