import { candidatoHistoryGet } from "#api/Api/requerimientos"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CreateIcon from "@mui/icons-material/Create"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1"
import Timeline from "@mui/lab/Timeline"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineDot from "@mui/lab/TimelineDot"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const ModalHistorialCandidato = ({ open, setOpen, candidato }) => {
	const token = useSelector((state) => state.user.token)
	const [historial, setHistorial] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (candidato == null || !open) return
		setLoading(true)
		const success = (data) => {
			setHistorial(data)
			setLoading(false)
		}
		candidatoHistoryGet(candidato.id, { success })
	}, [candidato, open, token])

	if (!open || candidato == null) return

	let contenido = []

	if (loading) contenido = <LoadingHistory />
	else
		contenido = (
			<Timeline position="left">
				{historial.map((log, index) => {
					let action = log.accion
					let Icon = null
					let color = "primary"
					switch (action) {
						case "Nuevo Candidato":
							Icon = CreateIcon
							break
						case "Candidato Aprobado":
							color = "success"
							Icon = CheckCircleIcon
							break
						case "Descartado":
							color = "error"
							Icon = CancelIcon
							break
						case "nuevo":
							Icon = PersonAddAlt1Icon
							break
						case "Entrevistado":
							color = "success"
							Icon = HowToRegIcon
							break
						case "Candidato Final":
							color = "success"
							Icon = EmojiEventsIcon
							break
						case "Se agenda la entrevista":
							Icon = CalendarMonthIcon
							break
						default:
							Icon = AutoStoriesIcon
					}

					return (
						<TimelineItem key={"hist" + index}>
							<TimelineOppositeContent
								sx={{ m: "auto 0" }}
								variant="body2"
								color="text.secondary"
							>
								{log.fecha}
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineConnector />
								<TimelineDot {...{ color }}>
									<Icon />
								</TimelineDot>
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent sx={{ py: "12px", px: 2 }}>
								<Typography
									variant="h6"
									component="span"
									fontWeight={"bolder"}
									fontSize={18}
								>
									{log.name}
								</Typography>
								<Typography> {log.descripcion} </Typography>
							</TimelineContent>
						</TimelineItem>
					)
				})}
			</Timeline>
		)
	return (
		<Dialog
			open={open}
			maxWidth={"md"}
			fullWidth={true}
			onClose={() => setOpen(false)}
		>
			<DialogTitle sx={{ fontSize: 24, fontWeight: "bold", color: "#555" }}>
				Historial de {candidato.nombre}{" "}
			</DialogTitle>
			<DialogContent>
				<Grid
					container
					sx={{ mt: 0 }}
					spacing={3}
				>
					{contenido}
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalHistorialCandidato

function LoadingHistory() {
	let radio = 60
	return (
		<Stack
			width={"100%"}
			direction={"row"}
			spacing={2}
			justifyContent={"center"}
			alignContent={"center"}
			alignItems={"center"}
			padding={3}
		>
			<Skeleton
				variant="rounded"
				width={"100%"}
				height={70}
			/>
			<Skeleton
				variant="circular"
				width={radio}
				height={radio}
			/>
			<Skeleton
				variant="rounded"
				width={"100%"}
				height={30}
			/>
		</Stack>
	)
}
