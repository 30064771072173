import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import Stack from "@mui/material/Stack"
import Switch from "@mui/material/Switch"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import React, { useEffect, useState } from "react"
import { useFondoContext } from "../useContextFondo"
import { useFondoFijo } from "../useFondoFijo"
import { alpha, styled } from "@mui/material/styles"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../../Constants/Styles"

export default function DialogVoBoRH({ solicitud }) {
	const { sendData, setSendData, setOpenDialogPDF } = useFondoContext()
	const [selected, setSelected] = useState({})
	const [checked, setChecked] = useState(false)
	let isCancel = solicitud.quantity === 0
	let isDecrement = solicitud.quantity < solicitud.quantity_previous
	const [disabledChecked, setDisabledChecked] = useState(true)
	//traer los datos
	const { fondoFijo: cuenta, loading } = useFondoFijo({
		params: { cuenta: true, colaborador: solicitud?.colaborador },
	})

	const pdf_url = solicitud?.file_signed
	//validar si existen los datos
	useEffect(() => {
		if (cuenta?.banco) {
			setDisabledChecked(false)
			setChecked(false)
			setSelected(cuenta)
		} else {
			setDisabledChecked(true)
			setChecked(true)
		}
	}, [cuenta])
	//datos preparados para mandar
	useEffect(() => {
		setSendData({ ...sendData, ...selected, editar_banca: checked })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected, checked])

	const handleChange = (event) => {
		setChecked(event.target.checked)
	}
	const onChangeInput = (event) => {
		setSelected({ ...selected, [event.target.id]: event.target.value })
	}

	if (loading || isCancel) return null

	if (isDecrement)
		return (
			<Box>
				<Stack
					container
					paddingTop={1}
					spacing={2}
				>
					<Button
						startIcon={<RemoveRedEyeIcon />}
						variant="contained"
						fullWidth
						sx={{ backgroundColor: PRIMARY_COLOR }}
						onClick={() => {
							setOpenDialogPDF({ open: true, url: pdf_url })
						}}
					>
						ver documento
					</Button>
				</Stack>
			</Box>
		)
	const PinkSwitch = styled(Switch)(({ theme }) => ({
		"& .MuiSwitch-switchBase.Mui-checked": {
			color: PRIMARY_COLOR,
			"&:hover": {
				backgroundColor: alpha(
					PRIMARY_COLOR,
					theme.palette.action.hoverOpacity
				),
			},
		},
		"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
			backgroundColor: PRIMARY_COLOR,
		},
	}))

	return (
		<Box>
			<Stack
				container
				paddingTop={1}
				spacing={2}
			>
				<Button
					startIcon={<RemoveRedEyeIcon />}
					variant="contained"
					fullWidth
					sx={{ backgroundColor: PRIMARY_COLOR }}
					onClick={() => {
						setOpenDialogPDF({ open: true, url: pdf_url })
					}}
				>
					ver documento
				</Button>
				<Typography tipo="h6">Datos Bancarios</Typography>
				<FormControlLabel
					control={
						<PinkSwitch
							checked={checked}
							onChange={handleChange}
							disabled={disabledChecked}
						/>
					}
					label="Editar datos bancarios"
				/>
				<TextField
					label="Banco"
					id="banco"
					value={selected?.banco}
					allSize={12}
					disabled={!checked}
					onChange={onChangeInput}
				/>
				<TextField
					label="Numero de cuenta"
					id="cuenta"
					type="number"
					value={selected?.cuenta}
					disabled={!checked}
					onChange={onChangeInput}
				/>
				<TextField
					id="clabe"
					label="Clabe interbancaria "
					type="number"
					value={selected?.clabe}
					disabled={!checked}
					onChange={onChangeInput}
				/>
			</Stack>
		</Box>
	)
}
