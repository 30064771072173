import { MasterDialog } from "#common/Dialog/Dialog"
import { useCallback, useMemo, useState } from "react"
import {
	FormularioNewVacante,
	validation,
} from "./FormularioVacante/FormularioVacante"
import { vacantesPost } from "#api/Api_Vacantes"

export default function DialogNewVacante({ open, setOpen, reload }) {
	const [send, setSend] = useState({})

	const handleSend = useCallback(() => {
		if (!validation(send)) {
			return
		}
		const success = (response) => {
			setOpen(false)
			setSend({})
			reload((old) => !old)
		}
		vacantesPost({ ...send.vacante, ...send.vacante_details }, { success })
	}, [reload, send, setOpen])

	const buttons = useMemo(
		() => [{ label: "Aceptar", onClick: handleSend }],
		[handleSend],
	)

	return (
		<MasterDialog {...{ open, setOpen, maxWidth: "xl", buttons }}>
			<FormularioNewVacante
				data={send}
				setData={setSend}
			/>
		</MasterDialog>
	)
}
