import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { useMemo } from "react"
/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {Array} props.options
 * @param {useState} props.value
 * @param {useState} props.setValue
 * @param {string} props.valueId
 * @param {boolean} props.disabled
 * @param {string} props.valueLabel
 *
 * @returns
 */
export const InputAutocomplete = ({
	label,
	options,
	value,
	setValue,
	valueId,
	disabled,
	valueLabel = "label",
}) => {
	const selectedValues = useMemo(() => {
		if (!value) return ""
		if (valueId) return options.filter((v) => v[valueId] === value)?.pop()
		else return value
	}, [options, value, valueId])

	const onChangeValues = useMemo(() => {
		return (event, newValue) => {
			if (newValue == null) setValue("")
			else if (valueId) setValue(newValue[valueId])
			else setValue(newValue)
		}
	}, [setValue, valueId])

	return (
		<Autocomplete
			getOptionLabel={(option) => {
				if (!option) return ""
				return `${option[valueLabel]}`
			}}
			fullWidth
			id="InputAutocomplete"
			options={options}
			value={selectedValues}
			onChange={onChangeValues}
			disabled
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
				/>
			)}
			{...{ disabled }}
		/>
	)
}
