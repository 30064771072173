import React from "react"
import { STATUS_LOAN } from "../constants"
import { PERFILES } from "../../../../Constants/Strings"
//Funciones
import { textButton } from "./TextosPrestamos"
import { useSelector } from "react-redux" //Token
import { putPrestamos, getLinkPDF } from "../../../../Services/Api_prestamos"
import { ButtonIcon } from "../../../Common/Buttons"
import { hasPermission } from "../../../Common/Funciones"

//ICONS
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import BeenhereTwoToneIcon from "@mui/icons-material/BeenhereTwoTone"
import HowToRegTwoToneIcon from "@mui/icons-material/HowToRegTwoTone"
import NoteAddIcon from "@mui/icons-material/NoteAdd"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff"
import IconButton from "@mui/material/IconButton"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import EditIcon from "@mui/icons-material/Edit"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"

//Material UI
import Menu from "@mui/material/Menu"
import Stack from "@mui/material/Stack"

import Tooltip from "@mui/material/Tooltip"

//Componentes
import DialogBase from "./prestamosButtonsDialog.jsx/DialogBase"
import { DialogConfirm } from "../../../Common/ModalConfirmacion"
/**
 *
 * @param {*} ID - ID de la solicitud
 * @param {*} setActualizar - Función para actualizar la tabla
 * @param {*} actualizar - Estado de la tabla
 * @returns botones para aprobar o rechazar una solicitud
 */
function PrestamosButtons({
	ID,
	data = {},
	user = "",
	filtro,
	actualizar = false,
	setActualizar = () => {},
}) {
	const TOKEN = useSelector((state) => state.user.token)
	let puestos = useSelector((state) => state.user.profiles)
	const [open, setOpen] = React.useState(false)
	const [confirm, setConfirm] = React.useState(false)
	const propiedades = Object.keys(STATUS_LOAN)

	const functionButton = () =>
		setOpen({
			status: buttons[buttonSelect]["status"],
			mensaje: buttons[buttonSelect]["mensaje"],
		})

	const hasPermissionButtons = (permisos = []) =>
		hasPermission(puestos, [PERFILES["RH"], ...permisos])

	const buttons = {
		PROCESS: {
			icon: <HowToRegTwoToneIcon />,
			status: STATUS_LOAN["VALIDATE_RH"],
			permiso: hasPermissionButtons(),
			operation: () => setOpen({ status: STATUS_LOAN["PROCESS"] }),
		},
		VALIDATE_RH: {
			icon: <BeenhereTwoToneIcon />,
			status: STATUS_LOAN["VALIDATE_BOSS"],
			permiso: hasPermissionButtons([PERFILES["GERENTE"]]),
			operation: functionButton,
		},
		VALIDATE_BOSS: {
			icon: <NoteAddIcon />,
			status: STATUS_LOAN["AWAITED_DOCUMENT"],
			permiso: hasPermissionButtons(),
			operation: functionButton,
		},
		AWAITED_DOCUMENT: {
			icon: <CloudUploadIcon />,
			status: STATUS_LOAN["PASS_PAY"],
			permiso: true,
			operation: functionButton,
		},
		PASS_PAY: {
			icon: <CheckCircleIcon />,
			status: STATUS_LOAN["PAY"],
			permiso: hasPermissionButtons(),
			operation: functionButton,
		},
		PAY: {
			icon: <MonetizationOnIcon />,
			status: STATUS_LOAN["PAY_BACK"],
			permiso: hasPermissionButtons([PERFILES["PAGOS"]]),
			operation: () => {},
		},
		PAY_BACK: {
			icon: <PriceCheckIcon />,
			status: STATUS_LOAN["FINISHED"],
			permiso: hasPermissionButtons(),
			operation: functionButton,
		},
		DOCUMENT_FAIL: {
			icon: <ContentPasteOffIcon />,
			status: 1,
			permiso: hasPermissionButtons(),
			operation: functionButton,
		},
		FINISHED: {
			icon: "",
			status: 1,
			permiso: false,
			operation: () => {},
		},
		REJECT: {
			icon: "",
			status: 1,
			permiso: false,
			operation: () => {},
		},
	}

	let status = data["status_id"]
	let documentoRH = data["documentoRH"]
	let documento = data["documento"]
	let documento_pago = data["documento_pago"]
	let showCancelar =
		status !== STATUS_LOAN["FINISHED"] &&
		status !== STATUS_LOAN["PAY_BACK"] &&
		status !== STATUS_LOAN["PAY"]

	let invertirStatus = {}
	for (const propiedad of propiedades) {
		invertirStatus[STATUS_LOAN[propiedad]] = propiedad
	}
	let buttonSelect = invertirStatus[status]

	let show =
		status !== STATUS_LOAN["VALIDATE_BOSS"] &&
		status !== STATUS_LOAN["AWAITED_DOCUMENT"]

	buttons[buttonSelect] = {
		...buttons[buttonSelect],
		...textButton[buttonSelect],
	}
	let { RECHAZAR } = textButton
	let buttonPermit = buttons[buttonSelect]["permiso"]

	const handleDownload = async (url = "") => {
		let urlNew = await getLinkPDF(TOKEN, url)
		window.open(urlNew.data, "_blank")
	}
	return (
		<React.Fragment>
			<Stack
				direction={{ xs: "column", sm: "row" }}
				spacing={{ xs: 1 }}
				justifyContent={"start "}
			>
				<LongMenu
					documento={documento}
					documentoRH={documentoRH}
					documento_pago={documento_pago}
					handleDownload={handleDownload}
					setOpen={setOpen}
					user={user}
					status={status}
				/>
				{filtro["status"] !== STATUS_LOAN["REJECT"] &&
					filtro["status"] !== 0 && (
						<React.Fragment>
							<ButtonIcon
								title="Calculo"
								show={status === STATUS_LOAN["AWAITED_DOCUMENT"]}
								icon={<CloudDownloadIcon />}
								sx={{ color: "blue" }}
								onClick={() => handleDownload(documentoRH)}
							/>
							<ButtonIcon
								title="Calculo firmado"
								show={status === STATUS_LOAN["PASS_PAY"]}
								icon={<CloudDownloadIcon />}
								onClick={() => handleDownload(documento)}
							/>
							<ButtonIcon
								title="Pago"
								show={status === STATUS_LOAN["PASS_PAY" && !!documento_pago]}
								icon={<CloudDownloadIcon />}
								sx={{ color: "#61C9F1" }}
								onClick={() => handleDownload(documento_pago)}
							/>
							<ButtonIcon
								//Botón para bajar turno
								show={
									show && buttonPermit && status === STATUS_LOAN["PASS_PAY"]
								}
								icon={<KeyboardDoubleArrowDownIcon />}
								title={textButton.ROW_DOWN.tooltip}
								onClick={() => setConfirm(true)}
								// sx={{ color: "red" }}
							/>
							<ButtonIcon
								show={
									show && buttonPermit && status === STATUS_LOAN["PASS_PAY"]
								}
								icon={<ContentPasteOffIcon />}
								title={textButton["DOCUMENT_FAIL"]["tooltip"]}
								onClick={() =>
									setOpen({
										status: -1,
										mensaje: textButton["DOCUMENT_FAIL"]["mensaje"],
									})
								}
								sx={{ color: "red" }}
							/>

							<ButtonUrl
								show={!show && buttonPermit}
								id={ID}
								icon={buttons[buttonSelect]["icon"]}
								status={buttons[buttonSelect]["status"]}
								token={TOKEN}
								actualizar={actualizar}
								setActualizar={setActualizar}
							/>
							<ButtonIcon
								show={
									hasPermissionButtons() &&
									status !== STATUS_LOAN["FINISHED"] &&
									status !== STATUS_LOAN["PAY_BACK"] &&
									status !== STATUS_LOAN["PAY"] &&
									status !== STATUS_LOAN["REJECT"]
								}
								title="Editar"
								icon={<EditIcon />}
								sx={{ color: "green" }}
								onClick={() =>
									setOpen({
										status: "editMethodPay",
									})
								}
							/>
							<ButtonIcon
								title={buttons[buttonSelect]["tooltip"]}
								show={show && buttonPermit}
								icon={buttons[buttonSelect]["icon"]}
								onClick={buttons[buttonSelect]["operation"]}
								sx={{ color: "green" }}
							/>

							<ButtonIcon
								show={showCancelar && PERFILES["COLABORADOR"] !== user}
								icon={<CancelIcon />}
								title={RECHAZAR["tooltip"]}
								onClick={() =>
									setOpen({
										status: STATUS_LOAN["REJECT"],
										mensaje: RECHAZAR["mensaje"],
									})
								}
								sx={{ color: "red" }}
							/>
						</React.Fragment>
					)}
			</Stack>
			<DialogConfirm
				open={confirm}
				setOpen={setConfirm}
				message={textButton.ROW_DOWN.mensaje}
				accept={async () => {
					setConfirm(false)
					let response = await putPrestamos(
						TOKEN,
						{},
						{ id: ID, turnoDown: "" }
					)
					if (response["success"]) setActualizar(!actualizar)
				}}
			/>
			<DialogBase
				TOKEN={TOKEN}
				ID={ID}
				cantidad={data["cantidad"]}
				method_pay_id={data["metodo_pago_id"]}
				open={open}
				setOpen={setOpen}
				actualizar={actualizar}
				setActualizar={setActualizar}
			/>
		</React.Fragment>
	)
}

export default PrestamosButtons

/**
 * Description placeholder
 *
 * @param {*} id
 * @param {*} icon
 * @param {string} [color="green"]
 * @param {*} status
 * @param {*} token
 * @param {*} actualizar
 * @param {() => void} [setActualizar=() => {}]
 * @param {boolean} [show=true]
 */
function ButtonUrl({
	id,
	icon,
	color = "green",
	status,
	token,
	actualizar,
	setActualizar = () => {},
	show = true,
}) {
	const handleFileUpload = (event) => {
		const file = event.target.files[0]
		var formData = new FormData()
		formData.append("pdf", file)
		formData.append("status", status)
		putPrestamos(
			token,
			formData,
			{ id: id, status: status },
			actualizar,
			setActualizar
		)
	}
	if (show)
		return (
			<Tooltip title="Subir PDF">
				<div>
					<input
						accept=".pdf"
						style={{ display: "none" }}
						id={`file-input${id}`}
						type="file"
						onChange={handleFileUpload}
					/>
					<label htmlFor={`file-input${id}`}>
						<IconButton
							sx={{ color: color }}
							component="span"
						>
							{icon}
						</IconButton>
					</label>
				</div>
			</Tooltip>
		)
	else return <React.Fragment></React.Fragment>
}

export function LongMenu({
	handleDownload,
	documento,
	documentoRH,
	documento_pago,
	setOpen,
	user,
	status,
	...props
}) {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	let mostrar =
		(PERFILES["ADMIN"] === user || PERFILES["RH"] === user) &&
		status !== 1 &&
		status !== 2 &&
		status !== 3 &&
		status !== 4 &&
		status !== 5 &&
		status !== 6

	return mostrar ? (
		<div>
			<ButtonIcon
				show={!!documento && !!documentoRH && !!documento_pago}
				title="Archivos"
				icon={<MoreVertIcon />}
				aria-label="more"
				id="long-button"
				aria-controls={open ? "long-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			/>

			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<ButtonIcon
					title="Calculo"
					show={!!documentoRH}
					icon={<CloudDownloadIcon />}
					sx={{ color: "blue" }}
					onClick={() => handleDownload(documentoRH)}
				/>
				<ButtonIcon
					title="Calculo firmado"
					show={!!documento}
					icon={<CloudDownloadIcon />}
					//sx={{ color: "blue" }}
					onClick={() => handleDownload(documento)}
				/>
				<ButtonIcon
					title="Pago"
					show={!!documento_pago}
					icon={<CloudDownloadIcon />}
					sx={{ color: "#61C9F1" }}
					onClick={() => handleDownload(documento_pago)}
				/>
			</Menu>
		</div>
	) : (
		<React.Fragment></React.Fragment>
	)
}
