import Grid from "@mui/material/Unstable_Grid2"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { toast } from "react-toastify"

/**
 *
 * @param {*}label- Texto Botón
 * @param {*}size - Tamaño Boton 3,
 * @param {*}sizeB - Tamaño interno boton 1,
 * @param {*}disabled - Desabilitar boton  false,
 * @param {*}propocito - Funcion a Ejecutar
 * @returns
 */
export function Boton({
	label = "Soy Un Boton",
	sx = {},
	size = 3,
	xs = 3,
	sizeB = 2,
	disabled = false,
	propocito = () => {
		toast(
			<Typography
				textAlign={"center"}
				variant={"h1"}
			>
				🐺
			</Typography>
		)
	}
}) {
	return (
		<Grid
			container
			sx={sx}
			xs={xs}
			md={size}
			justifyContent="center"
			spacing={1}
		>
			<Grid
				xs={sizeB * 2}
				md={sizeB}
			>
				<Button
					disabled={disabled}
					fullWidth
					variant="contained"
					size="medium"
					onClick={propocito}
				>
					{label}
				</Button>
			</Grid>
		</Grid>
	)
}

/**
 *
 * @param {*} label- Texto del Typography
 * @param {*} size - Tamaño del Grid por defecto 3
 * @param {*} tipo - "h4"
 * @returns
 */
export function Texto({ label = "tu texto", size = 3, tipo = "h4" }) {
	return (
		<Grid
			xs={size}
			//style={{ background: "#C4C4C4" }}
		>
			<Typography
				textAlign={"center"}
				variant={tipo}
			>
				{label}
			</Typography>
		</Grid>
	)
}

/**
 *
 * @param {object} param0
 * @param {*} label - Texto
 * @param {*} size - Tamaño
 * @param {*} tipo - Tipo de Input,
 * @param {*} max - Valor Maximo
 * @param {*} min - Valor Minimo
 * @param {*} funcion - () => {},
 * @param {*} error - cambia el color cuando hay error
 * @returns
 */
export function Input({
	label = "soy tu input",
	size = 3,
	tipo = "text",
	max = 1000,
	min = 0,
	funcion = () => {},
	error
}) {
	return (
		<Grid
			xs={size * 2}
			md={size}
		>
			<TextField
				autoComplete="off"
				error={error}
				id="outlined-basic"
				type={tipo}
				label={label}
				variant="outlined"
				onChange={funcion}
				fullWidth
				InputProps={{
					inputProps: {
						min: min,
						max: max
					}
				}}
				inputProps={{
					min: 0,
					max: 100
				}}
			/>
		</Grid>
	)
}
