import {
	apiDeleteGeneral,
	apiPostGeneral,
	apiPutGeneral,
	getApiGeneralV2,
} from "./Api_Call"
import { apiGetRH, apiPutRH } from "./API_RH"
const URL_API = process.env.REACT_APP_SERVER_URL + "rh/promotion"

/**
 * @typedef {'COLABORADOR_EDIT'|} URL_COLABORADOR
 */
const PROMOTION = `${URL_API}`
const PROMOTION_DETAIL = `${URL_API}/detail`
const DEPENDENT = `${URL_API}/colaborador`
const PERFIL_PUESTO = `${URL_API}/perfil_puesto`
const HISTORY = `${URL_API}/history`
// const DEPENDENT2 = `${process.env.REACT_APP_SERVER_URL}rh/cursos/colaborador/statistics`

//Promociones
/**
 * Realiza una solicitud GET a la API para obtener datos de dependientes.
 *
 * @param {Object} params - Parámetros para la solicitud.
 * @param {string} [params.token=""] - Token de autenticación para la solicitud.
 * @param {Function} [params.setDB=() => {}] - Función para actualizar la base de datos después de recibir los datos.
 * @param {Object} [params.params={}] - Parámetros adicionales para la solicitud GET.
 * @param {AbortController|null} [params.controller=null] - Controlador de aborto para cancelar la solicitud si es necesario.
 * @returns {Promise} Retorna una promesa que se resuelve con los datos obtenidos de la API.
 */

export function apiPromotionGet(settings) {
	apiGetRH("promotion", settings)
}
export function apiGetPromotion(params) {
	return getApiGeneralV2({ url: PROMOTION, ...params })
}
export function apiPostPromotion(token, data, params, message) {
	return apiPostGeneral(PROMOTION, token, data, params, message)
}
export function apiPutPromotion(token, data, params, message) {
	return apiPutGeneral(PROMOTION, token, data, { params }, message)
}
export function apiPromotionPut(data, settings) {
	return apiPutRH("promotion", data, settings)
}
export function apiDeletePromotion(token, params) {
	return apiDeleteGeneral(PROMOTION, token, params)
}

//Promociones
/**
 * Realiza una solicitud GET a la API para obtener datos de dependientes.
 *
 * @param {Object} params - Parámetros para la solicitud.
 * @param {string} [params.token=""] - Token de autenticación para la solicitud.
 * @param {Function} [params.setDB=() => {}] - Función para actualizar la base de datos después de recibir los datos.
 * @param {Object} [params.params={}] - Parámetros adicionales para la solicitud GET.
 * @param {AbortController|null} [params.controller=null] - Controlador de aborto para cancelar la solicitud si es necesario.
 * @returns {Promise} Retorna una promesa que se resuelve con los datos obtenidos de la API.
 */
export function apiGetDependent(params) {
	return getApiGeneralV2({ url: DEPENDENT, ...params })
}

export function apiGetPerfilPuesto(params) {
	return getApiGeneralV2({ url: PERFIL_PUESTO, ...params })
}
export function apiPromotionDetailGet(settings) {
	apiGetRH("promotion/detail", settings)
}

export function apiGetPromotionDetail(params) {
	return getApiGeneralV2({ url: PROMOTION_DETAIL, ...params })
}
export function apiGetPromotionHistory(params) {
	return getApiGeneralV2({ url: HISTORY, ...params })
}
// export function apiGetPerfilPuesto(params) {
// 	return getApiGeneralV2({ url: process.env.REACT_APP_SERVER_URL + "rh/perfiles", ...params })
// }
