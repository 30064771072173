import { cursoPutApi, cursosLinkApi } from "#api/Api_cursos"
import { ButtonIconFile } from "#common/Buttons"
import ButtonIcon from "#common/buttons/ButtonIcon"
import { HasPermission } from "#common/Funciones"
import { PERFILES } from "#constants/Strings"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import NotInterestedRoundedIcon from "@mui/icons-material/NotInterestedRounded"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp"
import { Stack } from "@mui/material"
import { ESTADO_CURSOS } from "Components/Capacitaciones/constants_capacitaciones"
import { useMemo, useState } from "react"
import Confirmation from "./Confirm"
import { useCursoContext } from "Components/Capacitaciones/CursoContext"
// import { PERFILES } from "../../Constants/Strings"
// import Confirmation from "./componentes/TarjetaCurso/Confirm"
// import { ESTADO_CURSOS } from "./constants_capacitaciones"

const getUrlSing = (url, success) => {
	cursosLinkApi({
		success,
		params: { url },
	})
}
const handleDownload = (url) => {
	getUrlSing(url, (link) => {
		window.open(link, "_blank")
	})
}
const handleFileUpload = (id, event, reload) => {
	const file = event.target.files[0]
	let formData = new FormData()
	formData.append("pdf", file)
	const success = () => {
		reload((old) => !old)
	}
	cursoPutApi(id, formData, { success, params: { pdf: "" } })
}
export function ButtonsCard({ cursoColaborador, setUrlPDF, reload }) {
	const { id, status, url_s3: link } = cursoColaborador
	const [dialog, setDialog] = useState({})
	const isApproved = status === ESTADO_CURSOS.APROBADO
	const isCancel = status === ESTADO_CURSOS.CANCELADO
	const isCheck = status === ESTADO_CURSOS.REVISAR
	const hasLink = !!link
	const isRH = HasPermission([PERFILES["RH"]])
	const { setCurso, setNuevoCurso } = useCursoContext() || {}
	const openDialog = (newStatus) => {
		setDialog({
			open: true,
			newStatus,
		})
	}
	const buttons = useMemo(
		() => [
			{
				title: "Descargar PDF",
				show: hasLink && !isCancel,
				onClick: () => handleDownload(link),
				icon: CloudDownloadIcon,
			},
			{
				title: "Mostrar PDF",
				show: hasLink && !isCancel,
				onClick: () => {
					const success = (link) => {
						setUrlPDF(link)
					}
					getUrlSing(link, success)
				},
				icon: PictureAsPdfIcon,
			},
			{
				title: "Cancelar",
				show: isRH && !isApproved && !isCancel,
				onClick: () => {
					openDialog(ESTADO_CURSOS.CANCELADO)
				},
				icon: NotInterestedRoundedIcon,
			},
			{
				title: "Aprobar",
				show: isRH && isCheck,
				onClick: () => {
					openDialog(ESTADO_CURSOS.APROBADO)
				},
				icon: VerifiedSharpIcon,
			},
			{
				title: "Rechazar",
				show: isRH && isCheck,
				onClick: () => {
					openDialog(ESTADO_CURSOS.RECHAZADO)
				},
				icon: CancelRoundedIcon,
			},
			{
				title: "Editar",
				show: isRH && !isCancel && !isCheck,
				onClick: () => {
					setCurso(cursoColaborador)
					setNuevoCurso(true)
				},
				icon: EditIcon,
			},
			{
				title: "Eliminar",
				show: isRH,
				onClick: () => {
					openDialog("eliminar")
				},
				icon: DeleteIcon,
			},
		],
		[hasLink, isApproved, isCancel, isCheck, isRH, link, setUrlPDF],
	)

	const ButtonShow = useMemo(() => {
		return buttons.map((button) => {
			return (
				<ButtonIcon
					key={button.title + id}
					title={button.title}
					hidden={!button.show}
					icon={<button.icon />}
					onClick={button.onClick}
				/>
			)
		})
	}, [buttons, id])

	return (
		<Stack
			direction={"row"}
			justifyContent={"flex-end"}
			alignItems={"center"}
			spacing={1}
		>
			<ButtonIconFile
				title="Subir pdf"
				id={id}
				onChange={(event) => {
					handleFileUpload(id, event, reload)
				}}
				accept=".pdf"
				show={!isApproved && !isCancel}
			/>
			{ButtonShow}
			<Confirmation
				{...{
					open: dialog?.open,
					setOpen: setDialog,
					newStatus: dialog?.newStatus,
					idCursoColaborador: id,
					reload,
				}}
			/>
		</Stack>
	)
}
