import dayjs from "dayjs"
import { useState } from "react"
//
import { ButtonDialog, DialogGeneral } from "#common/Dialog"
//componentes
import { useVacationContext } from "./UseContextVacation"
import { VACATION as VACATION_STATUS } from "#constants/StatusRequest "
//material
import { api_rh_put } from "#api/API_RH"
import { Box, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { dateStringFormatting } from "#common/Funciones"

export default function VacationDialogApprove() {
	const [disabledButtons, setDisabledButtons] = useState(false)
	const { setOpenApprove, openApprove, solicitud, updateData } =
		useVacationContext()

	const {
		id,
		date_start,
		date_end,
		date_request,
		days,
		colaborador_name,
		cancelar = false,
	} = solicitud
	const datos = [
		{ label: "Colaborador", dato: colaborador_name, sm: 5 },
		{ label: "Dias Solicitados", dato: days, sm: 3 },
		{
			label: "Fecha De Solicitud",
			dato: dateStringFormatting(date_request),
			sm: 4,
		},
		{
			label: "Fecha Inicio",
			dato: dateStringFormatting(date_start),
			sm: 4,
		},
		{
			label: "Fecha Termino",
			dato: dateStringFormatting(date_end),
			sm: 4,
		},
	]

	async function sendResponse(is_approve = true) {
		const { APPROVE, RECHAZADA, CANCELADAS } = VACATION_STATUS
		const data = {
			id,
			status: is_approve ? APPROVE : RECHAZADA,
		}
		if (cancelar) data["status"] = CANCELADAS

		setDisabledButtons(true)
		const response = await api_rh_put("vacation", data)
		setDisabledButtons(false)
		if (response) {
			setOpenApprove(false)
			updateData()
		}
	}
	let buttons = [
		<ButtonDialog
			onClick={() => setOpenApprove(false)}
			key={"cerrar"}
			label={"Cerrar"}
			disabled={disabledButtons}
		/>,
		<ButtonDialog
			key={"rechazar"}
			label={cancelar ? "cancelar" : "rechazar"}
			onClick={() => sendResponse(false)}
			disabled={disabledButtons}
		/>,
		// <ButtonDialog
		// 	key={"aceptar"}
		// 	label={"aceptar"}
		// 	onClick={() => sendResponse(true)}
		// 	disabled={disabledButtons}
		// />,
	]
	if (!cancelar)
		buttons.push(
			<ButtonDialog
				key={"aceptar"}
				label={"aceptar"}
				onClick={() => sendResponse(true)}
				disabled={disabledButtons}
			/>,
		)

	return (
		<DialogGeneral
			open={openApprove}
			setOpen={setOpenApprove}
			buttons={buttons}
		>
			<Box>
				<Grid2
					container
					spacing={3}
					justifyContent={"center"}
				>
					{datos.map((dato) => (
						<Grid2
							key={dato.label}
							{...dato}
						>
							<Typography
								sx={{ whiteSpace: "pre-line" }}
								textAlign={"center"}
							>
								{`${dato.label}\n`} <strong>{dato.dato}</strong>
							</Typography>
						</Grid2>
					))}
				</Grid2>
			</Box>
		</DialogGeneral>
	)
}
