import {  createSlice } from "@reduxjs/toolkit"

const initialState = {
	actualizar: true,
	actualizar2: true,
	actualizar3: true
}



export const actualizarSlice = createSlice({
	name: "counter",
	initialState,
	reducers: {
        startActualizacion: (state)=>{
            state.actualizar=!state.actualizar  
        },
    },
	
})
export const { startActualizacion } = actualizarSlice.actions;
//export const { setNotifications } = notificationSlice.actions;
export default actualizarSlice.reducer
