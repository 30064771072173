import React, { useEffect, useState } from "react"
import { Droppable } from "react-beautiful-dnd"

export default function StrictModeDroppable({ children, ...props }) {
	const [enabled, setEnabled] = useState(false)
	useEffect(() => {
		try {
			const animation = requestAnimationFrame(() => setEnabled(true))
			return () => {
				cancelAnimationFrame(animation)
				setEnabled(false)
			}
		} catch (error) {
			console.error(error)
		}
	}, [])
	if (!enabled) {
		return null
	}
	return <Droppable {...props}>{children}</Droppable>
}
