import React from "react"
import "./estilo.css"
import { ButtonGrid2 } from "../Common/Buttons"
import { exportPDF, exportPerfilPuestoPdf } from "../../Hooks/CreatePDF"
import { array } from "prop-types"

export default function FormatoPerfilPuesto(props) {
	const { perfil_competencia } = props
	const { perfil_interaccion } = props
	const { centro_costos } = props
	console.table(perfil_competencia)
	let {
		nombre = "",
		departamento = "departamento",
		reporta_a = "reporta_a",
		objetivo = " objetivo",
		education = [],
		formation = [],
		experience = [],
		lenguaje = [],
		software = [],
		adicional = [],
		responsabilidades = [],
		internal = [],
		externa = [],
	} = props
	departamento = centro_costos["descripcion"]
	if (Array.isArray(perfil_competencia)) {
		perfil_competencia.forEach((dato) => {
			if (dato["tipo"] === "Educación") education.push(dato["descripcion"])
			if (dato["tipo"] === "Capacitación") formation.push(dato["descripcion"])
			if (dato["tipo"] === "Idiomas") lenguaje.push(dato["descripcion"])
			if (dato["tipo"] === "Software") software.push(dato["descripcion"])
			if (dato["tipo"] === "Adicionales") adicional.push(dato["descripcion"])
			if (dato["tipo"] === "Experiencia") experience.push(dato["descripcion"])
			if (dato["tipo"] === "Responsabilidades")
				responsabilidades.push(dato["descripcion"])
		})
	}
	console.table(perfil_interaccion)
	if (Array.isArray(perfil_interaccion)) {
		perfil_interaccion.forEach((dato) => {
			if (dato["tipo"] === "Interna") {
				internal.push(dato)
			}
			if (dato["tipo"] === "Externa") {
				externa.push(dato)
			}
		})
	}

	function addRow(datos, colspan = 1) {
		return datos.map((dato) => (
			<tr style={{ height: "20px" }}>
				<td
					className="s20"
					colspan={colspan}
				>
					{dato}
				</td>
			</tr>
		))
	}
	function addTwoRow(datos, colspan = 3) {
		return datos.map((dato) => {
			return (
				<tr style={{ height: "20px" }}>
					<td
						className="twoRow"
						colspan={colspan}
					>
						{dato["para_que"]}
					</td>
					<td
						className="twoRow"
						colspan={colspan}
					>
						{dato["para_que"]}
					</td>
				</tr>
			)
		})
	}
	function addTable(data = [], colspan = 1) {
		return (
			<ul
				style={{
					alignContent: "space-evenly",
					listStyleType: "none",
					margin: "0",
					padding: "0",
					height: "auto",
				}}
			>
				{data.map((dato, index) => (
					<li
						key={index}
						// style={{
						// 	//width: "100%",
						// 	textAlign: "center",

						// 	borderBottom: "0.5px solid #000000",
						// 	height: "20px", // Ajus
						// }}
					>
						<b className="listaE"></b>
						{`* ${dato}`}
					</li>
				))}
			</ul>
		)
	}
	return (
		<div
			className="ritz grid-container"
			dir="ltr"
		>
			<table
				style={{ width: "1180px", border: "30px solid #0070c0" }}
				className="waffle"
				cellSpacing="0"
				cellPadding="0"
			>
				<tbody>
					<tr style={{ height: "20px" }}>
						<td
							className="s0"
							colspan="6"
						>
							PERFIL DE PUESTO
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s1"
							colspan="6"
						>
							F-DRH-09, Revisión E
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s2"
							colspan="6"
						>
							{nombre}
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s3"
							style={{ width: "10%" }}
						>
							Departamento
						</td>
						<td
							style={{ width: "40%" }}
							className="s4"
							colspan="2"
						>
							{departamento}
						</td>
						<td
							className="s3"
							style={{ width: "10%" }}
						>
							Reporta a
						</td>
						<td
							className="s4"
							colspan="2"
							style={{ width: "40%" }}
						>
							{reporta_a}
						</td>
					</tr>
					<tr>
						<td
							className="s7"
							// dir="ltr"
							colspan="6"
						>
							OBJETIVO DEL PUESTO
						</td>
					</tr>
					{/* Objetivo */}
					<tr style={{ height: "20px" }}>
						<td
							className="s8"
							dir="ltr"
							colspan="6"
						>
							{objetivo}
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s9"
							dir="ltr"
							colspan="6"
						>
							COMPETENCIAS
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s5"
							colspan="3"
						>
							Educación
						</td>
						<td
							className="s5"
							dir="ltr"
							colspan="3"
						>
							Formación (capacitación)
						</td>
					</tr>
					{/* Educacion - Formacion  */}
					<tr style={{ height: "20px", width: "800px" }}>
						<td
							className="cellTable"
							colspan="3"
						>
							{addTable(education)}
						</td>
						<td
							className="cellTable"
							colspan="3"
						>
							{addTable(formation)}
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s5"
							dir="ltr"
							colspan="3"
						>
							Experiencia (tiempo y/o área)
						</td>
						<td
							className="s5"
							dir="ltr"
							colspan="3"
						>
							Idiomas
						</td>
					</tr>
					{/* Experiencia - idiomas */}
					<tr style={{ height: "20px" }}>
						<td
							className="cellTable"
							dir="ltr"
							colspan="3"
						>
							{addTable(experience)}
						</td>
						<td
							className="cellTable"
							colspan="3"
						>
							{addTable(lenguaje)}
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s5"
							colspan="3"
						>
							Software
						</td>
						<td
							className="s5"
							colspan="3"
						>
							Adicionales
						</td>
					</tr>
					{/* software adicionales */}
					<tr style={{ height: "20px" }}>
						<td
							className="cellTable"
							colspan="3"
						>
							{addTable(software)}
						</td>
						<td
							className="cellTable"
							colspan="3"
						>
							{addTable(adicional)}
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s18"
							colspan="6"
						>
							El cumplimiento de este perfil se validará con las competencias de
							educación, formación y/o experiencia, No siendo limitativas para
							la contratación{" "}
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s19"
							colspan="6"
						>
							RESPONSABILIDADES
						</td>
					</tr>

					{addRow(responsabilidades, 6)}

					<tr style={{ height: "20px" }}>
						<td
							className="s19"
							colspan="6"
						>
							INTERACCIÓN CON OTROS
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s21"
							colspan="6"
						>
							INTERNAS
						</td>
					</tr>

					<tr style={{ height: "20px" }}>
						<td
							className="s22"
							colspan="3"
						>
							¿CON QUIÉN?
						</td>
						<td
							className="s22"
							colspan="3"
						>
							¿PARA QUÉ?
						</td>
					</tr>
					{/* con quien - para que  */}
					{addTwoRow(internal)}
					<tr style={{ height: "20px" }}>
						<td
							className="s21"
							colspan="6"
						>
							EXTERNAS
						</td>
					</tr>
					<tr style={{ height: "20px" }}>
						<td
							className="s22"
							colspan="3"
						>
							¿CON QUIÉN?
						</td>
						<td
							className="s22"
							colspan="3"
						>
							¿PARA QUÉ?
						</td>
					</tr>
					{/* externas con quien - para que  */}
					{addTwoRow(externa)}
				</tbody>
			</table>
		</div>
	)
}
