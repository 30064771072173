
import {Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VerifiedIcon from '@mui/icons-material/Verified';

const ModalHistorial = ({open, setOpen, historial}) => {
    if(!open) return
    return(
        <Dialog open={open} maxWidth={'md'} fullWidth={true}  onClose={()=>setOpen(false)}>
            <DialogTitle sx={{fontSize:24,fontWeight:'bold',color: "#555"}}>Historial de la Vacante</DialogTitle>
            <DialogContent>
                <Grid container sx={{mt:0}} spacing={3}>
                    <Timeline  position="left">
                        {historial.map((log, index) =>{
                            var icon = <></>
                            if(log.accion == 'Creacion')
                                icon = <TimelineDot color='primary'><CreateIcon /></TimelineDot>
                            if(log.accion == 'VoBo')
                                icon = <TimelineDot color="success"> <CheckCircleIcon /> </TimelineDot>
                            if (log.accion == 'Autorizada')
                                icon = <TimelineDot color="success"> <VerifiedIcon /> </TimelineDot>
                            if (log.accion == 'Rechazado')
                                icon = <TimelineDot color="error"> <CancelIcon /> </TimelineDot>
                            if(log.accion == 'Nuevo')
                                icon =  <TimelineDot color="primary"> <PersonAddAlt1Icon /> </TimelineDot>
                            if (log.accion == 'Actualizacion candidato')
                                icon = <TimelineDot color="primary"> <PublishedWithChangesIcon /> </TimelineDot>
                            if (log.accion == 'Agendar')
                                icon = <TimelineDot color="success"> <CalendarMonthIcon /> </TimelineDot>
                            return <TimelineItem key={'hist'+index}>
                                        <TimelineOppositeContent
                                            sx={{ m: 'auto 0' }}
                                            variant="body2"
                                            color="text.secondary" >
                                        {log.fecha}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            {icon} 
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant="h6" component="span" fontWeight={'bolder'} fontSize={18}>
                                                {log.autorizacion_nombre+' '+log.autorizacion_apellido}
                                            </Typography>
                                            <Typography> {log.descripcion} </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                        })}
                    </Timeline>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ModalHistorial