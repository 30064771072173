import { apiPromotionGet } from "#api/Api_promotion"
import { LoadViewTable } from "#common/LoadingView"
import { Box } from "@mui/material"
import MUIDataTable from "mui-datatables"
import { useEffect, useState } from "react"

export const usePromotionData = (reload) => {
	const [promotions, setPromotions] = useState([])
	const [loading, setLoading] = useState([])
	useEffect(() => {
		const controller = new AbortController()
		setLoading(true)
		const success = (data) => {
			setPromotions(data)
			setLoading(false)
		}
		apiPromotionGet({ success, signal: controller.signal })

		return () => {
			controller.abort()
		}
	}, [reload])
	return { promotions, loading }
}

export default function PromotionTable({ openVacanteDetail, actualizar }) {
	const { promotions, loading } = usePromotionData(actualizar)

	useEffect(() => {
		if (promotions.length === 0) {
			openVacanteDetail(null)
		}
	}, [actualizar, openVacanteDetail, promotions.length])

	let tableColumns = [
		{
			label: "# Id",
			name: "id",
			display: true,
			options: {
				filter: false,
			},
		},
		{
			label: "Status",
			name: "status",
			display: true,
			options: {
				filter: false,
			},
		},
		{ label: "Solicitante", name: "requestor" },
		{ label: "Fecha Solicitud", name: "request_date" },
	]

	const onRowClick = (rowData, rowMeta) => {
		const index = rowMeta.dataIndex
		openVacanteDetail(promotions[index])
	}
	const options = {
		selectableRowsHideCheckboxes: true,
		print: false,
		viewColumns: false,
		textLabels: { body: { noMatch: "No hay Promociones" } },
		onRowClick,
	}

	if (loading) return <LoadViewTable />

	return (
		<Box>
			<MUIDataTable
				data={promotions}
				columns={tableColumns}
				options={options}
			/>
		</Box>
	)
}
