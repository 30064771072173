import React, { useEffect, useState } from "react"
import AssetTable from "./AssetTable"
import { useSelector } from "react-redux"
import { apiGetAsset } from "../../Services/Api_inventario"
import { useSolicitudesContext } from "../Colaboradores/Solicitudes/SolicitudesContext"
import { SkeletonSquare } from "./../Common/Skeleton"
export default function Asset(props) {
	const token = useSelector((state) => state.user.token)
	const { colaboradorID, actualizar } = useSolicitudesContext()
	const [asset, setAsset] = useState()
	const [skeleton, setSkeleton] = useState(true)
	useEffect(() => {
		setSkeleton(true)
		apiGetAsset(token, setAsset, { colaborador: colaboradorID, all: "" })
			.then((response) => {
				if (response.success) setSkeleton(false)
			})
			.catch((valor) => {
				console.error(valor)
			})
	}, [token, actualizar, colaboradorID])
	const { dataColaborador } = props

	if (skeleton) return <SkeletonSquare height={500} />

	return (
		<React.Fragment>
			<AssetTable
				colaboradorData={dataColaborador}
				data={asset}
				token={token}
			/>
		</React.Fragment>
	)
}
