import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"

export default function ShowPdf({ pdfUrl }) {
	return (
		<div>
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
				<Viewer
					theme={{
						theme: "dark",
					}}
					fileUrl={pdfUrl}
					defaultScale={SpecialZoomLevel.PageWidth}
				/>
			</Worker>
		</div>
	)
}
