import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
})
/**
 *
 * @param {object} props
 * @param {string} props.label - Texto del botón
 * @param {ReactNode} props.startIcon - Icono del botón
 * @param {function} props.onChange - Función para el evento de cambio de archivo
 * @param {function} props.getFiles - Función para obtener los archivos
 * @param {string} props.accept - Tipo de archivo aceptado
 * @param {boolean} props.multiple - Indica si se debe permitir múltiples archivos
 * @param {boolean} props.fullWidth - Indica si el botón debe tener ancho completo
 * @param {boolean} props.hidden - Indica si el botón debe estar oculto
 * @returns
 */
export default function ButtonFile({
	id,
	label,
	startIcon,
	onChange,
	getFiles,
	accept,
	multiple = false,
	fullWidth = false,
	hidden = false,
}) {
	const handleFiles = (event) => {
		const files = event.target.files
		getFiles(files)
	}
	if (hidden) return null
	return (
		<Button
			fullWidth={fullWidth}
			component="label"
			variant="contained"
			startIcon={startIcon}
			onChange={onChange || handleFiles}
		>
			{label}
			<VisuallyHiddenInput
				type="file"
				accept={accept}
				multiple={multiple}
			/>
		</Button>
	)
}
