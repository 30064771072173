import {Fragment}from "react"
import { ESTADOS } from "../constants"
//Componentes
import BotonosCajaAhorro from "./BotonosCajaAhorro"
//Material UI
import Grid from "@mui/material/Unstable_Grid2"
import MUIDataTable from "mui-datatables"

function TablaCajaAhorro({
	db_cajaAhorro = [],
	rh = false,
	actualizar = true,
	opciones = 3,
	size = 8,
	setActualizar = () => {},
}) {
	const options = {
		textLabels: {
			body: {
				noMatch: "No hay solicitudes", // Cambia este mensaje
			},
		},
		search: rh,
		filter: rh,
		download: rh,
		print: false,
		viewColumns: false,
		selectableRows: "none",
	}
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
		{
			name: "Status",
			label: "Estado",
			options: {
				filter: false,
				sort: true,
				display: true,
			},
		},
		{
			name: "no_empleado",
			label: "N° Empleado",
			options: {
				filter: false,
				sort: true,
				display: rh,
			},
		},
		{
			name: "colaborador_nombre",
			label: "Colaborador",
			options: {
				filter: false,
				sort: true,
				display: rh,
			},
		},
		{
			name: "sucursal",
			label: "Sucursal",
			options: {
				filter: false,
				sort: true,
				display: rh,
			},
		},
		{
			name: "departamento",
			label: "Departamento",
			options: {
				filter: false,
				sort: true,
				display: rh,
			},
		},
		{
			name: "fecha_solicitud",
			label: "Fecha de Solicitud",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "fecha_ingreso",
			label: "Fecha de Ingreso",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "deduccion",
			label: "Deducción Quincenal",
			options: {
				customBodyRender: (value) => {
					return `$ ${value.toLocaleString()}`
				},
				filter: true,
				sort: true,
			},
		},
		{
			name: "action", // Nombre de la columna para el botón
			label: " ", // Título de la columna
			options: {
				filter: false,
				sort: false,
				display: rh && opciones === ESTADOS["REVISAR"],
				customBodyRender: (value, tableMeta) => {
					const valButton = tableMeta.rowData[1] === "En Proceso"
					return (
						<Fragment>
							{valButton && (
								<BotonosCajaAhorro
									ID={tableMeta.rowData[0]}
									setActualizar={setActualizar}
									actualizar={actualizar}
								/>
							)}
						</Fragment>
					)
				},
			},
		},
	]

	return (
		<Grid xs={size}>
			<MUIDataTable
				title={""}
				data={db_cajaAhorro}
				columns={columns}
				options={options}
			/>
		</Grid>
	)
}

export default TablaCajaAhorro
