import axios from 'axios'
import { apiPutRH } from './API_RH'
const URLAPI = process.env.REACT_APP_SERVER_URL
const URL_API = `${process.env.REACT_APP_SERVER_URL}rh/colaboradores/`


export const cambiarContraseña = async(token, colaborador_id, password = null) => {
    const request = await axios.put(URLAPI + 'core/user/cambiar_password',{colaborador_id, password},{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const getDatosGeneralesColaborador = async(token,colaborador_id) => {
    const request = await axios.get(`${URL_API}${colaborador_id}/generales`,{headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const modificarDatosGeneralesColaborador = async(token,colaborador_id,campo) => {
    const request = await axios.put(URLAPI +'rh/colaboradores/'+colaborador_id, campo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
/**
 * 
 * @param {int} id - id del colaborador
 * @param {*} fecha_baja 
 * @param {*} motivo_baja 
 * @param {apiPutRhSettingsParams} settings 
 */
export const bajaColaborador = async(id,fecha_baja, motivo_baja,settings) => {
    apiPutRH(`colaboradores/${id}/dar_baja`,{fecha_baja,motivo_baja},settings)
}

export const getColaboradoresPorPerfil = async(token,perfil_puesto_id) => {
    const request = await axios.get(URLAPI+'rh/colaboradores/colaboradores_por_perfil',{ params:{perfil_puesto_id},headers: { Authorization: 'Token ' + token }})
    return request.data
}
/**
 * @description Permite modificar los datos de un colaborador
 * 
 * @param {int} id - id del colaborador
 * @param {*} data - data a editar
 * @param {apiPutRhSettingsParams} settings 
 */
export const editGeneralDataColaborador = async(id,data,settings) => {
    apiPutRH(`colaboradores/${id}`,data,settings)
}