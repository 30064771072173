import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { apiGetFondoFijo } from "../../Services/Api_Fondo_Fijo"
import { useFondoContext } from "./useContextFondo"

export function useFondoFijo(parameters = {}, activar = true) {
	const token = useSelector((state) => state.user.token)
	const useFondo = useFondoContext()
	const [fondoFijo, setFondoFijo] = useState([])
	const [loading, setLoading] = useState(true)

	const { params } = parameters

	function loadingA() {
		setLoading(false)
	}

	useEffect(() => {
		if (activar) {
			setLoading(true)
			apiGetFondoFijo({
				token,
				setDB: setFondoFijo,
				loading: loadingA,
				params: params,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		token,
		useFondo?.actualizar,
		params?.colaborador,
		params?.colaboradores,
		activar,
	])

	return { fondoFijo, loading }
}

export function UseFondoFijoV2({ activar = true, params }) {
	const token = useSelector((state) => state.user.token)
	const useFondo = useFondoContext()

	const [fondoFijo, setFondoFijo] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const loadingA = () => {
			setLoading(false)
		}
		setLoading(true)

		if (activar) {
			apiGetFondoFijo({
				token,
				setDB: setFondoFijo,
				loading: loadingA,
				params,
			})
		}
	}, [token, params, activar, useFondo?.actualizar])

	return { fondoFijo, loading }
}
