import React from "react"

import { Grid } from "@mui/material"
//Icons

import Beneficiary from "./ContactosTables/TableBeneficiary"
import ContactEmergency from "./ContactosTables/TableContactEmergency"

const Contactos = ({ colaborador, edit = true }) => {
	return (
		<Grid container>
			<Grid
				item
				xs={12}
			>
				<Beneficiary
					colaborador={colaborador}
					edit={edit}
				/>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<ContactEmergency
					colaborador={colaborador}
					edit={edit}
				/>
			</Grid>
		</Grid>
	)
}

export default Contactos
