import { useState } from "react"
import { useDispatch, useSelector } from "react-redux" //Token

//Material
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import { Skeleton, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
//importaciones
// import { Solicitudes } from "./Pagos"
import { useMemo } from "react"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../../Constants/Styles"
import { apiPutFondoFijo } from "../../../Services/Api_Fondo_Fijo"
import { actualizarNotify } from "../../../app/notificationSlice"
import { ButtonDialog, DialogGeneral } from "../../Common/Dialog"
import { useFondoFijo } from "../useFondoFijo"
import { FONDO_STATUS } from "../ButtonsFondo"

const nombre_campos = {
	// colaborador_name: { campo: "Colaborador", grid: { xs: 12 } },
	nombre_en_cuenta: { campo: "Nombre", grid: { xs: 12 } },
	banco: { campo: "Banco", grid: { xs: 12 } },
	cuenta: { campo: "Cuenta", grid: { xs: 12 } },
	clabe: { campo: "Clabe", grid: { xs: 12 } },
	pago: { campo: "Cantidad", grid: { xs: 12 } },
}

export default function DialogColabPagos({
	open,
	setOpen,
	colaborador,
	solicitud = {},
	actualizar = () => {},
}) {
	const token = useSelector((state) => state.user.token)
	const dispatch = useDispatch()
	const params = useMemo(() => ({ colaborador, last: true }), [colaborador])
	const { fondoFijo } = useFondoFijo({ params }, open)
	const [imageURL, setImageURL] = useState("")
	const [imgPago, setImgPago] = useState(null)
	const { solicitudes: datosPago, loading } = {}
	const tamaño = 3

	const handleClose = () => {
		setOpen(false)
		setImageURL(null)
	}
	const enviar = async () => {
		let formData = new FormData()
		formData.append("fondo", fondoFijo.id)

		formData.append("img", imgPago)
		formData.append("status", FONDO_STATUS.NF7)

		const { success } = await apiPutFondoFijo(token, formData, {})

		if (success) {
			dispatch(actualizarNotify(token))
			setOpen(false)
			actualizar()
		}
	}

	let img = (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<input
				accept=".PNG"
				style={{ display: "none" }}
				id={`file-input${"id"}`}
				type="file"
				onChange={(e) => handleFileUpload(e, setImageURL, setImgPago)}
			/>
			<label htmlFor={`file-input${"id"}`}>
				<Button
					startIcon={<AddPhotoAlternateIcon />}
					sx={{
						"&:hover": {
							backgroundColor: "#DBDADA",
							boxShadow: "10",
						},
					}}
					label="Subir Imagen"
					variant="text"
					component="span"
				>
					Subir Imagen
				</Button>
			</label>
		</div>
	)

	solicitud = { ...datosPago, ...solicitud }
	if (imageURL) {
		img = (
			<img
				style={{ objectFit: "cover" }}
				height={"100%"}
				src={imageURL}
				alt="Imagen"
			/>
		)
	}

	return (
		<DialogGeneral
			open={open}
			onClose={handleClose}
			title={`Recibo de pago de fondo fijo `}
			maxWidth="md"
			buttons={[
				<ButtonDialog
					key="cerrar"
					label="Cerrar"
					onClick={handleClose}
				/>,
				<ButtonDialog
					key="enviar"
					label="Enviar"
					onClick={enviar}
				/>,
			]}
		>
			{loading ? (
				<Skeleton
					variant="rectangular"
					width="100%"
					height={"300px"}
				/>
			) : (
				<Box
					onDrop={(e) => handleDrop(e, setImageURL, setImgPago)}
					onDragOver={handleDragOver}
					onPaste={(e) => handlePaste(e, setImageURL, setImgPago)}
					overflow={"hidden"}
					height={"100%"}
				>
					<Grid
						container
						height={"100%"}
					>
						<Grid xs={tamaño}>
							<div>
								<Grid
									container
									spacing={0.5}
									// justifyContent={"space-between"}
								>
									{Object.keys(nombre_campos).map((campo) => {
										if (campo === "pago") {
											let valor = parseFloat(fondoFijo[campo])
											let value = `$${valor.toLocaleString(undefined, {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}`
											return (
												<Texto
													key={campo}
													{...nombre_campos[campo]}
													contenido={value}
												/>
											)
										}
										return (
											<Texto
												key={campo}
												{...nombre_campos[campo]}
												contenido={fondoFijo[campo]}
											/>
										)
									})}
								</Grid>
							</div>
						</Grid>
						<Grid xs={12 - tamaño}>
							<div
								style={{
									height: "300px",
									width: "100%",
									position: "relative",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									border: `3px dashed ${PRIMARY_COLOR}`,
									borderRadius: "19px",
									// boxShadow: `inset 28px 28px 56px ${PRIMARY_COLOR}, inset -28px -28px 56px ${PRIMARY_COLOR}`,
								}}
							>
								{img}
							</div>
						</Grid>
					</Grid>
				</Box>
			)}
		</DialogGeneral>
	)
}
const Texto = ({ campo, contenido, grid, props }) => {
	return (
		<Grid {...grid}>
			<Typography
				variant="body1"
				fontWeight="bold"
				noWrap
			>
				{campo}
			</Typography>
			<Typography
				variant="body2"
				// variant="body2"
				display="inline-block"
				{...props}
			>
				{contenido}
			</Typography>
		</Grid>
	)
}

const handlePaste = (event, setImageURL, setImgPago) => {
	const clipboardData = event.clipboardData || window.clipboardData
	const items = clipboardData.items

	for (let item of items) {
		if (item.type.indexOf("image") !== -1) {
			const blob = item.getAsFile()
			setImgPago(blob)
			const imageUrl = URL.createObjectURL(blob)
			setImageURL(imageUrl)
			break
		}
	}
}
const handleFileUpload = (event, setImageURL, setImgPago) => {
	const file = event.target.files[0]
	setImgPago(file)
	const imageURL = URL.createObjectURL(file)
	setImageURL(imageURL)
}
const handleDrop = (event, setImageURL, setImgPago) => {
	event.preventDefault()
	const file = event.dataTransfer.files[0]
	setImgPago(file)
	if (file) {
		const imageUrl = URL.createObjectURL(file)
		setImageURL(imageUrl)
	}
}
const handleDragOver = (event) => {
	event.preventDefault()
}
