import {
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material"
import Timeline from "@mui/lab/Timeline"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent"
// import TimelineDot from "@mui/lab/TimelineDot"
// import CreateIcon from "@mui/icons-material/Create"
// import CancelIcon from "@mui/icons-material/Cancel"
// import CheckCircleIcon from "@mui/icons-material/CheckCircle"
// import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1"
// import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
// import VerifiedIcon from "@mui/icons-material/Verified"
import { useContext, useEffect, useState } from "react"
import { apiGetPromotionHistory } from "../../../Services/Api_promotion"
import { useSelector } from "react-redux"
import { ActualizarContext } from "./ContextActualizar"

const useHistoryData = (promotion = null) => {
	const token = useSelector((state) => state.user.token)
	const { actualizar } = useContext(ActualizarContext)
	const [history, setHistory] = useState([])
	const [load, setLoad] = useState([])
	useEffect(() => {
		const controller = new AbortController()
		setLoad(true)
		apiGetPromotionHistory({
			token,
			setDB: setHistory,
			controller,
			params: { promotion },
		}).then((response) => {
			if (response?.success) setLoad(false)
		})
		return () => {
			controller.abort()
		}
	}, [token, actualizar, promotion])
	return { history, load }
}

const ModalHistorial = ({ open, setOpen, promotion }) => {
	const { history } = useHistoryData(promotion)
	if (!open) return
	return (
		<Dialog
			open={open}
			maxWidth={"md"}
			fullWidth={true}
			onClose={() => setOpen(false)}
		>
			<DialogTitle sx={{ fontSize: 24, fontWeight: "bold", color: "#555" }}>
				Historial Promociones
			</DialogTitle>
			<DialogContent>
				<Grid
					container
					sx={{ mt: 0 }}
					spacing={3}
				>
					<Timeline position="left">
						{history.map((log, index) => {
							var icon = <></>

							// if (log.accion == "Agendar")
							// 	icon = (
							// 		<TimelineDot color="success">
							// 			{" "}
							// 			<CalendarMonthIcon />{" "}
							// 		</TimelineDot>
							// 	)
							return (
								<TimelineItem key={"hist" + index}>
									<TimelineOppositeContent
										sx={{ m: "auto 0" }}
										variant="body2"
										color="text.secondary"
									>
										{log.date}
									</TimelineOppositeContent>
									<TimelineSeparator>
										<TimelineConnector />
										{icon}
										<TimelineConnector />
									</TimelineSeparator>
									<TimelineContent sx={{ py: "12px", px: 2 }}>
										<Typography
											variant="h6"
											component="span"
											fontWeight={"bolder"}
											fontSize={18}
										>
											{log.colaborador_name}
										</Typography>
										<Typography> {log.action} </Typography>
									</TimelineContent>
								</TimelineItem>
							)
						})}
					</Timeline>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalHistorial
