import { useEffect, useState } from "react"
import { useSelector } from "react-redux" //Token
import { COLOR_PRIMARY } from "../../../../Constants/Styles"

//APi
import { getPerfilesAll, getSucursalesAll } from "../../../../Services/Api"
import {
	apiDeleteTrayectoria,
	apiGetTrayectoria,
} from "../../../../Services/ApiColaborador"

//Componentes
import { DialogConfirm } from "../../../Common/Dialog"
import FormTrayectoria from "../../forms/FormTrayectoria"
import { CardBar } from "../EscolaresTables/CardComponent"

//Material UI
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { ButtonIcon } from "../../../Common/Buttons"

//icons
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"

const GetSucursalPerfil = (open = false) => {
	const token = useSelector((state) => state.user.token)
	const [sucursales, setSucursales] = useState([])
	const [perfiles, setPerfiles] = useState([])
	useEffect(() => {
		if (open) {
			getSucursalesAll(token, true).then((response) => {
				setSucursales(response.data.sucursales)
			})

			getPerfilesAll(token).then((response) => {
				setPerfiles(response.data.perfiles)
			})
		}
	}, [open, token])
	return { sucursales, perfiles }
}

export default function TrayectoriaCard({ colaborador, edit = true }) {
	const token = useSelector((state) => state.user.token)
	const [getData, setGetData] = useState(false)
	const { sucursales, perfiles } = GetSucursalPerfil(getData)
	const TEXT_COLOR_TABLET = "#eeeeee"
	const [trayectoria, setTrayectoria] = useState([])
	const [open, setOpen] = useState(false)
	const [openConfirm, setOpenConfirm] = useState({ open: false, id: null })
	const [actualizar, setActualizar] = useState(true)
	const actualizarNow = () => setActualizar(!actualizar)

	useEffect(() => {
		if (open?.open === true && !getData) setGetData(true)
	}, [getData, open?.open])

	useEffect(() => {
		apiGetTrayectoria(token, setTrayectoria, { colaborador: colaborador.id })
	}, [actualizar, colaborador.id, token])

	const handleNew = () => {
		setOpen({ open: true })
	}
	function handleEdit(curso) {
		setOpen({ open: true, edit: curso })
	}
	function handleDelete(id) {
		apiDeleteTrayectoria(token, {
			id: id,
		}).then((response) => {
			actualizarNow()
			setOpenConfirm(false)
		})
	}
	return (
		<>
			<Grid2
				container
				spacing={1}
			>
				<Grid2 xs={12}>
					<CardBar
						title="Trayectoria Lubtrac"
						edit={edit}
						buttonAdd={handleNew}
						color={TEXT_COLOR_TABLET}
					/>
				</Grid2>
				{trayectoria.map((trayecto) => {
					return (
						<Grid2
							key={trayecto.id}
							xs={4}
						>
							<CardComponen
								trayecto={trayecto}
								edit={edit}
								buttonEdit={() => handleEdit(trayecto)}
								buttonDeleted={() =>
									setOpenConfirm({
										open: true,
										id: trayecto.id,
									})
								}
								sucursales={sucursales}
								perfiles={perfiles}
							/>
						</Grid2>
					)
				})}
			</Grid2>
			<FormTrayectoria
				idColaborador={colaborador.id}
				open={open.open}
				edit={open.edit}
				setOpen={setOpen}
				actualizar={actualizarNow}
				sucursales={sucursales}
				perfiles={perfiles}
			/>
			<DialogConfirm
				setOpen={setOpenConfirm}
				open={openConfirm.open}
				message={`¿Eliminar Trayectoria?`}
				// disabledButtons
				accept={() => handleDelete(openConfirm.id)}
			/>
		</>
	)
}

export function CardComponen({ trayecto, edit, buttonEdit, buttonDeleted }) {
	let { perfil, fecha_inicio, sucursal_name } = trayecto
	const fontSize = 15
	return (
		<Card sx={{ flex: 1, minWidth: 250, borderRadius: 3, height: "100%" }}>
			<CardActions
				backgroundColor="red"
				sx={{ backgroundColor: COLOR_PRIMARY }}
			>
				<div
					style={{
						width: "100%",
						height: "50px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Typography
						sx={{ fontSize: 17, color: "#ffffff" }}
						variant="h7"
					>
						{perfil}
					</Typography>
					{edit && (
						<Stack
							direction="row"
							justifyContent="flex-end"
						>
							<ButtonIcon
								title="Editar"
								icon={<EditIcon />}
								onClick={buttonEdit}
								size="small"
								sx={{ color: "#ffffff" }}
							/>
							<ButtonIcon
								title="Eliminar"
								icon={<DeleteIcon />}
								onClick={buttonDeleted}
								size="small"
								sx={{ color: "#ffffff" }}
							/>
						</Stack>
					)}
				</div>
			</CardActions>

			<CardContent>
				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					<span style={{ fontWeight: "bold" }}>Inicio :</span>
					{` ${fecha_inicio}`}
				</Typography>
				<Typography
					sx={{ fontSize: fontSize }}
					color="text.secondary"
					gutterBottom
				>
					<span style={{ fontWeight: "bold" }}>Sucursal :</span>

					{` ${sucursal_name}`}
				</Typography>
			</CardContent>
		</Card>
	)
}
