import React from "react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { FormControl } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { InputFormGrid2 } from "../../Common/Inputs2"
import { useSelector } from "react-redux"
import { apiPutColaboradorGenerales } from "../../../Services/ApiColaborador"
import { useDispatch } from "react-redux"
import { startActualizacion } from "../../../app/actualizar"
import { InputSelectFormGrid } from "../../Common/Inputs2"
import { OPTIONS_TIPO_SANGRE } from "../../../assets/OptionsSelects"
import IsGuatemala from "./IsGuatemala"
export default function FormDataMedicos({ dataColaborador, click, setClick }) {
	const { have } = IsGuatemala()
	const token = useSelector((state) => state.user.token)
	const dispatch = useDispatch()
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm()
	useEffect(() => {
		if (click) {
			handleSubmit(onSubmit)()
			setClick(false)
		}
	}, [click, handleSubmit, setClick])

	useEffect(() => {
		reset(dataColaborador)
	}, [reset, dataColaborador])

	const onSubmit = async (data) => {
		setClick(true)
		let response = await apiPutColaboradorGenerales(token, data, {
			datosMedicos: "",
		})
		if (response["success"]) dispatch(startActualizacion())
		setClick(false)
	}

	const DATOS = {
		IMSS: {
			id: "no_imss",
			label: have ? "IGSS O CUI" : "no_imss",
			registerParams: { required: true },
			propsG: { xs: 6, md: 8.5 },
		},
		INFONAVIT: {
			id: "no_infonavit",
			label: "N° INFONAVIT",
			registerParams: { required: true },
			propsG: { xs: 12 },
		},
		ALERGIAS: {
			id: "alergias",
			label: "Alergias",
			registerParams: { required: true },
			multiline: true,
			propsG: { xs: 12 },
		},
		BLOOD: {
			id: "grupo_sanguineo",
			label: "Grupo Sanguíneo",
			dataLabel: "value",
			dataID: "value",
			propsG: { xs: 6, md: 3.5 },
		},
	}

	return (
		<FormControl sx={{ width: "100%" }}>
			<Grid2
				container
				spacing={2}
				columns={12}
			>
				<InputFormGrid2
					{...DATOS.IMSS}
					register={register}
					error={errors}
				/>
				<InputSelectFormGrid
					{...DATOS.BLOOD}
					data={OPTIONS_TIPO_SANGRE}
					control={control}
					error={errors}
				/>
				<InputFormGrid2
					{...DATOS.ALERGIAS}
					register={register}
					error={errors}
				/>
				{!have && (
					<InputFormGrid2
						{...DATOS.INFONAVIT}
						register={register}
						error={errors}
					/>
				)}
			</Grid2>
		</FormControl>
	)
}
