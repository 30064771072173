import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

//Componentes
import { postApiCursos } from "../../../Services/Api_cursos"
//Material UI
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { InputGrid2 } from "../../Common/Inputs2"

export function DialogNewPlataforma(props) {
	const { open, setOpen, actualizar, setActualizar } = props
	const TOKEN = useSelector((state) => state.user.token)
	const [newPlataforma, setNewPlataforma] = useState("")

	const handleClose = () => {
		setOpen(false)
	}
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Nueva plataforma</DialogTitle>
			<DialogContent>
				<Grid
					container
					paddingTop={1}
					spacing={3}
				>
					<InputGrid2
						label={"Nombre plataforma"}
						setOption={setNewPlataforma}
						propsGrid={{ xs: 12 }}
					/>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>Cancelar</Button>
				<Button
					onClick={async () => {
						setOpen(false)
						let response = await postApiCursos("plataforma", TOKEN, {
							descripcion: newPlataforma,
						})
						if (response) {
							setActualizar(!actualizar)
						}
					}}
					autoFocus
				>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
