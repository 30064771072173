import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
//
import { ButtonDialog, DialogGeneral } from "#common/Dialog"
import dayjs from "dayjs"
import { CardInfo } from "./VacationDetail"
//material
import { api_rh_post, api_rh_put } from "#api/API_RH"
import { HasPermission } from "#common/Funciones"
import { Box, Stack, Typography } from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateField } from "@mui/x-date-pickers/DateField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { useVacationContext } from "./UseContextVacation"
import UseVacation from "./UseVacation"

export default function VacationDialogRequest({
	open,
	setOpen,
	vacation,
	colaborador,
	update = () => {},
}) {
	const edit = useVacationContext()
	const [date_start, setDate_start] = useState(null)
	const [get, setGet] = useState(false)
	const [date_end, setDate_end] = useState(null)
	const [descanso, setDescanso] = useState([])
	let { all_remaining = 0 } = vacation || {}
	const params = useMemo(() => {
		return { params: { holidays: true } }
	}, [])
	const { vacation: holidays, loading } = UseVacation([], params, get)

	if (edit) {
		all_remaining = edit.solicitud.disponibles + edit.solicitud.days
		open = edit.openRequest
		setOpen = edit.setOpenRequest
	}

	useEffect(() => {
		if (!get && open) setGet(true)
	}, [get, open])

	useEffect(() => {
		let solicitud = edit?.solicitud
		if (solicitud) {
			setDate_start(dayjs(solicitud?.date_start, "YYYY-M-D"))
			setDate_end(dayjs(solicitud?.date_end, "YYYY-M-D"))
		}
	}, [edit?.solicitud])

	useEffect(() => {
		let x = vacation_holidays(holidays, date_start, date_end)?.holidays
		let y = vacation_saturday(date_start, date_end)
		y = y?.dates
		if ((y, x))
			setDescanso(
				[...x, ...y].sort((a, b) => {
					a = a?.date || a
					b = b?.date || b

					return a - b
				})
			)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_end, date_start])

	let solicitados = vacation_days_taken(descanso.length, date_end, date_start)
	let days_taken = all_remaining - solicitados
	let send_validate = days_taken < 0 || solicitados <= 0
	const is_rh = HasPermission("RH")
	if (is_rh) send_validate = false

	let is_date_great = date_start > date_end

	const send = async () => {
		let response = null
		if (edit) {
			response = await api_rh_put("vacation", {
				id: edit.solicitud.id,
				date_start: date_start.format("YYYY-MM-DD"),
				date_end: date_end.format("YYYY-MM-DD"),
			})
			edit.updateData()
		} else
			response = await api_rh_post("vacation", {
				colaborador,
				date_start: date_start.format("YYYY-MM-DD"),
				date_end: date_end.format("YYYY-MM-DD"),
			})

		if (response) {
			setOpen(false)
			update()
		}
	}

	if (loading)
		return (
			<DialogGeneral
				title="Solicitar Vacaciones"
				open={open}
				setOpen={setOpen}
				buttons={[
					<ButtonDialog
						onClick={() => setOpen(false)}
						key="cancelar"
						label="Cancelar"
					/>,
				]}
			>
				<VacationDialogRequestSkeleton />
			</DialogGeneral>
		)
	return (
		<DialogGeneral
			title={<Typography variant="h5">Solicitar Vacaciones</Typography>}
			open={open}
			setOpen={setOpen}
			buttons={[
				<ButtonDialog
					onClick={() => setOpen(false)}
					key="cancelar"
					label="Cancelar"
				/>,
				<ButtonDialog
					disabled={send_validate || is_date_great}
					key="solicitar"
					label="Solicitar"
					onClick={() => {
						send()
					}}
				/>,
			]}
		>
			<Box>
				<Stack
					direction={"row"}
					justifyContent={"space-around"}
				>
					<Typography variant="h6">Disponible : {all_remaining}</Typography>

					<Typography variant="h6">Solicitados : {solicitados}</Typography>
				</Stack>
				{/* <Typography>Dias festivos : {holidays_take.count}</Typography> */}
				<InputDateRange
					date_start={date_start}
					setDate_start={setDate_start}
					date_end={date_end}
					setDate_end={setDate_end}
					value_start={date_start}
					value_end={date_end}
				/>
				<Descanso descanso={descanso} />
			</Box>
		</DialogGeneral>
	)
}

export function InputDateRange({
	label_start = "Fecha Inicio",
	label_end = "Fecha Termino",
	date_start,
	setDate_start,
	date_end,
	setDate_end,
	getRange = () => {},
}) {
	const [dateSelect, setDateSelect] = useState(null)
	useEffect(() => {
		if (date_start?.isValid()) {
			setDateSelect(false)
			if (!date_end?.isValid()) setDate_end(date_start)
		} else setDateSelect(true)

		if (date_start?.$d < date_end?.$d) {
			getRange({ date_start, date_end })
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_start, date_end])

	return (
		<Stack
			direction={"row"}
			spacing={2}
			justifyContent={"center"}
			alignItems={"baseline"}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={["DateField"]}>
					<DateField
						label={label_start}
						value={date_start}
						format="DD/MM/YYYY"
						onChange={(date) => {
							if (date?.isValid()) {
								setDate_start(date)
								if (date?.$d >= date_end?.$d) setDate_end(date)
							} else setDate_start(null)
						}}
						slotProps={{
							textField: {
								helperText: "DD/MM/YYYY",
							},
						}}
					/>
				</DemoContainer>
			</LocalizationProvider>
			<Typography textAlign={"center"}>A</Typography>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={["DateField"]}>
					<DateField
						label={label_end}
						value={date_end}
						disabled={dateSelect}
						format="DD/MM/YYYY"
						onChange={(date) => {
							if (date?.isValid()) {
								setDate_end(date)
								if (date_start.$d > date.$d) {
									toast.error(
										"La fecha de termino no puede ser menor a la inicial ",
										{
											toastId: "range_date",
										}
									)
								}
							} else setDate_end(null)
						}}
						slotProps={{
							textField: {
								helperText: "DD/MM/YYYY",
							},
						}}
					/>
				</DemoContainer>
			</LocalizationProvider>
		</Stack>
	)
}

function vacation_saturday(date_start, date_end) {
	let count = 0
	let dates = []
	if (!date_start?.isValid() || !date_end?.isValid()) return { count, dates }

	let date = date_start
	while (date.$d <= date_end.$d) {
		if (date.day() === 0) {
			count += 1
			dates.push(date.$d)
			date = date.add(7, "day")
		} else date = date.add(1, "day")
	}
	return { count, dates }
}
function vacation_holidays(holidays, date_start, date_end) {
	if (!date_start?.isValid() || !date_end?.isValid())
		return { count: 0, holidays: [] }
	let year = dayjs().year()
	if (!holidays?.date)
		holidays.forEach((holiday) => {
			holiday["date"] = dayjs(
				`${holiday.month}-${holiday.day}-${year}`,
				"M-D-YYYY"
			).$d
		})
	let holidays_f = holidays.filter(
		(holiday) =>
			holiday.date >= date_start.$d &&
			date_end.$d >= holiday.date &&
			dayjs(holiday.date).day() !== 0
	)

	return { count: holidays_f.length, holidays: holidays_f }
}
function vacation_days_taken(descanso, date_end, date_start) {
	if (date_end?.isValid()) {
		let days_taken = date_end?.diff(date_start, "day") + 1
		return days_taken - descanso
	} else return 0
}

function Descanso({ descanso }) {
	let style = { flex: "1 0 100px" }
	let style_text = { variant: "h7" }
	if (!descanso?.length) {
		return null
	}
	return (
		<Stack>
			<Typography
				variant="h6"
				sx={{
					textAlign: "center",
				}}
			>
				Descanso
			</Typography>
			<Box
				display={"flex"}
				flexWrap={"wrap"}
				justifyContent={"center"}
				gap={"30px"}
			>
				{descanso.map((date, index) => {
					if (date?.date) {
						let fecha = dayjs(date?.date).format("DD/MM/YYYY")
						return (
							<div
								key={fecha}
								style={style}
							>
								<CardInfo
									contenido={
										<Typography
											sx={{
												// variant: "h7",
												whiteSpace: "pre-line",
												textAlign: "center",
											}}
										>{`${fecha} \n ${date?.name}`}</Typography>
									}
									textProps={style_text}
								/>
							</div>
						)
					} else {
						date = dayjs(date).format("DD/MM/YYYY")
						return (
							<div
								key={date}
								style={style}
							>
								<CardInfo
									contenido={date}
									textProps={style_text}
								/>
							</div>
						)
					}
				})}
			</Box>
		</Stack>
	)
}

const VacationDialogRequestSkeleton = () => {
	return (
		<Box>
			<Stack
				direction={"row"}
				justifyContent={"space-around"}
			>
				<Skeleton
					variant="text"
					sx={{ fontSize: "2rem" }}
					width={210}
				/>
				<Skeleton
					variant="text"
					sx={{ fontSize: "2rem" }}
					width={210}
				/>
			</Stack>
			<Stack
				direction={"row"}
				justifyContent={"space-around"}
			>
				<Skeleton
					variant="rectangular"
					width={210}
					height={60}
				/>
				<Skeleton
					variant="rectangular"
					width={210}
					height={60}
				/>
			</Stack>
			{/* <Descanso descanso={descanso} /> */}
		</Box>
	)
}
