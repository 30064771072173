import { createSlice } from '@reduxjs/toolkit'

export const pageSlice = createSlice({
    name: "page",
    initialState:{
        title: 'Gestor RH',
        reloadVacante: true,
        openDrawer: false,
        reloadTable: false
    },
    reducers:{
        changeTitle: (state, action)=>{
            var payload = action.payload
            state.title=  payload
        },
        reloadDataVacante:(state) =>{
            state.reloadVacante = !state.reloadVacante
        },
        reloadTable:(state) =>{
            state.reloadTable = !state.reloadTable
        },
        openDrawer:(state) =>{
            state.openDrawer = !state.openDrawer
        }
        
    }
})


export const { changeTitle} = pageSlice.actions
export const { reloadDataVacante } = pageSlice.actions
export const { reloadTable } = pageSlice.actions
export const { openDrawer } = pageSlice.actions

export default pageSlice.reducer