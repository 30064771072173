// Use Fom
import { Controller, useFormContext } from "react-hook-form"

import { Stack } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs from "dayjs"
import { useMemo } from "react"

/**
 * @typedef {import('@mui/material/Unstable_Grid2').Grid2Props} Grid2Props
 * @typedef {import('react-hook-form').ControllerProps} ControllerProps
 * @typedef {import('@mui/x-date-pickers/DatePicker').DatePickerProps} DatePickerProps
 */

/**
 * Componente de formulario para la selección de fecha con formato específico.
 * @param {Object} param - Props para el componente.
 * @param {string} param.id - Identificador único para el campo de fecha.
 * @param {string} param.label - Etiqueta del campo de fecha.
 * @param {Array<string>} [param.views=["day", "month", "year"]] - Vistas disponibles para la selección de fecha (día, mes, año).
 * @param {string} [param.parseFormat="DD/MM/YYYY"] - Formato de análisis de la fecha.
 * @param {Object} param.control - Control de formulario proporcionado por `react-hook-form`.
 * @param {Grid2Props} param.grid - Props para el componente `Grid2`.
 * @param {ControllerProps} param.controller - Props adicionales para el componente `Controller` de `react-hook-form`.
 * @param {Object} [param.error={}] - Objeto de error para manejar mensajes de error.
 * @param {DatePickerProps} props - Props adicionales para el componente `DatePicker`.
 *
 *
 * @returns {JSX.Element} - Componente JSX para la entrada de fecha en un formulario.
 *
 * @example
 * <InputDateFormGrid
 *   id="birthDate"
 *   label="Fecha de Nacimiento"
 *   control={control}
 *   grid={{ xs: 12, md: 6 }}
 * />
 */

export const InputDateFormGrid = ({
	id,
	label,
	views = ["day", "month", "year"],
	parseFormat = "DD/MM/YYYY",
	control,
	grid,
	controller,
	error = {},
	...props
}) => {
	const { watch } = useFormContext()
	const dateWatch = watch(id)

	const value = useMemo(() => {
		const date = dateWatch
		if (dayjs.isDayjs(date)) {
			return date
		} else {
			const newDate = dayjs(date, parseFormat)
			if (newDate.isValid()) return newDate
			else return null
		}
	}, [dateWatch, parseFormat])

	return (
		<Grid2
			padding={0}
			{...grid}
		>
			<Controller
				name={id}
				control={control}
				{...controller}
				render={({ field }) => {
					return (
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale="es-mx"
						>
							<DemoContainer components={["DatePicker"]}>
								<DatePicker
									label={label}
									views={views}
									{...field}
									value={value}
									slotProps={{
										textField: {
											helperText: parseFormat,
										},
									}}
									{...props}
								/>
							</DemoContainer>
						</LocalizationProvider>
					)
				}}
			/>
		</Grid2>
	)
}
/**
 * Componente de formulario para la selección de fecha con formato específico.
 * @param {Object} param - Props para el componente.
 * @param {string} param.id - Identificador único para el campo de fecha Inicio.
 * @param {string} param.id - Identificador único para el campo de fecha Fin.
 * @param {string} param.label - Etiqueta del campo de fecha Inicio.
 * @param {string} param.label - Etiqueta del campo de fecha Fin.
 * @param {Array<string>} [param.views=["day", "month", "year"]] - Vistas disponibles para la selección de fecha (día, mes, año).
 * @param {string} [param.parseFormat="DD/MM/YYYY"] - Formato de análisis de la fecha.
 * @param {Object} param.control - Control de formulario proporcionado por `react-hook-form`.
 * @param {Grid2Props} param.grid - Props para el componente `Grid2`.
 * @param {ControllerProps} param.controller - Props adicionales para el componente `Controller` de `react-hook-form`.
 * @param {Object} [param.error={}] - Objeto de error para manejar mensajes de error.
 * @param {DatePickerProps} props - Props adicionales para el componente `DatePicker`.
 * @returns {JSX.Element} - Componente JSX para la entrada de fecha en un formulario.
 *
 */
export const InputDateRangeFormGrid = ({
	idA,
	idB,
	labelA,
	labelB,
	views = ["day", "month", "year"],
	parseFormat = "DD/MM/YYYY",
	control,
	grid,
	rulesA = {},
	rulesB = {},
	error = {},
}) => {
	const { watch } = useFormContext()
	const watchA = watch(idA)
	const watchB = watch(idB)

	const valueA = useMemo(() => {
		const date = watchA
		if (dayjs.isDayjs(date)) {
			return date
		} else {
			const newDate = dayjs(date, parseFormat)
			if (newDate.isValid()) return newDate
			else return null
		}
	}, [parseFormat, watchA])

	const valueB = useMemo(() => {
		const date = watchB
		if (dayjs.isDayjs(date)) {
			return date
		} else {
			const newDate = dayjs(date, parseFormat)
			if (newDate.isValid()) return newDate
			else return null
		}
	}, [parseFormat, watchB])
	return (
		<Grid2 {...grid}>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				alignContent={"center"}
				alignItems={"center"}
				width={"100%"}
			>
				<Controller
					name={idA}
					control={control}
					rules={rulesA}
					render={({ field }) => {
						return (
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="es-mx"
							>
								<DemoContainer components={["DatePicker"]}>
									<DatePicker
										label={labelA}
										views={views}
										{...field}
										value={valueA}
										slotProps={{
											textField: {
												helperText: parseFormat,
											},
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>
						)
					}}
				/>
				a
				<Controller
					name={idB}
					control={control}
					rules={rulesB}
					render={({ field }) => {
						return (
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="es-mx"
							>
								<DemoContainer components={["DatePicker"]}>
									<DatePicker
										label={labelB}
										views={views}
										{...field}
										value={valueB}
										minDate={valueA}
										slotProps={{
											textField: {
												helperText: parseFormat,
											},
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>
						)
					}}
				/>
			</Stack>
		</Grid2>
	)
}
