import React from "react"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import TextoTypography from "../TextoTypography"
import ShowPdf from "../ShowPdf"
import Link from "@mui/material/Link"

export default function TCCardContent({ db_user, db_cursos, urlPDF, filtro }) {
	let mostrar = true
	if (filtro === "usuario") mostrar = false
	let haveLink = db_cursos.link !== "N/A" && !!db_cursos.link
	const fechaSubida = db_user.fecha_subida
	return (
		<CardContent>
			<Typography
				variant="h7"
				component="div"
				sx={{ fontWeight: "bold" }}
			>
				{mostrar ? db_user.colaborador_nombre : ""}
			</Typography>
			{haveLink ? (
				<Link
					target="_blank"
					href={db_cursos.link}
					variant="body2"
				>
					<Typography
						variant="h5"
						component="div"
						sx={{ mb: 1.5, fontWeight: "bold" }}
					>
						{db_cursos.curso}
					</Typography>
				</Link>
			) : (
				<Typography
					variant="h5"
					component="div"
					sx={{ mb: 1.5, fontWeight: "bold" }}
				>
					{db_cursos.curso}
				</Typography>
			)}
			<TextoTypography Texto={`Plataforma:${db_cursos.plataforma_nombre} `} />
			{!!fechaSubida && (
				<TextoTypography Texto={`Fecha de asignación: ${fechaSubida}`} />
			)}
			{filtro === "planAnual" && (
				<React.Fragment>
					<TextoTypography Texto={`Contenido: ${db_cursos.contenido}`} />
					<TextoTypography Texto={`Objetivo:  ${db_cursos.objetivo}`} />
					<TextoTypography Texto={`Horas: ${db_cursos.horas} Hrs`} />
				</React.Fragment>
			)}

			{urlPDF && <ShowPdf pdfUrl={urlPDF} />}
		</CardContent>
	)
}
