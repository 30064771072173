import { useCallback, useEffect, useMemo, useState } from "react"
// common
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
//componentes
import { UseBajasContabilidad } from "../../UseBajas"
import { UseDialogBajaContext } from "./DialogBajaContext"
// material
import { bajasContabilidadPut } from "#api/Api/baja"
import { formatMoney, HasPermission } from "#common/Funciones"
import InputNumber from "#common/input/InputNumber"
import InputText from "#common/input/InputText"
import { TextCampoValor } from "#common/text/CampoValor"
import { Button, InputAdornment, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { toast } from "react-toastify"
import { MasterDialog } from "#common/Dialog/Dialog"
import { PERFILES } from "#constants/Strings"

const GRID_CONTAINER = { container: true, spacing: 3, width: "100%" }
const GRID = { xs: 12, md: 4, xl: 3 }

export default function ContabilidadBajas({ hidden = false }) {
	const [actualizar, setActualizar] = useState(false)
	const { debts, loading } = UseBajasContabilidad(hidden, actualizar)
	const [moreAdeudos, setMoreAdeudos] = useState({})
	const isRH = HasPermission(PERFILES.RH)
	const {
		colaborador = {},
		adeudos = {},
		adeudos_finales = false,
		adeudos_exists,
	} = debts
	if (loading) return <CircularSkeleton />

	return (
		<Box
			sx={{
				alignItems: "center",
			}}
		>
			<NotTools herramientas={debts} />
			<Stack spacing={3}>
				<DataColaborador colaborador={colaborador} />

				<Adeudos
					adeudos={adeudos_finales || { ...adeudos, ...moreAdeudos }}
					disabled={!!adeudos_finales}
					setActualizar={setActualizar}
					adeudos_exists={adeudos_exists}
				/>
				{!adeudos_exists && (
					<Typography textAlign={"center"}>
						Se debe completar los adeudos para continuar
					</Typography>
				)}
				{adeudos_exists && isRH && (
					<AddItemsContabilidad
						{...{ moreAdeudos, setMoreAdeudos }}
						hidden={!!adeudos_finales}
					/>
				)}
			</Stack>
		</Box>
	)
}

const NotTools = ({ herramientas }) => {
	if (herramientas.length !== 0) return null

	return (
		<Typography textAlign="center">
			No hay adeudos para esta solicitud
		</Typography>
	)
}

const DataColaborador = ({ colaborador }) => {
	const fields = [
		{ label: "Departamento", name: "centro_costos" },
		{ label: "Sucursal", name: "sucursal" },
		{ label: "Fecha Alta", name: "fecha_alta" },
		{ label: "Fecha Baja", name: "fecha_baja" },
	]

	const data = fields.map((item) => {
		return (
			<Grid2
				key={item.label}
				{...GRID}
			>
				<TextCampoValor
					field={item.label}
					value={colaborador[item.name]}
				/>
			</Grid2>
		)
	})

	return (
		<Box sx={{ width: "100%" }}>
			<Grid2 {...GRID_CONTAINER}>{data}</Grid2>
		</Box>
	)
}
const Adeudos = ({ adeudos, disabled, setActualizar, adeudos_exists }) => {
	const { setButtonsDialog } = UseDialogBajaContext()

	const [open, setOpen] = useState(false)
	const [adeudosItems, setAdeudosItems] = useState(adeudos)
	const isRH = HasPermission(PERFILES.RH)

	const editItem = (value, item) =>
		setAdeudosItems((items) => ({ ...items, [item]: value }))

	useEffect(() => {
		setAdeudosItems(adeudos)
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(adeudos)])

	useEffect(() => {
		if (isRH && !disabled && adeudos_exists)
			setButtonsDialog([
				{
					label: "Confirmar",
					onClick: () => setOpen(true),
					disabled: open,
				},
			])
	}, [
		adeudos.adeudos_exists,
		adeudos_exists,
		disabled,
		isRH,
		open,
		setButtonsDialog,
	])

	const items = useMemo(() => {
		return disabled ? (
			<AdeudosItemsText adeudos={adeudosItems} />
		) : (
			<AdeudosItemsEdit
				adeudos={adeudosItems}
				editItem={editItem}
			/>
		)
	}, [adeudosItems, disabled])

	return (
		<Box>
			<Grid2 {...GRID_CONTAINER}>{items}</Grid2>
			<DialogConfirm
				adeudos={adeudosItems}
				{...{ open, setOpen, setActualizar }}
			/>
		</Box>
	)
}

const AdeudosItemsEdit = ({ adeudos, editItem }) => {
	const isRH = HasPermission(PERFILES.RH)
	return Object.keys(adeudos).map((item) => {
		return (
			<Grid2
				key={item}
				{...GRID}
			>
				<InputNumber
					fullWidth
					label={item}
					value={adeudos[item]}
					disabled={!isRH}
					setValue={(value) => editItem(value, item)}
					startAdornment={
						adeudos[item] || adeudos[item] === 0 ? (
							<InputAdornment position="start">$</InputAdornment>
						) : null
					}
				/>
			</Grid2>
		)
	})
}
const AdeudosItemsText = ({ adeudos }) => {
	return Object.keys(adeudos).map((item, index) => (
		<Grid2
			key={item + index}
			{...GRID}
		>
			<TextCampoValor
				field={item}
				value={formatMoney(adeudos[item])}
			/>
		</Grid2>
	))
}

const AddItemsContabilidad = ({ moreAdeudos, setMoreAdeudos, hidden }) => {
	const [nameItem, setNameItem] = useState("")
	const [valueItem, setValueItem] = useState("")

	const handleAddItem = useCallback(() => {
		if (!nameItem || !valueItem) {
			toast.error("Debe ingresar nombre y cantidad adeudos", {
				toastId: "errorAddItem",
			})
			return
		}
		setMoreAdeudos({
			...moreAdeudos,
			[nameItem]: valueItem,
		})
		setNameItem("")
		setValueItem("")
	}, [moreAdeudos, nameItem, valueItem, setMoreAdeudos])
	if (hidden) return null
	return (
		<Box>
			<Stack
				width={"100%"}
				direction={"row"}
				spacing={3}
				alignItems={"flex-start"}
			>
				<InputText
					label="Nombre Adeudo"
					value={nameItem}
					setValue={(value) => setNameItem(capitalizeEachWord(value))}
				/>
				<InputNumber
					label="Cantidad Adeudos"
					value={valueItem}
					setValue={setValueItem}
				/>
				<Button
					onClick={handleAddItem}
					variant="contained"
				>
					Agregar
				</Button>
			</Stack>
		</Box>
	)
}
function capitalizeEachWord(text) {
	if (!text) return "" // Verifica si el texto está vacío o es undefined
	return text
		.split(" ") // Divide el texto en un array de palabras
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitaliza la primera letra de cada palabra
		.join(" ") // Une las palabras nuevamente en una cadena
}
const DialogConfirm = ({ adeudos, open, setOpen, setActualizar }) => {
	const { baja, setButtonsDialog, reload } = UseDialogBajaContext()
	const [disabledButton, setDisabledButton] = useState(false)

	const success = useMemo(
		() => () => {
			setButtonsDialog(null)
			setActualizar((value) => !value)
			reload()
		},
		[reload, setActualizar, setButtonsDialog],
	)
	const end = () => setDisabledButton(false)

	const sendData = useCallback(() => {
		setDisabledButton(true)
		bajasContabilidadPut(baja.id, adeudos, { success, end })
	}, [adeudos, baja.id, success])

	const items = useMemo(() => {
		let items = []
		for (const [key, value] of Object.entries(adeudos)) {
			items.push(
				<TextCampoValor
					field={key}
					value={formatMoney(value)}
				/>,
			)
		}
		return items
	}, [adeudos])

	return (
		<MasterDialog
			{...{
				title: "Confirmación ",
				open,
				setOpen,
				buttons: [
					{ label: "Enviar", onClick: sendData, disabled: disabledButton },
				],
			}}
		>
			<Stack>{items}</Stack>
		</MasterDialog>
	)
}
