import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { toast } from 'react-toastify';
import { bajaColaborador } from '../..//Services/ApiColaboradores';

const ModalBajaColaborador = ({ open, setOpen, colaboradoID, reload }) => {
    const [fecha, setFecha] = React.useState(null)
    const [motivoBaja, setMotivoBaja] = React.useState('')

    const baja = () => {
        if (fecha === null) {
            toast.error('Debe seleccionar una fecha de baja')
            return
        }
        let formatFecha = fecha.format('YYYY-MM-DD')
        const success=()=>{
            reload()
            setOpen(false)
        }
        
        bajaColaborador( 
            colaboradoID, 
            formatFecha, 
            motivoBaja,
            {
                success,
                message:{
                    pending:"Solicitando baja",
                    success:"Se ha dato de baja al colaborador",
                    error:"No se pudo dar de baja el colaborador"
                }
            }
        ) 
        // {
        
            // toast.promise(
        //     pending: 'Enviando datos de baja',
        //     success: {
        //         render({ data }) {
        //             reload()
        //             setOpen(false)
        //             return "Colaborador se ha dado de baja"
        //         },
        //     },
        //     error: 'No se pudo dar de baja al colaborador'
        // }, { autoClose: 2000 })
    }
    return (
        <Dialog open={open} maxWidth="sm" >
            <DialogTitle>Baja de colaborador</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="spam" >
                            Al dar de baja a un colaborador, este no podrá acceder al sistema y se borraran todos los accesos de las plataformas internas
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                renderInput={(props) => <TextField fullWidth {...props} />}
                                value={fecha}
                                fullWidth
                                format='YYYY-MM-DD'
                                label="Fecha de baja"
                                onChange={(newValue) => setFecha(newValue)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Motivo de baja"
                            multiline
                            rows={2}
                            variant="outlined"
                            onChange={(e) => setMotivoBaja(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancelar</Button>
                <Button onClick={() => baja()}>Confirmar</Button>
            </DialogActions>
        </Dialog>

    )
}

export default ModalBajaColaborador