import React from "react"
import Skeleton from "@mui/material/Skeleton"
import Grid from "@mui/material/Unstable_Grid2"

function SkeletoCajaAhorro({ size = 3 }) {
	return (
		<Grid xs={size}>
			<Skeleton
				//sx={{ bgcolor: "grey.900" }}
				variant="rectangular"
				//width={}
				height={500}
			/>
		</Grid>
	)
}

export default SkeletoCajaAhorro
