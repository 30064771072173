import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect, useMemo, useState } from "react"

// Card Component
import Typography from "@mui/material/Typography"
//icons
// componentes
import { LoadSimpleTable } from "#common/LoadingView"
import { UseColaborador } from "../UseColaborador"
import { CardColaborador } from "./cardComponents/CardColaborador"
import { ColaboradorTools } from "./ColaboradorTools"

const MiEquipoCards = ({ colaborador }) => {
	const { id } = colaborador
	const [colaboradoresCargo, setColaboradoresCargo] = useState(false)
	const [colaboradoresFilter, setColaboradoresFilter] = useState([])
	const [actualizar, setActualizar] = useState(false)
	const reload = () => setActualizar(!actualizar)

	const params = useMemo(() => {
		let params = {}
		if (colaboradoresCargo) params["sub_colaboradores"] = true
		return params
	}, [colaboradoresCargo])

	const { colaboradores, loading } = UseColaborador(`${id}/my_team`, {
		params,
		actualizar: actualizar,
	})

	useEffect(() => {
		setColaboradoresFilter(colaboradores)
	}, [colaboradores])

	const cardsColaboradores = useMemo(() => {
		const cards = colaboradoresFilter.map((colaborador, index) => (
			<Grid2
				key={colaborador.id}
				// xs={12}
				// md={6}
				// lg={4}
				width={"50%"}
				minWidth={"500px"}
				maxWidth={"500px"}
			>
				<CardColaborador
					colaborador={colaborador}
					setActualizar={setActualizar}
					reload={reload}
				/>
			</Grid2>
		))
		if (cards.length === 0)
			return [
				<Typography
					key={"no_colaboradores"}
					variant="h6"
					sx={{ textAlign: "center" }}
				>
					No hay colaboradores
				</Typography>,
			]
		else return cards
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colaboradoresFilter])

	if (loading) return <LoadSimpleTable />

	return (
		<Grid2
			container
			spacing={2}
			justifyContent={"center"}
		>
			<Grid2 xs={12}>
				<ColaboradorTools
					colaborador={colaborador}
					colaboradores={colaboradores}
					setColaboradoresFilter={setColaboradoresFilter}
					setColaboradoresCargo={setColaboradoresCargo}
					colaboradoresCargo={colaboradoresCargo}
					setActualizar={setActualizar}
				/>
			</Grid2>

			{cardsColaboradores}
		</Grid2>
	)
}

export default MiEquipoCards
