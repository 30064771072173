import axios from "axios"
import { toast } from "react-toastify"
const URL_API = process.env.REACT_APP_SERVER_URL
const token = localStorage.getItem("token")

/**
 * Realiza una solicitud GET a la API para obtener datos.
 *
 * Esta función envía una solicitud GET a la URL especificada de la API para obtener datos y
 * almacena los resultados en el estado utilizando un useState. Puede aplicarse un filtro opcional.
 *
 * @async
 * @param {string} URL - La URL de la API desde la cual obtener los datos.
 * @param {string} token - Las credenciales de autenticación para la API.
 * @param {function} set_db - Función para almacenar los datos obtenidos en el estado.
 * @param {string} [filtro=""] - Filtro opcional para la API, debe ser una cadena que comience con '?'.
 * @param {function} [skeleton=() => {}] - Función de setState para desactivar el esqueleto cambiando el estado a true (opcional).
 * @returns {Array} - Un array con los datos obtenidos de la API.
 */
export async function get(
	URL,
	token,
	set_db = () => {},
	filtro = {},
	skeleton = () => {},
) {
	try {
		let response = await axios.get(`${URL}`, {
			headers: { Authorization: "Token " + token },
			params: filtro,
		})
		set_db(response.data.data)
		skeleton(true)

		return response.data
	} catch (error) {
		toast.error(`Error: ${error} `, {
			position: "top-right",
		})
		console.log(error.response)
		console.error(error.response.data)
		return false
	}
}
/**
 * Realiza una llamada a una API notificando con los parámetros proporcionados.
 *
 * @param {Object} options - Opciones de la función.
 * @param {string} options.URL - URL de la API a la que realizar la llamada.
 * @param {string} options.token - Token de autenticación para la API.
 * @param {Object} [options.params={}] - Parámetros adicionales para la consulta a la API.
 * @param {Function} [options.set_db=() => {}] - Función para establecer la base de datos con los resultados.
 * @param {Function} [options.skeleton=() => {}] - Función opcional para manejar la carga inicial.
 *
 * @returns {Promise<void>} - Una Promesa que se resuelve cuando la operación se completa.
 *
 */

/**
 * Realiza una solicitud POST a la API para enviar nuevas solicitudes.
 *
 * @async
 * @param {string} URL - La URL de la API a la que se realizará la solicitud.
 * @param {string} token - Las credenciales de autenticación para la API.
 * @param {object} datos - Los datos que se enviarán en la solicitud POST.
 * @param {object} [params={}] - Parámetros adicionales de la solicitud (opcional).
 * @param {*} [actualizar=""] - Valor opcional para realizar una actualización después de la solicitud.
 * @param {function} [setActualizar=()=>{}] - Función opcional para realizar una actualización después de la solicitud.
 */
export const post = async (
	URL,
	token,
	datos,
	params = {},
	actualizar = "",
	setActualizar = () => {},
) => {
	try {
		const request = await toast.promise(
			axios.post(URL, datos, {
				headers: { Authorization: "Token " + token },
				params: params,
			}),
			{
				pending: "Mandando solicitud",
				success: {
					render({ data }) {
						setActualizar(!actualizar)
						if (!!data.data.response) return `${data.data.response}`
						else return `${data.data.message}`
					},
				},
				error: {
					render({ data }) {
						console.log(data.response)
						if (!!data.response.data.mensaje) {
							console.error(data.response.data.mensaje)
							return `${data.response.data.mensaje}`
						} else {
							console.error(data.response.data.message)
							return `${data.response.data.message}`
						}
					},
				},
			},
		)
		return request.data
	} catch (error) {
		console.log(error)
		return false
	}
}

/**
 * Método PUT para enviar modificaciones a la API.
 *
 * Envía una solicitud PUT a la dirección de la API y muestra notificaciones mediante toasts
 * para indicar los diferentes estados durante el proceso de envío.
 *
 * @async
 * @param {string} URL_API - La URL de la API a la que se enviará la solicitud.
 * @param {string} token - El token de autenticación para la solicitud.
 * @param {object} datos - Los datos a enviar en el cuerpo de la solicitud.
 * @param {{}} [params={}] - Parámetros adicionales para la solicitud (opcional).
 * @param {boolean} [actualizar=true] -UseState indica el estado actual de actualizar (opcional).
 * @param {() => void} [setActualizar=() => {}] -UseState Función para actualizar después de la respuesta exitosa (opcional).
 * @returns {void} No devuelve un valor concreto.
 */
export const put = async (
	URL_API,
	token,
	datos,
	params = {},
	actualizar = true,
	setActualizar = () => {},
) => {
	try {
		const request = await toast.promise(
			axios.put(URL_API, datos, {
				headers: { Authorization: "Token " + token },
				params: params,
			}),
			{
				pending: "Actualizando solicitud",
				success: {
					render({ data }) {
						setActualizar(!actualizar)
						if (!!data.data.response) return `${data.data.response}`
						else return `${data.data.message}`
					},
				},
				error: {
					render({ data }) {
						if (!!data.response.data.mensaje) {
							console.error(data.response.data.mensaje)
							return `${data.response.data.mensaje}`
						} else {
							console.error(data.response.data.message)
							return `${data.response.data.message}`
						}
					},
				},
			},
		)
		return request.data
	} catch (error) {
		console.log(error)
		return false
	}
}
/**
 * Realiza una solicitud DELETE a la API para eliminar un curso.
 *
 * Esta función envía una solicitud DELETE a la dirección de la API especificada y muestra notificaciones
 * mediante toasts para indicar si el curso ha sido eliminado con éxito.
 *
 * @async
 * @param {string} URL_API - La URL de la API a la que se enviará la solicitud DELETE.
 * @param {string} token - El token de autenticación para la solicitud.
 * @param {object} [params={}] - Parámetros adicionales opcionales para la solicitud DELETE.
 * @param {boolean} [actualizar=true] - Indica el estado actual de actualizar (opcional).
 * @param {function} [setActualizar=() => {}] - Función para actualizar después de una respuesta exitosa (opcional).
 */
export const deleteApi = async (
	URL_API,
	token,
	params = {},
	actualizar = true,
	setActualizar = () => {},
) => {
	try {
		const request = await toast.promise(
			axios.delete(URL_API, {
				headers: { Authorization: "Token " + token },
				params: params,
			}),
			{
				pending: "Eliminando ",
				success: {
					render({ data }) {
						setActualizar(!actualizar)
						return `${data.data.message}`
					},
				},
				error: {
					render({ data }) {
						console.error(data.response.data.mensaje)
						return `${data.response.data.mensaje}`
					},
				},
			},
		)
		return request.data
	} catch (error) {}
}

//Version 2 -------------------------------------------------------------------------------------------------------------------------

/**
 * @typedef {Object} MessageOptions
 * @property {string} pending - Mensaje a mostrar mientras la solicitud está en curso.
 * @property {string} [success] - Mensaje a mostrar si la solicitud se completa con éxito.
 * @property {string} [error] - Mensaje a mostrar en caso de error durante la solicitud.
 */

export function notificationPromise(
	request,
	message = { pending: "Enviando", success: false, error: false },
) {
	const { pending = "...Enviando", success = false, error = false } = message
	try {
		toast.promise(request, {
			pending,
			success: {
				render({ data }) {
					if (success) return success
					return `${data.data.message}`
				},
			},
			error: {
				render({ data }) {
					if (error) return error

					if (!!data.response.data.message) return data.response.data.message

					return `${data.message}`
				},
			},
		})
	} catch (error) {}
}
export async function getApiGeneral(
	URL,
	token,
	set_db = () => {},
	filtro = {},
) {
	try {
		let response = await axios.get(`${URL}`, {
			headers: { Authorization: "Token " + token },
			params: filtro,
		})
		set_db(response.data.data)
		return response.data
	} catch (error) {
		toast.error(`Error: En obtener los datos`)
		console.log(error)
		return { success: false }
	}
}
export const apiPostGeneral = async (
	URL,
	token,
	datos,
	params = {},
	message = { pending: "Mandado solicitud" },
) => {
	try {
		let request = axios.post(URL, datos, {
			headers: { Authorization: "Token " + token },
			params: params,
		})
		notificationPromise(request, message)
		request = await request
		return request.data
	} catch (error) {
		console.log(error)
		return { success: false }
	}
}

export const apiPutGeneral = async (
	URL_API,
	token,
	datos,
	params = {},
	message = { pending: "Mandado solicitud" },
) => {
	try {
		let request = axios.put(URL_API, datos, {
			headers: { Authorization: "Token " + token },
			params: params,
		})
		notificationPromise(request, message)
		request = await request
		return request.data
	} catch (error) {
		console.log(error)
		return { success: false }
	}
}

/**
 * Realiza una solicitud DELETE a una API general con opciones de mensajes y manejo de errores.
 *
 * @param {string} URL_API - La URL de la API a la que se enviará la solicitud DELETE.
 * @param {string} token - El token de autenticación a incluir en la solicitud.
 * @param {Object} params - Parámetros adicionales a enviar en la solicitud DELETE (opcional).
 * @param {MessageOptions} [message] - Opciones de mensajes para indicar el estado de la operación (opcional).
 * @returns {Promise} Una promesa que se resuelve con la respuesta de la solicitud DELETE o se rechaza en caso de error.
 */
export const apiDeleteGeneral = async (
	URL_API,
	token,
	params = {},
	message = {
		pending: "eliminando",
	},
) => {
	try {
		let request = axios.delete(URL_API, {
			headers: { Authorization: "Token " + token },
			params: params,
		})
		notificationPromise(request, message)
		request = await request
		return request.data
	} catch (error) {
		console.log(error)
		return { success: false }
	}
}

/**
 * Realiza una solicitud GET para obtener datos de solicitudes de pagos.
 * @param {Object} params - Parámetros de configuración de la solicitud.
 * @param {string} [params.url=""] - URL para la solicitud. Por defecto, una URL predeterminada.
 * @param {string} params.token - Token de autenticación para la solicitud.
 * @param {Function} params.setDB - Función para actualizar la base de datos con los datos recibidos.
 * @param {Object} [params.params={}] - Parámetros adicionales para la solicitud.
 * @param {AbortController|null} [params.controller=null] - Controlador de aborto para cancelar la solicitud.
 * @returns {Promise} - Retorna una promesa con los datos de las solicitudes de pagos.
 */
export async function getApiGeneralV2(options) {
	const {
		url = "", // Valor predeterminado para url
		token = "",
		setDB = () => {},
		params = {},
		controller = null,
		loading = () => {},
	} = options
	try {
		let response = await axios.get(`${url}`, {
			headers: { Authorization: "Token " + token },
			params: params,
			signal: controller?.signal,
		})
		setDB(response.data.data)
		loading()
		return response.data
	} catch (error) {
		if (axios.isCancel(error)) {
			// Si el error es de cancelación, puedes manejarlo aquí
			// toast.error("La solicitud fue cancelada")
		} else {
			// Si es otro tipo de error, puedes manejarlo aquí
			console.log("Error:", error.message)
			toast.error("Error al obtener los datos")
		}
	}
}

const gestor_rh = axios.create({
	baseURL: URL_API + "rh/",
	headers: {
		Authorization: "Token " + token,
		// "Content-Type": "application/json",
	},
})

export function get_rh_api(
	url,
	setData = () => {},
	loading = () => {},
	params = {},
) {
	return gestor_rh
		.get(url, params)
		.then((response) => {
			loading()
			setData(response?.data?.data)
			return response?.data
		})
		.catch((error) => {
			console.log("Error:", error.message)
			toast.error("Error al obtener los datos")
		})
}

export const post_rh_api = async (
	URL,
	datos,
	params = {},
	message = { pending: "Mandado solicitud" },
) => {
	try {
		let request = gestor_rh.post(URL, datos, params)
		notificationPromise(request, message)
		request = await request
		return request.data
	} catch (error) {
		console.log(error)
		return { success: false }
	}
}
/**
 *
 * @param {string} url
 * @param {object} datos
 * @param {Object} settings - Objeto de configuración.
 * @param {axios} settings.axiosC
 * @returns
 */
export const put_rh = async (url, datos, settings = {}) => {
	const { axios = {}, message = {} } = settings
	try {
		const request = gestor_rh.put(url, datos, axios)
		notificationPromise(request, message)
		const response = await request
		return response.data
	} catch (error) {
		console.log(error)
		return { success: false }
	}
}
