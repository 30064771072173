import { promotionDetailPut } from "#api/Api/promotions"
import { Box, FormControlLabel, Stack, Switch, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { usePromotionsContext } from "../../PromotionContext"
import {
	FormularioNewVacante,
	validation,
} from "Components/Vacantes/FormularioVacante/FormularioVacante"
import { Checkbox } from "@mui/material"

export function ResponseTrue({ setMaxWidth, setButtons }) {
	const {
		response,
		promotionDetail,
		setOpenConfirm: setOpen,
		reload,
	} = usePromotionsContext()
	const colaboradores = [
		{
			id: promotionDetail.authorization,
			full_name: promotionDetail.authorization_name,
		},
		{
			id: promotionDetail.colaborador_id,
			full_name: promotionDetail.colaborador_name,
		},
	]
	const sucursales = [
		{ id: promotionDetail.sucursal_id, nombre: promotionDetail.sucursal_name },
	]
	const perfiles = [
		{
			id: promotionDetail.old_profile_id,
			nombre: promotionDetail.old_profile_name,
		},
	]
	const [sendResponse, setSendResponse] = useState({
		authorized: true,
		new_vacante: false,
	})

	const success = useMemo(
		() => (response) => {
			if (response) {
				setOpen(false)
				reload()
			}
			return false
		},
		[reload, setOpen],
	)

	const onClick = useCallback(() => {
		if (sendResponse.new_vacante && !validation(sendResponse)) {
			return
		}
		promotionDetailPut(promotionDetail.id, sendResponse, { success })
	}, [promotionDetail, sendResponse, success])
	useEffect(() => {
		if (response === true)
			setButtons([
				{
					label: "Aceptar",
					onClick,
				},
			])
	}, [onClick, response, setButtons])

	if (!response) return null
	return (
		<Box>
			<Stack
				spacing={3}
				alignItems={"center"}
			>
				<Typography
					whiteSpace="pre-line"
					textAlign={"center"}
				>
					Al aceptar, el puesto de{" "}
					<strong>{promotionDetail.old_profile}</strong> quedará vacío.
					{/* {"\n"}¿Deseas abrir un nuevo requerimiento? */}
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							checked={sendResponse.new_vacante}
							onClick={(event) => {
								event.stopPropagation()
								setMaxWidth(event.target.checked ? "xl" : "xs")
								setSendResponse((response) => ({
									...response,
									new_vacante: event.target.checked,
								}))
							}}
						/>
					}
					label="¿Deseas abrir un nuevo requerimiento?"
				/>

				{sendResponse.new_vacante && (
					<FormularioNewVacante
						setData={setSendResponse}
						promotion
						data={promotionDetail}
						list={{ colaboradores, sucursales, perfiles }}
					/>
				)}
			</Stack>
		</Box>
	)
}

// function validation(data) {
// 	const vacante = data?.vacante || {}
// 	const fields = [
// 		"sucursal_id",
// 		"perfil_puesto_id",
// 		"jefe_directo_id",
// 		"tipo_vacante",
// 		"sueldo_mensual",
// 		"bono_trimestral",
// 		"garantia",
// 		"duracion",
// 	]
// 	for (const field in fields) {
// 		const value = vacante[fields[field]]
// 		if (value === "" || value < 0 || !value) {
// 			toast.error(`se necesita todos los campo de la vacante.`, {})
// 			return false
// 		}
// 	}
// 	return true
// }
