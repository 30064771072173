import CastForEducationIcon from "@mui/icons-material/CastForEducation"
import LaptopIcon from "@mui/icons-material/Laptop"
import SchoolIcon from "@mui/icons-material/School"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import TranslateIcon from "@mui/icons-material/Translate"
import WorkIcon from "@mui/icons-material/Work"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import ListCompetencia from "../ListCompetencia"

const Competencias = ({ dataPerfil, setDataSend }) => {
	const competencias = [
		{
			label: "Educación",
			id: "Educación",
			listName: "educacion",
			Iconlist: SchoolIcon,
		},
		{
			label: "Formación y Capacitación",
			id: "Capacitación",
			listName: "capacitacion",
			Iconlist: CastForEducationIcon,
		},
		{
			label: "Experiencia (tiempo y/o area)",
			id: "Experiencia",
			listName: "experiencia",
			Iconlist: WorkIcon,
		},
		{
			label: "Idiomas",
			id: "Idiomas",
			listName: "idiomas",
			Iconlist: TranslateIcon,
		},
		{
			label: "Software",
			id: "Software",
			listName: "software",
			Iconlist: LaptopIcon,
		},
		{
			label: "Adicionales",
			id: "Adicionales",
			listName: "adicionales",
			Iconlist: ThumbUpIcon,
		},
	]
	return (
		<Grid
			container
			spacing={2}
		>
			{competencias.map((props) => (
				<ItemCompetencia
					key={props.label}
					{...{ ...props, dataPerfil, setDataSend }}
				/>
			))}
		</Grid>
	)
}

export default Competencias

const ItemCompetencia = ({
	label,
	id,
	dataPerfil,
	listName,
	setDataSend,
	Iconlist,
}) => {
	const [text, setText] = useState("")

	const [list, setList] = useState(
		dataPerfil == null
			? []
			: dataPerfil.perfil_competencia.filter((item) => item.tipo === id),
	)

	const addItem = (list, item) => {
		setList((oldList) => [...oldList, { id: 0, tipo: id, descripcion: item }])
		setText("")
	}

	const removeItem = (list, indexList) => {
		setList((oldList) => oldList.filter((item, index) => index !== indexList))
	}

	useEffect(() => {
		setDataSend((oldData) => ({ ...oldData, [`list_${listName}`]: list }))
	}, [list, listName, setDataSend])

	return (
		<ListCompetencia
			titulo={`${label}*`}
			list={list}
			nameList={id}
			addItem={addItem}
			removeItem={removeItem}
			textValue={text}
			setTextValue={setText}
			Iconlist={Iconlist}
		/>
	)
}
