import VerifiedIcon from '@mui/icons-material/Verified';
import WarningIcon from '@mui/icons-material/Warning';
import { Grid, createTheme } from '@mui/material/';
import { UseColaborador } from 'Components/Colaboradores/UseColaborador';
import MUIDataTable from "mui-datatables";
import { useState } from 'react';
import { useSelector } from "react-redux";
import DetalleColaborador from '../Components/Colaboradores/DetalleColaborador';
import { LoadViewTable } from '../Components/Common/LoadingView';
import { RED_COLOR } from '../Constants/Styles';
import { ChangeTitle } from 'Components/menu/funciones';

const Colaboradores = () => {
    ChangeTitle("Colaboradores")
    const reloadTable = useSelector(state => state.page.reloadTable);
    const { colaboradores, loading } = UseColaborador("all", { reload: reloadTable })

    const [gridSize, setGridSize] = useState([12, 0])
    const [idColab, setIdColab] = useState(null)
    const mdTheme = createTheme();
    let contentView = null

    const closeColabView = () => {
        setGridSize([12, 0])
        setIdColab(null)
    }

    const openColabView = (rowData) => {
        if (idColab !== 0 && idColab === rowData[0]) {
            closeColabView()
            return
        }
        setGridSize([3, 9])
        setIdColab(rowData[0])
    }

    const options = {
        selectableRowsHideCheckboxes: true,
        print: false,
        viewColumns: false,
        onRowClick: (rowData) => openColabView(rowData),
    };


    const optionCellGeneral = {
        customBodyRender: renderCustomBody
    }
    function renderCustomBody(value, tableMeta) {
        let color = tableMeta.rowData[6] === null ? '' : RED_COLOR;
        return <span style={{ color: color }}>{value}</span>;
    }


    function styleName(value, activo, verificado) {
        let color = activo === null ? '' : RED_COLOR;
        let icon = null
        if (verificado === false)
            icon = <WarningIcon sx={{ fontSize: 18, marginRight: "5px", color: "#B3BE20" }} />
        else
            if (verificado)
                icon = <VerifiedIcon sx={{ fontSize: 18, marginRight: "5px", color: "#49A7A7" }} />

        return (
            <div style={{
                display: 'flex',
                color: color
            }}>
                {icon} <span>{value}</span>
            </div>
        );
    }

    const columns = [
        {
            name: 'id',
            label: 'id',
            options: {
                display: false,
                filter: false,
                sort: true,

            },
        },
        {
            name: "nombre_completo",
            label: "Nombre",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return styleName(
                        value,
                        tableMeta.rowData[6],
                        tableMeta.rowData[7]
                    )
                }
            }
        },
        {
            name: "sucursal",
            label: "Sucursal",
            options: {
                filter: true,
                sort: true,
                display: idColab == null
                , ...optionCellGeneral,
            }
        },
        {
            name: "centro_costos",
            label: "Departamento",
            options: {
                filter: true,
                sort: true,
                display: idColab == null
                , ...optionCellGeneral,
            }
        },
        {
            name: "perfil_puesto",
            label: "Puesto",
            options: {
                filter: true,
                sort: true,
                display: idColab == null
                , ...optionCellGeneral,
            }
        },
        {
            name: "no_empleado",
            label: "No empleado",
            options: {
                filter: true,
                sort: true,
                display: idColab == null
                , ...optionCellGeneral,
            }
        },
        {
            name: 'fecha_baja', label: 'Activo',
            options: {
                display: false,
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return value == null ? "Activo" : "Baja"
                },
            },
        },
        {
            name: 'verificado',
            label: 'Verificado',
            options: {
                display: false,
                filter: true,

                sort: false,
                customBodyRender: (value, tableMeta) => {
                    let is_verify = "No Verificado"
                    if (value)
                        is_verify = "Verificado"
                    else if (value === false)
                        is_verify = "Por Verificar"
                    return is_verify
                },

            },
        }

    ];

    if (loading) contentView = <LoadViewTable />
    else contentView = <MUIDataTable
        data={colaboradores}
        columns={columns}
        options={options}
    />
    return (
        <Grid container spacing={5}>
            <Grid item md={gridSize[0]} align='center'
                style={{
                    transition: mdTheme.transitions.create("all", {
                        easing: mdTheme.transitions.easing.sharp,
                        duration: mdTheme.transitions.duration.leavingScreen,
                    })
                }}>
                {contentView}
            </Grid>
            <Grid item md={gridSize[1]}
                style={{
                    transition: mdTheme.transitions.create("all", {
                        easing: mdTheme.transitions.easing.sharp,
                        duration: mdTheme.transitions.duration.leavingScreen,
                    })
                }}>
                <DetalleColaborador idColaborador={idColab} />
            </Grid>
        </Grid>
    )
}

export default Colaboradores
