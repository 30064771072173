import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"

//Componentes
import { apiGetAsset2, apiPostAsset } from "../../Services/Api_inventario"
import { useSolicitudesContext } from "../Colaboradores/Solicitudes/SolicitudesContext"
import { InputFormGrid2, InputSelectFormGrid } from "../Common/Inputs2"

//Material
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { Typography } from "@mui/material"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { styled } from "@mui/material/styles"
import { DialogForm } from "../Common/Dialog"
import { ASSET_TEXT } from "./AssetText"

function AssetFormulario({ open, setOpen }) {
	const token = useSelector((state) => state.user.token)
	const { colaboradorID, actualizarA } = useSolicitudesContext()
	const [policy, setPolicy] = useState(false)
	const [responsive, setResponsive] = useState(false)
	const [buttonSend, setButtonSend] = useState(false)
	const [types, setTypes] = useState([])
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm()

	const onSubmit = async (data) => {
		let datos = new FormData()

		datos.append("colaborador", colaboradorID)
		for (let d in data) {
			if (!!data[d]) {
				datos.append(d, data[d])
			}
		}
		if (policy) datos.append("url_policy", policy)
		if (responsive) datos.append("url_responsive", responsive)
		setButtonSend(true)
		let response = await apiPostAsset(
			"ASSET_COLABORADOR",
			token,
			datos,
			{},
			{ pending: "Enviando solicitud" },
		)
		if (response["success"]) {
			setOpen(false)
			actualizarA()
		}
		setButtonSend(false)
	}
	useEffect(() => {
		apiGetAsset2("ASSET_TYPE", token, setTypes)
	}, [token])

	useEffect(() => {
		reset({
			name: "",
			model: "",
			brand: "",
			serial: "",
			description: "",
			fresh_service_id: "",
			phone_number: "",
			active: true,
			type_asset: "",
		})
		setPolicy(false)
		setResponsive(false)
	}, [open, reset])

	const responsiveUpload = (event) => {
		const file = event.target.files[0]
		setResponsive(file)
	}
	const policyUpload = (event) => {
		const file = event.target.files[0]
		setPolicy(file)
	}
	//rh id 2
	let viewInput = watch("type_asset") === 2

	return (
		<DialogForm
			title="Agregar equipo"
			open={open}
			setOpen={setOpen}
			buttonText={ASSET_TEXT.BUTTON}
			accept={handleSubmit(onSubmit)}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					spacing={2}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<InputFormGrid2
						id="name"
						label={ASSET_TEXT.NAME}
						multiline
						register={register}
						error={errors}
						registerParams={{
							required: true,
							maxLength: {
								value: 150,
								message: "Máximo 255 caracteres ",
							},
						}}
						propsG={{
							xs: 12,
						}}
					/>
					<InputFormGrid2
						id="model"
						label={ASSET_TEXT.MODEL}
						multiline
						register={register}
						error={errors}
						registerParams={{
							required: true,
							maxLength: {
								value: 150,
								message: "Máximo 255 caracteres ",
							},
						}}
						propsG={{
							xs: 12,
						}}
					/>
					<InputFormGrid2
						id="brand"
						label={ASSET_TEXT.BRAND}
						multiline
						register={register}
						error={errors}
						registerParams={{
							required: true,
							maxLength: {
								value: 150,
								message: "Máximo 255 caracteres ",
							},
						}}
						propsG={{
							xs: 12,
						}}
					/>
					<InputFormGrid2
						id="serial"
						label={ASSET_TEXT.SERIAL}
						multiline
						register={register}
						error={errors}
						registerParams={{
							required: true,
							maxLength: {
								value: 150,
								message: "Máximo 255 caracteres ",
							},
						}}
						propsG={{
							xs: 12,
						}}
					/>
					<InputFormGrid2
						id="description"
						label={ASSET_TEXT.DESCRIPTION}
						multiline
						register={register}
						error={errors}
						registerParams={{
							required: false,
						}}
						propsG={{
							xs: 12,
						}}
					/>

					<InputSelectFormGrid
						id={"type_asset"}
						label={ASSET_TEXT.TYPE}
						data={types}
						dataLabel="description"
						control={control}
						error={errors}
						propsG={{ xs: 8 }}
					/>
					<InputFormGrid2
						id="fresh_service_id"
						label={ASSET_TEXT.FRESH}
						type="number"
						register={register}
						error={errors}
						registerParams={{
							required: true,
							maxLength: {
								value: 150,
								message: "Máximo  caracteres 150",
							},
						}}
						propsG={{
							xs: 4,
						}}
					/>
					{viewInput && (
						<InputFormGrid2
							id="phone_number"
							label={ASSET_TEXT.PHONE}
							type="number"
							register={register}
							error={errors}
							registerParams={{
								required: false,
								maxLength: {
									value: 150,
									message: "Máximo 255 caracteres ",
								},
							}}
							propsG={{
								xs: 12,
							}}
						/>
					)}
					<Grid xs={4}>
						<Button
							onChange={responsiveUpload}
							component="label"
							variant="contained"
							startIcon={<CloudUploadIcon />}
						>
							{ASSET_TEXT.FILE}
							<VisuallyHiddenInput
								type="file"
								accept=".pdf"
							/>
						</Button>
						<Typography>{responsive["name"]}</Typography>
					</Grid>
					<Grid xs={4}>
						<Button
							onChange={policyUpload}
							component="label"
							variant="contained"
							startIcon={<CloudUploadIcon />}
						>
							{ASSET_TEXT.FILE_POLICY}
							<VisuallyHiddenInput
								type="file"
								accept=".pdf"
							/>
						</Button>
						<Typography>{policy["name"]}</Typography>
					</Grid>
					<Grid xs={4}>
						<Controller
							name="active"
							control={control}
							rules={{ required: false }}
							render={({ field }) => (
								<FormControlLabel
									error={false}
									control={
										<Checkbox
											defaultChecked
											{...field}
											sx={{ "& .MuiSvgIcon-root": { fontSize: 40 } }}
										/>
									}
									label={ASSET_TEXT.ACTIVATE}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</form>
		</DialogForm>
	)
}

export default AssetFormulario
const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
})
