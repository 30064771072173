// import { InputSelect } from "#common/Inputs2"
import { bajaPut } from "#api/Api/baja"
import { InputSelect } from "#common/input/InputSelect"
import { TERMINATION } from "#constants/StatusRequest "
import { Stack } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import {
	ERROR_TYPE_1,
	ERROR_TYPE_2,
	ERROR_TYPE_3,
	SEND_BUTTON,
	TYPE_1,
	TYPE_2,
	TYPE_3,
} from "../../TextBaja"
import UseBajas from "../../UseBajas"
import { UseDialogBajaContext } from "./DialogBajaContext"

export default function ApproveTermination({
	hidden = true,
	onChange = () => {},
}) {
	const { setButtonsDialog, baja, reload, closeDialog } = UseDialogBajaContext()
	const { termination_types } = UseBajas("termination_types", {
		disable: hidden,
		nameData: "termination_types",
	})
	const [selected, setSelected] = useState({
		type1: "",
		type2: "",
		type3: "",
	})

	const sendResponse = useCallback(() => {
		//validación de los datos
		for (const type_n in selected) {
			if (selected[type_n] === "") {
				switch (type_n) {
					case "type1":
						toast.error(ERROR_TYPE_1)
						break
					case "type2":
						toast.error(ERROR_TYPE_2)
						break
					default:
						toast.error(ERROR_TYPE_3)
				}
				return false
			}
		}
		//Formateo de los datos
		const { type1, type2, type3 } = selected
		const data = {
			id: baja.id,
			termination_status: TERMINATION.VOBO_RH,
			termination_type_1: type1,
			termination_type_2: type2,
			termination_type_3: type3,
		}
		// Success
		const success = () => {
			reload()
			closeDialog()
		}
		//send
		bajaPut(baja.id, data, { success })
	}, [baja.id, closeDialog, reload, selected])

	useEffect(() => {
		if (!hidden)
			setButtonsDialog([
				{
					label: SEND_BUTTON,
					onClick: sendResponse,
				},
			])
	}, [hidden, sendResponse, setButtonsDialog])

	const dependencyFilter = useCallback(
		(id) =>
			termination_types.filter(
				(baja) => baja?.termination_dependency_id === id,
			),
		[termination_types],
	)

	const type1 = useMemo(() => dependencyFilter(null), [dependencyFilter])

	const type2 = useMemo(
		() => dependencyFilter(selected.type1),
		[dependencyFilter, selected.type1],
	)

	const type3 = useMemo(
		() => dependencyFilter(selected.type2),
		[dependencyFilter, selected.type2],
	)

	const changeType_1 = (value) =>
		setSelected({
			...selected,
			type1: value,
			type2: "",
			type3: "",
		})

	const changeType_2 = (value) => {
		setSelected({ ...selected, type2: value, type3: "" })
	}

	const changeType_3 = (value) => setSelected({ ...selected, type3: value })

	useEffect(() => {
		if (!hidden) onChange(selected)
	}, [hidden, onChange, selected])

	if (hidden) return null
	return (
		<Stack direction={"row"}>
			<InputSelect
				label={TYPE_1}
				data={type1}
				value={selected.type1}
				setValue={changeType_1}
				dataId="id"
				dataLabel="description"
				menuItemProps={{
					sx: {
						whiteSpace: "normal",
					},
				}}
			/>
			<InputSelect
				label={TYPE_2}
				data={type2}
				value={selected.type2}
				setValue={changeType_2}
				dataId="id"
				dataLabel="description"
				formControlProps={{ width: "100%" }}
				disabled={!selected.type1}
				menuItemProps={{
					sx: {
						whiteSpace: "normal",
					},
				}}
			/>
			<InputSelect
				label={TYPE_3}
				data={type3}
				value={selected.type3}
				setValue={changeType_3}
				dataId="id"
				dataLabel="description"
				disabled={!selected.type2}
				menuItemProps={{
					sx: {
						whiteSpace: "normal",
					},
				}}
			/>
		</Stack>
	)
}
