import { useRef } from "react"
import { PRIMARYCOLOR } from "../../../../Constants/Styles"
//API
//componentes
//material ui
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { UseColaborador } from "./UsoMovimientos"

export const Movimiento = ({ colaborador, perfilPuesto, tableData, key }) => {
	const { id } = colaborador
	const params = useRef({
		fields: ["photo", "area", "perfil_puesto"],
	})
	const { colaboradores, loading } = UseColaborador(id, params.current)
	if (loading)
		return (
			<TableRow key={key}>
				<TableCell
					colSpan={tableData.length}
					sx={{ textAlign: "center" }}
				>
					<CircularProgress />
				</TableCell>
			</TableRow>
		)

	return (
		<TableRow key={key}>
			{tableData.map((campo) => {
				if (campo.id === "foto")
					return (
						<TableCell
							component="th"
							scope="row"
						>
							<Box
								component="img"
								sx={{ height: 75 }}
								alt="No se cuenta con fotografía"
								src={colaboradores?.photo}
								onError={(e) => {
									e.target.onerror = null
									e.target.src =
										"https://imagenespublicas.s3.us-east-2.amazonaws.com/usuario.webp"
								}}
							/>
						</TableCell>
					)
				if (campo.id === "nombre")
					return (
						<TableCell
							sx={{ fontSize: 16, color: PRIMARYCOLOR, fontWeight: "bold" }}
						>
							{perfilPuesto[campo.id]}
						</TableCell>
					)
				if (campo.id === "departamento")
					return (
						<TableCell
							sx={{ fontSize: 16, color: PRIMARYCOLOR, fontWeight: "bold" }}
						>
							{colaboradores?.area}
						</TableCell>
					)
				if (campo.id === "puesto")
					return (
						<TableCell
							sx={{ fontSize: 16, color: PRIMARYCOLOR, fontWeight: "bold" }}
						>
							{colaboradores?.perfil_puesto}
						</TableCell>
					)
				return (
					<TableCell
						sx={{ fontSize: 16, color: PRIMARYCOLOR, fontWeight: "bold" }}
					>
						{colaborador[campo.id]}
					</TableCell>
				)
			})}
		</TableRow>
	)
}
