import React from "react"

import Grid from "@mui/material/Unstable_Grid2"
import ResumenCusos from "./ResumenCusos"
import MUIDataTable from "mui-datatables"
const columns = [
	{
		name: "sucursal_id",
		label: "Sucursal Id",
		options: {
			filter: false,
			sort: false,
			display: false,
		},
	},
	{
		name: "sucursal",
		label: "Sucursal",
		options: {
			filter: false,
			sort: false,
		},
	},
	{
		name: "plan",
		label: "Plan",
		options: {
			filter: false,
			sort: false,
		},
	},
	{
		name: "real",
		label: "Real",
		options: {
			filter: false,
			sort: false,
		},
	},
	{
		name: "porcentaje",
		label: "% Porcentaje",
		options: {
			filter: false,
			sort: false,
		},
	},
]

function TablaEstadisticas({ db_data, sucursal, departamento, year, size = 3 }) {
	const options = {
		filter: false,
		download: true,
		print: true,
		selectableRows: "none",
		//filterType: "none",
		rowsPerPage: 50,
		rowsPerPageOptions: [],
		tableBodyHeight: "100%",
		viewColumns: false,
		expandableRowsHeader: false,
		expandableRows: true,
		renderExpandableRow: (rowData, rowMeta) => {
			return (
				<React.Fragment>
					<tr>
						<td
							colSpan={5}
							style={{ padding: 10 }}
						>
							<ResumenCusos
								sucursal={rowData[0] ? [rowData[0]] : sucursal}
								departamento={departamento}
								year={year}
							/>
						</td>
					</tr>
				</React.Fragment>
			)
		},
	}

	return (
		<Grid xs={size}>
			<MUIDataTable
				title={"Cursos"}
				data={db_data}
				columns={columns}
				options={options}
			/>
		</Grid>
	)
}

export default TablaEstadisticas
