import { useState, useEffect } from 'react'
import { LoadViewTable, LoadViewDetail } from '../Components/Vacantes/LoadViews';
import ModalNewPerfil from '../Components/PerfilesPuesto/ModalNewPerfil';
import DetallePuesto from '../Components/PerfilesPuesto/DetallePuesto';
import { getPerfiles, getPerfilDetail } from '../Services/Api';
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import { Grid, IconButton, createTheme } from '@mui/material/';
import { Add } from '@mui/icons-material/';
import { changeTitle } from '../app/pageSlice';
import { Toaster, toast } from 'sonner'


const PerfilesPuesto = () => {
    const [openModal,setOpenModal]       = useState(false) 
    const [gridSize,setGridSize]         = useState([6, 6])
    const [idPerfil,setIdPerfil]         = useState(0)
    const [loadPerfil,setLoadPerfil]     = useState(false) 
    const [loadList,setLoadList]         = useState(true)
    const [dataPerfiles,setDataPerfiles] = useState([]) 
    const [listPerfiles,setListPerfiles] = useState([]) 
    const [dataPerfil,setDataPerfil]     = useState(null)
    const token = useSelector(state => state.user.token);
    const reload   = useSelector(state => state.page.reloadVacante);
    const dispatch = useDispatch()
    const mdTheme = createTheme();
    var contentView = <></>
    var contentDetail = <></>

    useEffect(()=>{
        dispatch(changeTitle('Perfiles de puesto'))
        getPerfiles(token).then((result)=>{
            var arrayPerfiles = []
            result.map( perfil => arrayPerfiles.push([perfil.id,perfil.nombre,perfil.centro_costos__descripcion]) )
            setListPerfiles(arrayPerfiles)
            setDataPerfiles(result)
        }).catch((error)=>{ toast.error(error) })
        .finally(()=> setLoadList(false) )
    },[])

   

    const columns = [
        { name: 'id', label: 'id', options: { display: false, filter: false, sort: true, }, },
        "Nombre Perfil", 
        {
            name: "Departamento",
            options: {
                filter: true,
                sort: true,
                display: idPerfil == 0
            }
    },];

    const openPerfilView = (id) =>{
        if(idPerfil !== 0 && idPerfil === id){
            closePerfilView()
            return
        }
        setGridSize([3,9]) 
        setIdPerfil(id)
        
    }

    useEffect(()=>{ // obtener detalle de la vacante
       setDataPerfil(null)
        if(idPerfil == 0 || idPerfil == null) return
        setGridSize([3,9]) 
        setLoadPerfil(true)
        getPerfilDetail(token, idPerfil).then(response => setDataPerfil(response)
        ).catch(error => alert(error)
        ).finally(()=> setLoadPerfil(false))
    }, [idPerfil, reload])

    const closePerfilView = () =>{
        setGridSize([6,6])
        setIdPerfil(0)
    }

    const options = {
        selectableRowsHideCheckboxes:true,
        print:false,
        viewColumns:false,
        onRowClick:(rowData)=>openPerfilView(rowData[0]),
        customToolbar: () => {
            return (
                <IconButton style={{ order: -1 }} onClick={()=> setOpenModal(true)}>
                  <Add />
                </IconButton>
            );
          }
      };
    
    contentView = loadList ? <LoadViewTable/> :  <MUIDataTable
                                                    title={""}
                                                    data={listPerfiles}
                                                    columns={columns}
                                                    options={options}
                                                    sx={{with: 400}} />

    if (loadPerfil) 
        contentDetail = <LoadViewDetail />
     else if (dataPerfil !== null)
        contentDetail = <DetallePuesto dataPerfil={dataPerfil} />
    
    return (
        <Grid container spacing={5}>
            <Grid item md={gridSize[0]}
                align='center'
                style={{transition: mdTheme.transitions.create("all", {
                    easing: mdTheme.transitions.easing.sharp, 
                    duration: mdTheme.transitions.duration.leavingScreen,
                 })}}>
               {contentView}
            </Grid>
            <Grid item md={gridSize[1]} 
                style={{transition: mdTheme.transitions.create("all", {
                    easing: mdTheme.transitions.easing.sharp, 
                    duration: mdTheme.transitions.duration.leavingScreen,
                })}}>
               {contentDetail}
            </Grid>
            <ModalNewPerfil open={openModal} setOpen={setOpenModal} perfiles={dataPerfiles} />
            <Toaster richColors  />
        </Grid>
    )
}

export default PerfilesPuesto
