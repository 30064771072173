import axios from "axios"
import { saveAs } from "file-saver"
import { apiPostGeneral, apiPutGeneral, getApiGeneralV2 } from "./Api_Call"
import { toast } from "react-toastify"
const URL_API = process.env.REACT_APP_SERVER_URL + "rh/fondo_fijo"

const FONDO_FIJO = `${URL_API}`
/**
 * Realiza una solicitud GET o POST para obtener o enviar datos de solicitudes de pagos.
 * @param {Object} params - Parámetros de configuración de la solicitud.
 * @param {string} [params.url=""] - URL para la solicitud. Por defecto, una URL predeterminada.
 * @param {string} params.token - Token de autenticación para la solicitud.
 * @param {Function} params.setDB - Función para actualizar la base de datos con los datos recibidos.
 * @param {Object} [params.params={}] - Parámetros adicionales para la solicitud.
 * @param {AbortController|null} [params.controller=null] - Controlador de aborto para cancelar la solicitud.
 * @returns {Promise} - Retorna una promesa con los datos de las solicitudes de pagos.
 */
//get
export function apiGetFondoFijo(params) {
	return getApiGeneralV2({ url: FONDO_FIJO, ...params })
}
//post
export function apiPostFondoFijo(token, data, params, message) {
	return apiPostGeneral(FONDO_FIJO, token, data, params, message)
}
//put
export function apiPutFondoFijo(token, data, params, message) {
	return apiPutGeneral(FONDO_FIJO, token, data, params, message)
}
//documentó
export const getDocumentoFondo = async (token, params) => {
	try {
		let response = await axios.get(FONDO_FIJO, {
			headers: { Authorization: "Token " + token },
			params,
			responseType: "blob", // Use arraybuffer for binary data
		})
		let data = response.data
		saveAs(data, `Fondo_Fijo.pdf`)
	} catch (error) {
		toast.error("No se pudo bajar el documento")
		console.error("Error fetching data:", error)
	}
}
