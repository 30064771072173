import { createContext, useState } from "react"

export const ActualizarContext = createContext()

export const ActualizarProvider = ({ children }) => {
	const [actualizar, setActualizar] = useState(true)
	const actualizarNow = () => setActualizar(!actualizar)
	return (
		<ActualizarContext.Provider value={{ actualizar, actualizarNow }}>
			{children}
		</ActualizarContext.Provider>
	)
}
